import { pick } from "lodash";
import React from "react";
import { Navigate } from "react-router-dom";
import { FlagInterface } from "../../common/Flags";
import { MeasurementSchedule } from "../../common/MeasurementSchedule";
import { Step } from "../../common/Schema";
import { Key } from "../../common/Selections";
import SelectFlags from "../flags/SelectFlags";
import SelectGoals from "../goals/SelectGoals";
import SelectSettings from "../settings/SelectSettings";

interface Props {
  goals: Selections<Key>;
  subGoals: Selections<Key>;
  complaints: Selections<Key>;
  flags: FlagInterface;
  schedule: MeasurementSchedule;
  autoProtocolId: string;
  researchProjectId: number;
  patientPhone: string;
  currentStep: Step;
}

// The Main component serves as a wrapper around the pages of the Wizard
// for creating a new diary study, and defines functions to navigate between them.
// The Main and Receipt component together form the interface of the CreateNewDiary
// component which falls under the Petra (overview) component. The Main component houses the
// main interface for the user to edit and click through, whereas the Receipt
// component only serves to show and summarize information.
const Main: React.FunctionComponent<Props> = (props) => {
  // On page refresh in later step, start at beginning.
  if (props.currentStep !== "goals" && props.goals.selected.length === 0) {
    return <Navigate to="/wizard/goals" />;
  }

  switch (props.currentStep) {
    case "goals":
      return <SelectGoals {...pick(props, ["goals", "schedule", "subGoals", "complaints"])} />;
    case "flags":
      return <SelectFlags flags={props.flags} />;
    case "settings":
      return (
        <SelectSettings
          {...pick(props, [
            "flags",
            "schedule",
            "goals",
            "subGoals",
            "autoProtocolId",
            "researchProjectId",
            "complaints",
            "patientPhone",
          ])}
        />
      );
    default:
      return <></>;
  }
};

export default Main;
