// -------------- Type Definitions --------------

import { PetraProtocolSubscription, PetraSingleProtocolSubscription } from "./Results";
import { PetraProjectWizardQuery } from "../../../grapqhl";
import { PerDesign } from "./Schema";
import { FlagObject, typedFlags } from "./Flags";
import { mapValues, uniqBy } from "lodash";
import { QUESTIONNAIRE_NAMES } from "./Constants";

export type PetraProtocol = PetraProtocolSubscription["protocol"];
export type PetraSingleProtocol = PetraSingleProtocolSubscription["protocol"];
export type PetraMeasurement = PetraProtocol["measurements"][number];
type FullPetraProtocols = NonNullable<PetraProjectWizardQuery["currentOrganization"]>["researchProject"]["protocols"];

// Function to construct flagObjects from either the full or partial petra protocols queries.
export const getFlags = (protocols: PetraProtocol[] | FullPetraProtocols): PerDesign<FlagObject[]> => {
  const allFlags = uniqBy(
    protocols.flatMap((protocol) => protocol.measurements.flatMap((measurement) => measurement.questionnaires)),
    "key"
  ).flatMap((questionnaire) => questionnaire.flags);
  // convert graphql result to our own typed flags
  const typedAllFlags: FlagObject[] = typedFlags(allFlags);
  // Split the flags up into arrays (one per design).
  return mapValues(QUESTIONNAIRE_NAMES, (name) => typedAllFlags.filter((flag) => flag.key.startsWith(`${name}_`)));
};
