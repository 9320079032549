import { ApolloClient } from "apollo-boost";
import { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { ApolloLink } from "apollo-link";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
import apolloLogger from "apollo-link-logger";
import * as fragmentTypes from "../../fragmentTypes.json";

type Grant = {
  type: string;
  userId: string | null;
  organizationId: string | null;
  dossierId: string | null;
};

// log erors
const logError = (title: string, details: object) => console.error(title, details);

// create error link
const createErrorLink = () =>
  onError(({ graphQLErrors, networkError, operation }) => {
    console.debug(operation);
    if (graphQLErrors) {
      logError("GraphQL - Error", {
        errors: graphQLErrors,
        operationName: operation.operationName,
        variables: operation.variables,
      });
    }
    if (networkError) {
      logError("GraphQL - NetworkError", networkError);
    }
  });

// http link
const createHttpLink = (activeGrant: Grant) =>
  new HttpLink({
    uri: "/graphql",
    credentials: "include",
    headers: {
      "X-Grant-Type": activeGrant.type,
      "X-Grant-OrganizationId": activeGrant.organizationId,
      "X-Grant-DossierId": activeGrant.dossierId,
    },
  });

export const createLink = (activeGrant: Grant) => {
  return ApolloLink.from([apolloLogger, createErrorLink(), createHttpLink(activeGrant)]);
};

export const createCache = () => {
  const data = (fragmentTypes as any).default;
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: data,
  });

  const cache = new InMemoryCache({ fragmentMatcher });
  if (process.env.NODE_ENV === "development") {
    window.secretVariableToStoreCache = cache;
  }
  return cache;
};

export const createClient = (activeGrant: Grant) => {
  const link = createLink(activeGrant);
  const cache = createCache();
  return new ApolloClient({ link, cache });
};

export { ApiResult } from "./ApiResult";
