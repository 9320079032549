/* eslint-disable @typescript-eslint/camelcase */
import { max, clamp, last } from "lodash";
import { BaseChart, BaseSettings } from "./BaseChart";

const LEGEND_MAX_HEIGHT = 100; // 6 lines
const MIN_CHART_HEIGHT = 400; // see calculateHeight

interface UndocumentedLabelOptions {
  labels: {
    allowOverlap: boolean
  }
};

export interface MultiScoreChartSettings extends BaseSettings {
  series: any;
  y_categories: any;
  categories: string[];
}

// Calculate what seems to be a useful height (in most cases) of the whole chart based on current series selection, type and legend height.
// Test on one/few/many series/categories when changing.
function calculateHeight(highchart, ourSettings) {
  let calculatedHeight = 0;
  if (ourSettings.radar) {
    return 500;
  } else if (ourSettings.chart_type === 'bar') {
    calculatedHeight = 200 + 40 * highchart.series.filter((i) => i.visible).length + highchart.legend.clipHeight;
  } else if (ourSettings.chart_type === 'column') {
    calculatedHeight = 360 + highchart.legend.clipHeight;
  }
  return (calculatedHeight > MIN_CHART_HEIGHT) ? calculatedHeight : MIN_CHART_HEIGHT;
}

export class MultiScoreChart extends BaseChart<MultiScoreChartSettings> {
  chart() {
    const ths = this;
    return {
      polar: this.settings.radar,
      type: this.settings.chart_type,
      height: MIN_CHART_HEIGHT, // recalculated on load
      animation: false,
      events: {
        load: function() { ths.onRedrawAndLoad(this) },
        redraw: function() { ths.onRedrawAndLoad(this) }
      }
    }
  }

  onRedrawAndLoad(highchart) {
    const newHeight = calculateHeight(highchart, this.settings);
    if (newHeight != highchart.chartHeight) {
      highchart.update({
        chart: {
          height: newHeight
        }
      })
    }
  }

  pane() {
    return { size: "80%" };
  }

  xAxis(): Highcharts.XAxisOptions & UndocumentedLabelOptions {
    return {
      categories: this.settings.categories,
      tickmarkPlacement: "on",
      lineWidth: 0,
      labels: {
        allowOverlap: true
      }
    };
  }

  yAxis() {
    return Object.assign(super.yAxis(), {
      categories: this.settings.y_categories?.length > 0 ? this.settings.y_categories : undefined,
      gridLineInterpolation: "polygon",
      tickmarkPlacement: this.settings.chart_type === "bar" ? "on" : undefined,
      lineWidth: 0,
      title: () => {
        return { text: "" };
      },
    });
  }

  tooltip() {
    return { shared: true };
  }

  series(): any {
    const lastIdx = this.settings.series.length - 1;

    return this.settings.series.map((series, idx) => {
      if (this.settings.radar) {
        series.pointPlacement = "on";
      }

      if (this.settings.chart_type === "bar" && idx > 1 && idx < lastIdx) {
        series.visible = false;
      }

      return series;
    });
  }

  plotOptions() {
    return {
      series: {
        animation: false, // Remove this line and get a type error...
        minPointLength: this.settings.chart_type === "bar" ? 5 : undefined,
        groupPadding: 0.1, // otherwhise outside margins become really big on large series.
        maxPointWidth: 50, // it gets quite ridiculous with one data-point.
        centerInCategory: this.settings.chart_type === "column" || this.settings.chart_type === "bar" ? true : undefined,
      },
    };
  }

  legend(): Highcharts.LegendOptions {
    return {
      enabled: true,
      itemWidth: 2000, // wider than  half the page, makes one response per line. Overflow is hidden.
      align: "left",
      maxHeight: LEGEND_MAX_HEIGHT,
      symbolWidth: 10,
      symbolHeight: 10,
      symbolRadius: 5,
    };
  }
}
