import { useEpdSession } from "lib/useSession";
import React from "react";

interface Props {
  epdNextUrl: string;
}

export const EditButton: React.FunctionComponent<Props> = ({ epdNextUrl }) => {
  const { organizationKey, dossierId } = useEpdSession();
  return <Link href={epdNextUrl + `/${organizationKey}/${dossierId}/dossier`} />;
};

interface LinkProps {
  href: string;
}

export const Link: React.FunctionComponent<LinkProps & React.HTMLProps<HTMLAnchorElement>> = ({ href }) => {
  return (
    <a
      className="edit-details"
      id="edit-patient-details"
      title={I18n.t("edit_patient_details")}
      data-placement="bottom"
      href={href}
    >
      <span className="icon roqicon-patient-settings" />
    </a>
  );
};
