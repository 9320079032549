import * as React from "react";

interface Props {
  name: string;
  color?: string;
}

export const Icon: React.FunctionComponent<Props> = ({ name, color = "black" }) => {
  return <i className={`icon-${name} icon-${color}`} />;
};
