// rights is array met alle mogelijke rechten
// initialSelected is array met rechten die gebruiker al heeft

import { difference, includes, union } from "lodash";
import { withRailsMountable } from "logic/rails_component";
import React, { useState } from "react";

interface Props {
  rights: string[];
  initialSelected: string[];
}

export const ProfessionalRightsCheckboxes: React.FC<Props> = (props) => {
  const [selection, setSelection] = useState(props.initialSelected);

  const addRight = (right: string) => {
    if (right === "all") {
      setSelection(props.rights);
    } else {
      setSelection((current) => union(current, [right]));
    }
  };

  const removeRight = (right: string) => {
    if (right === "all") {
      setSelection([]);
    } else {
      setSelection((current) => difference(current, [right]));
    }
  };

  const setRight = (right: string, selected: boolean) => {
    if (selected) {
      addRight(right);
    } else {
      removeRight(right);
    }
  };

  return (
    <div className="rights-control">
      <h5>{I18n.t("activerecord.attributes.user.rights")}</h5>
      <div className="rm">
        {props.rights.map((right) => (
          <Checkbox
            key={right}
            right={right}
            checked={includes(selection, right)}
            disabled={includes(selection, "all") && right !== "all"}
            setRight={setRight}
          />
        ))}
      </div>
    </div>
  );
};

interface CheckboxProps {
  right: string;
  disabled?: boolean;
  checked?: boolean;
  setRight: (right: string, selected: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
  const toggleRight = () => {
    props.setRight(props.right, !props.checked)
  }

  return (
    <label className="checkbox">
      <input className="check_boxes optional" id={`professional-rights-${props.right}`}
        value={props.right}
        name="professional[rights][]"
        type="checkbox"
        checked={props.checked}
        disabled={props.disabled}
        onChange={toggleRight}
        />
        <span>{I18n.t(`professional.rights.${props.right}`)}</span>
    </label>
  );
};

export default withRailsMountable(ProfessionalRightsCheckboxes)
