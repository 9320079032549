import React from "react";
import { compact } from "lodash";
import classNames from "classnames";

interface Props {
  selected?: boolean;
  onClick?: () => void;
  id?: string;
  className?: string;
  date?: string | Date; // TODO should ne just date
  icon?: any; // TODO be specific
}

export const TimelineRowWrapper: React.FC<Props> = (props) => {
  return (
    <div className={classNames("timeline-row",
                               props.className, {
                               "selected": props.selected,
                               "clickable": props.onClick})}
         id={props.id}
         onClick={props.onClick}
         style={props.selected ? {display: 'none'} : {}}>
      <div className="timeline-row-timestamp">
        <div className="timeline-row-timestamp-date">
          {props.date && I18n.localize('date.formats.short_with_year', props.date)}
        </div>
      </div>
      <div className="timeline-row-icon">{props.icon}</div>
      <div className="timeline-row-content">{props.children}</div>
    </div>
  )
}
