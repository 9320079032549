import { ListPicker } from "components/ListPicker";
import { withRailsMountable } from "logic/rails_component";
import React from "react";

interface Protocol {
  id: number;
  name: string;
}

interface Props {
  protocols: Protocol[];
  selectedProtocols: Protocol[];
  limitToProtocols: boolean;
}

export const NotificationTemplatesProtocolPicker: React.FC<Props> = (props) => {
  const [checked, setChecked] = React.useState(props.limitToProtocols);

  return (
    <div className="protocol-picker">
      <label>{I18n.t("activerecord.attributes.notification_template.protocols")}</label>
      <label>
        <input
          className="limit-to-protocols"
          type="checkbox"
          checked={checked}
          onChange={() => setChecked((val) => !val)}
        />
        <input type="hidden" name="notification_template[limit_to_protocols]" value={checked ? "on" : "off"} readOnly />
        <span>{I18n.t("activerecord.attributes.notification_template.limit_to_protocols")}</span>
      </label>
      <ListPicker
        items={props.protocols}
        initialSelectedItems={props.selectedProtocols}
        inputName="notification_template[protocol_ids][]"
        itemRenderer={({ item, onSelectItem }) => <Item key={item.id} item={item} onSelectItem={onSelectItem} />}
      />
    </div>
  );
};

const Item: React.FC<{ item: Protocol; onSelectItem: () => void }> = ({ item, onSelectItem }) => {
  return (
    <li className="questionnaire-picker-list-item" id={`protocol-${item.id}`} onDoubleClick={onSelectItem}>
      <span className="name">{item.name}</span>
      <a href="#self" className="select-item" onClick={onSelectItem} title={I18n.t("admin_area.add")}>
        +
      </a>
    </li>
  );
};

export default withRailsMountable(NotificationTemplatesProtocolPicker);
