import React from "react";
import classNames from "classnames";

interface Props {
  label: string;
  isDefault?: boolean;
  disabled?: boolean;
  disabledTip?: string;
  onClick?: () => void;
  className?: string;
}

// A common class for buttons used within Petra (e.g., the navigation buttons
// and buttons on the recent diaries list).
// The main thing added is that it allows for an extra text to show why a button
// is currently disabled.
const Button: React.FunctionComponent<Props> = ({
  label,
  className = "",
  onClick = () => {},
  isDefault = false,
  disabled = false,
  disabledTip = undefined,
}) => {
  return (
    <button className={classNames(className, isDefault && "default")} disabled={disabled} onClick={onClick}>
      {disabled && disabledTip && disabledTip.length > 0 && <span>{disabledTip}</span>}
      {label}
    </button>
  );
};

export default Button;
