// Common functionality for components that handle results

import { getDescriptionForAnswer, getQuestion, getValue } from "logic/ResponseWithCache";
import { ResultQuestionnaire, ResultResponseWithCache } from "./Graphs";
import { QUESTIONNAIRE_NAMES } from "./Constants";
import { Design } from "./Schema";
import { compact } from "lodash";
import { ResearchParticipationQuery, ResearchParticipationsListQuery } from "../../../grapqhl";

// Type definitions for ResearchParticipationsListQuery
export type PetraResearchParticipation = NonNullable<
  ResearchParticipationsListQuery["currentDossier"]
>["researchParticipations"][number];
export type PetraProtocolSubscription = PetraResearchParticipation["protocolSubscriptions"][number];
export type PetraSingleProtocolSubscription = NonNullable<
  ResearchParticipationQuery["currentDossier"]
>["researchParticipation"]["protocolSubscriptions"][number];
export type PetraProtocolSubscriptionTextvars = PetraProtocolSubscription["textvars"];

// Return the design for a questionnaire. The design is either fixed, semi random, or one time per day.
// It finds the design by looking at the key of the given questionnaire and matching it against the known
// keys of the questionnaires belonging to each design.
export const findDesign = (questionnaire: ResultQuestionnaire) => {
  return Object.keys(QUESTIONNAIRE_NAMES).find((key) => QUESTIONNAIRE_NAMES[key] === questionnaire.key) as
    | Design
    | undefined;
};

// Returns the array of question keys that are text inputs in the given questionnaire.
// This includes both text inputs and textareas, i.e., both have the TEXT type in graphql.
const determineTextFields = (questionnaire: ResultQuestionnaire): string[] => {
  return questionnaire.questions.filter((question) => question.type === "TEXT").map((question) => question.key);
};

// Return the filled out answers for all (free) text fields in a given response of a given questionnaire,
// concatenated with the given separator (e.g., ", "). Returns a string.
export const freeTextFromResponse = (responseWithCache: ResultResponseWithCache, separator: string): string => {
  const freeTextFields: string[] = determineTextFields(responseWithCache.questionnaire);
  return compact(freeTextFields.map((qKey) => getValue(responseWithCache, qKey))).join(separator);
};

// Returns the array of all question keys that are "select" questions in the given questionnaire.
// This includes both single select (i.e., radio button) questions as well as multi-select (i.e.,
// checkbox) questions.
const getSelectQuestionKeys = (questionnaire: ResultQuestionnaire): string[] => {
  return questionnaire.questions
    .filter((question) => ["SINGLE_SELECT", "MULTI_SELECT"].includes(question.type))
    .map((question) => question.key);
};

// Return a string that is the concatenation of all "select" questions in the given response of
// the given questionnaire, separated by the given separator (e.g., ", "). The selected values
// are prefixed by the (contextfree) title of the question.
// This function is meant to be used for search query/matching purposes only, and not for
// display purposes (a long string is not good to display somewhere).
export const queryMatchStringFor = (resultResponseWithCache: ResultResponseWithCache, separator: string): string => {
  const selectFields: string[] = getSelectQuestionKeys(resultResponseWithCache.questionnaire);
  const selectedOptionDescriptionsForSearching = compact(
    selectFields.map((qKey) => {
      const selectedValues = getDescriptionForAnswer(resultResponseWithCache, qKey);
      // Include the contextFreeTitle (so we can filter for responses that include at least one selected option)
      if (selectedValues) {
        const question = getQuestion(resultResponseWithCache, qKey);
        return `${question?.contextFreeTitle}: ${selectedValues}`;
      }
      return null;
    })
  ).join(separator);
  return `${selectedOptionDescriptionsForSearching}${
    selectedOptionDescriptionsForSearching !== "" ? separator : ""
  }${freeTextFromResponse(resultResponseWithCache, separator)}`;
};

// Return an array of all possible answer options from either a checkbox- or radio question.
export const allSelectOptions = (key: string, questionnaire: ResultQuestionnaire): string[] => {
  const question = questionnaire.questions.find((question) => question.key === key);
  if (question?.__typename === "MultiSelectQuestion") {
    return compact(
      question.options.filter((option) => option.key !== question.uncheckAllOption).map((option) => option.description)
    );
  }
  if (question?.__typename === "SingleSelectQuestion") {
    return compact(question.options.map((option) => option.description));
  }
  return [];
};
