import React, { useState } from "react";
import classNames from "classnames";
import MoreInformation from "./MoreInformation";
import { MoreInformationKey } from "../common/Graphs";

interface Props {
  moreInformationKey: MoreInformationKey;
}

// A container class so that clicking to expand the more information doesn't trigger a re-render on the wordcloud.
const MoreInformationContainer: React.FunctionComponent<Props> = ({ moreInformationKey }) => {
  const [extraInfoExpanded, setExtraInfoExpanded] = useState<boolean>(false);

  return (
    <div className="petra-tg-extra-information">
      <div className="petra-tgei-header" onClick={() => setExtraInfoExpanded(!extraInfoExpanded)}>
        <div className="petra-tgei-title">
          {extraInfoExpanded
            ? "Extra informatie weer inklappen"
            : `Meer informatie over deze ${moreInformationKey === "wordcloud" ? "wordcloud" : "grafiek"}`}
        </div>
        <div className={classNames("petra-tgei-expansion-arrow", { expanded: extraInfoExpanded })} />
      </div>
      {extraInfoExpanded && (
        <div className="petra-tgei-body">
          <MoreInformation moreInformationKey={moreInformationKey} />
        </div>
      )}
    </div>
  );
};

export default MoreInformationContainer;
