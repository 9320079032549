import React from "react";
import { useBackboneModel } from "logic/backbone_hooks";
import { destroyNonResponse, updateNonResponse } from "lib/actions"
import { arrayToSentence } from "logic/array";
import { Translate } from "./Translate";
import { Selection } from "lib/non_response/schema";
import { ButtonLink } from "./ui/buttons";
import { Icon } from "./ui/icon";
import { NonResponseSchemaGroupFieldset } from "./NonResponseSchemaGroupFieldset";
import { NonResponseDate } from "./NonResponseDate";
import { NonResponseDeprecatedSchema } from "./NonResponseDeprecatedSchema";
import { BackboneNonResponse } from "lib/non_response/backbone";
import { stripTags } from "logic/string";
import { parseISO } from "date-fns";

interface Props {
  model: BackboneNonResponse;
}

export const NonResponseOverlay: React.FC<Props> = ({ model }) => {
  useBackboneModel(model);

  const [syncing, setSyncing] = React.useState(false);
  const [selection, setSelection] = React.useState<Selection>({ option: model.getSelectedOption(), otherText: model.get("reason_other") });
  const [nonResponseAt, setNonResponseAt] = React.useState<Date>(parseISO(model.get("non_response_at")));
  const destroyThisNonResponse = () => {
    RoQua.showConfirmDialog({
      message: I18n.t("epd_area.app.non_response.confirm_destroy"),
      ok: (confirmDialog) => {
        destroyNonResponse(model, { complete: () => confirmDialog.close(), success: () => RoQua.overlay.close() });
      },
    });
  };
  const updateThisNonResponse = () => {
    if (syncing || !selection.option) return;

    setSyncing(true);
    const data = {
      reason_code: selection.option.code,
      reason_option: stripTags(selection.option.label),
      reason_other: selection.otherText,
      non_response_at: nonResponseAt.toISOString(),
    };
    updateNonResponse(model, data, { complete: () => setSyncing(false) });
  };
  const group = model.getSchemaGroup();

  return (
    <div className="non-response-overlay">
      <div id="header-region" className="header-and-buttons">
        <div className="header">
          <Translate content="epd_area.non_response.non_response_no_timestamp" questionnaires={arrayToSentence(model.get("questionnaire_names"))} />
        </div>
        <div className="buttons">
          <ButtonLink href="#" className="btn-danger destroy-button" onClick={destroyThisNonResponse}>
            <Icon name="trash" color="white" />
            <Translate content="epd_area.non_response.delete" />
          </ButtonLink>
        </div>
      </div>

      <div id="form-region" className="non-response-form">
        <div id="non-response">
          {group && selection.option ? (
            <NonResponseSchemaGroupFieldset group={group} selection={selection} onChange={setSelection} />
          ) : (
            <NonResponseDeprecatedSchema model={model} />
          )}

          <NonResponseDate date={nonResponseAt} disabled={!group || !group.allowCustomDate} onChange={setNonResponseAt} />

          <div className="form-buttons">
            <input
              type="submit"
              className="btn btn-primary update-button"
              disabled={syncing || !selection.option}
              value={syncing ? I18n.t("dialog.loading") : I18n.t("epd_area.non_response.submit")}
              onClick={updateThisNonResponse}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
