import { isMidnight, Timestamp } from "logic/date";
import * as React from "react";

interface TimeUnlessMidnightProps {
  timestamp: Timestamp;
  label?: string;
}

export const TimeUnlessMidnight: React.FunctionComponent<TimeUnlessMidnightProps> = (props) => {
  if (isMidnight(props.timestamp)) {
    return <span></span>;
  } else {
    return (
      <span>
        {props.label} {I18n.localize("time.formats.time", props.timestamp)}
      </span>
    );
  }
};

interface DateWithTimeUnlessMidnightProps {
  timestamp: Timestamp;
}

export const DateWithTimeUnlessMidnight: React.FunctionComponent<DateWithTimeUnlessMidnightProps> = (props) => {
  return (
    <span>
      {I18n.localize("time.formats.date", props.timestamp)} {isMidnight(props.timestamp) ? "" : I18n.localize("time.formats.time", props.timestamp)}
    </span>
  );
};
