import { RespondentSyncStatusEnum, useCurrentDossierInfoQuery } from "grapqhl";
import { useEpdSession } from "lib/useSession";
import { withRailsMountable } from "logic/rails_component";
import * as React from "react";
import { useEffect } from "react";
import { DossierInfo } from "./DossierInfo";

export const CurrentDossierInfo: React.FunctionComponent = () => {
  const { organizationKey, dossierId } = useEpdSession();
  const { data, loading, stopPolling } = useCurrentDossierInfoQuery({
    variables: { organizationKey, dossierId },
    pollInterval: 5000,
  });
  const syncStatus = data?.dossier.patientRespondent.syncStatus;

  useEffect(() => {
    // Stop polling if we have a final sync result
    if (syncStatus === RespondentSyncStatusEnum.SyncSucceeded || syncStatus === RespondentSyncStatusEnum.SyncFailed) {
      stopPolling();
    }

    // Stop polling after a minute
    const id = setTimeout(() => {
      stopPolling();
    }, 60 * 1000);

    return () => clearTimeout(id);
  }, [syncStatus, stopPolling]);

  if (loading || !data) {
    return null;
  }

  return <DossierInfo dossier={data.dossier} epdNextUrl={data.organization.epdNextUrl} />;
};

const CurrentDossierInfoRails = withRailsMountable(CurrentDossierInfo);
export default CurrentDossierInfoRails;
