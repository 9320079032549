import React from "react";

interface NoSession {
  sessionType: "no_session";
  locale: string;
}

interface EpdSession {
  sessionType: 'epd_area';
  organizationKey: string;
  dossierId: string;
  locale: string;
}

interface AdminSession {
  sessionType: 'admin_area';
  organizationKey: string;
  locale: string;
}

export type Session = NoSession | EpdSession | AdminSession

export const SessionContext = React.createContext<Session | null>(null);

export const useEpdSession = () => {
  const data = React.useContext(SessionContext);

  if (!data) throw("Should not happen: SessionContext has no data");
  if (data.sessionType !== "epd_area") throw(`Should not happen: SessionContext has no epd_area session type but ${data.sessionType}`)

  return data;
}
