import classNames from "classnames";
import { Translate } from "components/Translate";
import { Icon } from "components/ui/icon";
import React from "react";
import { AutoProtocol } from "./models";

interface Props {
  autoProtocol: AutoProtocol;
}

const DeleteButton: React.FunctionComponent<{ autoProtocol: AutoProtocol }> = ({ autoProtocol }) => {
  const href = `/admin/auto_protocols/${autoProtocol.id}`;
  return (
    <a href={href} className="btn btn-danger" data-method="delete">
      <Icon name="trash" color="white" />
    </a>
  );
};

const RestoreButton: React.FunctionComponent<{ autoProtocol: AutoProtocol }> = ({ autoProtocol }) => {
  const href = `/admin/auto_protocols/${autoProtocol.id}/restore`;
  return (
    <a href={href} className="btn" data-method="patch">
      <Translate content="restore" />
    </a>
  );
};

export const Row: React.FunctionComponent<Props> = ({ autoProtocol }) => {
  const className = classNames("sortable-row", { active: autoProtocol.active, inactive: !autoProtocol.active });

  return (
    <tr className={className}>
      <td>
        <i className="fa fa-arrows-v" />
      </td>

      <td>
        <a href={`/admin/auto_protocols/${autoProtocol.id}/edit`}>{autoProtocol.name}</a>

        {!autoProtocol.active && (
          <span className="label">
            <Translate content="admin_area.protocols.deleted_label" />
          </span>
        )}
      </td>

      <td>{autoProtocol.teams.length > 0 && autoProtocol.teams.map((team) => team.name).join(", ")}</td>

      <td>
        {autoProtocol.active ? (
          <DeleteButton autoProtocol={autoProtocol} />
        ) : (
          <RestoreButton autoProtocol={autoProtocol} />
        )}
      </td>
    </tr>
  );
};
