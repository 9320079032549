import React, { useEffect, useMemo } from "react";
import { withRailsMountable } from "logic/rails_component";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Root from "./Root";
import Wizard from "./Wizard";
import Results from "./Results";
import { createBrowserHistory } from "history";
import ReactPiwik from "react-router-piwik";

const piwik = new ReactPiwik({
  url: "https://matomo.roqua.nl", // Needs to be https otherwise the browser will do two requests always
  siteId: 1,
  jsFilename: "matomo.js",
  phpFilename: "matomo.php",
  enableLinkTracking: true,
  trackDocumentTitle: true,
  pathBasename: "petra",
});

// For better page timings, see https://developer.matomo.org/api-reference/tracking-javascript
ReactPiwik.push(["enableHeartBeatTimer"]);

// Needed together with false as second argument to `connectToHistory` to track the initial
// pageview when this page is loaded.
ReactPiwik.push(["trackPageView"]);

// This library requires a callback or it errors.
const doNothing = () => null;

interface Props {
  basename: string;
  researchProjectId: number;
}

// Petra router
// Currently we default to the Root component, which shows the Petra
// overview page. Users can also navigate to /wizard directly to create
// a new PETRA diary study.
const Petra: React.FunctionComponent<Props> = (props) => {
  const history = useMemo(() => createBrowserHistory({ basename: props.basename }), []);
  useEffect(() => {
    // TypeScript type definition is incorrect here. The function can accept up to three arguments.
    piwik.connectToHistory(history, false, doNothing);
    return () => {
      piwik.disconnectFromHistory();
    };
  });
  // Add Petra webfonts to the DOM only when this component is loaded
  useEffect(() => {
    loadFonts();
  }, []);
  return (
    <BrowserRouter basename={props.basename}>
      <Routes>
        <Route path="/wizard/:step" element={<Wizard {...props} />} />
        <Route path="/results/:id" element={<Results researchProjectId={props.researchProjectId} />} />
        <Route path="/" element={<Root researchProjectId={props.researchProjectId} />} />
      </Routes>
    </BrowserRouter>
  );
};

const loadFonts = () => {
  if (!document.getElementById("petra-fonts")) {
    const head = document.getElementsByTagName("head")[0];
    const link = document.createElement("link");
    link.id = "petra-fonts";
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = "/fonts/petra.css";
    link.media = "all";
    head.appendChild(link);
  }
};

export default withRailsMountable(Petra);
