import React from "react";
import { useBackboneCollection, useBackboneModel } from "logic/backbone_hooks"
import { Loader } from "../ui/loader";

interface Props {
  model: any;
  retry: () => void;
  retryLabel: string;
}

export const LoadingState: React.FunctionComponent<Props> = (props) => {
  useBackboneCollection(props.model, "add remove reset sort sync loading");

  if (props.model.loading()) {
    return <div className="loading-state loading-state-loading">
      <Loader />
    </div>
  } else if (props.model.loadingFailed()) {
    return <div className="loading-state-loading-state-failed" onClick={props.retry}>
      {props.retryLabel}
    </div>
  } else {
    return <div className="loading-state loading-state-loaded" />
  }
}