import * as React from "react";
import { Button } from "../bootstrap";

import { Translate } from "./Translate";

import { Dialog } from "./QuestionnaireSelector/Dialog";
import { IQuestionnaire } from "models";
import { useBoolean } from "ahooks";

interface IQuestionnaireSelectorProps {
  questionnaires?: IQuestionnaire[];
  addedQuestionnaires?: IQuestionnaire[];
  baseUrl: string;
  authenticityToken: string;
}

export interface IQuestionnaireSelectorState {
  selectorVisible: boolean;
}

export const QuestionnaireSelector: React.FunctionComponent<IQuestionnaireSelectorProps> = (props) => {
  const [visible, { setTrue: setVisible, setFalse: setHidden }] = useBoolean(false);

  return (
    <div id="questionnaire-selector">
      <Button highlight="primary" onClick={setVisible} className="toggle-button">
        <Translate content="admin_area.measured_questionnaires.add_trigger" />
      </Button>
      {visible && <Dialog onClose={setHidden} {...props} />}
    </div>
  );
};

export default QuestionnaireSelector;
