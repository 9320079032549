import * as React from "react";
import { withRailsMountable } from "logic/rails_component";
import { Translate } from "./Translate";
import { OverlayTab } from "./OverlayTab";
import { Glyphicon } from "./ui/glyphicon";
import { TokensManager } from "./ManageTokens/TokensManager";
import { useBoolean } from "ahooks";

export const ManageTokens: React.FunctionComponent = () => {
  const [visible, { toggle }] = useBoolean(false);

  return (
    <div className="manage-tokens">
      <OverlayTab position="right" onClick={() => toggle()}>
        <Glyphicon name="halflings-wrench" />
        <Translate content="epd_area.app.manage_tokens" />
      </OverlayTab>

      {visible && <TokensManager />}
    </div>
  );
};

export default withRailsMountable(ManageTokens);
