import React from "react";
import { Translate } from "../Translate";
import { Team } from "grapqhl";

type ArgTeam = Pick<Team, "id" | "name">;

interface Props {
  teams: ArgTeam[];
  selected: ArgTeam | null;
  onChange: (teamId: ArgTeam["id"] | null) => void;
}

export const TeamSelectorView: React.FunctionComponent<Props> = ({ teams, selected, onChange }) => {
  const handleChange = (idOrEmpty: string) => {
    if (idOrEmpty === "") {
      onChange(null);
    } else {
      onChange(idOrEmpty);
    }
  };

  if (teams.length > 0) {
    return (
      <div id="team-selector">
        <form className="navbar-form">
          <span className="filter_label">
            <Translate content="activerecord.models.team.one" />
            {": "}
          </span>
          <select id="team_id" onChange={(e) => handleChange(e.target.value)} value={selected?.id || ""}>
            <option key="none" value="">
              {I18n.t("activerecord.models.team.none")}
            </option>
            {teams.map((team) => (
              <option key={team.id} value={team.id}>
                {team.name}
              </option>
            ))}
          </select>
        </form>
      </div>
    );
  } else {
    return null;
  }
};
