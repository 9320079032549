/* eslint-disable @typescript-eslint/camelcase */
import { BaseChart, BaseSettings } from "./BaseChart";
import { merge, update } from "lodash";

export interface CustomChartSettings extends BaseSettings {
  [key: string]: any;
}

function reviveFormatters(settings) {
  if (!settings) {
    return;
  }
  update(settings, "yAxis.labels.formatter", (value: string | null | undefined) => (value ? eval(value) : undefined));
}

function reviveChartEvents(settings) {
  if (!settings) {
    return;
  }
  update(settings, "chart.events.load", (value: string | null | undefined) => (value ? new Function(value) : undefined));
  update(settings, "chart.events.redraw", (value: string | null | undefined) => (value ? new Function(value) : undefined));
}

export class CustomChart extends BaseChart<CustomChartSettings> {
  chartSettings() {
    const defaults = super.chartSettings();

    reviveFormatters(this.settings);
    reviveChartEvents(this.settings);
    return merge(defaults, this.settings);
  }
}
