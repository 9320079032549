import React from "react";
import { Scheduler, Settings } from "./Settings";
import { FormControlGroup } from "components/ui/forms";
import { DEFAULT_SETTINGS } from "./Settings";

interface Props {
  scheduler: Scheduler;
  onChange: (settings: Partial<Settings>) => void;
}

export const SchedulerSelector: React.FunctionComponent<Props> = (props) => {
  const handleChange = (event) => props.onChange(Object.assign({}, { scheduler: event.target.value }, DEFAULT_SETTINGS[event.target.value]));
  return <FormControlGroup label={I18n.t("activerecord.attributes.protocol/settings.scheduler")}>
    <select value={props.scheduler} onChange={handleChange}>
      <option value="IntervalScheduler">IntervalScheduler</option>
      <option value="DailyScheduler">DailyScheduler</option>
      <option value="RandomScheduler">RandomScheduler</option>
      <option value="FixedScheduler">FixedScheduler</option>
    </select>
  </FormControlGroup>;
};
