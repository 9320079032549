import { useSelections } from "ahooks";
import React, { useEffect, useRef, useState } from "react";
import {
  COMPLAINTS,
  FIXED,
  GOAL_DEFINITIONS,
  ONE_TIME_PER_DAY,
  POSSIBLE_COMPLAINTS,
  POSSIBLE_GOALS,
  POSSIBLE_SUB_GOALS,
  REQUIRED_ITEM_DOMAINS,
  SEMI_RANDOM,
  preferredDesigns,
} from "../common/Constants";
import {
  FlagInterface,
  FlagObject,
  TextvarObject,
  TextvarsValues,
  defaultFlagsInInterfaceForDomains,
  flagsLikeMultiple,
} from "../common/Flags";
import { Questions } from "../common/Graphs";
import { MeasurementSchedule, useMeasurementSchedule } from "../common/MeasurementSchedule";
import { PerDesign, Step } from "../common/Schema";
import { Key } from "../common/Selections";
import { NewDiaryState } from "./index";
import Main from "./main_and_receipt/Main";
import Receipt from "./main_and_receipt/Receipt";

interface Props {
  allFlags: PerDesign<FlagObject[]>;
  allTextvars: PerDesign<TextvarObject[]>;
  allQuestions: PerDesign<Questions>;
  allAutoProtocolIds: PerDesign<string>;
  currentStep: Step;
  researchProjectId: number;
  patientPhone: string;
  newDiaryState: NewDiaryState;
}

// The CreateNewDiary component houses all of the state for creating a new diary study.
const CreateNewDiary: React.FunctionComponent<Props> = (props: Props) => {
  // A way to detect when we don't want to reset the sub goal or design in the use effects.
  // repeatedDiary is true if we have specified a goal, subgoal, complaint, and design.
  // So don't change those things when first loading this component.
  // We use a ref instead of a useState thingie because changing a ref doesn't
  // cause a re-render.
  const lockSettings = useRef<boolean>(props.newDiaryState.repeatedDiary);

  // The selected goal of the diary study. This is one of the properties of the `GOALS`
  // constant in Constants.tsx. In this constant, the "keys" are the goals, and the "values"
  // are the list of subgoals associated with this goal. That is, the GOALS constant defines
  // the dependency structure of goals and subgoals.
  // The `GOAL_DEFINITIONS` constant provides more information (such as title, design,
  // preferred number of beeps) for both goals and subgoals.
  // Note that this is an array, but in the current implementation we only select a
  // single goal. It is (currently unneeded) future-proofing for the case where
  // they might want to select multiple goals in the future, rather than a single one.
  // Goals, subgoals, and complaints are of a `Selections<Key>` type. This is simply so that
  // we don't have to write a set of functions to toggle, add, remove, or check whether an
  // element appears in a set, but instead use those from ahooks.
  // See https://ahooks.js.org/hooks/ui/use-selections
  const goals: Selections<Key> = useSelections<Key>(POSSIBLE_GOALS, props.newDiaryState.goals);

  // The selected subgoal for the diary study. A subgoal belongs to a goal. Their dependency
  // structure is defined in the GOALS constant in Constants.tsx. In the `GOAL_DEFINITIONS`
  // constant, subgoals indicate which diary study design (e.g., fixed or flex, how many
  // measurements per day) is preferred, and also which domains in the (fixed or
  // semi-random (=flex)) questionnaire to check by default.
  // Checking a domain in this case means looking up the associated flag in the questionnaire,
  // and setting the value for all flags directly depending on this flag to true if
  // their `defaultInInterface` value is true.
  // Note that the current structure allows for multiple subgoals to be selected, but
  // in practice only a single subgoal is ever selected (again, possibly unneeded future-proofing).
  const subGoals: Selections<Key> = useSelections<Key>(POSSIBLE_SUB_GOALS, props.newDiaryState.subGoals);

  // After selecting a goal and subgoal, a user may select a complaint.
  // This complaint is saved with the diary study, may provide a
  // set of flags to additionally be checked by default, and will feature
  // in the feedback part of the PETRA module to highlight certain results.
  // Below is the selected complaints for the diary study. Possible values are defined by the
  // `COMPLAINTS` constant in Constants.tsx. Complaints are specified using the same type
  // (`GoalDefinitions`) as goals and subgoals are, and like subgoals, complaints may specify
  // a set of domains to be checked by default in a questionnaire.
  // The GoalDefinitions type also allows for complaints to specify designs and beeps, but these
  // are not used (other than the `description` attribute of the design property).
  const complaints: Selections<Key> = useSelections<Key>(POSSIBLE_COMPLAINTS, props.newDiaryState.complaints);

  // The set of selected (= checked) flags for the semi-random (=flex) questionnaire. This is an array
  // of Keys (= strings). The full flag definitions are given by the props to the current component.
  // Here, we merely store a list of the ones that are checked for the current diary study.
  // Note also the use of the `REQUIRED_ITEM_DOMAINS` constant in Constants.tsx, which
  // defines a list of required flex domains that are always checked and cannot be unchecked by the
  // user for the Petra flex questionnaire. This is enforced by a useEffect in the `SelectGoals`
  // component.
  const flexFlags: Selections<Key> = useSelections<Key>(
    props.allFlags[SEMI_RANDOM].map((item) => item.key),
    []
  );

  // The set of selected (= checked) flags for the fixed questionnaire. Analogous to the
  // set of selected flex flags (explained above).
  // Note here also the `REQUIRED_ITEM_DOMAINS` constant in Constants.jsx, which dictates
  // the list of required fixed flags (enforced by a useEffect in the `SelectGoals` component).
  const fixedFlags: Selections<Key> = useSelections<Key>(
    props.allFlags[FIXED].map((item) => item.key),
    []
  );

  // Selection for flags belonging to the oneTimePerDay questionnaire.
  const oneTimePerDayFlags: Selections<Key> = useSelections<Key>(
    props.allFlags[ONE_TIME_PER_DAY].map((item) => item.key),
    []
  );

  // Construct the measurementschedule
  const schedule: MeasurementSchedule = useMeasurementSchedule({
    design: props.newDiaryState.design,
    measurementAmount: props.newDiaryState.measurementAmount,
    blocksPerDay: props.newDiaryState.blocksPerDay,
    dailyStartTime: props.newDiaryState.dailyStartTime,
  });

  // The textvars for the currently selected design. They are reset when the design changes.
  const [textvars, setTextvars] = useState<TextvarsValues>(props.newDiaryState.textvars);

  useEffect(() => {
    if (lockSettings.current) return;

    setTextvars({});
  }, [schedule.design]);

  // Clear the subgoal selection when a new or different goal is selected.
  // This enforces the rule that subgoals belong to a specific goal, and
  // once that goal changes, a new subgoal also has to be selected.
  useEffect(() => {
    if (lockSettings.current) return;

    subGoals.setSelected([]);
  }, [goals.selected]);

  // Subgoals for diary studies have preferred designs attached to them (e.g., "fixed measurements,
  // 3 times per day" or "semi-random, 10 times per day". Some subgoals are compatible with more than
  // one design. In such cases, where appropriate,
  // we can steer the user to label one of the two options as the "preferred" one. Note that this
  // is different from the `design` setting. (see above).
  // Also, when the user changes subgoals, select the preferred design as current design
  useEffect(() => {
    if (lockSettings.current) return;

    const newlyPreferredDesigns = preferredDesigns(subGoals);
    if (newlyPreferredDesigns.length > 0) {
      schedule.setDesign(newlyPreferredDesigns[0]);
    }
  }, [subGoals.selected]);

  const flagsSelector: PerDesign<Selections<Key>> = {
    fixed: fixedFlags,
    semiRandom: flexFlags,
    oneTimePerDay: oneTimePerDayFlags,
  };

  const flags: FlagInterface = {
    flagsSelection: flagsSelector[schedule.design],
    flags: props.allFlags[schedule.design],
    textvars: props.allTextvars[schedule.design],
    textvarsSettings: { textvarsValues: textvars, setTextvarsValues: setTextvars },
    questions: props.allQuestions[schedule.design],
  };

  // Get the id for the current protocol to start.
  const autoProtocolId = props.allAutoProtocolIds[schedule.design];

  // When selecting or deselecting a complaint, or when changing design
  // (e.g., between semi-random and fixed diary study design), reset the
  // selection of flags to the "recommended" list consisting of domains
  // recommended by the selected subgoal and the domains recommended by
  // the selected complaint, as well as the required domains. For each
  // of these domains, select the child flags that have
  // `defaultInInterface` set to true.
  useEffect(() => {
    if (lockSettings.current) return;

    const newFlags: string[] = [];
    // add complaint domains
    for (const key of complaints.selected) {
      const complaint = COMPLAINTS[key];
      if (!complaint.item_domains) continue;
      const itemDomainsForDesign = complaint.item_domains[schedule.design];
      if (!itemDomainsForDesign) continue;
      for (const flagKey of itemDomainsForDesign) {
        newFlags.push(flagKey);
      }
    }
    // add subgoal domains
    for (const key of subGoals.selected) {
      const subGoal = GOAL_DEFINITIONS[key];
      if (!subGoal.item_domains) continue;
      const itemDomainsForDesign = subGoal.item_domains[schedule.design];
      if (!itemDomainsForDesign) continue;
      for (const flagKey of itemDomainsForDesign) {
        newFlags.push(flagKey);
      }
    }
    // add required domains
    const itemDomainsForDesign = REQUIRED_ITEM_DOMAINS[schedule.design];
    if (itemDomainsForDesign) {
      for (const itemDomain of itemDomainsForDesign) {
        newFlags.push(itemDomain);
      }
    }
    const newFlagsUniq = Array.from(new Set(newFlags));
    flags.flagsSelection.setSelected(
      flagsLikeMultiple(defaultFlagsInInterfaceForDomains(newFlagsUniq, flags.flags), flags.flags)
    );
  }, [complaints.selected, subGoals.selected, schedule.design]);

  // When changing the complaint, reset the diary name to be an empty string,
  // because it is probably no longer fitting to the previously given name (if any).
  // We do this here instead of in the SelectSettings component, so that the useEffect
  // is not executed whenever the settings page is opened (i.e., it remains possible to
  // go back a step and then forward without having the diary name being reset.
  useEffect(() => {
    schedule.setDiaryName("");
  }, [complaints.selected]);

  // Run after all the other useEffects to unlock the useEffects.
  useEffect(() => {
    if (lockSettings.current) {
      lockSettings.current = false;
      // Set the selected flags now that we know we have set the design correctly.
      // flagLikeMultiple is given the removeNoImpactFlags argument to remove flags
      // that have no impact. Since we get the list of flags from a previous measurementsequence,
      // this includes all flags that are domains or subdomains but have no real impact on which
      // questions are being shown in the questionnaire. We choose here to remove those to have
      // a state that is equivalent to if we were to select these flags manually. When submitting
      // this diary study to create a new research participation in RoQua, we add the ancestor flags
      // back (see SelectSettings.tsx).
      flags.flagsSelection.setSelected(flagsLikeMultiple(props.newDiaryState.flags, flags.flags, true));
    }
  }, []);

  // The interface of creating a diary study within PETRA contains of a main page (on the left)
  // and a smaller receipt (on the right). The receipt keeps track of the choices of the user
  // so that they are visible in future steps.
  // For some steps, the receipt might also show a progress bar to indicate to the user,
  // e.g., how many flags they have currently selected.
  // The idea is that the Receipt only shows information, and that
  // the actual editing is done in the Main component. The Receipt may therefore be hidden
  // behind a menu button on smaller screen layouts.
  // The receipt serves to show the user where they are in the process of creating a diary study,
  // and how many steps are left. We supply both the Main and the Receipt component with
  // the minimum number of properties required to function properly.
  return (
    <div className="main-and-receipt">
      <div className="petra-main">
        {props.newDiaryState.repeatedDiary && (
          <div className="attention-well">
            <h3>Je bent een dagboek aan het herhalen</h3>
            <p>
              Je bent aan het herhalen, dus we hebben al een paar dingen ingevuld. Je kunt nog wel aanpassen welke
              vragen er in het dagboek zitten.
            </p>
          </div>
        )}
        <Main
          goals={goals}
          subGoals={subGoals}
          complaints={complaints}
          flags={flags}
          schedule={schedule}
          autoProtocolId={autoProtocolId}
          patientPhone={props.patientPhone}
          researchProjectId={props.researchProjectId}
          currentStep={props.currentStep}
        />
      </div>
      <div className="receipt">
        <Receipt
          goals={goals}
          currentStep={props.currentStep}
          subGoals={subGoals}
          complaints={complaints}
          flags={flags}
          schedule={schedule}
        />
      </div>
    </div>
  );
};

export default CreateNewDiary;
