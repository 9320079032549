import * as React from "react";
import classNames from "classnames";

interface ButtonLinkProps {
  href?: string;
  className?: string;
  onClick?: () => void;
}

export const ButtonLink: React.FunctionComponent<React.PropsWithChildren<ButtonLinkProps>> = (props) => {
  return <a href={props.href} className={classNames('btn', props.className)} onClick={props.onClick}>
    {props.children}
  </a>;

}

