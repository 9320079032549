import { Badge } from "components/ui/Badge";
import {
  Respondent,
  RespondentWithSelfInitiableMeasuredQuestionnairesFragment,
  SelfInitiableMeasuredQuestionnaireFragment,
  useDeleteSelfInitiableMeasuredQuestionnaireMutation,
  useSelfInitiableMeasuredQuestionnairesForDossierQuery
} from "grapqhl";
import { assumeSuccess } from "lib/apollo/resultHelpers";
import { useEpdSession } from "lib/useSession";
import { withRailsMountable } from "logic/rails_component";
import React from "react";

export const ManageSelfInitiableMeasuredQuestionnaires: React.FunctionComponent = (props) => {
  const { dossierId } = useEpdSession();
  const results = useSelfInitiableMeasuredQuestionnairesForDossierQuery({ variables: { dossierId: dossierId } });

  return assumeSuccess(results, ({dossier}) => {
    const respondents = dossier.respondents.filter(
      (respondent) => respondent.selfInitiableMeasuredQuestionnaires.nodes.length > 0
    );

    if (respondents.length > 0) {
      return (
        <div className="pending-answers">
          <p>{I18n.t("epd_area.self_initiable_measured_questionnaires.present")}</p>
          {respondents.map((respondent) => (
            <Row key={respondent.id} respondent={respondent} />
          ))}
        </div>
      );
    } else {
      return null;
    }
  });
};

const Row: React.FunctionComponent<{ respondent: RespondentWithSelfInitiableMeasuredQuestionnairesFragment }> = ({
  respondent,
}) => {
  return (
    <div className="row-fluid">
      <div className="span4">{respondentDescription(respondent)}</div>
      <div className="span8">
        {respondent.selfInitiableMeasuredQuestionnaires.nodes.map((selfInitiableMeasuredQuestionnaire) => (
          <Label selfInitiableMeasuredQuestionnaire={selfInitiableMeasuredQuestionnaire} />
        ))}
      </div>
    </div>
  );
};

const Label: React.FunctionComponent<{
  selfInitiableMeasuredQuestionnaire: SelfInitiableMeasuredQuestionnaireFragment;
}> = ({ selfInitiableMeasuredQuestionnaire }) => {
  const [deleteMutation] = useDeleteSelfInitiableMeasuredQuestionnaireMutation({
    refetchQueries: ["SelfInitiableMeasuredQuestionnairesForDossier"],
  });

  return (
    <Badge
      key={selfInitiableMeasuredQuestionnaire.id}
      className="pending-answer pending-answer-info"
      onRemove={() => confirmRemoval(selfInitiableMeasuredQuestionnaire, deleteMutation)}
    >
      {selfInitiableMeasuredQuestionnaire.measuredQuestionnaire.questionnaire.name}
    </Badge>
  );
};

type RemovalFunction = ReturnType<typeof useDeleteSelfInitiableMeasuredQuestionnaireMutation>[0];

function confirmRemoval(
  selfInitiableMeasuredQuestionnaire: SelfInitiableMeasuredQuestionnaireFragment,
  removalFunction: RemovalFunction
) {
  RoQua.showConfirmDialog({
    message: I18n.t("epd_area.self_initiable_measured_questionnaires.confirm_destroy"),
    ok: (confirmDialog) => {
      removalFunction({
        variables: { input: { selfInitiableMeasuredQuestionnaireId: selfInitiableMeasuredQuestionnaire.id } },
      }).then(confirmDialog.close);
    },
  });
}

function respondentDescription(respondent: Pick<Respondent, "label" | "type">) {
  const type = I18n.t(respondent.type, { scope: "questionnaire.respondent_types" });

  if (respondent.label) {
    return `${type} (${respondent.label})`;
  } else {
    return type;
  }
}

export default withRailsMountable(ManageSelfInitiableMeasuredQuestionnaires);
