import React from "react";
import { FormControlGroup, SecondDurationField } from "components/ui/forms";
import { Translate } from "components/Translate";
import { MeasurementSelector } from "./MeasurementSelector";
import { RandomSchedulerSettings, Measurement } from "./Settings";

interface Props {
  settings: RandomSchedulerSettings;
  measurements: Measurement[];
  onChange: (settings: Partial<RandomSchedulerSettings>) => void;
}

export const RandomSchedulerFields: React.FunctionComponent<Props> = (props) => {
  const setBlockDuration = (blockDuration) => props.onChange({ block_duration: blockDuration });
  const setBlocksPerDay = (event) => props.onChange({ blocks_per_day: parseInt(event.target.value) });
  const setMinTimeBetweenBeeps = (minTimeBetweenBeeps) => props.onChange({ min_time_between_beeps: minTimeBetweenBeeps });
  const setMeasurement = (measurementId) => props.onChange({ measurement_id: measurementId });
  const setDailyStartTime = (value) => props.onChange({ daily_start_time: value });

  return <div>
    <div><Translate content="protocol.random_scheduler_explanation" /></div>

    <br />

    <FormControlGroup label={I18n.t("activerecord.attributes.protocol/settings.blocks_per_day")}>
      <input type="number" id="blocks_per_day" value={props.settings.blocks_per_day} onChange={setBlocksPerDay} />
    </FormControlGroup>

    <FormControlGroup label={I18n.t("activerecord.attributes.protocol/settings.block_duration")}>
      <SecondDurationField id="block_duration" value={props.settings.block_duration} onChange={setBlockDuration} />
    </FormControlGroup>

    <FormControlGroup label={I18n.t("activerecord.attributes.protocol/settings.min_time_between_beeps")}>
      <SecondDurationField id="min_time_between_beeps" value={props.settings.min_time_between_beeps} onChange={setMinTimeBetweenBeeps} />
    </FormControlGroup>

    <FormControlGroup label={I18n.t("activerecord.attributes.protocol/settings.measurement_id")}>
      <MeasurementSelector measurements={props.measurements} value={props.settings.measurement_id} onChange={setMeasurement} />
    </FormControlGroup>

    <FormControlGroup label="Voorgesteld startmoment op de dag">
      <SecondDurationField id="daily_start_time" value={props.settings.daily_start_time} onChange={setDailyStartTime} />
    </FormControlGroup>
  </div>;
};
