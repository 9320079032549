import * as React from "react";
import Backbone from "backbone";

/** React Hook to set up your component to rerender automatically whenever the
 * given Backbone collection changes. By default listens for addition/removal
 * or models to the collection, complete collection overwrites and resorting of
 * elements in the collection.
 * @param collection - the backbone collection to monitor
 * @param changeOptions - the events to listen to
 */
export function useBackboneCollection<T>(collection: GenBackbone.Collection<T>, changeOptions: string = "add remove reset sort") {
  const [_ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const callback = (...args) => {
    forceUpdate();
  };

  // the react-backbone lib had this, will we need it?
  // updateScheduler: function(func) { return _.debounce(func, 0); }

  React.useEffect(() => {
    collection.on(changeOptions, callback);

    return function cleanup() {
      collection.off(changeOptions, callback);
    };
  });
}

/** React Hook to set up your component to rerender automatically whenever the
 * given Backbone model changes. By default only listens to attribute change events.
 * @param model - the backbone model to monitor
 * @param changeOptions - the events to listen to
 */
export function useBackboneModel<T>(model: GenBackbone.Model<T>, changeOptions: string = "change") {
  const [_ignored, forceUpdate] = React.useReducer((x) => x + 1, 0);
  const callback = (...args) => {
    forceUpdate();
  };

  React.useEffect(() => {
    model.on(changeOptions, callback);

    return function cleanup() {
      model.off(changeOptions, callback);
    };
  });
}
