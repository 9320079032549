// -------------- Type Definitions --------------
import { QuestionOption, ResearchParticipationQuery, ResponsesQuery, TextvarValue } from "grapqhl";
import { getCheckedOptions, getDescriptionForAnswer, getQuestion, ResponseWithCache } from "logic/ResponseWithCache";

export type MoreInformationKey =
  | "stemming"
  | "wordcloud"
  | "situatie-activiteit"
  | "situatie-gezelschap"
  | "situatie-locatie"
  | "situatie-eten"
  | "situatie-middelen"
  | "situatie-lichamelijke-klachten"
  | "stemming-daily-and-weekly"
  | "situatie-coping-met-stemmen"
  | "situatie-bijwerkingen"
  | "situatie-met-wie-ben-ik"
  | "situatie-gesprekken";
export type GraphType = "lineplot" | "wordcloud" | "situationplot" | "daily-patterns" | "weekly-patterns";

// A plotvariable is a description of a variable (typically a slider question in a questionnaire)
// to be plotted in a highcharts plot. The long description is used in the legend, the short
// description is used in the side menus to toggle the status of plotting the variable. Each
// PlotVariable has a category, which is one of the top domains under which the associated
// flag lies that can toggle the visibility of this question in the questionnaire.
export interface PlotVariable {
  key: string;
  shortDescription: string;
  longDescription: string;
  initiallySelected: boolean;
  category: string;
}

// A TabConfiguration is a configuration to display a highcharts graph in a tab.
// It has information about the variables to be plotted in the graph, the graph type, and of course
// more information to show under the graph.
export interface TabConfiguration {
  type: GraphType;
  label: string;
  title: string;
  variables: PlotVariable[];
  labelField?: string; // e.g., v_xx
  moreInformationKey: MoreInformationKey;
  alwaysShown?: boolean;
}

// The below types are derived from grapqhl query types

// The responses type is used to denote the type that we get back from a graphql responses query.
export type Responses = NonNullable<ResponsesQuery["currentDossier"]>["responses"];
export type SingleResponse = Responses[number];

export type ResultQuestionnaire = NonNullable<
  ResearchParticipationQuery["currentDossier"]
>["researchParticipation"]["protocolSubscriptions"][number]["protocol"]["measurements"][number]["questionnaires"][number];
// The questions type is used to denote the type that we get back from a graphql questions query.
export type Questions = ResultQuestionnaire["questions"];
export type Textvar = { __typename?: "TextvarValue" | undefined } & Pick<TextvarValue, "key" | "value">;
export type ResultResponseWithCache = ResponseWithCache<Responses[number], ResultQuestionnaire, Questions[number]>;

// -------------- Graph functions ---------------

// Function to return the options of either a single or multi select question as an array. I was unable
// to write a generic function for this within getSelectedOption.ts because the types just wouldn't match.
export const selectedOptionsAsArray = (rc: ResultResponseWithCache, labelField: string): (string | null)[] => {
  const question = getQuestion(rc, labelField);
  if (question.__typename === "MultiSelectQuestion") {
    return getCheckedOptions(rc, question.key).map((option) => (option as QuestionOption).description);
  }
  if (question.__typename === "SingleSelectQuestion") {
    return [getDescriptionForAnswer(rc, question.key)];
  }
  return [];
};

// Function to determine if a tab of a graph should be visible.
export const tabVisible = (
  tabConfiguration: TabConfiguration,
  responsesWithCache: ResultResponseWithCache[]
): boolean => {
  // Only hide situation plot tabs, except the ones that should always be shown.
  if (!tabConfiguration.labelField || tabConfiguration.type !== "situationplot" || tabConfiguration.alwaysShown)
    return true;

  // The rest of the tabs only show if we have data for them.
  for (const rc of responsesWithCache) {
    const selectedOptionsNotNull = selectedOptionsAsArray(rc, tabConfiguration.labelField).filter(
      (option) => option !== null
    );
    if (selectedOptionsNotNull.length !== 0) return true;
  }
  return false;
};
