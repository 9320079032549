// This file contains type definitions used throughout Petra.
// The type definitions used for handling flags are in a separate file, Flags.tsx.
// The type definitions used for handling selections are in a separate file, Selections.tsx.

// steps of the wizards in order, /wizard/:step
// goals = Selecting goal, subgoal, complaint, and design (fixed or semi-random)
// flags = Select flags from the fixed, semi random, or one time per day questionnaire.
// settings = measurements per day, start time, start date, end date, mobile number, and a name for subscription
export type Step = "goals" | "flags" | "settings";
export const STEPS: Step[] = ["goals", "flags", "settings"];

// A wrapper object for interfacing with the expansion
// status for the receipt steps. These functions are defined
// in Receipt.tsx.
// We use it here as a wrapper object so that we only need to pass
// a single object for interfacing with the expansion status of receipt
// steps down to the individual ReceiptStep components.
export interface ReceiptStepSelection {
  canBeExpanded: (step: Step) => boolean;
  isExpanded: (step: Step) => boolean;
  toggleExpansion: (step: Step) => void;
}

// We currently have three designs, fixed, semiRandom, and
// oneTimePerDay. These are strings defined in Constants.tsx.
// We make it a type simply to be explicit and to minimize the risk of
// supplying a string as a design for a diary study that wasn't explicitly
// typed to be a design.
export type Design = "fixed" | "semiRandom" | "oneTimePerDay";

// ItemDomains are flags in the fixed, semi random, or one time per day
// questionnaires that typically have empty showsQuestions and hidesQuestions
// arrays, i.e., they have no impact, but they are used as a nesting structures
// for other flags (who include item domains in their `dependsOn` array).
// ItemDomains are defined in Constants.tsx. This isn't an exhaustive list.
// We only include the items that are used by subgoals or complaints to set
// an initial default selection of flags to be checked.
export type ItemDomain = string;

// A designs object encodes which designs are supported, and which are
// preferred. These are used in the definition of subgoals, as the PETRA
// team has defined specific sets of designs that work for each subgoal.
// Currently, subgoals support one of three distinct Designs variants,
// "only semi-random", "prefer semi-random but also support fixed", and
// "prefer fixed or one time per day, but also support semi-random."
// These three Designs are defined in Constants.tsx, as well as the subgoals
// that use them.
export interface Designs {
  description: string;
  preferredDesigns: Design[];
  alsoSupportedDesigns: Design[];
}

// Beeps is language used by the domain experts of PETRA to denote
// the number of measurements per day. In the `GOAL_DEFINITIONS` constant,
// each subgoal defines a certain Designs setting, and separately, a Beeps
// setting, denoting the supported numbers of measurements per day for
// measuring this subgoal. The `preferred` setting is used as the default
// setting for the measurements per day dropdown on the `SelectSettings` page.
export interface Beeps {
  supported: number[];
  preferred: number;
}

// Defines a subgoal, goal, or complaint.
// These concepts share the same type because they have a lot of overlap and so that
// they can be fed to the same rendering component (`Goal`), saving a lot of code duplication.
// Also, this makes it easy in the future to add support for when, e.g., a goal should
// impose additional domains to be selected by default.
// Subgoals and goals are defined in the `GOAL_DEFINITIONS` constant in Constants.tsx.
// Complaints are defined in the `COMPLAINTS` constant in Constants.tsx.
// Goals should define the following properties: `title`.
// Subgoals should define the following properties: `title`, `design`, `beeps`, and
// `item_domains.
// Complaints should define the following properties: `title`, `design`, and
// and `item_domains`. For the `design` property of a complaint, only the
// `description` is used, the rest should be empty arrays.
export interface GoalDefinition {
  title: string;
  design?: Designs;
  beeps?: Beeps;
  item_domains?: PerDesign<ItemDomain[]>;
}

// Wrapper for a an array of GoalDefinitions.
// Used to define the `GOAL_DEFINITIONS` and `COMPLAINTS` constants in Constants.tsx.
// The name should be unique and is used, e.g., in the Selections
// to denote which goal is selected. The actual definition of the goal
// can then be looked up separately using the goal key in the appropriate constant.
export interface GoalDefinitions {
  [name: string]: GoalDefinition;
}

// A time offset is an integer and represents the number of seconds since midnight.
export type TimeOffset = number;

// A BeepLimit struct defines for a certain design (i.e., fixed or semi-random),
// and a given number of measurements (= beeps) per day, other properties
// that should apply for that design. Since these properties rely on the
// number of beeps per day and the design, they are coded separately in their
// own constant `BEEP_LIMITS` in Constants.tsx.
// Using the `isFixed` setting from the FlagInterface object and the
// `measurementsPerDay` setting of the MeasurementSchedule object, a certain
// BeepLimit object is selected from the `BEEP_LIMITS` constant to define
// the current settings for minimal study duration, total block duration,
// and so on, in the `SelectSettings` component.
export interface BeepLimit {
  numberOfBeepsPerDay: number;
  minimalStudyDurationInDays: number;
  totalBlockDuration: number; // In seconds
  singleBlockDuration: number; // In seconds
  minTimeBetweenBeeps: number; // In seconds
  measurementExpiresAfter: number; // In seconds
}

// Generic type for things that we have one type of per design.
// - Used by AllProtocolIds to pass the protocol Ids from Wizard to Settings.
// - Used also by the `BEEP_LIMITS` constant defined in Constants.tsx, which gives an array of
//   supported BeepLimits for that design, each of them having a different number
//   of beeps per day.
// - Used also by Itemdomains (see Constants.tsx).
// - Used also by Allflags to pass flags from the Wizard/index component to CreateNewDiary.
export interface PerDesign<T> {
  fixed: T;
  semiRandom: T;
  oneTimePerDay: T;
}
