import React from "react";
import { MoreInformationKey } from "../common/Graphs";

interface Props {
  moreInformationKey: MoreInformationKey;
}

const stemming = () => {
  return (
    <div className="row-fluid more-information">
      <div className="span6">
        <h5>Wat betekent de grafiek?</h5>
        <p>
          Deze grafiek toont hoe <strong>opgewekt</strong> de invuller van het dagboek was. De <strong>periode</strong>{" "}
          die je ziet kan je <strong>aanpassen</strong>.
        </p>
        <p>
          De lijn met stippen laat het <strong>gemiddelde</strong> zien. De blauwe balk er omheen toont de{" "}
          <strong>hoogste</strong> en <strong>laagste meting</strong> per meting. Dat noemen we het bereik.
        </p>
      </div>
      <div className="span6">
        <h5>De grafiek aanpassen</h5>
        <p>
          De grafiek zelf kan je aanpassen door je muis te gebruiken. Je kunt <strong>inzoomen</strong> door een
          gedeelte van de grafiek te selecteren, of door te scrollen. Als je <strong>klikt</strong> op een dag / meting
          krijg je <strong>meer informatie</strong> over dat moment te zien.
        </p>
        <p>
          De data die weergegeven wordt kan je aanpassen door rechts van de grafiek <strong>meer items</strong> aan te
          vinken.
        </p>
      </div>
    </div>
  );
};

const stemmingDailyAndWeekly = () => {
  return (
    <div className="row-fluid more-information">
      <div className="span6">
        <h5>Wat betekent de grafiek?</h5>
        <p>
          Deze grafiek toont hoe <strong>opgewekt</strong> de invuller van het dagboek was. De <strong>periode</strong>{" "}
          die je ziet kan je <strong>aanpassen</strong>.
        </p>
        <p>
          De lijn met stippen laat het <strong>gemiddelde</strong> zien. De blauwe balk er omheen toont de{" "}
          <strong>hoogste</strong> en <strong>laagste meting</strong> per meting. Dat noemen we het bereik.
        </p>
      </div>
      <div className="span6">
        <h5>De grafiek aanpassen</h5>
        <p>
          De data die weergegeven wordt kan je aanpassen door rechts van de grafiek <strong>meer items</strong> aan te
          vinken.
        </p>
      </div>
    </div>
  );
};

const wordcloud = () => {
  return (
    <div className="row-fluid more-information">
      <div className="span6">
        <h5>Wat betekent de figuur?</h5>
        <p>
          Je ziet een wolk van woorden. Deze woorden komen uit de vrije tekstvelden van het dagboek. Hoe groter het
          woord, hoe vaker het in de antwoorden voorkomt.
        </p>
        <p>
          Klik op een woord om te zien in welke context het gebruikt is. Klik op het moment om te zien hoe de stemming
          op die dag was.
        </p>
      </div>
      <div className="span6">
        <h5>De grafiek aanpassen</h5>
        <p>Je kan deze grafiek niet aanpassen.</p>
      </div>
    </div>
  );
};

const situatie = (variable: string) => {
  return (
    <div className="row-fluid more-information">
      <div className="span6">
        <h5>Wat betekent de grafiek?</h5>
        <p>
          Je ziet {variable} afgezet tegen een dagboekitem. De grafiek begint altijd met ‘opgewektheid’. Hoe{" "}
          <strong>verder</strong> de stip naar <strong>rechts</strong> staat, hoe <strong>opgewekter</strong> het
          moment.
        </p>
        <p>
          Zet je de metingen aan? Dan zie je kleine stippen verschijnen. <strong>Meer stippen</strong> betekent{" "}
          <strong>meer metingen</strong>.
        </p>
        <p>
          <strong>Bespreek samen</strong> wat deze gegevens precies betekenen. Heeft {variable} effect op stemming, of
          andersom?
        </p>
      </div>
      <div className="span6">
        <h5>De grafiek aanpassen</h5>
        <p>
          De data die weergegeven wordt kan je aanpassen door rechts van de grafiek <strong>meer items</strong> aan te
          vinken. Kies je meerdere items, dan zie je die in het groene blok terug.
        </p>
        <p>
          Je kunt ook de <strong>zelf ingevoerde vragen</strong> tonen in relatie tot {variable}. Dit werkt op dezelfde
          manier.
        </p>
      </div>
    </div>
  );
};

// The MoreInformation component is used by the TabGraph component
// to display additional information about the active tab's graph.
const MoreInformation: React.FunctionComponent<Props> = (props) => {
  switch (props.moreInformationKey) {
    case "stemming":
      return stemming();
    case "stemming-daily-and-weekly":
      return stemmingDailyAndWeekly();
    case "wordcloud":
      return wordcloud();
    case "situatie-activiteit":
      return situatie("activiteit");
    case "situatie-gezelschap":
      return situatie("gezelschap");
    case "situatie-locatie":
      return situatie("locatie");
    case "situatie-eten":
      return situatie("eten");
    case "situatie-middelen":
      return situatie("middelen");
    case "situatie-lichamelijke-klachten":
      return situatie("lichamelijke klachten");
    case "situatie-coping-met-stemmen":
      return situatie("coping met stemmen");
    case "situatie-bijwerkingen":
      return situatie("bijwerkingen");
    case "situatie-met-wie-ben-ik":
      return situatie("met wie ben ik");
    case "situatie-gesprekken":
      return situatie("gesprekken");
  }
};

export default MoreInformation;
