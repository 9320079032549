import React from "react";
import { renderReactToString } from "./ReactToString";
import { TooltipFormatterCallbackFunction } from "highcharts";

export interface Props {
  change: number;
  absolute: number;
}

function formatLabel(props: Props): string {
  if (props.change > 0) {
    return I18n.t("epd_area.outcome.clinical_relevant_improvement", { change: props.absolute });
  } else if (props.change < 0) {
    return I18n.t("epd_area.outcome.clinical_relevant_deterioration", { change: props.absolute });
  } else {
    return "";
  }
}

export const OQ45Tooltip: React.FunctionComponent<Props> = (props) => {
  const label = formatLabel(props);
  return <span style={{ fontSize: "10px" }} dangerouslySetInnerHTML={{ __html: label }} />;
};

// Highcharts will rebind this to the chart instance, which means that `this` will point
// to an object containing all the settings needed to format a tooltip.
//
// NOTE: This needs to explicitly be *function()* and not an arrow. The arrow would
// prevent Highcharts from rebinding _this_ to the chart.
export function tooltipFormatter() {
  if (this.series.index === 1) {
    return false;
  }

  const current = this.series.chart.options.series[1] && this.series.chart.options.series[1].data && this.series.chart.options.series[1].data[this.point.x];
  const previous = this.point.y;
  const crc = this.series.chart.options.series[1] && this.series.chart.options.series[1].clinically_relevant_change;
  const change = current - previous;
  const absolute = Math.abs(change);

  if (absolute < crc) {
    return false;
  }

  const props = { change, absolute };
  const element = <OQ45Tooltip {...props} />;
  return renderReactToString(element);
}
