import React, { useState, useMemo } from "react";
import Joyride, { CallBackProps, Step, ACTIONS, EVENTS } from "react-joyride";

interface Props {
  steps: Array<Step>;
  id: string;
}

// Wrapper around the Joyride component to set options that all PETRA tips have in common.
// The ID is used to save the state for the tour. Steps seen by the user are saved in localStorage.
// The tour is "reset" (=shown from the start) if the provided ID changes or if a user empties their localStorage.
// If steps are added, users who have already completed the tour previously will see a beacon to complete the
// tour starting from the newly added steps.
const MyJoyride: React.FunctionComponent<Props> = (props: Props) => {
  const storageKey = `joyride-${props.id}`;
  const [continuous, setContinuous] = useState<boolean>(true);
  const [stepIndex, setStepIndex] = useState<number>(parseInt(localStorage.getItem(storageKey) || "0"));
  // Initialize run to false iff we don't want to show the tour. We use a `useMemo` here because
  // we are only interested in setting `run` once (when we load the page), based on what value was in the
  // localStorage at that time (to determine if we should show a beacon or not for this tour).
  // stepIndex as retrieved from localStorage is the next step to show (which is equivalent to the number of
  // steps the user has seen).
  const run = useMemo(() => {
    return stepIndex < props.steps.length;
  }, []);

  // Handle the callback from react-joyride. See https://docs.react-joyride.com/callback
  const handleCallback = (data: CallBackProps): void => {
    const { action, index, type } = data;
    // The type EVENTS.TARTGET_NOT_FOUND is called when the target of a step cannot be found. In this
    //   case, we proceed to the next step.
    // The type EVENTS.STEP_AFTER is always called after a user has reacted to a step card.
    //   It can be called with one of three actions:
    //   - ACTIONS.PREV:  user clicked the "previous" button.
    //   - ACTIONS.NEXT:  user clicked the "next" or "finish" button.
    //                    The tour will proceed to show next step card if there is one.
    //   - ACTIONS.CLOSE: user clicked on the close button or outside the step card.
    //                    The tour is interrupted.
    //                    Since a user has seen the current card, we increase the stepIndex.
    //                    If there is a next step, the beacon will light up at that step's position,
    //                    and when a user clicks the beacon, the tour will continue from that (next) step.
    //                    Interrupting the tour is something that is done by react-joyride internally,
    //                    i.e., we don't need to set `run` to `false` to cause it to close the tour or
    //                    something.
    if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      // We're done with the current step, so change it.
      const newStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setStepIndex(newStepIndex);
      // Persist the new stepIndex.
      if (`${newStepIndex}` !== localStorage.getItem(storageKey)) {
        localStorage.setItem(storageKey, `${newStepIndex}`);
      }
    } else if (action === ACTIONS.START && type === EVENTS.TOUR_START) {
      // This is to get it not to open when we navigate to the page if a tour is in progress.
      // Setting `continuous` to false makes it so that it doesn't immediately open the tour if
      // the stepIndex is > 0 and < stepCount. But we actually do want `continuous` to be true
      // (because after showing one step we want to go to the next step, and not close the tour),
      // so we wait a delay and then set continuous back to true. I couldn't get this to work
      // reliably in any other way.
      setContinuous(false);
      // Doesn't work when mocking the component, because it tries to fire after it is already gone.
      if (process.env.NODE_ENV !== "test") setTimeout(() => setContinuous(true), 500);
    }
  };

  return (
    <Joyride
      callback={handleCallback}
      steps={props.steps}
      locale={joyrideLocale}
      stepIndex={stepIndex}
      run={run}
      styles={{
        tooltipContainer: { textAlign: "left" },
        options: {
          zIndex: 1040,
          primaryColor: "#e96117",
        },
      }}
      continuous={continuous}
      disableScrollParentFix // Needs to be true or else scrolling doesn't work after running a tour.
      scrollOffset={70} // To take into account the RoQua top menu banner.
    />
  );
};

const joyrideLocale = {
  back: "Terug",
  close: "Sluiten",
  last: "Klaar",
  next: "Volgende",
  open: "Open de uitleg over deze pagina",
  skip: "Stoppen",
};

export default MyJoyride;
