/* global moment */

// moment.js language configuration
// language : dutch (nl)
// author : Joris Röling : https://github.com/jjupiter

var monthsShortWithDots = "jan._feb._mrt._apr._mei_jun._jul._aug._sep._okt._nov._dec.".split(
    "_"
  ),
  monthsShortWithoutDots = "jan_feb_mrt_apr_mei_jun_jul_aug_sep_okt_nov_dec".split(
    "_"
  );

moment.locale("nl", {
  months: "januari_februari_maart_april_mei_juni_juli_augustus_september_oktober_november_december".split(
    "_"
  ),
  monthsShort: function(m, format) {
    if (/-MMM-/.test(format)) {
      return monthsShortWithoutDots[m.month()];
    } else {
      return monthsShortWithDots[m.month()];
    }
  },
  weekdays: "zondag_maandag_dinsdag_woensdag_donderdag_vrijdag_zaterdag".split(
    "_"
  ),
  weekdaysShort: "zo._ma._di._wo._do._vr._za.".split("_"),
  weekdaysMin: "Zo_Ma_Di_Wo_Do_Vr_Za".split("_"),
  longDateFormat: {
    LT: "HH:mm",
    L: "DD-MM-YYYY",
    LL: "D MMMM YYYY",
    LLL: "D MMMM YYYY LT",
    LLLL: "dddd D MMMM YYYY LT",
  },
  calendar: {
    sameDay: "[Vandaag om] LT",
    nextDay: "[Morgen om] LT",
    nextWeek: "dddd [om] LT",
    lastDay: "[Gisteren om] LT",
    lastWeek: "[afgelopen] dddd [om] LT",
    sameElse: "L",
  },
  relativeTime: {
    future: "over %s",
    past: "%s geleden",
    s: "een paar seconden",
    m: "één minuut",
    mm: "%d minuten",
    h: "één uur",
    hh: "%d uur",
    d: "één dag",
    dd: "%d dagen",
    M: "één maand",
    MM: "%d maanden",
    y: "één jaar",
    yy: "%d jaar",
  },
  ordinal: function(number) {
    return (
      number + (number === 1 || number === 8 || number >= 20 ? "ste" : "de")
    );
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 4, // The week that contains Jan 4th is the first week of the year.
  },
});
