import * as React from "react";
import { Results } from "./Results";
import * as I18n from "i18n-js";
import { IQuestionnaire } from "models";

interface ISearchProps {
  questionnaires: IQuestionnaire[];
  addedQuestionnaires: IQuestionnaire[];
  onSelect: (questionnaire: IQuestionnaire) => void;
}

export const Search = (props: ISearchProps) => {
  const questionnaireFilter = React.useRef<HTMLInputElement>(null);
  const [query, setQuery] = React.useState("");

  const focusSearchField = () => {
    questionnaireFilter.current && questionnaireFilter.current.focus();
  };
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };
  const onSelect = (questionnaire) => {
    props.onSelect(questionnaire);
    focusSearchField();
  };

  React.useEffect(focusSearchField);

  return (
    <div>
      <input
        placeholder={I18n.t("admin_area.measured_questionnaires.search")}
        type="text"
        onChange={onChange}
        ref={questionnaireFilter}
        value={query}
        autoFocus
      />

      <Results
        questionnaires={props.questionnaires}
        addedQuestionnaires={props.addedQuestionnaires}
        onSelect={onSelect}
        query={query}
      />
    </div>
  );
};
