import React from "react";
import { ResultResponseWithCache } from "../../common/Graphs";
import BestMoodBlock from "./BestMoodBlock";
import PercentageFilledOutBlock from "./PercentageFilledOutBlock";

interface Props {
  responsesWithCache: ResultResponseWithCache[];
}

// Render four squares with summarized information about the diary study
const ResultsSummary: React.FunctionComponent<Props> = ({ responsesWithCache }) => {
  const now = new Date();
  const pastResponses = responsesWithCache.filter((rc) => rc.response.openFrom && new Date(rc.response.openFrom) < now);
  const passedProps: BlockProps = { responsesWithCache: pastResponses };

  // Exclude blocks that rendered to null
  const renderedBlocks = renderAvailableBlocks(passedProps).filter((block) => block.renderedContent);
  // Get the four blocks with highest priority
  const sortedBlocks = renderedBlocks.slice(0, NR_BLOCKS_TO_SHOW);

  return (
    <div className="petra-wells">
      {sortedBlocks.map((block) => (
        <React.Fragment key={block.key}>{block.renderedContent}</React.Fragment>
      ))}
    </div>
  );
};

// All blocks are called with these properties
export interface BlockProps {
  responsesWithCache: ResultResponseWithCache[];
}

interface BlockDescription {
  component: React.FunctionComponent<BlockProps>;
  key: string;
}

interface RenderedBlockDescription {
  renderedContent: any;
  key: string;
}

const EmptyBlock: React.FunctionComponent<BlockProps> = () => <div className="petra-well" />;

// Show four blocks.
const NR_BLOCKS_TO_SHOW = 3;

// Include four empty blocks in case we have no responses.
const AVAILABLE_BLOCKS: BlockDescription[] = [
  { component: BestMoodBlock, key: "bmb" },
  { component: PercentageFilledOutBlock, key: "pfob" },
  { component: EmptyBlock, key: "empty1" },
  { component: EmptyBlock, key: "empty2" },
  { component: EmptyBlock, key: "empty3" },
  { component: EmptyBlock, key: "empty4" },
];

const renderAvailableBlocks = (passedProps: BlockProps): RenderedBlockDescription[] => {
  return AVAILABLE_BLOCKS.map((Block) => {
    return {
      renderedContent: <Block.component {...passedProps} />,
      key: Block.key,
    };
  });
};

export default ResultsSummary;
