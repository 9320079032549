import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "components/ui/datatable";
import { withRailsMountable } from "logic/rails_component";
import React from "react";

interface Questionnaire {
  id: string;
  key: string;
  name: string;
  short_description: string;
  license: string;
  url: string;
}

export const QuestionnaireOverview: React.FC<{ questionnaires: Questionnaire[] }> = (props) => {
  const columnHelper = createColumnHelper<Questionnaire>();
  const columns = [
    columnHelper.accessor("name", {
      header: "Naam",
      size: 400,
      meta: { enableGlobalFilter: true },
      cell: (info) => (
        <a href={info.row.original.url} title={info.getValue()}>
          {info.getValue()}
        </a>
      ),
    }),
    columnHelper.accessor("short_description", {
      header: "Omschrijving",
      size: 600,
      meta: { enableGlobalFilter: true },
    }),
    columnHelper.accessor("license", {
      header: "Licentie",
      size: 200,
      cell: (info) => I18n.t(info.getValue(), { scope: "activerecord.attributes.questionnaire.license_types" }),
    }),
  ];

  return <DataTable columns={columns} data={props.questionnaires} />;
};

export default withRailsMountable(QuestionnaireOverview);
