import * as jQuery from "jquery";
import { first, uniq } from "lodash";
import { arrayToSentence } from "logic/array";

export interface XHRCallbacks {
  success?: (response?: any) => void;
  error?: () => void;
  complete?: () => void;
}

export const updateMeasuredQuestionnairesOrder = (protocol_id, measurement_id, order) => {
  return jQuery.ajax(`/admin/protocols/${protocol_id}/measurements/${measurement_id}/measured_questionnaires/reorder`, {
    type: "patch",
    data: { order: order },
  });
};

export const updateMeasurementsOrder = (protocol_id, order) => {
  return jQuery.ajax(`/admin/protocols/${protocol_id}/measurements/reorder`, {
    type: "patch",
    data: { order: order },
  });
};

export const createNonResponse = (nonResponse, callbacks: XHRCallbacks = {}) => {
  return jQuery.ajax(RoQua.epdPath(["api", "non_responses"]), {
    type: "post",
    data: nonResponse.toJSON(),
    success: (response) => {
      if (response.id) {
        RoQua.timeline.loadAgain();
        RoQua.timeline.selected = { non_response_ids: [response.id] };
      }
      callbacks.success && callbacks.success(response);
    },
    error: () => callbacks.error && callbacks.error(),
    complete: () => callbacks.complete && callbacks.complete(),
  });
};

export const updateNonResponse = (nonResponse, data, callbacks: XHRCallbacks = {}) => {
  nonResponse.set(data);
  return nonResponse.sync("update", nonResponse, {
    url: RoQua.epdPath(["api", "non_responses", nonResponse.id]),
    complete: () => callbacks.complete && callbacks.complete(),
    success: (response) => {
      RoQua.timeline.loadAgain();
      RoQua.showFlash({ state: "success", message: I18n.t("epd_area.app.non_response.update_success") });
      callbacks.success && callbacks.success();
    },
    error: () => {
      RoQua.showFlash({ state: "error", message: I18n.t("epd_area.app.non_response.update_failure") });
      callbacks.error && callbacks.error();
    },
  });
};

export const destroyNonResponse = (nonResponse, callbacks: XHRCallbacks = {}) => {
  return nonResponse.destroy({
    complete: () => {
      RoQua.timeline.loadAgain();
      callbacks.complete && callbacks.complete();
    },
    success: () => {
      RoQua.showFlash({ state: "success", message: I18n.t("epd_area.app.non_response.destroy_success") });
      callbacks.success && callbacks.success();
    },
    error: () => {
      RoQua.showFlash({ state: "error", message: I18n.t("epd_area.app.non_response.destroy_failure") });
      callbacks.error && callbacks.error();
    },
  });
};

export const deletePendingAnswers = (pendingAnswers, nonResponse, callbacks: XHRCallbacks = {}) => {
  const answer_ids = pendingAnswers.pluck("id");
  const non_response = nonResponse ? nonResponse.toJSON() : undefined;

  return jQuery.ajax(RoQua.epdPath(["api", "answers", "destroy_all"]), {
    type: "post",
    data: { answer_ids, non_response },
    success: (response) => {
      BackboneRelational.store.reset();
      const fillOutTasksRefetch = RoQua.fillOutTasks.fetch({ reset: true });
      let timelineRefetch;

      if (response.non_response_id) {
        timelineRefetch = RoQua.timeline.loadAgain();
        RoQua.timeline.selected = { non_response_ids: [response.non_response_id] };
      }

      jQuery.when([fillOutTasksRefetch, timelineRefetch]).done(() => {
        _showDeletedPendingAnswersFlash(pendingAnswers);
        callbacks.success && callbacks.success();
      });
    },
    error: () => {
      _showFailureToDeletePendingAnswersFlash(pendingAnswers);
      callbacks.error && callbacks.error();
    },
    complete: () => {
      callbacks.complete && callbacks.complete();
    },
  });
};

export const updatePendingAnswerOrder = (fill_out_task_id, order, callbacks: XHRCallbacks = {}) => {
  return jQuery.ajax(RoQua.epdPath(["api", "answers", "reorder"]), {
    type: "patch",
    data: {
      fill_out_task_id: fill_out_task_id,
      order: order,
    },
    success: (response) => callbacks.success && callbacks.success(response),
    error: () => callbacks.error && callbacks.error(),
    complete: () => callbacks.complete && callbacks.complete(),
  });
};

const _showDeletedPendingAnswersFlash = (pendingAnswers) => {
  const deleted = uniq(pendingAnswers.pluck("questionnaire_name"));
  let message;

  if (deleted.length > 1) {
    message = I18n.t("epd_area.pending_answers.deleted.other", { questionnaires: arrayToSentence(deleted) });
  } else {
    message = I18n.t("epd_area.pending_answers.deleted.one", { questionnaire: first(deleted) });
  }
  RoQua.showFlash({ state: "success", message: message });
};

const _showFailureToDeletePendingAnswersFlash = (pendingAnswers) => {
  const deleted = uniq(pendingAnswers.pluck("questionnaire_name"));
  let message;
  if (deleted.length > 1) {
    message = I18n.t("epd_area.pending_answers.delete_failed.other", { questionnaires: arrayToSentence(deleted) });
  } else {
    message = I18n.t("epd_area.pending_answers.delete_failed.one", { questionnaire: first(deleted) });
  }
  RoQua.showFlash({ state: "error", message: message });
};

export const updateAutoProtocolsOrder = (order: (string | number)[]) => {
  return jQuery.ajax("/admin/auto_protocols/reorder", {
    type: "patch",
    data: { order: order },
  });
};

export const updateProtocolsOrder = (order: (string | number)[]) => {
  return jQuery.ajax("/admin/protocols/reorder", {
    type: "patch",
    data: { order: order },
  });
};
