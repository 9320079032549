export function underscore(str: string) {
  if (!ActiveSupport) return undefined;
  return ActiveSupport.String.underscore(str.toString());
}

export function pluralize(str: string) {
  if (!ActiveSupport) return undefined;
  return ActiveSupport.String.pluralize(str.toString());
}

export function humanize(str: string) {
  return (
    str
      .toString()
      .charAt(0)
      .toUpperCase() +
    str
      .toString()
      .slice(1)
      .replace(/_/g, " ")
  );
}

export function trim(str: string) {
  if (!ActiveSupport) return undefined;
  return ActiveSupport.String.trim(str.toString());
}

const TAGS = /<\/?([a-z][a-z0-9]*)\b[^>]*>/g;
const COMMENTS = /<!--[\s\S]*?-->/g;

export function stripTags(str: string, allowed = "") {
  const allowedLowercase = ((allowed + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join("");
  return str
    .toString()
    .replace(COMMENTS, "")
    .replace(TAGS, ($0, $1) => (allowedLowercase.indexOf("<" + $1.toLowerCase() + ">") > -1 ? $0 : ""));
}

export function ensureType<T extends string>(str: any, set: T[]): T {
  // This if statement verifies that str is in the set so
  // we can feel good about using a type assertion below.
  if (typeof str === 'string' && (set as string[]).includes(str)) {
    return (str as T);
  }
  throw new Error(`${str} not in ${set}`);
}
