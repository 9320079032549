import React, { useEffect, useRef, useState } from "react";
import { DATE_PICKER_DEFAULT_OPTIONS } from "../common/Constants";
import ResponsesSummary from "./ResponsesSummary";
// TODO: The react-sliding-pane is basically our "overlay" that is used in the timeline.
//       Maybe it is better to not have two separate implementations solving the same problem.
import SlidingPane from "react-sliding-pane";
import { enUS, nl } from "date-fns/locale";
import { startOfDay, endOfDay } from "date-fns";
import { ResultResponseWithCache } from "../common/Graphs";
import DatePicker from "react-datepicker";
import { trackEvent } from "../common/Matomo";
import classNames from "classnames";

const locales = { en: enUS, nl };

interface Props {
  isOpen: boolean;
  setOpen: (boolean) => void;
  responsesWithCache: ResultResponseWithCache[];
  openToDate: Date | null;
}

// Render the slideover for the results page to give context for the graph points.
const ResultsSlideOver: React.FunctionComponent<Props> = (props) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [query, setQuery] = useState("");
  const highlightRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);

  // Reference to keep track of the search field for input focusing.
  const searchRef = useRef<HTMLInputElement>(null);

  // When opening the slide over, set focus to the search box so that
  // the user can start typing immediately.
  const setSearchFieldFocus = () => {
    trackEvent({
      category: "Petra - results - Open context drawer",
      name: props.responsesWithCache[0].questionnaire.key,
    });
    if (props.isOpen && searchRef.current) {
      searchRef.current.focus();
    }
    highlightRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  // When we click the 'X' button in the search field, clear it and focus it.
  const clearAndFocusSearch = () => {
    setQuery("");
    if (searchRef.current) {
      searchRef.current.focus();
    }
  };

  // Clear the end date when the start date passes it.
  useEffect(() => {
    if (startDate && endDate && startDate >= endDate) {
      setEndDate(null);
    }
  }, [startDate]);

  // If we click on a point in the graph, filter to responses from that date.
  useEffect(() => {
    if (props.openToDate) {
      setStartDate(startOfDay(props.openToDate));
      setEndDate(endOfDay(props.openToDate));
    }
  }, [props.openToDate]);

  const [minDate, maxDate] = determineMinAndMaxDate(props.responsesWithCache);
  const locale = locales[window.dateFnsLocale];

  return (
    <SlidingPane
      className="petra"
      closeIcon={<div />}
      isOpen={props.isOpen}
      title="Context bij dagboekmetingen"
      from="right"
      width="600px"
      onRequestClose={() => props.setOpen(false)}
      onAfterOpen={setSearchFieldFocus}
    >
      <div className="results slide-over">
        <div className="petra-main">
          <div className="colored-header">
            <div className="header-close-button" onClick={() => props.setOpen(false)} />
            <h2>Context bij dagboekmetingen</h2>
          </div>
          <div className="petra-content">
            <p>
              Je ziet hier alle vrije opmerkingen die gemaakt zijn bij het invullen van dit dagboek. Je kunt zoeken op
              periode of op trefwoord.
            </p>
            <div className="contains-petra-arrow sticky">
              <div className="search-field">
                <div>
                  <label htmlFor="search">Trefwoord</label>
                  <input
                    id="search"
                    ref={searchRef}
                    type="search"
                    placeholder=""
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                  {query.length > 0 && (
                    <button className="clear-search" onClick={clearAndFocusSearch}>
                      ✖
                    </button>
                  )}
                </div>
                <div>
                  <label htmlFor="start">Periode</label>
                  <div className="contains-clear-date-field">
                    <DatePicker
                      id="start"
                      name="start-date"
                      {...DATE_PICKER_DEFAULT_OPTIONS}
                      selected={startDate}
                      onChange={(date) => setStartDate(date ? startOfDay(date) : date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      minDate={minDate}
                      maxDate={maxDate}
                      className={classNames("date-box", { filled: startDate })}
                      locale={locale}
                      openToDate={startDate ?? minDate}
                      isClearable
                    />
                  </div>
                  <div className="input-and">t/m</div>
                  <div className="contains-clear-date-field">
                    <DatePicker
                      id="end"
                      name="end-date"
                      {...DATE_PICKER_DEFAULT_OPTIONS}
                      selected={endDate}
                      onChange={(date) => setEndDate(date ? endOfDay(date) : date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate ?? minDate}
                      maxDate={maxDate}
                      className={classNames("date-box", { filled: endDate })}
                      locale={locale}
                      openToDate={endDate ?? maxDate}
                      isClearable
                    />
                  </div>
                </div>
              </div>
              <div className="petra-arrow-down search" />
            </div>
            <ResponsesSummary
              responsesWithCache={props.responsesWithCache}
              query={query.toLowerCase()}
              startDate={startDate}
              endDate={endDate}
              openToDate={props.openToDate}
              highlightRef={highlightRef}
            />
          </div>
        </div>
      </div>
    </SlidingPane>
  );
};

// Determine the min and max date the date pickers can choose from based on the
// dates that the responses were completed at or opened.
export const determineMinAndMaxDate = (responsesWithCache: ResultResponseWithCache[]) => {
  let minDate = new Date();
  let maxDate = new Date(0);
  responsesWithCache.forEach((rc) => {
    // Don't consider responses with status "scheduled" etc. when determining the min and max date.
    if (rc.response.status !== "completed") return;

    const responseDateString = rc.response.completedAt || rc.response.openFrom;
    if (!responseDateString) return;

    const responseDate = new Date(responseDateString);
    minDate = responseDate < minDate ? responseDate : minDate;
    maxDate = responseDate > maxDate ? responseDate : maxDate;
  });
  // If we don't have any responses with dates, then ignore min and max date.
  if (maxDate < minDate) {
    maxDate = new Date();
    minDate = new Date(0);
  }
  // Allow including items on the min and max date themselves.
  minDate = startOfDay(minDate);
  maxDate = endOfDay(maxDate);
  return [minDate, maxDate];
};

export default ResultsSlideOver;
