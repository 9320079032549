import { Ace } from "ace-builds";
import { setCompleters } from 'ace-builds/src-noconflict/ext-language_tools';
import isEqual from 'lodash/isEqual';

export interface ClassProperties {
  [key: string]: {
    caption: string;
  };
}

export interface TreeNodes {
  className?: string;
  caption?: string;
  nodes?: { [key: string]: TreeNodes[]}
}

export interface TreeCompleterConfig {
  classes: ClassProperties;
  nodes: TreeNodes;
  parentTokensForCache?: string[];
  cache?: Ace.Completion[];
}

interface EditSession extends Ace.EditSession {
  roquaTreeCompleter?: TreeCompleterConfig;
}

// Called on componentDidMount.
// Save config in session, since you can only add completers globally, and config for it is instance specific.
export const configureTreeCompleter = (editor: Ace.Editor, treeConfig: TreeCompleterConfig) => {
  const session = editor.session as EditSession;
  session.roquaTreeCompleter = treeConfig;

  // When adding the dot, the old completer finds no matches and therefore closes.
  // New results will not be calculated until another character has been typed.
  // Very annoying, so we try to open it on every dot anew, nothing will open when there are no matches.
  editor.on('change', (delta) => {
    if (delta.action === 'insert' && delta.lines[delta.lines.length - 1] === '.') {
      setTimeout(() => editor.commands.byName.startAutocomplete.exec(editor), 10);
    }
  });
};

// We use a cache, since otherwise every keystroke after a no-match will calculate the same list again.
// Note: As long as there's one match, no new completions are calculated,
//       finishing the last match or the first character that leaves no matches close the autocomplete with no new calculations done,
//       the character after that will then open the autocomplete again with new completions calculated,
//       every character after a no-match will recalculate.
// Note: callback must always be called exactly once.
export const aceTreeCompleter: Ace.Completer = {
  // By default it doesn't match a `.`, which we explicitly want
  identifierRegexps: [/[.a-zA-Z_0-9]/],
  getCompletions: function(editor, session: EditSession, pos, prefix, callback) {
    try {
      const config = session.roquaTreeCompleter;
      if (!config) {
        // useEffect callback that sets the config didn't run yet, so return no completions for now.
        callback(null, []);
        return
      }

      // This should work, but within brackets our identifierRegexps are ignored
      // const token = session.getTokenAt(pos.row, pos.column);
      // so instead we get the token ourselves
      const token = getFullToken(session, pos);
      if (!token) return callback(null, []);

      // if (token.type.lastIndexOf('identifier') > -1 || token)
      const tokens =  token.split('.')
      const parentTokens = tokens.slice(0, -1);
      const lastToken = tokens[tokens.length - 1];

      if (isEqual(parentTokens, config.parentTokensForCache) && config.cache)
        return callback(null, config.cache);

      const lastParent = parentTokens.reduce((parent, token) => parent.nodes[token], config);
      if (!lastParent) return callback(null, []);

      const results = [
        ...resultsFromChildren(config.classes[lastParent.className], {parentTokens: parentTokens, score: 99}),
        ...resultsFromChildren(lastParent.nodes, {parentTokens: parentTokens, score: 88})
      ]
      config.parentTokensForCache = parentTokens;
      config.cache = results;

      callback(null, results);
    } catch (error) {
      // throwing error in this function gives weird multiple characters appearing on typing sometimes (v.1.7.1)
      console.log(error)
      callback(null, []);
    }
  }
};

// Return what getTokenAt should return, all identifiers joined by dots around cursor.
const getFullToken = (session: EditSession, pos: Ace.Point): null | string => {
  const currentToken = session.getTokenAt(pos.row, pos.column);
  if (!currentToken || !identifierOrDot(currentToken)) return null;
  const tokens = session.getTokens(pos.row);
  const currentIndex = tokens.findIndex((token) => token.start) // current token has index and start prop.

  let firstIndex = currentIndex;
  while(identifierOrDot(tokens[firstIndex - 1])) --firstIndex;

  let lastIndex = currentIndex;
  while(identifierOrDot(tokens[lastIndex + 1])) ++lastIndex;

  return tokens.slice(firstIndex, lastIndex + 1).map((token) => token.value).join('');
};

// identifier tokens are strings within {{ }} or {% %}.
// { vrgl. }} last text token will be `. ` so we need to trim.
const identifierOrDot = (token: Ace.Token | undefined) => token && (token.value.trimEnd() === '.' || token.type === 'identifier');

setCompleters([aceTreeCompleter]);

interface ResultsFromChildrenProps {
  parentTokens: string[];
  score: number;
}


const resultsFromChildren = (children: ClassProperties | TreeNodes, {parentTokens, score}: ResultsFromChildrenProps): Ace.Completion[] => {
  if (!children) return []

  return Object.entries(children).map(([childKey, child]) => ({
    name: childKey,
    value: [...parentTokens, childKey].join('.'),
    score: score,
    meta: child.caption
  }))
}
