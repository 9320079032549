export type Scheduler = "NullScheduler" | "IntervalScheduler" | "DailyScheduler" | "FixedScheduler" | "RandomScheduler";

export interface ScheduleItem {
  offset: number;
  measurement_id: number;
}

export interface Notification {
  event: string;
  notifier: string;
  delay: number;
}

export interface BaseSettings {
  notifications: Notification[];
  measurement_amount: number;
  expire_meas_instances_after: number;
  bcc: string;
  measurement_id: number | null;
}

export interface NullSchedulerSettings extends BaseSettings {
  scheduler: "NullScheduler";
}

export interface DailySchedulerSettings extends BaseSettings {
  scheduler: "DailyScheduler";
  schedule: ScheduleItem[];
  daily_start_time: number;
}

export interface FixedSchedulerSettings extends BaseSettings {
  scheduler: "FixedScheduler";
  schedule: ScheduleItem[];
}

export interface IntervalSchedulerSettings extends BaseSettings {
  scheduler: "IntervalScheduler";
  interval: number;
  measurement_id: number | null;
}

export interface RandomSchedulerSettings extends BaseSettings {
  scheduler: "RandomScheduler";
  block_duration: number;
  blocks_per_day: number;
  min_time_between_beeps: number;
  measurement_id: number | null;
  daily_start_time: number;
}

export type Settings = NullSchedulerSettings | FixedSchedulerSettings | DailySchedulerSettings | IntervalSchedulerSettings | RandomSchedulerSettings;

export interface Measurement {
  id: number;
  name: string;
}

export const DEFAULT_SETTINGS = {
  IntervalSchedulerSettings: {},
  DailyScheduler: {schedule: []},
  RandomScheduler: {
    block_duration: 4 * 60 * 60,
    blocks_per_day: 4,
    min_time_between_beeps: 60 * 60
  },
  FixedScheduler: {schedule: []},
}
