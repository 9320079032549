import React, { useState } from "react";
import classNames from "classnames";

interface Props {
  title: string;
  initiallyExpanded?: boolean;
  orange?: boolean;
}

// The sidemenu is a way to display an orange or green dropdown that can be expanded
// by clicking on the header. It has a title, and anything that should be shown inside
// of the expanded menu can be given as children props.
// It is used in the TabGraphOptions component.
const SideMenu: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  title,
  initiallyExpanded = false,
  orange = false,
  children,
}) => {
  const [expanded, setExpanded] = useState<boolean>(initiallyExpanded);
  return (
    <div className={classNames("side-menu", { orange: orange })}>
      <div className={classNames("side-menu-header", { expanded: expanded })} onClick={() => setExpanded(!expanded)}>
        <div className="side-menu-title">{title}</div>
        <div className={classNames("side-menu-expansion-arrow", { expanded: expanded })} />
      </div>
      {expanded && <div className="side-menu-body">{children}</div>}
    </div>
  );
};

export default SideMenu;
