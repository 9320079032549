import React, { useMemo } from "react";
import FlagWithoutChildren from "./FlagWithoutChildren";
import FlagWithChildren from "./FlagWithChildren";
import { currentflagMatchesQuery, FlagInterface, hasChildren, matchesQuery } from "../../common/Flags";
import { MIN_SEARCH_QUERY_LENGTH } from "../../common/Constants";
import { Key } from "../../common/Selections";

interface Props {
  currentFlag: Key;
  flagExpansion: Selections<Key>;
  flags: FlagInterface;
  matching: boolean;
  query: string;
  setShowExplanation: (boolean) => void;
}

// Renders a flag and its descendants if any of these match the given query,
// or if the given query is below the minimum required length.
// props.matching is a boolean that is true iff any ancestor of this flag matches
// the search query. It is false for domains (= top-level flags).
const Flag: React.FunctionComponent<Props> = (props) => {
  // Cache the result of whether or not the current flag has child flags.
  const flagWithChildren: boolean = useMemo(() => hasChildren(props.currentFlag, props.flags.flags), [
    props.flags,
    props.currentFlag,
  ]);

  // If we have a valid search query, we may or may not render the current flag,
  // depending on whether the query matches the current flag or any of its descendants.
  if (props.query.length >= MIN_SEARCH_QUERY_LENGTH) {
    // Define `queryMatches` as a boolean that is true iff the current flag or any of
    // its descendants matches the given search query.
    const queryMatches = matchesQuery(props.query, props.currentFlag, props.flags.flags);
    // If the current flag does not match the search query, and neither does any of its
    // ancestors or descendants, we render nothing.
    if (!props.matching && !queryMatches) {
      return <></>;
    }
    // When we are here, we know that either an ancestor, or the current flag,
    // or a descendant matches the search query.
    // The variable `currentlyMatching` is true if the current flag matches the search query.
    const currentlyMatching = currentflagMatchesQuery(props.query, props.currentFlag, props.flags.flags);
    if (flagWithChildren) {
      // If the current flag matches the search query, then from now on the
      // `matching` prop is passed along as true, and also `currentlyMatching`
      // is true. The `currentlyMatching` property is used to display the text
      // of the flag in boldface.
      // If the current flag matches the search query,
      // make sure that it is expanded (by means of the expandIt override).
      if (currentlyMatching) {
        return <FlagWithChildren {...props} matching={true} currentlyMatching={true} expandIt={true} />;
      }
      // If the current flag does not match the search query, do not change the value
      // of the `matching` parameter but simply pass it along, and `currentlyMatching`
      // is false.
      // If any of its descendants match the search query, we expand it, otherwise we let the expansion
      // state be controlled by the user (expandIt ==== undefined).
      // This makes it so that the user can freely expand and explore all
      // descendents of any matching flag after having entered a search query.
      return <FlagWithChildren {...props} currentlyMatching={false} expandIt={queryMatches || undefined} />;
    }
    // If the current flag does not have child flags, simply draw the checkbox.
    // This allows us to toggle expansion of flags by hand after having entered
    // a search query.
    // That is, if a flag matches the search query, we can also choose to expand
    // and see any of its descendant flags.
    return <FlagWithoutChildren {...props} currentlyMatching={currentlyMatching} />;
  }

  // If there is no valid search query, we always render this flag.
  // Render the flag with the `FlagWithChildren` component if it has children, and
  // otherwise with the `FlagWithoutChildren` component. The prop `currentlyMatching`
  // is false, because nothing matches an invalid search query.
  // We pass expandIt is false to let the user control expansion.
  if (flagWithChildren) return <FlagWithChildren {...props} currentlyMatching={false} expandIt={undefined} />;
  return <FlagWithoutChildren {...props} currentlyMatching={false} />;
};

export default Flag;
