import React from "react";
import classNames from "classnames";

interface Props {
  label: string;
  active: boolean;
  setActive: () => void;
}

// TabHeaders are rendered by TabCollections to display and change the active tab.
const TabHeader: React.FunctionComponent<Props> = (props) => {
  return (
    <>
      <div
        className={classNames("petra-tab-header contains-petra-arrow", { active: props.active })}
        onClick={() => props.setActive()}
      >
        {props.label}
        {props.active && <div className="petra-arrow-down" />}
      </div>
    </>
  );
};

export default TabHeader;
