import { last, assign } from "lodash";

function arrayToSentence(arr, options = {}) {
    options = assign({
      connector: I18n.t('and'),
    }, options);
    switch(arr.length) {
      case 0: return ''
      case 1: return `${arr[0]}`
      case 2: return `${arr[0]} ${options['connector']} ${arr[1]}`
      default:
        return `${arr.slice(0, -1).join(', ')} ${options['connector']} ${last(arr)}`
    }
};

export { arrayToSentence }