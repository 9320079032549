import React from "react";
import { DateSelect } from "./DateSelect";

interface Props {
  date: Date;
  onChange: (date: Date) => void;
  disabled: boolean;
}

export const NonResponseDate: React.FC<Props> = (props) => {
  return (
    <fieldset>
      <legend>Datum Meting:</legend>
      <DateSelect name="non_response_at" {...props} t={I18n.t} />
    </fieldset>
  );
};
