import * as React from "react";

interface DossierDetailProps {
  label?: string;
}

export const DossierDetail: React.FunctionComponent<React.PropsWithChildren<DossierDetailProps>> = (props) => {
  const { label } = props;

  return (
    <div className="patient-detail" title={label}>
      {props.children ? props.children : I18n.t("patient.details.unknown")}
    </div>
  );
};
