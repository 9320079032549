/* eslint-disable */

// this adds polyfills for various features,
// notably in current use and missing in IE11: Object.assigns, Promises
import "core-js/stable";

// Require libraries and make them globally available.
// This can be trimmed down later, but for now code in the
// app/assets/ (Sprockets) directory depends on them.
// Doing this here moves it into Yarn/Webpack so that
// 1) it's easier to update
// 2) upon moving our code into webpack, you can import it locally

window._ = require("underscore");

window.jQuery = require("jquery");
window.$ = window.jQuery;

window.I18n = require("i18n-js");

window.moment = require("moment");
require("../config/moment_nl");

window.Backbone = require("backbone");
window.Backbone.$ = window.jQuery;
require("../vendor/backbone.marionette"); // this one didn't want to install via yarn
require("../vendor/backbone-named-routes"); // this one doesn't have v0.1.7 in npm
require("backbone-relational");

window.React = require("react");
// we don't load react-dom/client, since we need findDOMNode and don't want to upgrade all that deprecated code.
window.ReactDOM = require("react-dom");
window.createReactClass = require("create-react-class");
window.PropTypes = require("prop-types");

window.Highcharts = require("../lib/highcharts").Highcharts;

// requiring will set up event listeners
require("../lib/appsignal").appsignal;

import "../stylesheets/application.scss";

// Carefully constructed regex. This context needs to include file paths without extension, so that the
// react_component view helper can require them. It may or may not also need to include the file paths with
// extension. But it needs to not include the spec files, since in a production setting the devDependencies
// will not be available, which will cause the asset precompilation to fail. (Plus, they'd get included in
// the asset bundle that clients need to download, and that's just needless bloat.)
//
// should match: ./ComponentName
// should match: ./ComponentName.tsx
// should match: ./Folder/ComponentName
// should match: ./Folder/ComponentName.tsx
//
// should NOT match: ./ComponentName.spec.tsx
var componentRequireContext = require.context("components", true, /\.\/([^\.]+)(\.js|\.jsx|\.ts|\.tsx|)$/);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

// Backbone Application
window.RoQua = new Backbone.Marionette.Application();

window.RoQua.actions = require("../lib/actions");

import { ButtonLink } from "../components/ui/buttons";
import { DateSelect } from "../components/DateSelect";
import { DateTimeSelect } from "../components/DateTimeSelect";
import { FormControlGroup, SecondDurationField } from "../components/ui/forms";
import { Glyphicon } from "../components/ui/glyphicon";
import { Icon } from "../components/ui/icon";
import { Label } from "../components/ui/labels";
import { Loader } from "../components/ui/loader";
import Lightbox from "../components/Lightbox";
import { TimeUnlessMidnight, DateWithTimeUnlessMidnight } from "../components/ui/date_time";
import { LoadingState } from "../components/TimelinePage/LoadingState";

window.RoQua.module("UI", function() {
  this.ButtonLink = ButtonLink;
  this.DateSelect = DateSelect;
  this.DateTimeSelect = DateTimeSelect;
  this.DateWithTimeUnlessMidnight = DateWithTimeUnlessMidnight;
  this.SecondDurationField = SecondDurationField;
  this.FormControlGroup = FormControlGroup;
  this.Glyphicon = Glyphicon;
  this.Icon = Icon;
  this.Label = Label;
  this.Loader = Loader;
  this.Lightbox = Lightbox;
  this.TimeUnlessMidnight = TimeUnlessMidnight;
  this.LoadingState = LoadingState;
});

import { AnswerList } from "../components/TimelinePage/AnswerList";
import { ConfirmDialog } from "../components/ConfirmDialog";
import { ConfirmDeletePendingAnswers } from "../components/ConfirmDeletePendingAnswers";
import { DoNotWantNonResponseFieldset } from "../components/DoNotWantNonResponseFieldset";
import { NonResponseSchemaGroupFieldset } from "../components/NonResponseSchemaGroupFieldset";
import { NonResponseOverlay } from "../components/NonResponseOverlay";
import { GenericTooltip } from "../components/Highcharts/GenericTooltip";
import { OQ45Tooltip } from "../components/Highcharts/OQ45Tooltip";
import { ReportTemplateContentEditor } from "../components/ReportTemplateContentEditor";
import { TimelineRowWrapper } from "../components/TimelinePage/TimelineRowWrapper";
import { NotificationRow } from "../components/TimelinePage/NotificationRow";
import { TimelineRowIconSmall } from "../components/TimelinePage/TimelineRowIconSmall";

window.RoQua.module("Components", function() {
  this.AnswerList = AnswerList;
  this.ConfirmDialog = ConfirmDialog;
  this.ConfirmDeletePendingAnswers = ConfirmDeletePendingAnswers;
  this.DoNotWantNonResponseFieldset = DoNotWantNonResponseFieldset;
  this.NonResponseOverlay = NonResponseOverlay;
  this.NonResponseSchemaGroupFieldset = NonResponseSchemaGroupFieldset;
  this.AnswerList = AnswerList;
  this.HighchartsGenericTooltip = GenericTooltip;
  this.HighchartsOQ45Tooltip = OQ45Tooltip;
  this.ReportTemplateContentEditor = ReportTemplateContentEditor;
  this.TimelineRowWrapper = TimelineRowWrapper;
  this.NotificationRow = NotificationRow;
  this.TimelineRowIconSmall = TimelineRowIconSmall;
});

import { BackboneNotification } from "../lib/notification/backbone";

window.RoQua.module("Models", function() {
  this.Notification = BackboneNotification;
});

import { ShouldNotHappenError } from "../lib/errors";

window.RoQua.ShouldNotHappenError = ShouldNotHappenError;

import { LegacyLineChart, LegacyMultiScoreChart, LegacyColumnChart, LegacyCustomChart, LegacyCspDnaCarousel } from "../components/Highcharts/LegacyWrappers";

// References to these are in most of the reports, so the API for this cannot easily be
// changed (since the templates of those aren't versioned, we can't rerender them to generate new JS code in them).
window.RoQua.module("Viewmodels", function() {
  this.LineChart = LegacyLineChart;
  this.MultiScoreChart = LegacyMultiScoreChart;
  this.ColumnChart = LegacyColumnChart;
  this.CustomChart = LegacyCustomChart;
  this.CspDnaCarousel = LegacyCspDnaCarousel;
});
