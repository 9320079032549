import React from "react";
import { Quby, IQuestionnaire, IFlags } from "@roqua/quby-frontend";
import { withRailsMountable } from "logic/rails_component";
import { IValues } from "@roqua/quby-frontend/dist/core/response";

interface Props {
  questionnaireJson: IQuestionnaire;
  flags?: IFlags;
  textvars?: {[key: string]: string};
  handleSave?: () => void;
}

export const Previewer: React.FunctionComponent<Props> = ({
  questionnaireJson,
  flags = {},
  textvars = {},
  handleSave = () => {},
}) => {
  const displayMode = "single_page"; // 'paged'
  const responseJson = { flags: flags, textvars: textvars, value: {} };

  return (
    <Quby key={questionnaireJson.key} questionnaireJson={questionnaireJson} responseJson={responseJson} displayMode={displayMode} onSave={handleSave} unsavedResponseWarning="" />
  );
};

const PreviewerRails = withRailsMountable(Previewer);
// noinspection JSUnusedGlobalSymbols
export default PreviewerRails;
