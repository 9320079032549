import React from "react";
import { Translate } from "components/Translate";
import { Icon } from "components/ui/icon";
import { IMeasuredQuestionnaire, IQuestionnaire } from "models";

interface Props {
  measuredQuestionnaire: IMeasuredQuestionnaire;
  questionnaire: IQuestionnaire;
  id: number;
  protocolId: number;
  measurementId: number;
  sortEnabled: boolean;
}

const ActualRespondentType = ({ measuredQuestionnaire }) => {
  if (measuredQuestionnaire.actual_respondent_type) {
    return (
      <span>
        (
        <Translate content="epd_area.fill_out_sessions.actual_respondent_type_notice" />
        <span className="label">
          <Translate content={`questionnaire.respondent_types.${measuredQuestionnaire.actual_respondent_type}`} />
        </span>
        )
      </span>
    );
  } else {
    return null;
  }
};

export const Row: React.FunctionComponent<Props> = (props) => {
  const { sortEnabled = true } = props;

  return (
    <tr id={`questionnaire_${props.questionnaire.id}`} className="sortable-row">
      {sortEnabled ? (
        <td>
          <i className="fa fa-arrows-v" />
        </td>
      ) : null}

      <td>
        {props.questionnaire.name}
        <ActualRespondentType measuredQuestionnaire={props.measuredQuestionnaire} />
      </td>

      <td>{props.questionnaire.key}</td>
      <td>{props.measuredQuestionnaire.short_description}</td>
      <td>
        {props.measuredQuestionnaire.respondent_types.map((respondentType) => (
          <span key={respondentType} className="label">
            <Translate content={`questionnaire.respondent_types.${respondentType}`} />
          </span>
        ))}
      </td>
      <td className="actions">
        <div className="pull-right">
          <a 
            href={`/admin/protocols/${props.protocolId}/measurements/${props.measurementId}/measured_questionnaires/${props.id}/edit`} 
            className="btn" 
            role="button"
          >
            <Icon name="wrench" color="black" />
          </a>
          <a
            href={`/admin/protocols/${props.protocolId}/measurements/${props.measurementId}/measured_questionnaires/${props.id}`}
            className="btn btn-danger"
            data-method="delete"
            data-confirm={I18n.t("admin_area.measured_questionnaires.delete_confirm", { questionnaire_name: props.questionnaire.name })}
          >
            <Icon name="trash" color="white" />
          </a>
        </div>
      </td>
    </tr>
  );
};
