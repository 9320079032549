import React from "react";
import { ReactSortable } from "react-sortablejs";
import { Row } from "./MeasuredQuestionnairesTable/Row";
import classNames from "classnames";
import { Translate } from "./Translate";
import { isEqual } from "lodash";
import { IMeasuredQuestionnaire } from "models";
import { updateMeasuredQuestionnairesOrder } from "lib/actions";
import { SaveOrderButton, useSortable } from "logic/useSortable";

interface Props {
  protocolId: number;
  measurementId: number;
  measuredQuestionnaires: IMeasuredQuestionnaire[];
  sortEnabled: boolean;
}

export const MeasuredQuestionnairesTable: React.FunctionComponent<Props> = (props) => {
  const { list, setList, orderChanged, saving, persistListOrder } = useSortable<IMeasuredQuestionnaire>(props.measuredQuestionnaires);
  const saveOrder = () => {
    persistListOrder((ids) => updateMeasuredQuestionnairesOrder(props.protocolId, props.measurementId, ids).fail(() => RoQua.showFlash({ state: "error", message: I18n.t("tables.sorting.failure") })));
  };

  const className = classNames("table", { "sortable-table": props.sortEnabled });

  return (
    <div>
      {props.sortEnabled && <SaveOrderButton orderChanged={orderChanged} saving={saving} onClick={saveOrder} />}
      <table className={className}>
        <thead>
          <tr>
            {props.sortEnabled ? <th className="span1"></th> : null}
            <th className="span3">{I18n.t("activerecord.attributes.measured_questionnaire.name")}</th>
            <th className="span1">{I18n.t("activerecord.attributes.measured_questionnaire.key")}</th>
            <th className="span4">{I18n.t("activerecord.attributes.measured_questionnaire.description")}</th>
            <th className="span1">{I18n.t("activerecord.attributes.measured_questionnaire.respondent_types")}</th>
            <th className="span2">{I18n.t("tables.actions.header")}</th>
          </tr>
        </thead>
        <ReactSortable tag="tbody" list={list} setList={setList} disabled={!props.sortEnabled}>
          {list.map((measuredQuestionnaire) => (
            <Row
              key={measuredQuestionnaire.id}
              id={measuredQuestionnaire.id}
              measuredQuestionnaire={measuredQuestionnaire}
              questionnaire={measuredQuestionnaire.questionnaire}
              protocolId={props.protocolId}
              measurementId={props.measurementId}
              sortEnabled={props.sortEnabled}
            />
          ))}
        </ReactSortable>
      </table>
      {props.sortEnabled && <SaveOrderButton orderChanged={orderChanged} saving={saving} onClick={saveOrder} />}
    </div>
  );
};

export default MeasuredQuestionnairesTable;
