import * as React from "react";
import { range } from 'lodash';
import { getDaysInMonth, setMonth, setYear, setDate } from 'date-fns';

export type TranslationFunction = (key: string) => string;

export interface IDateSelectProps {
  name: string;
  disabled: boolean;
  onChange: (date: Date) => void;
  date: Date;
  allowedYears?: Array<number>;
  t: TranslationFunction;
}

export const DateSelect = ({allowedYears = defaultAllowedYears(),  ...props}: IDateSelectProps) => {
  function handleChangeYear(e: React.ChangeEvent<HTMLSelectElement>) {
    handleChange(Number.parseInt(e.target.value), props.date.getMonth(), props.date.getDate());
  }

  function handleChangeMonth(e: React.ChangeEvent<HTMLSelectElement>) {
    handleChange(props.date.getFullYear(), Number.parseInt(e.target.value), props.date.getDate());
  }

  function handleChangeDay(e: React.ChangeEvent<HTMLSelectElement>) {
    handleChange(props.date.getFullYear(), props.date.getMonth(), Number.parseInt(e.target.value));
  }

  function handleChange(year: number, month: number, day: number) {
    // If user had "31" selected, she probably now wants to have the last day
    // for the selected month, instead of something that's not actually a date
    // on the calendar, or a day that's not actually in the month she just
    // selected (ie. new Date(2014, 1, 31) results in 2014-03-03).
    const daysInMonth = getDaysInMonth(new Date(year, month))
    if (day > daysInMonth)
      day = daysInMonth;
    let date = setYear(props.date, year)
    date = setMonth(date, month)
    date = setDate(date, day)
    props.onChange(date)
  }

  const validDays = range(1, getDaysInMonth(props.date)+1);

  return (
    <span key='date-select'>
      <select name={`${props.name}_day`}
              disabled={props.disabled}
              value={props.date.getDate()}
              title={props.t('datetime.prompts.day')}
              onChange={handleChangeDay}>
        {validDays.map((day) =>
          <option key={day} value={day}>{day}</option>
        )}
      </select>

      <select name={`${props.name}_month`}
              disabled={props.disabled}
              value={props.date.getMonth()}
              title={props.t('datetime.prompts.month')}
              onChange={handleChangeMonth}>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) =>
            <option key={month} value={month}>{props.t('date.month_names')[month+1]}</option>
        )}
      </select>

      <select name={`${props.name}_year`}
              disabled={props.disabled}
              value={props.date.getFullYear()}
              title={props.t('datetime.prompts.year')}
              onChange={handleChangeYear}>
        {allAllowedYears(allowedYears!, props.date).map((year) =>
          <option key={year} value={year}>{year}</option>
        )}
      </select>
    </span>
  );
};

const defaultAllowedYears = () => range(new Date().getFullYear() - 20, new Date().getFullYear() + 5);

function allAllowedYears(years: Array<number>, date: Date): Array<number> {
  years = ([] as number[]).concat(years)
  if (years.indexOf(date.getFullYear()) === -1)
    years.unshift(date.getFullYear());
  return years;
}
