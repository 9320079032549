import React from "react";
import { useTeamsForOrganizationQuery, useCurrentTeamQuery, useSwitchTeamMutation, Team } from "grapqhl";
import { TeamSelectorView } from "./view";

interface Props {
  reloadPageOnChange?: boolean;
}

export const TeamSelectorApollo: React.FunctionComponent<Props> = ({ reloadPageOnChange }) => {
  const sessionResults = useCurrentTeamQuery();
  const teamsResult = useTeamsForOrganizationQuery();
  const [switchTeam] = useSwitchTeamMutation();

  const currentUser = sessionResults.data?.session?.user;
  const teams = teamsResult.data?.currentOrganization?.teams;

  if (sessionResults.loading || teamsResult.loading || !teams || currentUser?.__typename !== "Professional") {
    return null;
  }

  const professionalId = currentUser.id;

  const handleChange = (teamId: Team["id"] | null) => {
    switchTeam({ variables: { input: { professionalId: professionalId, teamId: teamId } } }).then(() => reloadPageOnChange && window.location.reload());
  };

  return <TeamSelectorView teams={teams} selected={currentUser.team} onChange={handleChange} />;
};
