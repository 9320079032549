import React, { useRef, useState, useEffect, useMemo } from "react";
import classNames from "classnames";
import { ResultResponseWithCache, TabConfiguration, tabVisible } from "../common/Graphs";
import Button from "../common/Button";
import TabCollection from "./TabCollection";
import { trackEvent } from "../common/Matomo";
import { Design } from "../common/Schema";

interface Props {
  title: string;
  initiallyExpanded?: boolean;
  addToReportButton?: boolean;
  tabConfigurations: TabConfiguration[];
  responsesWithCache: ResultResponseWithCache[];
  addToReport: (elemRef: React.RefObject<HTMLDivElement>) => void;
  openToDate: (Date) => void;
  blocksPerDay: number;
  design: Design;
}

// The container class for a graph element in a Petra results page. This class
// can contain multiple tabs.
const Graph: React.FunctionComponent<Props> = ({
  title,
  initiallyExpanded = true,
  addToReportButton = true,
  addToReport,
  tabConfigurations,
  responsesWithCache,
  openToDate,
  blocksPerDay,
  design,
}) => {
  const scrollToRef = useRef<HTMLDivElement>(null);
  const graphRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState<boolean>(initiallyExpanded);

  const firstUpdate = useRef<boolean>(true);
  // When opening the graph container, scroll the entire graph into view.
  // But not on the initial page load.
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (expanded) {
      scrollToRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [expanded]);

  const visibleTabConfigurations = useMemo(() => {
    return tabConfigurations.filter((tabConfiguration) => tabVisible(tabConfiguration, responsesWithCache));
  }, [tabConfigurations]);

  const addGraphToReport = () => {
    const title = graphRef.current?.getElementsByClassName("petra-tg-title-title")?.[0]?.textContent ?? "";
    trackEvent({ category: "Petra - Add graph to report", name: title });
    addToReport(graphRef);
  };

  return (
    <div className="petra-graph">
      <div className={classNames("petra-graph-header", { expanded: expanded })} onClick={() => setExpanded(!expanded)}>
        <div className="petra-graph-title">{title}</div>
        <div className={classNames("petra-graph-expansion-arrow", { expanded: expanded })} />
      </div>
      {expanded && (
        <div className="petra-graph-body" ref={graphRef}>
          <TabCollection
            tabConfigurations={visibleTabConfigurations}
            responsesWithCache={responsesWithCache}
            openToDate={openToDate}
            blocksPerDay={blocksPerDay}
            design={design}
          />
        </div>
      )}
      {expanded && addToReportButton && (
        <div className="petra-graph-add-to-report contains-petra-arrow">
          <Button label="Voeg toe aan rapport" onClick={addGraphToReport} />
          <div className="petra-arrow-up" />
        </div>
      )}
      <div className="petra-graph-anchor" ref={scrollToRef} />
    </div>
  );
};

export default Graph;
