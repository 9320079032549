import { addDays, differenceInCalendarDays } from "date-fns";
import { enGB, nl } from "date-fns/locale";
import { sendError } from "lib/appsignal";
import { useEpdSession } from "lib/useSession";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import {
  RespondentTypeEnum,
  ResponseStatusEnum,
  UserError,
  usePetraInformedConsentsQuery,
  usePetraStartInformedConsentMutation,
  useResearchParticipationCreateMutation,
} from "../../../../grapqhl";
import Button from "../../common/Button";
import {
  COMPLAINTS,
  DATE_PICKER_DEFAULT_OPTIONS,
  FIXED,
  GOAL_DEFINITIONS,
  INFORMED_CONSENT_QUESTIONNAIRE_KEY,
  ONE_TIME_PER_DAY,
  SEMI_RANDOM,
} from "../../common/Constants";
import {
  FlagInterface,
  allFlagsBooleans,
  noMorningQuestionsSelected,
  selectedBothMorningAndEveningQuestions,
} from "../../common/Flags";
import { trackEvent } from "../../common/Matomo";
import {
  DEFAULT_EVENING_START_TIME,
  DEFAULT_MORNING_START_TIME,
  MeasurementSchedule,
} from "../../common/MeasurementSchedule";
import { BeepLimit, Design, GoalDefinition } from "../../common/Schema";
import { Key } from "../../common/Selections";
import { formatDuration, formatTime, monthYear, unformatTime } from "../../common/Time";
import SchedulerPreview from "./SchedulerPreview";

const locales = { en: enGB, nl };

// The specification of supported Beeps is defined on a subgoal level, and in general,
// applies to all designs. There are some exceptions, which we enforce with the code below.
// The exceptions are that the semi random design needs at least 5 measurements per day,
// the fixed design needs at least 3 measurements per day, and the one time per day
// design needs 1 or 2 measurements per day (depending on selected questions).
// We also take into account the case where a subgoal does not specify a beep design.
// In our case this will never happen, but typescript complains because beeps is an
// optional property (as we do not use it for goals (which are of the same type), only
// for subgoals).
const supportedBeeps = (subGoal: GoalDefinition, design: Design, flags: FlagInterface): number[] => {
  if (!subGoal.beeps) return [];
  let supported = subGoal.beeps.supported;
  if (design === SEMI_RANDOM) {
    supported = supported.filter((measurementsPerDay) => measurementsPerDay >= 5);
  } else if (design === FIXED) {
    supported = supported.filter((measurementsPerDay) => measurementsPerDay >= 3);
  } else if (design === ONE_TIME_PER_DAY) {
    if (selectedBothMorningAndEveningQuestions(flags)) {
      supported = supported.filter((measurementsPerDay) => measurementsPerDay === 2);
    } else {
      supported = supported.filter((measurementsPerDay) => measurementsPerDay === 1);
    }
  }
  return supported;
};

interface Props {
  flags: FlagInterface;
  schedule: MeasurementSchedule;
  goals: Selections<Key>;
  subGoals: Selections<Key>;
  complaints: Selections<Key>;
  autoProtocolId: string;
  researchProjectId: number;
  patientPhone: string;
}

// Render the page for selecting the number of measurements per day, start time, start date,
// end date, mobile phone number, and diary study name for creating a new PETRA diary study.
// We take the approach of having sensible defaults (rather than null values) for all settings
// (except the phone nubmer and diary study name), and by enforcing e.g., minimal diary study
// length requirements, we are always in a valid configuration state. Thus, the selected start
// and end date will always have at least the minimum number of required days between then,
// the end time is always exactly totalBlockDuration away from the start time, there is always
// a number of measurements per day selected, etc.
const SelectSettings: React.FunctionComponent<Props> = (props) => {
  const navigate = useNavigate();
  const { dossierId } = useEpdSession();
  const [potentialStartTime, setPotentialStartTime] = useState<string>(formatTime(props.schedule.startTime));

  const informedConsents = usePetraInformedConsentsQuery({
    variables: {
      questionnaireKey: INFORMED_CONSENT_QUESTIONNAIRE_KEY,
    },
  });
  const informedConsentGivenOrQueued = !!(
    informedConsents.data?.currentDossier?.responses.length &&
    informedConsents.data.currentDossier.responses.filter(
      (resp) => resp.status && [ResponseStatusEnum.Open, ResponseStatusEnum.Completed].includes(resp.status)
    ).length > 0
  );

  // Look up the definition of the currently selected subgoal. Note that
  // when we are on this page, we know for sure that exactly one subgoal is selected
  // (because the button to go to the current page is disabled as long as no subgoal
  // has been selected).
  const subGoal = GOAL_DEFINITIONS[props.subGoals.selected[0]];

  // If the currently selected number of measurements per day is not supported by the diary
  // study design specified by the subgoal, then set it to the preferred number of measurements
  // per day for this design, if supported by the design, and otherwise to the first supported value otherwise.
  useEffect(() => {
    const supportedBeepsForDesign = supportedBeeps(subGoal, props.schedule.design, props.flags);
    if (
      subGoal.beeps &&
      supportedBeepsForDesign.length > 0 &&
      !supportedBeepsForDesign.includes(props.schedule.measurementsPerDay)
    ) {
      const newMeasurementsPerDay = supportedBeepsForDesign.includes(subGoal.beeps.preferred)
        ? subGoal.beeps.preferred
        : supportedBeepsForDesign[0];
      props.schedule.setMeasurementsPerDay(newMeasurementsPerDay);
    }
  }, [props.subGoals.selected, props.schedule]);

  // Set the diary name to a default name when loading the page, if it is not already set.
  useEffect(() => {
    if (props.schedule.diaryName !== "") return;
    const complaint = COMPLAINTS[props.complaints.selected[0]].title;
    props.schedule.setDiaryName(`${monthYear(props.schedule.startDate)} - ${complaint}`);
  }, []);

  // If we load this page for the first time, and we have the 1x per day design selected,
  // and we have no morning questions selected, then change the start time to 17:30.
  // If we have morning questions selected, change the start time to 7:30.
  // This way, if we have an "evening only" study, then we will preselect a time that is
  // in the evening, and if we have a "morning and evening" or "morning only" study,
  // then set the start time to a default of 7:30.
  useEffect(() => {
    if (props.schedule.design !== ONE_TIME_PER_DAY) return;

    let enforcedStartTime = DEFAULT_MORNING_START_TIME;
    // If we should start in the evening
    if (noMorningQuestionsSelected(props.flags)) {
      enforcedStartTime = DEFAULT_EVENING_START_TIME;
    }
    if (props.schedule.startTime !== enforcedStartTime) {
      props.schedule.setStartTime(enforcedStartTime);
      setPotentialStartTime(formatTime(enforcedStartTime));
    }
  }, []);

  // The mutation for starting a research participation and protocol subscription
  const [researchParticipationCreateMutation, participationStatus] = useResearchParticipationCreateMutation();

  // The mutation for creating a fill out request for the informed consent questionnaire
  const [informedConsentCreateMutation, informedConsentCreateStatus] = usePetraStartInformedConsentMutation();

  // Disable the button when the mutation is in progress
  const startDisabled = participationStatus.loading || informedConsents.loading || informedConsentCreateStatus.loading;

  // The default number of measurements per day is set to 0, so it could be that this component
  // is rendered before the before the `useEffect` clause that sets the number of measurements
  // per day to a valid setting for the current design has taken effect.
  // In this case, we simply return here, because the render method will be called again
  // once we have a valid setting for measurements per day and thus also for `beepLimits`.
  if (!props.schedule.beepLimits || !props.schedule.endTime) return <></>;

  // Since we now know that we have defined `beepLimits`, force the `beepLimits`
  // type to BeepLimit instead of BeepLimit | undefined.
  const beepLimits: BeepLimit = props.schedule.beepLimits;

  // Start a ProtocolSubscription with the correct settings
  const startResearchParticipationAndProtocolSubscription = () => {
    const promises: Promise<unknown>[] = [];
    // Create a fill out request for the informed consent questionnaire if the user has not yet given informed consent
    if (!informedConsentGivenOrQueued) {
      promises.push(
        informedConsentCreateMutation({
          variables: {
            input: {
              dossierId: dossierId,
              responsePreparations: [
                {
                  questionnaireReference: {
                    byQuestionnaireKey: {
                      questionnaireKey: INFORMED_CONSENT_QUESTIONNAIRE_KEY,
                    },
                  },
                },
              ],
              respondentType: RespondentTypeEnum.Patient,
            },
          },
        })
          .then((data) => {
            const result = data?.data?.fillOutRequestCreate;
            const errors = result?.errors;
            if (errors && errors.length > 0) {
              RoQua.showFlash({
                state: "error",
                message: "Er ging iets fout bij het aanmaken van de informed consent",
              });
              throw new Error("Error creating fill out request for informed consent");
            }
          })
          .catch((e) => {
            sendError(e, { flash: "Er ging iets fout bij het aanmaken van de informed consent" });
          })
      );
    }

    // Create a research participation and protocol subscription.
    promises.push(
      researchParticipationCreateMutation({
        variables: {
          input: {
            dossierId: dossierId,
            researchProjectId: props.researchProjectId.toString(),
            name: props.schedule.diaryName,
            settings: {
              goal: props.goals.selected[0],
              subGoal: props.subGoals.selected[0],
              complaint: props.complaints.selected[0],
            },
            subscriptions: [
              {
                dailyStartTime: props.schedule.startTime,
                flags: allFlagsBooleans(props.flags.flagsSelection, props.flags.flags),
                // Measurement amount for the random scheduler is the number of measurement days.
                measurementAmount: differenceInCalendarDays(props.schedule.endDate, props.schedule.startDate),
                autoProtocolId: props.autoProtocolId.toString(),
                schedulerSettingOverrides: {
                  randomSchedulerSettings: {
                    blocksPerDay: props.schedule.measurementsPerDay,
                    blockDuration: beepLimits.singleBlockDuration,
                    minTimeBetweenBeeps: beepLimits.minTimeBetweenBeeps,
                  },
                },
                startAt: props.schedule.startDate,
                textvars: Object.keys(props.flags.textvarsSettings.textvarsValues).map((textvarKey) => ({
                  key: textvarKey,
                  value: props.flags.textvarsSettings.textvarsValues[textvarKey],
                })),
              },
            ],
          },
        },
      })
        .then((data) => {
          const result = data?.data?.researchParticipationCreate;
          const errors = result?.errors;
          if (errors && errors.length > 0) {
            RoQua.showFlash({ state: "error", message: createErrorMessage(errors || [], props.schedule.design) });
            throw new Error("Error creating research participation");
          }
        })
        .catch((e) => {
          sendError(e, { flash: "Er ging iets fout bij het aanmaken van de deelname" });
        })
    );

    // Wait for all promises to resolve
    Promise.all(promises).then(() => {
      trackEvent({
        category: "Petra - Start diary",
        name: `${props.schedule.measurementsPerDay}x per dag ${props.schedule.design}: ${props.goals.selected[0]} - ${props.subGoals.selected[0]} - ${props.complaints.selected[0]}`,
      });
      RoQua.showFlash({ state: "success", message: "Het dagboek is aangemaakt" });
      // Navigate to overview page
      navigate("/", { state: { protSubStarted: true } });
    });
  };

  const updateStartTime = () => {
    const newStartTime = unformatTime(potentialStartTime);
    if (typeof newStartTime === "number") {
      props.schedule.setStartTime(newStartTime);
      setPotentialStartTime(formatTime(newStartTime));
    } else {
      setPotentialStartTime(formatTime(props.schedule.startTime));
    }
  };

  const locale = locales[I18n.locale];

  return (
    <>
      <div className="colored-header">
        <h2>Instellen en afronden</h2>
      </div>
      <fieldset className="petra-content">
        <div className="raised-border">
          <div className="petra-field">
            <h3>Hoe vaak wordt het dagboek ingevuld per dag?</h3>
            <select
              name="measurements-per-day"
              value={props.schedule.measurementsPerDay}
              onChange={(e) => props.schedule.setMeasurementsPerDay(parseInt(e.target.value))}
            >
              {subGoal.beeps &&
                supportedBeeps(subGoal, props.schedule.design, props.flags).map((beep) => (
                  <option key={beep} value={beep}>
                    {beep} keer per dag
                  </option>
                ))}
            </select>
            <p>
              {props.schedule.design === ONE_TIME_PER_DAY
                ? props.schedule.measurementsPerDay === 1
                  ? ""
                  : "Je krijgt elke vraag maar 1 keer per dag. Een deel 's ochtends en een deel 's avonds."
                : "Het aantal is inclusief ochtend- en avondvragen, als die gekozen zijn."}
            </p>
          </div>
        </div>
        <div className="raised-border">
          <div className="petra-field">
            {props.schedule.measurementsPerDay !== 1 && (
              <>
                <h3>
                  {props.schedule.design === ONE_TIME_PER_DAY ? "Op" : "Tussen"} welke tijdstippen wordt gevraagd om het
                  dagboek in te vullen?
                </h3>
                <p>
                  {props.schedule.design !== ONE_TIME_PER_DAY && (
                    <>
                      Bij {props.schedule.measurementsPerDay} metingen per dag hoort een tijdsspanne van{" "}
                      {formatDuration(beepLimits.totalBlockDuration)}.
                    </>
                  )}
                  {props.schedule.design === ONE_TIME_PER_DAY && (
                    <>
                      De tweede meting komt standaard {formatDuration(beepLimits.totalBlockDuration)} na de eerste. Je
                      hebt {formatDuration(beepLimits.measurementExpiresAfter)} de tijd om het dagboek in te vullen
                      nadat je een SMS hebt gekregen.
                    </>
                  )}
                </p>
              </>
            )}
            {props.schedule.measurementsPerDay === 1 && (
              <>
                <h3>Op welk tijdstip wordt gevraagd om het dagboek in te vullen?</h3>
                <p>
                  Je hebt {formatDuration(beepLimits.measurementExpiresAfter)} de tijd om het dagboek in te vullen nadat
                  je een SMS hebt gekregen.
                </p>
              </>
            )}
            <input
              type="text"
              className="time-box"
              name="start-time"
              id="start-time"
              value={potentialStartTime}
              onFocus={() => setPotentialStartTime(potentialStartTime.replace(":", ""))}
              onChange={(e) => setPotentialStartTime(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.currentTarget.blur();
                }
              }}
              onBlur={updateStartTime}
            />
            {props.schedule.measurementsPerDay !== 1 && (
              <>
                <div className="input-and">en</div>
                <input
                  type="text"
                  className="time-box"
                  name="end-time"
                  value={formatTime(props.schedule.endTime)}
                  disabled
                  readOnly
                />
              </>
            )}
          </div>
        </div>
        <SchedulerPreview schedule={props.schedule} subGoals={props.subGoals} beepLimits={beepLimits} />
        <div className="raised-border">
          <div className="petra-field">
            <h3>Van wanneer tot wanneer moet het onderzoek lopen?</h3>
            <p>
              Bij {props.schedule.measurementsPerDay} meting{props.schedule.measurementsPerDay === 1 ? "" : "en"} per
              dag moet je minimaal {beepLimits.minimalStudyDurationInDays} dagen meten voor een goed resultaat.
            </p>
            <DatePicker
              id="start"
              name="start-date"
              {...DATE_PICKER_DEFAULT_OPTIONS}
              selected={props.schedule.startDate}
              onChange={(date) => date && props.schedule.setStartDate(date)}
              selectsStart
              startDate={props.schedule.startDate}
              endDate={props.schedule.endDate}
              minDate={addDays(new Date(), 1)}
              className="date-box"
              locale={locale}
            />
            <div className="input-and">en</div>
            <DatePicker
              id="end"
              name="end-date"
              {...DATE_PICKER_DEFAULT_OPTIONS}
              selected={props.schedule.endDate}
              onChange={(date) => date && props.schedule.setEndDate(date)}
              selectsEnd
              startDate={props.schedule.startDate}
              endDate={props.schedule.endDate}
              minDate={addDays(props.schedule.startDate, beepLimits.minimalStudyDurationInDays)}
              className="date-box"
              locale={locale}
            />
          </div>
        </div>
        <div className="raised-border">
          <div className="petra-field">
            <h3>Naar welk mobiel nummer moeten de smsjes verstuurd worden?</h3>
            <input
              type="text"
              name="phone-number"
              value={props.patientPhone}
              className="full-width"
              disabled
              onChange={() => {}}
            />
          </div>
        </div>
        <div className="raised-border">
          <div className="petra-field">
            <h3>Hoe heet dit dagboek?</h3>
            <p>Let op: gebruik geen patiëntgegevens voor de naam van het dagboek in verband met privacy</p>
            <input
              type="text"
              name="diary-name"
              className="full-width"
              value={props.schedule.diaryName}
              onChange={(e) => props.schedule.setDiaryName(e.target.value)}
            />
          </div>
        </div>
        <div className="navigation">
          <Button label="Terug naar de vragen" onClick={() => navigate(-1)} />
          <Button
            label="Start dagboek"
            isDefault
            disabled={startDisabled}
            onClick={startResearchParticipationAndProtocolSubscription}
          />
        </div>
      </fieldset>
    </>
  );
};

const createErrorMessage = (errors: Array<Pick<UserError, "path" | "message">>, protocol: Design) => {
  console.log(errors);
  return [
    `Er ging iets fout bij het starten van de protocolsubscriptie voor protocol ${protocol}:`,
    ...errors.map(
      (error) =>
        `${
          JSON.stringify(error.path) === '["subscriptions","0","attributes","patientId"]'
            ? "deze patiënt"
            : `${error.path}:`
        } ${error.message}.`
    ),
  ].join("<br />");
};

export default SelectSettings;
