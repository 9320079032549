import { ApolloProvider } from "@apollo/react-hooks";
import { ErrorBoundary } from "components/ErrorBoundary";
import { createClient } from "lib/apollo";
import { Session, SessionContext } from "lib/useSession";
import * as React from "react";

interface GlobalProps {
  sessionId: string;
  sessionType: string;
  userId: string | null; // Only required field for back office
  organizationKey: string;
  organizationId: string | null; // Nullable for back office
  dossierId: string | null;
  locale?: string;
}

export function withRailsMountable<P extends object>(WrappedComponent: React.ComponentType<P>) {
  return class WithRailsMountable extends React.Component<GlobalProps & P> {
    componentDidMount() {
      if (!window.apolloClient) {
        window.apolloClient = createClient({
          type: this.props.sessionType,
          userId: this.props.userId,
          organizationId: this.props.organizationId,
          dossierId: this.props.dossierId
        })
      }
      this.setState({client: window.apolloClient})
    }

    render() {
      // to unpack any custom props
      const sessionType = this.props.sessionType as string;
      const organizationKey = this.props.organizationKey as (string | undefined)
      const dossierId = this.props.dossierId as (string | undefined | null);
      const locale = (this.props.locale || "nl") as string;

      let session: Session;

      if (sessionType === "epd_area" && organizationKey && dossierId && locale) {
        session = { sessionType, organizationKey, dossierId, locale };
      } else if (sessionType === "admin_area" && organizationKey) {
        session = { sessionType, organizationKey, locale };
      } else {
        session = { sessionType: "no_session", locale };
      }

      if (!window.apolloClient) {
        return <div>Loading</div>;
      }

      return (
        <ApolloProvider client={window.apolloClient}>
          <ErrorBoundary>
            <SessionContext.Provider value={session}>
              <WrappedComponent {...(this.props as P)} />
            </SessionContext.Provider>
          </ErrorBoundary>
        </ApolloProvider>
      );
    }
  }
}
