import * as React from "react";
import * as jQuery from "jquery";
import { openModal, closeModal } from "./interop";

interface IModalProps {
  onClose: Function;
  backdrop?: boolean;
  static?: boolean;
  keyboard?: boolean;
}

export class Modal extends React.Component<React.PropsWithChildren<IModalProps>> {
  private modalRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    const options = {
      backdrop: (this.props.static ? "static" : this.props.backdrop) || true,
      keyboard: this.props.keyboard || true,
    };

    if (this.modalRef.current) {
      openModal(this.modalRef.current, options, this.props.onClose);
    }
  }

  componentWillUnmount() {
    if (this.modalRef.current) {
      closeModal(this.modalRef.current, this.props.onClose);
    }
  }

  render() {
    return (
      <div className="modal hide fade" role="aria-dialog" ref={this.modalRef}>
        {this.props.children}
      </div>
    );
  }
}

export interface IModalHeaderProps {
  close?: boolean;
}

export const ModalHeader: React.FunctionComponent<React.PropsWithChildren<IModalHeaderProps>> = (props) => {
  return (
    <div className="modal-header">
      {props.close ? (
        <button type="button" className="close" data-dismiss="modal" aria-hidden="true">
          &times;
        </button>
      ) : null}
      {props.children}
    </div>
  );
};

export const ModalBody = (props) => <div className="modal-body">{props.children}</div>;
export const ModalFooter = (props) => <div className="modal-footer">{props.children}</div>;
