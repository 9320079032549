import * as React from "react";
import classNames from "classnames";

interface ITagProps {
  highlight?: "success" | "warning" | "important" | "info" | "inverse";
  onDismiss?: Function;
  dismissButtonLabel: string;
}

export const Tag: React.FunctionComponent<
  ITagProps & React.HTMLProps<HTMLDivElement>
> = (props) => {
  const { highlight, onDismiss, dismissButtonLabel, ...rest } = props;

  const handleClose = () => onDismiss && onDismiss();
  const dismissIcon = onDismiss && <button onClick={handleClose} aria-label={dismissButtonLabel}>X</button>;

  const className = classNames("label", highlight && `label-${highlight}`);

  return (
    <div className={className} {...rest}>
      {props.children}
      {dismissIcon}
    </div>
  );
};
