import { ResponseValue } from "grapqhl";

import { generateQuestionsHash, generateValuesHash, ResponseWithCache, QuestionnaireWithQuestionsWithKeys, QuestionWithKey } from "./ResponseWithCache";

// Creates array of ResponsesWithCache's with cache primed and single cache instance for questionHash.
export function createResponsesWithCache<
  TResponse extends {values: ResponseValue[]},
  TQuestionnaire extends QuestionnaireWithQuestionsWithKeys<TQuestion>,
  TQuestion extends QuestionWithKey
>(
  responses: TResponse[],
  questionnaire: TQuestionnaire & QuestionnaireWithQuestionsWithKeys<TQuestion>
): ResponseWithCache<TResponse, TQuestionnaire, TQuestion>[] {
  const questionsHash = generateQuestionsHash(questionnaire.questions);
  return responses.map(response => ({
    response: response,
    responseHash: generateValuesHash(response.values),
    questionnaire: questionnaire,
    questionsHash: questionsHash
  }));
};
