import * as React from "react";
import { SessionContext } from "lib/useSession";

interface ITranslateProps {
  content: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export class Translate extends React.Component<ITranslateProps> {
  static contextType = SessionContext;
  context!: React.ContextType<typeof SessionContext>;

  render() {
    const locale  = this.context ? this.context.locale : "nl";
    const options = { locale: locale, ...this.props };
    return I18n.t(this.props.content, options);
  }
}
