import React from "react";
import { useBackboneModel } from "logic/backbone_hooks";
import { BackboneNotification } from "lib/notification/backbone";
// import { BackboneActivity } from "lib/activity";
import { TimelineRowWrapper } from "./TimelineRowWrapper";
import { TimelineRowIconSmall } from "./TimelineRowIconSmall";

interface Props {
  model: BackboneNotification;
  activity: any;
}

export const NotificationRow: React.FC<Props> = ({ model, activity }) => {
  useBackboneModel(model);
  useBackboneModel(activity);

  var openOverlay = () => RoQua.overlay.showNotification(model)

  return (
    <TimelineRowWrapper
      id={activity.polymorphicId()}
      selected={activity.isSelected()}
      icon={<TimelineRowIconSmall icon='invitation' />}
      onClick={openOverlay}
      date={activity.get("created_at")}>
      <div key="notification-row">
        <strong key="name">{model.get('name')}</strong>
        <span key="spacer"> </span>
        {model.lastEventMessage()}
      </div>
    </TimelineRowWrapper>
  )
}
