import React from "react";

interface NormalState {
  hasError: false;
}

interface ErrorState {
  hasError: true;
  error: Error;
}

type State = NormalState | ErrorState;

export class ErrorBoundary extends React.Component<React.PropsWithChildren, State> {
  constructor(props: React.PropsWithChildren) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <section>
          <header>Something went wrong.</header>
          <p>
            <strong>{this.state.error.name}</strong>
          </p>
          <p>{this.state.error.message}</p>
          <p>{this.state.error.stack && JSON.stringify(this.state.error.stack)}</p>
        </section>
      );
    }

    return this.props.children;
  }
}
