import React, { useMemo } from "react";
import { Key } from "../../common/Selections";
import classNames from "classnames";
import {
  directChildrenWithoutChildren,
  subDomains,
  descendantsWithoutChildren,
  flagHasImpact,
  determineSelected,
  descendantQuestionCount,
  selectAllQuestions,
  unselectAllQuestions,
  isRequiredDomain,
  FlagInterface,
  FlagObject,
  textVarsForFlag,
} from "../../common/Flags";
import Flag from "./Flag";
import SelectAll from "../../common/SelectAll";
import { trackEvent } from "../../common/Matomo";

interface Props {
  currentFlag: Key;
  flagExpansion: Selections<Key>;
  flags: FlagInterface;
  matching: boolean;
  query: string;
  currentlyMatching: boolean;
  expandIt: boolean | undefined;
  setShowExplanation: (boolean) => void;
}

// Renders a flag that has children.
// props.matching is a boolean that is true iff any ancestor of this flag matches
// the search query. It is false for domains (= top-level flags).
// props.currentlyMatching is true if the current flag matches the search query.
const FlagWithChildren: React.FunctionComponent<Props> = (props) => {
  // Look up the current flag and cache the result.
  const currentFlag = useMemo(() => props.flags.flags.filter((flag) => flag.key === props.currentFlag)[0], [
    props.flags,
    props.currentFlag,
  ]);

  const isExpanded = props.flagExpansion.isSelected(props.currentFlag);
  // Subdomains are the children of a flag that have children themselves.
  const currentSubDomains = useMemo(() => subDomains(props.currentFlag, props.flags.flags), [
    props.flags,
    props.currentFlag,
  ]);
  // Calculate and cache the "leaf nodes"
  const currentChildrenWithoutChildren = useMemo(
    () => directChildrenWithoutChildren(props.currentFlag, props.flags.flags),
    [props.flags, props.currentFlag]
  );
  // Calculate and cache the descendants that are leaf nodes. This is used to display question number totals.
  const currentDescendantsWithoutChildren = useMemo(
    () => descendantsWithoutChildren(props.currentFlag, props.flags.flags),
    [props.flags, props.currentFlag]
  );
  // Wrapper for properties that should be passed down to child flags.
  const passedProps = {
    flagExpansion: props.flagExpansion,
    flags: props.flags,
    matching: props.matching,
    query: props.query,
    setShowExplanation: props.setShowExplanation,
  };

  const topicCount = currentSubDomains.length;
  const questionCount = descendantQuestionCount(props.currentFlag, props.flags.flags);
  // In order to avoid counting "identical" questions multiple times, we first perform deduplication by only counting
  // the number of distinct impact signatures belonging to the selected questions. Note that the total question count
  // does not take deduplication into account. Therefore, if deduplication occurs, the counts may indicate that not
  // all questions are selected, when in fact all questions are selected.
  const selectedImpactSignatures = currentDescendantsWithoutChildren
    .filter((flag) => flagHasImpact(flag) && props.flags.flagsSelection.isSelected(flag.key))
    .map((flag) => flag.impactSignature);
  // NOTE: Here we assume that flags without impact do not occur more than once since we have no way to distinguish
  // between them. If they do occur more than once, we count them double.
  const hiddenFlagCount = currentDescendantsWithoutChildren.filter(
    (flag) => !flagHasImpact(flag) && determineSelected(flag, props.flags.flagsSelection, props.flags.flags)
  ).length;
  const selectedQuestionCount = new Set<string>(selectedImpactSignatures).size + hiddenFlagCount;

  // Returns a text indicating the number of subjects (= subdomains) and questions (= descendants without children)
  // for the current flag.
  const renderDomainDescription = () => {
    // Display a different text depending on if we have subdomains or not.
    if (topicCount > 0) {
      if (selectedQuestionCount > 0) {
        return (
          <>
            {topicCount} onderwerpen, {questionCount} vragen,{" "}
            <span className="currently-matching">{selectedQuestionCount} geselecteerd</span>
          </>
        );
      }
      return `${topicCount} onderwerpen, ${questionCount} vragen, ${selectedQuestionCount} geselecteerd`;
    }
    if (selectedQuestionCount > 0) {
      return (
        <>
          <span className="currently-matching">
            {selectedQuestionCount} van de {questionCount} vragen geselecteerd
          </span>
        </>
      );
    }
    return `${selectedQuestionCount} van de ${questionCount} vragen geselecteerd`;
  };

  const renderDomainExtraExplanation = () => {
    if (!anyDirectChildrenHaveTextvars(currentChildrenWithoutChildren, props.flags)) return <></>;
    return (
      <p className="domain-extra-explanation">
        Voeg hier eigen vragen toe. De antwoorden bij deze vragen zijn vast: van ‘helemaal niet’ tot ‘heel erg’.{" "}
        <span className="petra-link" onClick={() => props.setShowExplanation(true)}>
          Lees hier hoe je een goede vraag formuleert
        </span>
        .
      </p>
    );
  };

  const renderChildrenWithoutChildren = () => {
    return currentChildrenWithoutChildren.map((flag) => {
      return <Flag key={flag.key} currentFlag={flag.key} {...passedProps} />;
    });
  };

  const renderSubDomains = () => {
    return currentSubDomains.map((flag) => {
      return <Flag key={flag.key} currentFlag={flag.key} {...passedProps} />;
    });
  };

  // Selects all questions of this (sub)domain
  const selectAll = () => {
    trackEvent({ category: "Petra - Compose - Toggle all questions on", name: props.currentFlag });
    selectAllQuestions(props.currentFlag, props.flags.flags, props.flags.flagsSelection);
  };

  // Unselects all questions of this (sub)domain
  const unSelectAll = () => {
    trackEvent({ category: "Petra - Compose - Toggle all questions off", name: props.currentFlag });
    unselectAllQuestions(props.currentFlag, props.flags.flags, props.flags.flagsSelection);
  };

  const renderSelectAll = () => {
    const allSelected = selectedQuestionCount === questionCount;
    return <SelectAll allSelected={allSelected} selectAll={selectAll} unSelectAll={unSelectAll} />;
  };

  // If props.expandIt is undefined, it means we let the user control the expansion,
  // by means of the expansion state in the flagExpansion settings.
  const expansionState: boolean = props.expandIt === undefined ? isExpanded : props.expandIt;

  const toggleFlagExpansion = () => {
    if (props.flagExpansion.isSelected(props.currentFlag)) {
      trackEvent({ category: "Petra - Compose - Close question category", name: props.currentFlag });
    } else {
      trackEvent({ category: "Petra - Compose - Open question category", name: props.currentFlag });
    }
    props.flagExpansion.toggle(props.currentFlag);
  };

  // Note that we only render descendant flags if the current flag is expanded. If the current flag matches
  // the search query, we add the `currently-matching` class, which in petra.sass is defined to show the
  // flag description in boldface.
  return (
    <>
      <div className="flag-container">
        <div
          className={classNames("flag", {
            expanded: expansionState,
            "contains-selected-flags": selectedQuestionCount > 0,
          })}
          onClick={toggleFlagExpansion}
        >
          <div className="flag-contents">
            <div className={classNames("flag-title", props.currentlyMatching && "currently-matching")}>
              {currentFlag.description}
            </div>
            <div className="flag-subtitle">{renderDomainDescription()}</div>
          </div>
          <div className="flag-actions" />
        </div>
        {expansionState && (
          <div className={classNames("flag-expansion", isRequiredDomain(props.currentFlag) && "required")}>
            {renderSelectAll()}
            {renderDomainExtraExplanation()}
            {renderChildrenWithoutChildren()}
            {renderSubDomains()}
          </div>
        )}
      </div>
    </>
  );
};

const anyDirectChildrenHaveTextvars = (currentChildrenWithoutChildren: FlagObject[], flags: FlagInterface): boolean => {
  return currentChildrenWithoutChildren.some((flagObj) => textVarsForFlag(flagObj, flags).length > 0);
};

export default FlagWithChildren;
