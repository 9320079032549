import React from "react";
import Lightbox from "../../../Lightbox";
import PreviewQuestionnaire from "./PreviewQuestionnaire";
import { FlagInterface } from "../Flags";

interface Props {
  previewShown: boolean;
  hidePreview: () => void;
  questionnaireKey: string;
  flags: FlagInterface;
}

// Render a preview for the given questionnaire key.
const PreviewQuestionnaireLightbox: React.FC<Props> = ({ previewShown, hidePreview, questionnaireKey, flags }) => {
  return (
    <Lightbox show={previewShown} onCloseClicked={hidePreview}>
      <div className="lightbox-background petra-questionnaire">
        <div className="colored-header">
          <div className="header-close-button" onClick={hidePreview} />
          <h2>Voorbeeld van de vragenlijst</h2>
        </div>
        <div className="petra-content-inset">
          {previewShown && (
            <PreviewQuestionnaire questionnaireKey={questionnaireKey} flags={flags} handleSave={hidePreview} />
          )}
        </div>
      </div>
    </Lightbox>
  );
};

export default PreviewQuestionnaireLightbox;
