import React from "react";
import classNames from "classnames";

interface Props {
  checked: boolean;
  setChecked: (checked: boolean) => void;
  label: string;
}

// Display a toggle
const Toggle: React.FunctionComponent<Props> = ({ checked, setChecked, label }) => {
  return (
    <div className="petra-toggle" onClick={() => setChecked(!checked)}>
      <div className="petra-toggle-label">{label}</div>
      <div className={classNames("petra-toggle-container", { checked: checked })}>
        <div className="petra-toggle-knob" />
      </div>
    </div>
  );
};

export default Toggle;
