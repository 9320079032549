import React, { useMemo, useState, useCallback } from "react";
import { maxBy, minBy } from "lodash";
import WordCloud from "react-d3-cloud";
import { ResultResponseWithCache, TabConfiguration } from "../common/Graphs";
import MoreInformationContainer from "./MoreInformationContainer";
import { freeTextFromResponse } from "../common/Results";
import { TabState } from "./useTabState";
import { localizedFormat } from "../../../logic/date";
import { escapeRegExp } from "lodash";
import WordCloudDetails from "./WordCloudDetails";
import { Design } from "../common/Schema";
import { Word } from "react-d3-cloud/lib/WordCloud";

// Don't add these words to the wordcloud
const EXCLUDED_LIST = [
  "aan",
  "af",
  "de",
  "een",
  "en",
  "heb",
  "hebben",
  "hebt",
  "heeft",
  "het",
  "in",
  "is",
  "m'n",
  "me",
  "met",
  "mijn",
  "na",
  "nog",
  "om",
  "was",
  "word",
  "wordt",
  "zijn",
];

interface Props {
  tabConfiguration: TabConfiguration;
  responsesWithCache: ResultResponseWithCache[];
  tabState: TabState; // Not used in this class right now.
  design: Design;
}

export interface SelectedWord {
  word: string;
  times: number;
}

// The TabWordCloud component renders a wordcloud graph in a tab.
// A tab is part of a TabCollection component, which is part of a Graph component.
const TabWordCloud: React.FunctionComponent<Props> = ({ tabConfiguration, responsesWithCache, design }) => {
  const [selectedWord, setSelectedWord] = useState<SelectedWord | undefined>(undefined);
  const words = useMemo(() => generateWordCloud(responsesWithCache), [responsesWithCache]);
  const filteredData = useMemo(() => filterData(responsesWithCache, selectedWord), [responsesWithCache, selectedWord]);
  const minVal = useMemo(() => minBy(words, "value")?.value ?? 0, [words]);
  const maxVal = useMemo(() => maxBy(words, "value")?.value ?? 1, [words]);
  const fontSize = useCallback((word: Word) => 20 + (44 * (word.value - minVal)) / maxVal, [minVal, maxVal]);
  const onWordClick = useCallback((_e, word: Word) => {
    setSelectedWord({ word: word.text, times: word.value });
  }, []);
  const containerClicked = (e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as Element).tagName !== "text") {
      setSelectedWord(undefined);
    }
  };

  return (
    <div className="petra-tab-graph">
      <div className="petra-tg-title full-width">
        <div className="petra-tg-title-title">{tabConfiguration.title}</div>
      </div>
      <div className="petra-tg-graph">
        <div className="petra-tgg-container full-width">
          <div className="petra-tgg-wordcloud" onClick={containerClicked}>
            <WordCloud data={words} width={800} height={400} fontSize={fontSize} onWordClick={onWordClick} />
          </div>
          {selectedWord !== undefined && (
            <WordCloudDetails
              filteredData={filteredData}
              word={selectedWord}
              setSelectedWord={setSelectedWord}
              responsesWithCache={responsesWithCache}
              design={design}
            />
          )}
          <MoreInformationContainer moreInformationKey={tabConfiguration.moreInformationKey} />
        </div>
      </div>
    </div>
  );
};

const generateWordCloud = (responsesWithCache: ResultResponseWithCache[]): Word[] => {
  const result = {};

  const processString = (str) => {
    const lettersOnlyString = str.replace(/[!,.…"]/gi, "").split(" ");
    for (const word of lettersOnlyString) {
      if (word.length === 0 || EXCLUDED_LIST.includes(word)) continue;

      if (result[word] === undefined) result[word] = 0;
      result[word] += 1;
    }
  };

  for (const rc of responsesWithCache) {
    if (rc.response.values.length === 0) continue;

    const freeText = freeTextFromResponse(rc, " ").toLowerCase();
    processString(freeText);
  }

  return Object.keys(result).map((key) => {
    return { text: key, value: result[key] };
  });
};

export type FilteredDatum = {
  date: string;
  text: string;
  dateObj: Date;
};

const filterData = (responsesWithCache: ResultResponseWithCache[], word: SelectedWord | undefined): FilteredDatum[] => {
  if (word === undefined) return [];

  const filteredData: FilteredDatum[] = [];
  for (const rc of responsesWithCache) {
    if (rc.response.values.length === 0) continue;

    const freeText = freeTextFromResponse(rc, " ").toLowerCase();
    // match string only at word boundaries
    if (freeText.match(new RegExp('(^|\\s|!|,|\\.|…|")' + escapeRegExp(word.word) + '(\\s|!|,|\\.|…|"|$)'))) {
      const date = new Date(rc.response.completedAt || rc.response.openFrom);
      filteredData.push({ date: localizedFormat(date, "eeee dd-MM-yyyy - HH:mm"), text: freeText, dateObj: date });
    }
  }
  return filteredData;
};

export default TabWordCloud;
