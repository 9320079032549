import { IQuestionnaire } from "models";
import * as React from "react";
import { Tag } from "../../bootstrap";

interface ISelectionProps {
  questionnaires: IQuestionnaire[];
  onRemove: (questionnaire: IQuestionnaire) => void;
}

export const Selection = (props: ISelectionProps) => {
  const [formState, setFormState] = React.useState({ preselected: true });

  const handleRemove = (questionnaire: IQuestionnaire) =>
    props.onRemove(questionnaire);

  return (
    <div id="questionnaires-to-add" data-testid="questionnaire-selection">
      {props.questionnaires.map((questionnaire) => (
        <Tag
          key={questionnaire.id}
          id={`questionnaire-${questionnaire.id}`}
          onDismiss={() => handleRemove(questionnaire)}
          dismissButtonLabel={I18n.t("questionnaire_selector.dismiss_button_label", {questionnaire_name: questionnaire.name})}
          role="listitem"
        >
          {questionnaire.name}
        </Tag>
      ))}
    </div>
  );
};
