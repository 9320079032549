import React from "react";

interface Props {
  show: boolean;
  onCloseClicked: () => void;
}

// The Lightbox component is used to display something in the foreground against a backdrop.
// It is currently used by the previewer to show the preview of a questionnaire with
// a mobile phone backdrop around it to give the impression of what a questionnaire would
// look like on a phone.
const Lightbox: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  if (!props.show) return <></>;

  const closeClicked = (e) => {
    if (e.target.classList.contains("lightbox")) {
      props.onCloseClicked();
    }
  };

  return (
    <div className="lightbox" onClick={closeClicked}>
      {props.children}
    </div>
  );
};

export default Lightbox;
