// rights is array met alle mogelijke rechten
// selected_rights is array met rechten die gebruiker al heeft

import classNames from "classnames";
import { difference, includes, union } from "lodash";
import { withRailsMountable } from "logic/rails_component";
import React, { useState } from "react";

interface Props {
  rights: string[];
  initialSelected: string[];
}

export const AccountOrganizationMembershipRights: React.FC<Props> = (props) => {
  const [selectedRights, setSelectedRights] = useState(props.initialSelected);

  const addRight = (right) => {
    if (right === 'all') {
      setSelectedRights(props.rights)
    } else {
      setSelectedRights((rights) => union(rights, [right]));
    }
  };

  const removeRight = (right) => {
    if (right === 'all') {
      setSelectedRights([])
    } else {
      setSelectedRights((rights) => difference(rights, [right]))
    }
  };

  const setRight = (right: string, selected: boolean) => selected ? addRight(right) : removeRight(right);

  return <div className='rights-control'>
    <h5>{ I18n.t('activerecord.attributes.user.rights') }</h5>
    <div className='professional-rights-form'>
      { 
        props.rights.map(right => 
          <Right key={right} right={right} disabled={includes(selectedRights, "all") && right !== "all"} checked={includes(selectedRights, right)} setRight={setRight}  />
        )
      }
    </div>
  </div>
}

const Right: React.FC<{right: string; disabled: boolean; checked: boolean; setRight: (right: string, checked: boolean) => void}> = (props) => {
  const className = classNames("professional-right-icon-container", {active: props.checked});
  const toggleRight = () => {
    props.setRight(props.right, !props.checked);
  }

  return <label className="checkbox">
    <input className="check_boxes optional" id={`account-organization-membership-rights-${props.right}`} value={props.right} name="account_organization_membership[rights][]" type="checkbox" checked={props.checked} disabled={props.disabled} onChange={toggleRight} />
    <div className={className}>
      <div className={`icon glyphicons-${props.right}`} />
    </div>
    <span>{ I18n.t(`rights.${props.right}`) }</span>
  </label>
}

export default withRailsMountable(AccountOrganizationMembershipRights);
