import React from "react";
import { Measurement } from "./Settings";

interface Props {
  measurements: Measurement[];
  value: number | null;
  onChange: (measurementId: number) => void;
}

export const MeasurementSelector: React.FunctionComponent<Props> = (props) => {
  const handleChange = (event) => props.onChange(parseInt(event.target.value));

  return <select value={props.value || undefined} onChange={handleChange}>
    {props.measurements.map((measurement) => <option key={measurement.id} value={measurement.id}>{measurement.name}</option>)}
  </select>;
};
