import * as React from "react";
import { useManageTokensQuery } from "grapqhl";
import { Translate } from "components/Translate";
import { NoTokensView } from "./NoTokens";
import { TokenManager } from "./TokenManager";

export const TokensManager: React.FunctionComponent = () => {
  const { data, loading } = useManageTokensQuery();

  if (loading) {
    return (
      <div>
        <Translate content="busy" />
      </div>
    );
  }

  if (!data || !data.currentDossier) {
    throw "Not loading but no data? Should not happen";
  }

  const respondents = data.currentDossier.respondents;

  if (respondents.length == 0) {
    return (
      <div className="token-manager token-manager-open">
        <NoTokensView />
      </div>
    );
  } else {
    return (
      <div className="token-manager token-manager-open">
        <p>
          <Translate content="epd_area.app.tokens" />
        </p>
        <div className="tokens">
          {respondents.map((respondent) => (
            <TokenManager key={respondent.type} respondent={respondent} />
          ))}
        </div>
      </div>
    );
  }
};
