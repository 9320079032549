import * as React from "react";
import { FormControlGroup, SecondDurationField } from "components/ui/forms";
import { ButtonLink } from "components/ui/buttons";
import { Icon } from "components/ui/icon";

interface Notification {
  event: string;
  delay: number;
  notifier: string;
}

interface Props {
  notifications: Notification[];
  onChange: (notifications: Notification[]) => void;
}

export const NotificationsSettings: React.FunctionComponent<Props> = (props) => {
  const addNotification = () => {
    const blankNotification = {event: 'opened', delay: 0, notifier: 'null'};
    props.onChange(props.notifications.concat(blankNotification));
  }

  const changeNotification = (idx) => {
    return (notification) => {
      const notifications = props.notifications.slice(0) // clone array
      notifications[idx] = notification;
      props.onChange(notifications);
    }
  }

  const removeNotification = (idx) => {
    return () => {
      const notifications = props.notifications.slice(0);
      notifications.splice(idx, 1);
      props.onChange(notifications);
    }
  }
  return <FormControlGroup label={I18n.t("activerecord.attributes.protocol/settings.notifications")}>
    <div>
      { props.notifications.map((notification, idx) => <NotificationSettings key={idx} notification={notification} onChange={changeNotification(idx)} onRemove={removeNotification(idx)} />)}
      <ButtonLink onClick={addNotification}>+ notificatie</ButtonLink>
    </div>
  </FormControlGroup>
}

const NotificationSettings = (props) => {
  return <div>
    <EventSelector {...props} />
    <NotifierSelector {...props} />
    <DelaySelector {...props} />
    <ButtonLink onClick={props.onRemove}><Icon name="trash" /></ButtonLink>
  </div>;
}

const EventSelector = (props) => {
  const handleChange = (event) => {
    const notification = Object.assign({}, props.notification);
    notification.event = event.target.value;
    props.onChange(notification);
  }

  return <select value={props.notification.event} onChange={handleChange}>
    <option value="opened">Opened</option>
    <option value="reminder">Reminder</option>
  </select>;
}

const NotifierSelector = (props) => {
  const handleChange = (event) => {
    const notification = Object.assign({}, props.notification);
    notification.notifier = event.target.value;
    props.onChange(notification);
  }

  return <select value={props.notification.notifier} onChange={handleChange}>
    <option value="null">Geen</option>
    <option value="mail">E-mail</option>
    <option value="sms">SMS</option>
  </select>
}

const DelaySelector = (props) => {
  const handleChange = (value) => {
    const notification = Object.assign({}, props.notification);
    notification.delay = value;
    props.onChange(notification);
  }

  return <SecondDurationField id={""} value={props.notification.delay} onChange={handleChange} />
}
