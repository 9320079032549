import React from "react";
import { ReactSortable } from "react-sortablejs";
import { IMeasurement } from "models";
import { useSortable, SaveOrderButton } from "logic/useSortable";
import { Row } from "./MeasurementsTable/Row";
import { Translate } from "./Translate";
import { updateMeasurementsOrder } from "lib/actions";

interface Props {
  protocolId: number;
  measurements: IMeasurement[];
}

export const MeasurementsTable: React.FunctionComponent<Props> = ({ protocolId, measurements }) => {
  const { list, setList, orderChanged, saving, persistListOrder } = useSortable<IMeasurement>(measurements);
  const saveOrder = () => persistListOrder((ids) => updateMeasurementsOrder(protocolId, ids));

  return (
    <div>
      <SaveOrderButton orderChanged={orderChanged} saving={saving} onClick={saveOrder} />
      <table className="table sortable-table">
        <thead>
          <tr>
            <th className="span4">
              <Translate content="activerecord.attributes.measurement.name" />
            </th>
            <th className="span7">
              <Translate content="admin_area.measurements.questionnaires" />
            </th>
            <th className="span1">
              <Translate content="tables.actions.header" />
            </th>
          </tr>
        </thead>

        <ReactSortable list={list} setList={setList} tag="tbody">
          {list.map((measurement) => (
            <Row key={measurement.id} protocolId={protocolId} measurement={measurement} />
          ))}
        </ReactSortable>
      </table>
      <SaveOrderButton orderChanged={orderChanged} saving={saving} onClick={saveOrder} />
    </div>
  );
};

export default MeasurementsTable;
