import { TimeOffset } from "./Schema";
import { isThisYear, isSameMonth, isSameYear, differenceInDays, startOfDay } from "date-fns";
import { localizedFormat } from "../../../logic/date";
// Parse-time uses matchAll which is not correctly polyfilled for IE 10-11 in current babel preset-env/CoreJS.
import "core-js/proposals/string-match-all";
import { parseTimeOfDay } from "@diatche/parse-time";

// ------------ Exported constants ------------
// A format that is supported in all browsers, including Safari.
export const PARSEABLE_DATE_FORMAT = "%Y/%m/%d %H:%M:%S";

// -------------- Time functions --------------

// Returns a string that prefixes the string representation of the given number `num`
// by `width` times the given string (defaults to '0').
// Acts as a simple toString if `width` is smaller than or equal to the length
// of the string representation of `num`.
export const pad = (num: number, width: number, z: string = "0") => {
  return `${num}`.padStart(width, z);
};

// Returns an integer that is the given `number` rounded to the specified
// number of `decimalPlaces` (defaults to 0).
export const round = (number: number, decimalPlaces: number = 0) => {
  return Math.round(number * 10 ** decimalPlaces) / 10 ** decimalPlaces;
};

// Returns an H:mm (e.g., '7:37' or '23:02') time notation for a given time offset.
// Time offsets are specified in seconds.
export const formatTime = (time: TimeOffset) => {
  time = time % 86400;
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  return `${hours}:${pad(minutes, 2)}`;
};

// Returns a longer format (e.g., '7 uur en 37 minuten' or '23 uur en 2 minuten')
// time notation for a given time offset.
// Time offsets are specified in seconds.
export const formatDuration = (time: TimeOffset) => {
  time = time % 86400;
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  let msg = "";
  if (hours !== 0 || minutes === 0) {
    msg = `${hours} uur`;
  }
  if (minutes !== 0) {
    if (hours !== 0) msg += " en ";
    msg += `${minutes} ${minutes === 1 ? "minuut" : "minuten"}`;
  }
  return msg;
};

// Given a string with a time written in H:mm or HH:mm notation (e.g., '7:37' or '23:02'),
// return the equivalent time offset (in seconds since midnight).
// Returns undefined if parsing failed.
export const unformatTime = (str: string) => {
  const ms = parseTimeOfDay(str);
  if (typeof ms === "undefined") return undefined;

  return ms.valueOf() / 1000;
};

// Returns a string that is a given time offset (in seconds) as a percentage of
// 24 hours, rounded up to two decimals and postfixed with a percent (%) sign.
// If the up to two decimals are not needed, they are not shown (e.g., an
// exact 50% will return as '50%').
// An optional `adjustment` can be provided to nudge the percentage in a positive
// or negative direction. The `adjustment` is given in percentages.
export const durationInPercent = (time: TimeOffset, adjustment: number = 0) => {
  return `${round((100 * (time % 86400)) / 86400 - adjustment, 2)}%`;
};

// Localized "Month Year" notation for dates.
export const monthYear = (date: Date) => {
  return localizedFormat(date, "LLLL uuuu");
};

// Turn a date string into a date object.
export const parseDate = (date: Date | null): Date => {
  if (!date) {
    return new Date();
  }
  return new Date(date);
};

// Format "van 21 oktober tot 29 december" in the most succinct way.
export const formatDatesInterval = (startAt: Date | null, stopAt: Date | null): string => {
  const startDate = parseDate(startAt);
  const endDate = parseDate(stopAt);
  const endDateFormat = isThisYear(startDate) && isThisYear(endDate) ? "d LLLL" : "d LLLL yyyy";
  const startDateFormat = isSameMonth(endDate, startDate)
    ? "d"
    : isSameYear(endDate, startDate)
    ? "d LLLL"
    : "d LLLL yyyy";

  return `van ${localizedFormat(startDate, startDateFormat)} tot ${localizedFormat(endDate, endDateFormat)}`;
};

// Format "(5 dagen)" as the number of full days between start and end date
export const formatDatesNumberOfDays = (startAt: Date | null, stopAt: Date | null): string => {
  const startDate = startOfDay(parseDate(startAt));
  const endDate = startOfDay(parseDate(stopAt));
  const daysDifference = differenceInDays(endDate, startDate);
  return daysDifference > 0 ? `(${daysDifference} dagen)` : "";
};
