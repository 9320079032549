import * as React from "react";
import { range } from 'lodash';
import { setHours, setMinutes } from 'date-fns';
import { IDateSelectProps, DateSelect } from './DateSelect';

export const DateTimeSelect = (props: IDateSelectProps) => {
  function handleChangeHours(e: React.ChangeEvent<HTMLSelectElement>) {
    props.onChange(setHours(props.date, Number.parseInt(e.target.value)))
  }

  function handleChangeMinutes(e: React.ChangeEvent<HTMLSelectElement>) {
    props.onChange(setMinutes(props.date, Number.parseInt(e.target.value)))
  }

  return (
    <span key='date-select'>
      <DateSelect {...props} />
      —
      <select name={`${props.name}_hour`}
              disabled={props.disabled}
              value={props.date.getHours()}
              title={props.t('datetime.prompts.hour')}
              onChange={handleChangeHours}>
        {range(0, 24).map((hour) =>
          <option key={hour} value={hour}>{hour}</option>
        )}
      </select>

      <select name={`${props.name}_minute`}
              disabled={props.disabled}
              value={props.date.getMinutes()}
              title={props.t('datetime.prompts.minute')}
              onChange={handleChangeMinutes}>
        {range(0, 60).map((minute) =>
          <option key={minute} value={minute}>{minute}</option>
        )}
      </select>
    </span>
  );
};
