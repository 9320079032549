import React, { useState, useMemo } from "react";
import { ResultResponseWithCache, TabConfiguration } from "../common/Graphs";
import TabGraph from "./TabGraph";
import TabHeader from "./TabHeader";
import TabWordCloud from "./TabWordCloud";
import TabSituationGraph from "./TabSituationGraph";
import { useTabState } from "./useTabState";
import TabGraphWeekly from "./TabGraphWeekly";
import TabGraphDaily from "./TabGraphDaily";
import { Design } from "../common/Schema";

interface Props {
  tabConfigurations: TabConfiguration[];
  responsesWithCache: ResultResponseWithCache[];
  openToDate: (Date) => void;
  blocksPerDay: number;
  design: Design;
}

// The TabCollection is part of a graph. A Graph component can have multiple tabs.
// A TabCollection is the container component for these tabs. It controls which
// tab is active and also renders the graph for the active tab.
// Each tab displays a different highcharts graph.
const TabCollection: React.FunctionComponent<Props> = (props) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  // Fix the `initialVariables` so we don't have to refetch/reset `tabState`.
  const initialVariables = useMemo(() => {
    return props.tabConfigurations[activeIndex].variables;
  }, []);
  const tabState = useTabState(initialVariables);
  return (
    <div className="petra-tab-collection">
      <div className="petra-tab-headers">
        {props.tabConfigurations.map((tabConfiguration, idx) => {
          return (
            <TabHeader
              key={tabConfiguration.label}
              label={tabConfiguration.label}
              active={idx === activeIndex}
              setActive={() => setActiveIndex(idx)}
            />
          );
        })}
      </div>
      {props.tabConfigurations[activeIndex].type === "wordcloud" && (
        <TabWordCloud
          key={props.tabConfigurations[activeIndex].label}
          tabConfiguration={props.tabConfigurations[activeIndex]}
          responsesWithCache={props.responsesWithCache}
          tabState={tabState}
          design={props.design}
        />
      )}
      {props.tabConfigurations[activeIndex].type === "lineplot" && (
        <TabGraph
          key={props.tabConfigurations[activeIndex].label}
          tabConfiguration={props.tabConfigurations[activeIndex]}
          responsesWithCache={props.responsesWithCache}
          openToDate={props.openToDate}
          tabState={tabState}
          blocksPerDay={props.blocksPerDay}
        />
      )}
      {props.tabConfigurations[activeIndex].type === "weekly-patterns" && (
        <TabGraphWeekly
          key={props.tabConfigurations[activeIndex].label}
          tabConfiguration={props.tabConfigurations[activeIndex]}
          responsesWithCache={props.responsesWithCache}
          openToDate={props.openToDate}
          tabState={tabState}
        />
      )}
      {props.tabConfigurations[activeIndex].type === "daily-patterns" && (
        <TabGraphDaily
          key={props.tabConfigurations[activeIndex].label}
          tabConfiguration={props.tabConfigurations[activeIndex]}
          responsesWithCache={props.responsesWithCache}
          openToDate={props.openToDate}
          tabState={tabState}
        />
      )}
      {props.tabConfigurations[activeIndex].type === "situationplot" && (
        <TabSituationGraph
          key={props.tabConfigurations[activeIndex].label}
          tabConfiguration={props.tabConfigurations[activeIndex]}
          responsesWithCache={props.responsesWithCache}
          openToDate={props.openToDate}
          tabState={tabState}
        />
      )}
    </div>
  );
};

export default TabCollection;
