import React from "react";
import { FormControlGroup, SecondDurationField } from "components/ui/forms";
import { ButtonLink } from "components/ui/buttons";
import { clone, last } from "lodash";
import { ScheduleItem, Measurement, DailySchedulerSettings } from "./Settings";
import { ScheduleItemRow } from "./ScheduleItemRow";

interface Props {
  settings: DailySchedulerSettings;
  measurements: Measurement[];
  onChange: (settings: Partial<DailySchedulerSettings>) => void;
}

export const DailySchedulerFields: React.FunctionComponent<Props> = (props) => {
  const add = () => {
    const newItem = clone(last(props.settings.schedule)) || { offset: 0, measurement_id: props.measurements[0].id };
    props.onChange({ schedule: props.settings.schedule.concat(newItem) });
  };

  const change = (idx: number) => {
    return (newItem: ScheduleItem) => {
      const schedule = props.settings.schedule.slice(0);
      schedule[idx] = newItem;
      props.onChange({ schedule: schedule });
    }
  }

  const remove = (idx: number) => {
    return () => {
      const schedule = props.settings.schedule.slice(0);
      schedule.splice(idx, 1);
      props.onChange({ schedule: schedule });
    };
  };

  const setDailyStartTime = (value) => props.onChange({ daily_start_time: value });

  return <div>
    <FormControlGroup label={I18n.t("activerecord.attributes.protocol/settings.schedule")}>
      <div>
        {
          props.settings.schedule.map((item, idx) =>
            <ScheduleItemRow key={`offset-${item.measurement_id}-${idx}`}
                             durationComponentClass={SecondDurationField}
                             measurements={props.measurements}
                             item={item}
                             onChange={change(idx)}
                             onRemove={remove(idx)}/>
          )
        }

        <ButtonLink onClick={add}>+ meetmoment</ButtonLink>
      </div>
    </FormControlGroup>

    <FormControlGroup label="Voorgesteld startmoment op de dag">
      <SecondDurationField id="daily_start_time" value={props.settings.daily_start_time} onChange={setDailyStartTime}/>
    </FormControlGroup>
  </div>;
};

