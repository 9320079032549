import React from "react";
import { PlotVariable } from "../common/Graphs";
import SideMenu from "./SideMenu";
import { uniq, uniqBy, filter, countBy } from "lodash";
import VariableSelector from "./VariableSelector";

interface Props {
  selectedVariables: Selections<string>;
  variables: PlotVariable[];
  singleSelect?: boolean;
  name: string;
}

// The TabGraphOptions component is called by TabGraph component, and renders all
// the different side menus for the top domains containing checkboxes for the variables
// that are plotted. If `singleSelect` is true, then at most one variable can be selected
// (upon selecting a second variable, the first variable becomes unselected),
// and the list of options are presented as radio buttons rather than checkboxes.
const TabGraphOptions: React.FunctionComponent<Props> = ({
  selectedVariables,
  variables,
  singleSelect = false,
  name,
}) => {
  const categories = uniq(variables.map((variable) => variable.category));
  const selectedPlotVariables = uniqBy(variables, "key").filter((variable) =>
    selectedVariables.isSelected(variable.key)
  );
  return (
    <>
      {selectedPlotVariables.length > 0 && (
        <SideMenu title="Getoond" initiallyExpanded>
          {selectedPlotVariables.map((variable) => (
            <VariableSelector
              key={variable.key}
              selectedVariables={selectedVariables}
              variable={variable}
              singleSelect={singleSelect}
              name={`${name}-selected`}
            />
          ))}
        </SideMenu>
      )}
      {categories.map((category) => {
        const categoryVariables = filter(variables, { category: category });
        const keysOfVariablesInCategory = categoryVariables.map((variable) => variable.key);
        let menuTitle = `${category}`;
        // Only when we can have multiple variables selected, do we care about how many
        // variables we have selected from each category.
        if (!singleSelect) {
          menuTitle += ` (${countBy(keysOfVariablesInCategory, selectedVariables.isSelected).true || 0}/${
            categoryVariables.length
          })`;
        }
        return (
          <SideMenu key={category} title={menuTitle} orange>
            {categoryVariables.map((variable) => (
              <VariableSelector
                key={`${category}-${variable.key}`}
                selectedVariables={selectedVariables}
                variable={variable}
                singleSelect={singleSelect}
                name={`${name}-category-${category}`}
              />
            ))}
          </SideMenu>
        );
      })}
    </>
  );
};

export default TabGraphOptions;
