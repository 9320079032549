import * as React from "react";
import classNames from "classnames";

interface Props {
  className: string
  text?: string
}

export const Label: React.FunctionComponent<Props> = (props) => {
  if (props.text) {
    return <span className={classNames('label', props.className)}>{props.text}</span>;
  }
  else {
    return null;
  }
}
