import React, { useMemo } from "react";
import { determineMinAndMaxDate } from "./ResultsSlideOver";
import { enUS, nl } from "date-fns/locale";
import { ResultResponseWithCache } from "../common/Graphs";
import { DATE_PICKER_DEFAULT_OPTIONS } from "../common/Constants";
import DatePicker from "react-datepicker";
import Toggle from "../common/Toggle";
import { TabState } from "./useTabState";
import classNames from "classnames";
import { startOfDay, endOfDay } from "date-fns";

const locales = { en: enUS, nl };

interface Props {
  responsesWithCache: ResultResponseWithCache[];
  tabState: TabState;
}

// The TabSituationGraphControls renders the controls for the situation graphs.
// It controls date filtering and toggling the display for the measurement points and means.
const TabSituationGraphControls: React.FunctionComponent<Props> = ({ responsesWithCache, tabState }) => {
  const [minDate, maxDate] = useMemo(() => {
    return determineMinAndMaxDate(responsesWithCache);
  }, [responsesWithCache]);
  const locale = locales[window.dateFnsLocale];
  return (
    <div className="petra-tab-controls contains-petra-arrow">
      <div className="petra-controls-field">
        <div>
          <Toggle checked={tabState.showMeans} setChecked={tabState.setShowMeans} label="Toon gemiddeldes" />
        </div>
        <div>
          <Toggle checked={tabState.showMoments} setChecked={tabState.setShowMoments} label="Toon losse momenten" />
        </div>
        <div>
          <label htmlFor="start">Toon periode tussen</label>
          <div className="contains-clear-date-field">
            <DatePicker
              id="start"
              name="start-date"
              {...DATE_PICKER_DEFAULT_OPTIONS}
              selected={tabState.startDate}
              onChange={(date) => tabState.setStartDate(date ? startOfDay(date) : date)}
              selectsStart
              startDate={tabState.startDate}
              endDate={tabState.endDate}
              minDate={minDate}
              maxDate={maxDate}
              className={classNames("date-box", { filled: tabState.startDate })}
              locale={locale}
              openToDate={tabState.startDate ?? minDate}
              isClearable
            />
          </div>
          <div className="input-and">en</div>
          <div className="contains-clear-date-field">
            <DatePicker
              id="end"
              name="end-date"
              {...DATE_PICKER_DEFAULT_OPTIONS}
              selected={tabState.endDate}
              onChange={(date) => tabState.setEndDate(date ? endOfDay(date) : date)}
              selectsEnd
              startDate={tabState.startDate}
              endDate={tabState.endDate}
              minDate={tabState.startDate ?? minDate}
              maxDate={maxDate}
              className={classNames("date-box", { filled: tabState.endDate })}
              locale={locale}
              openToDate={tabState.endDate ?? maxDate}
              isClearable
            />
          </div>
        </div>
      </div>
      <div className="petra-arrow-down gray" />
    </div>
  );
};

export default TabSituationGraphControls;
