import React, { useState } from "react";
import { useResearchParticipationsListQuery } from "grapqhl";
import { Link } from "react-router-dom";
import { Loader } from "components/ui/loader";
import RecentDiary from "./recent_diaries/RecentDiary";
import { orderBy } from "lodash";
import Video from "../common/Video";
import { useLocation } from "react-router-dom";
import { FlagInterface } from "../common/Flags";
import PreviewQuestionnaireSettings from "../common/Previewer/PreviewQuestionnaireSettings";

interface Props {
  researchProjectId: number;
}

// For reading parameters out of the browser history state
interface State {
  protSubStarted?: boolean;
}

// Retrieve and show the list of research participations for the PETRA research project.
// The Root component by shows the overview of petra studies, and displays
// a button allowing the user to start a new diary study. Clicking that button
// navigates the user to /wizard.
const Root: React.FunctionComponent<Props> = (props) => {
  const [hideContainer, setHideContainer] = useState<boolean>(false);
  const [previewSettings, setPreviewSettings] = useState<PreviewSettings | null>(null);

  const hidePreview = () => {
    setPreviewSettings(null);
  };

  const { protSubStarted = false } = useLocation<State>().state || {};

  const results = useResearchParticipationsListQuery({
    variables: {
      researchProjectId: props.researchProjectId.toString(),
    },
    fetchPolicy: "network-only",
  });

  if (results.loading) {
    return <Loader />;
  }

  if (!results.data || !results.data.currentDossier) {
    return <div>{JSON.stringify(results.error)}</div>;
  }

  const patientPhone = results.data.currentDossier?.patientRespondent?.phoneCell || "";

  // Renders the main overview page of Petra.
  // On this page, users can either click on the button to start a new diary study,
  // stop one of the ongoing diary studies from the list of diary studies,
  // or view the feedback for one of the completed diary studies.
  return (
    <div className="petra">
      <div className="main-only">
        <div className="petra-main">
          <PreviewQuestionnaireSettings previewSettings={previewSettings} hidePreview={hidePreview} />
          <div className="colored-header">
            <h2>Welkom bij PETRA, dagboeken op maat voor de psychiatrie</h2>
          </div>
          <div className="petra-content">
            <div className="navigation with-warning">
              <Link to="wizard/goals" className="petra-start-button default">
                Start nieuw dagboek
              </Link>
              {patientPhone === "" && (
                <div className="petra-phone-warning contains-petra-arrow">
                  Let op: voor deze persoon is geen telefoonnummer ingesteld. Zonder telefoonnummer kan je geen dagboek
                  starten.
                  <div className="petra-arrow-left" />
                </div>
              )}
            </div>
            <div>
              <div className="recent-diaries-container">
                {protSubStarted && !hideContainer && (
                  <div className="attention-well">
                    <h3>
                      Dagboek gestart{" "}
                      <span className="close-button" onClick={() => setHideContainer(true)}>
                        ✖
                      </span>
                    </h3>
                    <p>
                      Wat goed! Je dagboek is gestart. Wil je weten hoe je om kunt gaan met het invullen van een
                      dagboek? Bekijk dan dit filmpje van 2 minuten met praktische tips.
                    </p>
                    <Video width="399" height="224" name="PETRA_Instructie" />
                  </div>
                )}
                <h3>Recente dagboeken</h3>
                {orderBy(results.data.currentDossier.researchParticipations, ["createdAt"], ["desc"]).map(
                  (researchParticipation) => (
                    <RecentDiary
                      key={researchParticipation.id}
                      researchParticipation={researchParticipation}
                      setPreviewSettings={setPreviewSettings}
                    />
                  )
                )}
                {results.data.currentDossier.researchParticipations.length === 0 && (
                  <div className="petra-field">
                    <p>
                      <em>
                        Nog geen recente dagboeken gestart. Klik op de knop "Start nieuw dagboek" om één aan te maken.
                      </em>
                    </p>
                  </div>
                )}
              </div>
              <div className="side-explanation">
                <h3>Hoe werkt PETRA?</h3>
                <Video width="399" height="224" name="PETRA_Kennismaking" />
                <ul>
                  <li>Bekijk onze korte instructievideo (2 minuten)</li>
                  <li>
                    Bekijk{" "}
                    <a
                      href="https://www.petrapsy.nl/wp-content/uploads/2021/07/PETRA-Keuzehulp.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      onze handleiding (pdf)
                    </a>
                  </li>
                </ul>
                <h3>Suggesties voor PETRA?</h3>
                <ul>
                  <li>
                    We zijn altijd op zoek naar tips en trucs om PETRA te verbeteren. Je kunt je suggesties sturen naar{" "}
                    <a href="mailto:petra@umcg.nl" target="_blank" rel="noopener noreferrer">
                      petra@umcg.nl
                    </a>
                  </li>
                  <li>
                    Meer weten over PETRA? Dat kan natuurlijk. Op onze{" "}
                    <a href="https://www.petrapsy.nl" target="_blank" rel="noopener noreferrer">
                      website (petrapsy.nl)
                    </a>{" "}
                    vind je meer
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export interface PreviewSettings {
  flagInterface: FlagInterface;
  questionnaireKey: string;
}

export default Root;
