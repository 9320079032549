import { Response, ResponseValue } from "grapqhl";

import { generateValuesHash, ResponseWithCache } from "./ResponseWithCache";

export const getValue = (
  responseWithCache: ResponseWithCache<Pick<Response, 'values'>, any, any>,
  fieldKey: ResponseValue["key"]
): ResponseValue["value"] => {
  responseWithCache.valuesHash ??= generateValuesHash(responseWithCache.response.values);
  return responseWithCache.valuesHash[fieldKey] || null;
};
