import { Translate } from "components/Translate";
import { CurrentDossierInfoQuery, RespondentSyncStatusEnum, useRefreshDossierMutation } from "grapqhl";
import * as React from "react";

type SyncableDossier = CurrentDossierInfoQuery["dossier"];

interface Props {
  dossier: SyncableDossier;
}

export const RefreshButton: React.FunctionComponent<Props> = ({ dossier }) => {
  const [refreshDossier, { loading }] = useRefreshDossierMutation({ variables: { input: { dossierId: dossier.id } } });

  if (loading) {
    return (
      <span className="patient-detail loading">
        <Translate content="busy" />
      </span>
    );
  } else {
    switch (dossier.patientRespondent.syncStatus) {
      case RespondentSyncStatusEnum.SyncPending:
        return (
          <span className="patient-detail loading">
            <Translate content="epd_area.current_dossier_info.sync_status.sync_pending" />
          </span>
        );
      case RespondentSyncStatusEnum.Syncing:
        return (
          <span className="patient-detail loading">
            <Translate content="epd_area.current_dossier_info.sync_status.syncing" />
          </span>
        );
      default:
        return (
          <a
            className="refresh"
            id="refresh-button"
            data-original-title={refreshButtonTitle(dossier)}
            data-placement="bottom"
            onClick={() => refreshDossier()}
          >
            <span className="icon roqicon-refresh" />
          </a>
        );
    }
  }
};

function refreshButtonTitle(dossier: SyncableDossier) {
  let title = I18n.t("refresh_patient_details");
  const statusMessage = syncStatusMessage(dossier);

  if (statusMessage !== "") {
    title = `${title} (${statusMessage})`;
  }

  return title;
}

function syncStatusMessage(dossier: SyncableDossier) {
  let status = "";

  if (dossier.patientRespondent.lastSyncSuccess) {
    status += ` ${I18n.t("patient.last_refreshed_at", {
      date: I18n.l("time.formats.date", dossier.patientRespondent.lastSyncSuccess),
      time: I18n.l("time.formats.time", dossier.patientRespondent.lastSyncSuccess),
    })}`;
  }

  switch (dossier.patientRespondent.syncStatus) {
    case RespondentSyncStatusEnum.SyncFailed:
      status += `\n ${I18n.t("patient.out_of_sync")}`;
      break;
    case RespondentSyncStatusEnum.Syncing:
      status += `\n ${I18n.t("patient.potentially_out_of_sync")}`;
      break;
  }

  return status;
}
