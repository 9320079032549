import React from "react";
import { isEqual } from "lodash";
import classNames from "classnames";
import { Translate } from "components/Translate";
import { ItemInterface } from "react-sortablejs";

type ID = ItemInterface["id"];
const idFunction = (item: ItemInterface) => item.id;

type PersistCallbackFn = (ids: ID[]) => JQuery.jqXHR;
type PersistFn = (callback: PersistCallbackFn) => JQuery.jqXHR;

interface SortableHook<T> {
  list: T[];
  setList: React.Dispatch<React.SetStateAction<T[]>>;
  orderChanged: boolean;
  saving: boolean;
  persistListOrder: PersistFn;
}


export function useSortable<T extends ItemInterface>(initialList: T[]): SortableHook<T> {
  const [ list, setList ] = React.useState<T[]>(initialList);
  const [ savedOrder, setSavedOrder ] = React.useState<ID[]>(initialList.map(idFunction));
  const [ saving, setSaving ] = React.useState<boolean>(false);
  const orderChanged = !isEqual(list.map(idFunction), savedOrder);

  const persistListOrder: PersistFn = (callback) => {
    setSaving(true);

    const ids = list.map(idFunction);

    return callback(ids)
      .done(() => setSavedOrder(ids))
      .always(() => setSaving(false));
  }

  return { list, setList, orderChanged, saving, persistListOrder };
}

export const SaveOrderButton = ({ orderChanged, saving, onClick }) => {
  const className = classNames("sortable-table-mixin", { disabled: !orderChanged, saving: saving });

  return (
    <a className={className} onClick={() => orderChanged && !saving && onClick()}>
      <Translate content="tables.actions.save_order" />
    </a>
  );
};