import React, { useRef } from "react";
import SlidingPane from "react-sliding-pane";
import Button from "../common/Button";
import { useReportCreateMutation } from "../../../grapqhl";
import { trackEvent } from "../common/Matomo";
import { Design } from "../common/Schema";
import { sendError } from "lib/appsignal";

interface Props {
  isOpen: boolean;
  setOpen: (boolean) => void;
  renderReport: (highlightRef: React.RefObject<HTMLDivElement>, highlightLastGraph: boolean) => JSX.Element;
  renderStaticReport: () => string;
  highlightLastGraph: boolean;
  reportTemplateId: string | null;
  dossierId: string;
  researchParticipationId: string;
  responseIds: string[];
  design: Design;
}

// Render the slideover for creating a report.
const ReportSlideOver: React.FunctionComponent<Props> = (props) => {
  const highlightRef: React.RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const [reportCreateMutation, reportCreationStatus] = useReportCreateMutation();

  // If we opened the slide over as a result of adding a graph to the report, then
  // highlight this graph and scroll to it.
  // Otherwise (meaning if we opened this slideover by clicking the "show report"
  // button instead), then we don't highlight or scroll to a specific graph.
  const scrollToLastGraph = () => {
    if (props.highlightLastGraph) {
      highlightRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  // Disable the create report button when the mutation is in progress.
  const createDisabled = reportCreationStatus.loading;

  const createReport = () => {
    if (!props.reportTemplateId) {
      // Only happens if this is ran in an organization where the PETRA report template seeds
      // were not run. In this case, we want to show an error but not prevent the entire report
      // page from working.
      RoQua.showFlash({
        state: "error",
        message: "Er ging iets fout bij het aanmaken van het rapport: report template niet gevonden",
      });
      return;
    }
    trackEvent({ category: "Petra - Create report", name: props.design });
    reportCreateMutation({
      variables: {
        input: {
          dossierId: props.dossierId,
          reportTemplateId: props.reportTemplateId,
          researchParticipationId: props.researchParticipationId,
          responseIds: props.responseIds,
          unsafeHtml: props.renderStaticReport(),
        },
      },
    })
      .then((data) => {
        const result = data?.data?.reportCreateForHtml;
        const errors = result?.errors;
        if (errors && errors.length > 0) {
          console.log(errors);
          RoQua.showFlash({ state: "error", message: "Er ging iets fout bij het aanmaken van het rapport" });
          return;
        }
        // The flash message is displayed briefly after closing the slideover and while loading the redirect.
        RoQua.showFlash({ state: "success", message: "Het rapport is aangemaakt" });
        const reportId = data?.data?.reportCreateForHtml?.result?.report?.id;
        props.setOpen(false);
        // Redirect to the newly created report.
        if (reportId) {
          window.location.href = `/epd/dossiers/${props.dossierId}/reports/${reportId}`;
        }
      })
      .catch((e) => {
        sendError(e, { flash: "Er ging iets fout bij het aanmaken van het rapport" });
      });
  };

  return (
    <SlidingPane
      className="petra"
      closeIcon={<div />}
      isOpen={props.isOpen}
      title="Rapport - nog niet opgeslagen"
      from="right"
      width="600px"
      onRequestClose={() => props.setOpen(false)}
      onAfterOpen={scrollToLastGraph}
    >
      <div className="results slide-over">
        <div className="petra-main">
          <div className="colored-header">
            <div className="header-close-button" onClick={() => props.setOpen(false)} />
            <h2>Rapport - nog niet opgeslagen</h2>
          </div>
          <div className="petra-content">
            {props.renderReport(highlightRef, props.highlightLastGraph)}
            <div className="navigation sticky">
              <Button label="Sluiten" onClick={() => props.setOpen(false)} />
              <Button label="Sla rapport op" isDefault disabled={createDisabled} onClick={createReport} />
            </div>
          </div>
        </div>
      </div>
    </SlidingPane>
  );
};

export default ReportSlideOver;
