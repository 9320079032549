import React from "react";

import { ProtocolSubscriptionUnsubscribeReasonEnum, useProtocolSubscriptionStopMutation } from "../../../../grapqhl";
import { useNavigate } from "react-router-dom";
import { PetraResearchParticipation } from "../../common/Results";
import { previewDiary } from "./RecentDiaryUtils";
import { FlagInterface } from "../../common/Flags";
import useResearchParticipation from "./useResearchParticipation";
import { PreviewSettings } from "../index";
import { sendError } from "lib/appsignal";

interface Props {
  researchParticipation: PetraResearchParticipation;
  setPreviewSettings: (previewSettings: PreviewSettings) => void;
}

// Class for rendering the buttons for an active protocol subscription.
const ActiveButtons: React.FunctionComponent<Props> = ({ researchParticipation, setPreviewSettings }) => {
  const navigate = useNavigate();
  const [protocolSubscriptionStopMutation, subScriptionStatus] = useProtocolSubscriptionStopMutation();

  const stopDisabled = subScriptionStatus.loading;

  // Turns a research participation into a flagInterface
  const flagInterface: FlagInterface | null = useResearchParticipation(researchParticipation);

  // Stop all protocol subscriptions for a given research participation
  const stopProtocolSubscriptions = () => {
    for (const protSub of researchParticipation.protocolSubscriptions) {
      protocolSubscriptionStopMutation({
        variables: {
          input: {
            protocolSubscriptionId: protSub.id,
            unsubscribeReason: ProtocolSubscriptionUnsubscribeReasonEnum.UnsubscribedByProfessional,
          },
        },
      })
        .then((_data) => {
          RoQua.showFlash({ state: "success", message: "De protocolsubscriptie is gestopt" });
        })
        .catch((e) => {
          sendError(e, { flash: `Er ging iets fout bij het stoppen van de protocolsubscriptie met id ${protSub.id}` });
        });
    }
  };

  return (
    <>
      <button onClick={() => navigate(`/results/${researchParticipation.id}`)}>
        Feedback
        <div className="button-icon summary" />
      </button>
      <button onClick={() => previewDiary(researchParticipation, setPreviewSettings, flagInterface)}>
        Dagboek
        <div className="button-icon diary" />
      </button>
      {/* Hide the reports button for now because we don't have that functionality yet.
      <button>
        Rapporten
        <div className="button-icon reports" />
      </button>
      */}
      <button className="stop" disabled={stopDisabled} onClick={stopProtocolSubscriptions}>
        Stoppen
        <div className="button-icon stop" />
      </button>
    </>
  );
};

export default ActiveButtons;
