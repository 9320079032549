import * as React from "react";

export const Loader: React.FunctionComponent = () => {
  return <div className="loader">
    <div className="bullet1" />
    <div className="bullet2" />
    <div className="bullet3" />
    <div className="bullet4" />
    <div className="bullet5" />
  </div>;
}