export interface NonResponse {
  reason: string;
  reasonGroup: string;
  reasonOption: string;
  reasonCode: string;
  reasonOther: string;
}

export interface Selection {
  option: NonResponseOption | null | undefined;
  otherText: string | null;
}

export interface NonResponseOption {
  code: string;
  label: string;
}

export interface NonResponseGroup {
  label: string;
  allowCustomDate: boolean;
  options: NonResponseOption[];
  otherOption?: NonResponseOption;
}

export interface NonResponseGroups {
  didInvite: NonResponseGroup;
  didNotInvite: NonResponseGroup;
}

export interface NonResponseSchema {
  groups: NonResponseGroups;
}

export const SCHEMA: NonResponseSchema = {
  groups: {
    didInvite: {
      label: "Patiënt is wel benaderd voor de ROM meting, maar:",
      allowCustomDate: false,
      options: [
        { code: "01", label: "Patiënt weigert meting." },
        { code: "02", label: "Patiënt is niet in staat tot responderen." },
        { code: "02a", label: "Patiënt is niet in staat tot responderen <i>vanwege taal</i>." },
        { code: "02b", label: "Patiënt is niet in staat tot responderen <i>vanwege de ernst van de stoornis</i>." },
        { code: "03", label: "Patiënt is (herhaaldelijk) niet verschenen op de afspraak voor de meting." },
        { code: "04", label: "Patiënt retourneert meetinstrument niet." },
        { code: "05", label: "Meetinstrument is incompleet of onjuist ingevuld." },
      ],
    },

    didNotInvite: {
      label: "Patiënt is niet benaderd voor de ROM meting, omdat:",
      allowCustomDate: true,
      options: [
        { code: "06", label: "Patiënt niet in staat werd geacht tot responderen." },
        { code: "06a", label: "Patiënt niet in staat werd geacht tot responderen <i>vanwege taal</i>." },
        {
          code: "06b",
          label: "Patiënt niet in staat werd geacht tot responderen <i>vanwege de ernst van de stoornis</i>.",
        },
        { code: "07", label: "Er geen actie is ondernomen door de behandelaar/assessor." },
        {
          code: "08",
          label: "Er geen evalueerbare behandeling is uitgevoerd (crisis, diagnostiek, ultrakorte &#39;behandeling&#39;, etc.).",
        },
        { code: "09", label: "De behandeling niet regulier is afgerond." },
        { code: "09a", label: "De behandeling niet regulier is afgerond <i>vanwege overlijden van de patiënt</i>." },
        {
          code: "09b",
          label: "De behandeling niet regulier is afgerond <i>door eenzijdig staken van de behandeling door de patiënt</i>.",
        },
      ],
      otherOption: { code: "10", label: "Andere reden, namelijk:" },
    },
  },
};
