import React from "react";

interface Props {
  width: string;
  height: string;
  name: "PETRA_Kennismaking" | "PETRA_Feedbackmodule" | "PETRA_Instructie";
}

const VIDEO_ASSET_PREFIX = "//petra-assets.s3.eu-central-1.amazonaws.com/";
const THUMBNAIL_POSTFIX = ".png";
const VIDEO_POSTFIX = ".mp4";

// Display an embedded Petra video
const Video: React.FunctionComponent<Props> = (props) => {
  const videoSource = `${VIDEO_ASSET_PREFIX}${props.name}${VIDEO_POSTFIX}`;
  const videoThumbnail = `${VIDEO_ASSET_PREFIX}${props.name}${THUMBNAIL_POSTFIX}`;
  return (
    <video
      width={props.width}
      height={props.height}
      poster={videoThumbnail}
      preload="none"
      controls
      style={{ width: "100%", height: "100%", maxWidth: "1000px" }}
    >
      <source src={videoSource} type="video/mp4" />
      Uw browser understeunt de video tag niet.
    </video>
  );
};

export default Video;
