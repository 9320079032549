import React, { useEffect } from "react";
import PreviewQuestionnaireLightbox from "./PreviewQuestionnaireLightbox";
import { PreviewSettings } from "../../Root";
import { trackEvent } from "../Matomo";

interface Props {
  previewSettings: PreviewSettings | null;
  hidePreview: () => void;
}

// Render a questionnaire preview lightbox based on the given PreviewSettings..
const PreviewQuestionnaireSettings: React.FC<Props> = ({ previewSettings, hidePreview }) => {
  if (!previewSettings) return null;

  useEffect(() => {
    trackEvent({ category: "Petra - Compose - Show preview", name: previewSettings.questionnaireKey });
  }, []);

  return (
    <PreviewQuestionnaireLightbox
      previewShown
      hidePreview={hidePreview}
      questionnaireKey={previewSettings.questionnaireKey}
      flags={previewSettings.flagInterface}
    />
  );
};

export default PreviewQuestionnaireSettings;
