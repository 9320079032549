import * as React from "react";

export interface ICheckboxProps {
  value: boolean;
  label: string;
  onChange: Function;

  // simple-react-form will pass in other props given to <Field> as passProps
  passProps: React.HTMLProps<HTMLInputElement>;
}

export function CheckboxControlGroup(props: ICheckboxProps) {
  return (
    <div className="control-group">
      <div className="controls">
        <Checkbox {...props} />
      </div>
    </div>
  );
}

export function Checkbox(props: ICheckboxProps) {
  const handleChange = (event) => props.onChange(event.target.checked);

  return (
    <label className="checkbox">
      <input
        type="checkbox"
        checked={props.value}
        onChange={handleChange}
        {...props.passProps}
      />
      {props.label}
    </label>
  );
}
