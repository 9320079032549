import { QuestionInterface } from "grapqhl";

import { generateQuestionsHash, QuestionWithKey, ResponseWithCache } from "./ResponseWithCache";

interface QuestionnaireWithQuestionsWithKeys<TQuestion> {
  key: string;
  questions: TQuestion[]
};

export function getQuestion<TQuestion extends QuestionWithKey>(
  responseWithCache: ResponseWithCache<any, QuestionnaireWithQuestionsWithKeys<TQuestion>, TQuestion>,
  questionKey: string
): TQuestion {
  responseWithCache.questionsHash ??= generateQuestionsHash(responseWithCache.questionnaire.questions);
  const question = responseWithCache.questionsHash[questionKey];
  if (!question) throw `Unknown question  ${responseWithCache.questionnaire.key}.${questionKey}`;
  return question;
};
