import React from "react";

interface Props {
  model: any;
}

export const NonResponseDeprecatedSchema: React.FC<Props> = ({ model }) => {
  return (
    <fieldset>
      <legend>{model.get("reason_group")}</legend>

      <div>
        <label className="radio">
          <input name="reason_option" className="non-response-reason" type="radio" disabled={true} checked={true} />
          <span dangerouslySetInnerHTML={{ __html: model.get("reason_option") }} />
        </label>
      </div>

      <div>
        <textarea name="reason_other" rows={3} className="span12" disabled={true} value={model.get("reason_other")} />
      </div>
    </fieldset>
  );
};
