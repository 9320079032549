import { ListPicker } from "components/ListPicker";
import { withRailsMountable } from "logic/rails_component";
import React from "react";

interface Questionnaire {
  id: number;
  key: string;
  name: string;
  short_description: string;
}

interface Props {
  questionnaires: Questionnaire[];
  selectedQuestionnaires: Questionnaire[];
  inputName: string;
}
export const QuestionnairePicker: React.FC<Props> = (props) => {
  return (
    <ListPicker
      items={props.questionnaires}
      initialSelectedItems={props.selectedQuestionnaires}
      inputName={props.inputName}
      itemRenderer={({ item, onSelectItem }) => <Item key={item.id} item={item} onSelectItem={onSelectItem} />}
      matcher={matcher}
    />
  );
};

const Item: React.FC<{ item: Questionnaire; onSelectItem: () => void }> = ({ item, onSelectItem }) => {
  return (
    <li className="questionnaire-picker-list-item" id={`${item.id}`} onDoubleClick={() => onSelectItem()}>
      <span className="name">{item.name}</span>
      <span className="description">{`[${item.key}]${item.short_description && ` - ${item.short_description}`}`}</span>
      <a href="#self" className="select-item" onClick={() => onSelectItem()} title={I18n.t("admin_area.add")}>
        {"+"}
      </a>
    </li>
  );
};

const matcher = (item: Questionnaire, filter: string) => {
  const normalizedFilter = filter.toUpperCase();

  return item.name.toUpperCase().includes(normalizedFilter) || item.short_description?.includes(normalizedFilter);
};

export default withRailsMountable(QuestionnairePicker);
