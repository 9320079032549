import { Map } from "immutable";
import { IQuestionnaire } from "models";
import * as React from "react";
import { Field, Form } from "simple-react-form";
import { Button, Checkbox, Modal, ModalBody, ModalFooter, ModalHeader } from "bootstrap";
import { Translate } from "../Translate";
import { Search } from "./Search";
import { Selection } from "./Selection";
import { AuthenticityToken } from "components/AuthenticityToken";
import { useSelections } from "ahooks";

interface IDialogProps {
  onClose: () => void;
  questionnaires?: IQuestionnaire[];
  addedQuestionnaires?: IQuestionnaire[];
  baseUrl: string;
  authenticityToken: string;
}

export const Dialog = (props: IDialogProps) => {
  const { questionnaires = [], addedQuestionnaires = [], baseUrl, authenticityToken } = props;
  const selection = useSelections(questionnaires);

  const handleCancel = () => {
    selection.unSelectAll();
    props.onClose();
  };

  const selectedQuestionnaires = selection.selected;

  return (
    <Modal onClose={handleCancel}>
      <ModalHeader close>
        <h3>
          <Translate content="admin_area.measured_questionnaires.add_trigger" />
        </h3>
        <Selection questionnaires={selectedQuestionnaires} onRemove={selection.unSelect} />
      </ModalHeader>

      <ModalBody>
        <Search
          questionnaires={questionnaires}
          addedQuestionnaires={addedQuestionnaires}
          onSelect={selection.select} />
      </ModalBody>

      <ModalFooter>
        <form className="form-inline" action={baseUrl} method="POST" role="form">
          <AuthenticityToken value={authenticityToken} />
          <Form useFormTag={false}>
            {selectedQuestionnaires.map((questionnaire) => {
              return (
                <input
                  key={questionnaire.id}
                  type="hidden"
                  name="measured_questionnaire[questionnaire_id][]"
                  value={questionnaire.id}
                />
              );
            })}

            <Field
              fieldName="preselected"
              name="measured_questionnaire[preselected]"
              label={I18n.t("activerecord.attributes.measured_questionnaire.preselected")}
              type={Checkbox}
            />

            <Button onClick={handleCancel} data-dismiss="modal">
              <Translate content="admin_area.measured_questionnaires.cancel" />
            </Button>
            <Button disabled={selectedQuestionnaires.length == 0} highlight="primary" type="submit">
              <Translate content="admin_area.measured_questionnaires.add" />
            </Button>
          </Form>
        </form>
      </ModalFooter>
    </Modal>
  );
};
