import React from "react";
import AceEditor from "react-ace";
import { LintWarning } from "models/ace_editor";
import "ace-builds/src-noconflict/mode-liquid";
import "ace-builds/src-noconflict/theme-textmate";
import { Ace } from "ace-builds";

interface Props {
  sourcecode: string;
  lintWarnings: LintWarning[];
  onChange: (code: string) => void;
  editorRef?: React.RefObject<AceEditor>;
  enableAutocompletion?: boolean;
}

interface State {
  sourcecode: string;
}

export class ReactAceEditor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <AceEditor
        ref={this.props.editorRef || null}
        className="code-editor"
        mode="liquid"
        theme="textmate"
        defaultValue={this.props.sourcecode}
        onChange={this.props.onChange}
        setOptions={{ tabSize: 2, useSoftTabs: true }}
        annotations={this.getAnnotations()}
        markers={this.getMarkers()}
        width="100%"
        enableBasicAutocompletion={this.props.enableAutocompletion}
        enableLiveAutocompletion={this.props.enableAutocompletion}
      />
    );
  }

  getAnnotations() {
    return this.props.lintWarnings.map(lintWarning => {
      return { row: lintWarning.from, column: 0, text: lintWarning.msg, type: "error" };
    });
  }

  getMarkers() {
    return this.props.lintWarnings.map(lintWarning => {
      return { startRow: lintWarning.from, startCol: 0, endRow: lintWarning.to, endCol: 144, className: "error-highlight", type: "fullLine" as "fullLine" };
    });
  }
}
