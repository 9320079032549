import React from "react";
import ReactDOM from "react-dom";
import ChartRenderer from "components/Highcharts/ChartRenderer";
import { CspDnaCarousel } from "components/CspDnaCarousel";

export class LegacyCspDnaCarousel {
  json;
  containerElement;

  constructor(json, containerElement: HTMLElement) {
    this.json = json;
    this.containerElement = containerElement;
  }

  render() {
    ReactDOM.render(<CspDnaCarousel images={this.json.images} interval={this.json.interval} />, this.containerElement);
  }
}

export class LegacyLineChart {
  json;
  containerElement;

  constructor(json, containerElement: HTMLElement) {
    this.json = json;
    this.containerElement = containerElement;
  }

  render() {
    ReactDOM.render(<ChartRenderer renderer="LineChart" settings={this.json} />, this.containerElement);
  }
}

export class LegacyMultiScoreChart {
  json;
  containerElement;

  constructor(json, containerElement: HTMLElement) {
    this.json = json;
    this.containerElement = containerElement;
  }

  render() {
    ReactDOM.render(<ChartRenderer renderer="MultiScoreChart" settings={this.json} />, this.containerElement);
  }
}

export class LegacyColumnChart {
  json;
  containerElement;

  constructor(json, containerElement: HTMLElement) {
    this.json = json;
    this.containerElement = containerElement;
  }

  render() {
    ReactDOM.render(<ChartRenderer renderer="ColumnChart" settings={this.json} />, this.containerElement);
  }
}

export class LegacyCustomChart {
  json;
  containerElement;

  constructor(json, containerElement: HTMLElement) {
    this.json = json;
    this.containerElement = containerElement;
  }

  render() {
    ReactDOM.render(<ChartRenderer renderer="CustomChart" settings={this.json} />, this.containerElement);
  }
}
