import React from "react";
import { IMeasurement, IQuestionnaire } from "models";
import classNames from "classnames";
import { Translate } from "components/Translate";
import { Icon } from "components/ui/icon";

interface Props {
  protocolId: number;
  measurement: IMeasurement;
}

function measurementUrl(measurement: IMeasurement) {
  return `/admin/protocols/${measurement.protocol_id}/measurements/${measurement.id}`;
}

const QuestionnairesList = ({ measurement }: { measurement: IMeasurement }) => {
  const numberToShow = 3;
  const questionnaires = measurement.questionnaires.slice(0, numberToShow);
  const hasMore = measurement.questionnaires.length - numberToShow > 0;
  const editUrl = `${measurementUrl(measurement)}/edit`;

  let rows = questionnaires.map((q) => <li key={q.id}>{q.name}</li>);

  if (hasMore) {
    rows.push(
      <li key="and_more">
        <a href={editUrl}>
          <Translate content="and_more" />
        </a>
      </li>
    );
  }

  return <ul>{rows}</ul>;
};

const Deleted = () => (
  <span className="label">
    <Translate content="admin_area.measurements.deleted_label" />
  </span>
);

const DeleteButton = ({ measurement }) => {
  const href = measurementUrl(measurement);
  return (
    <a href={href} className="btn btn-danger" data-method="delete">
      <Icon name="trash" color="white" />
    </a>
  );
};

const RestoreButton = ({ measurement }) => {
  const href = `${measurementUrl(measurement)}/restore`;
  return (
    <a href={href} className="btn" data-method="patch">
      <Translate content="restore" />
    </a>
  );
};

export const Row: React.FunctionComponent<Props> = ({ protocolId, measurement }) => {
  const className = classNames("sortable-row", { active: measurement.active });
  const editUrl = `${measurementUrl(measurement)}/edit`;

  return (
    <tr className={className}>
      <td>
        <a href={editUrl}>{measurement.name}</a>
        {measurement.active ? null : <Deleted />}
      </td>

      <td>
        <QuestionnairesList measurement={measurement} />
      </td>

      <td>{measurement.active ? <DeleteButton measurement={measurement} /> : <RestoreButton measurement={measurement} />}</td>
    </tr>
  );
};
