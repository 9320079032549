import React from "react";
import { useSortable } from "logic/useSortable";
import { ReactSortable } from "react-sortablejs";
import { updatePendingAnswerOrder } from "lib/actions";
import { AnswerBadge } from "./AnswerBadge";
import { useBackboneCollection } from "logic/backbone_hooks";

interface Props {
  fillOutTaskId: number;
  answers: Backbone.Collection<any>;
  onRemovePendingAnswer: any;
}

export const AnswerList: React.FunctionComponent<Props> = (props) => {
  // ReactSortable will shallow-clone items when it updates some properties like "selected",
  // (https://github.com/SortableJS/react-sortablejs#list). Because that removes the prototype of
  // our models, we need to wrap them in one layer of "objectness" so that a shallow clone won't
  // actually touch our models.
  const wrappedItems = props.answers.models.map(model => ({id: model.id, answer: model}));
  const { list, setList } = useSortable<any>(wrappedItems);

  const onEnd = () => {
    const order = list.map(item => item.id);
    updatePendingAnswerOrder(props.fillOutTaskId, order, {
      success: () => {
        RoQua.showFlash({state: 'success', message: I18n.t("epd_area.answers.reorder_answers_success")});
      },
      error: () => RoQua.showFlash({state: 'error', message: I18n.t("epd_area.answers.reorder_answers_failure")})
    });
  }


  return <div className="span8">
    <div className="answer-badges">
      <ReactSortable list={list} setList={setList} onEnd={onEnd} className="ui-sortable">
        { list.map((item) => <AnswerBadge key={item.id} model={item.answer} onRemovePendingAnswer={props.onRemovePendingAnswer} />)}

      </ReactSortable>
    </div>
  </div>
};
