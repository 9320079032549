import { PetraProtocolSubscription, PetraResearchParticipation } from "../../common/Results";
import { convertToTextvarsValues, FlagInterface, FlagObject, TextvarsSettings } from "../../common/Flags";
import { useSelections } from "ahooks";
import { Key } from "../../common/Selections";
import { determineDesign } from "../../common/Constants";
import { PerDesign } from "../../common/Schema";
import { getFlags } from "../../common/Protocols";

const useResearchParticipation = (researchParticipation: PetraResearchParticipation): FlagInterface | null => {
  const protSub: PetraProtocolSubscription = researchParticipation.protocolSubscriptions[0];
  const protocol = protSub.protocol;

  const design = determineDesign(protocol);
  if (!design) return null;

  const allFlags: PerDesign<FlagObject[]> = getFlags([protocol]);
  const flags = allFlags[design];

  const trueFlags: string[] = [];
  for (const entry of protSub.flags) {
    if (entry.value) {
      trueFlags.push(entry.key);
    }
  }
  const flagsSelection: Selections<Key> = useSelections<Key>(trueFlags, trueFlags);
  const textvarsSettings: TextvarsSettings = {
    textvarsValues: convertToTextvarsValues(protSub.textvars),
    setTextvarsValues: () => {}, // Mock function. not used.
  };

  // These properties are fed to the PreviewQuestionnaire component, which is called with
  // a FlagInterface object because that's how we move the flags and textvars settings around in Petra.
  // However, the previewer does not use all properties of the FlagInterface object.
  // In particular, the textvars and questions arrays are not used. (The textvars property
  // is supposed to represent all textvars in a questionnaire, whereas textvarsSettings holds
  // only those that selected for the current diary study). Furthermore, since the previewer does not need
  // to change the textvars, setTextvarsValues can be a mock function. Finally, even though we do
  // pass the actual textvars to the quby-frontend AnswerPage component, the current quby-frontend
  // does not make use of these textvars (i.e., it doesn't substitute the textvars for their values).
  // But we still pass the textvars so that when quby-frontend is updated to support textvar substitution,
  // it should be relatively easy to make it work.
  return {
    flagsSelection: flagsSelection,
    flags: flags,
    textvars: [], // Not used right now
    textvarsSettings: textvarsSettings,
    questions: [],
  };
};

export default useResearchParticipation;
