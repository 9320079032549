import { Response } from "grapqhl";

import { getQuestion } from "./getQuestion";
import { getValue } from "./getValue";
import { ResponseWithCache } from "./ResponseWithCache";

interface QuestionWithKey {
  key: string;
}
interface QuestionOptionWithKey {
  key: string;
}
interface SingleSelectQuestion<SingleSelectQuestionOption> {
  __typename: 'SingleSelectQuestion';
  options: SingleSelectQuestionOption[];
};
interface UnsupportedQuestion {
  __typename: 'TextQuestion' | 'DatePartsQuestion' | 'IntegerQuestion' | 'FloatQuestion' | 'MultiSelectQuestion';
}

type QuestionWithOptionsWhenSensible<TOption> = QuestionWithKey & (SingleSelectQuestion<TOption> | UnsupportedQuestion);

interface QuestionnaireWithQuestionOptionKeys<
  TQuestion extends QuestionWithOptionsWhenSensible<TOption>,
  TOption extends QuestionOptionWithKey,
> {
  key: string;
  questions: TQuestion[];
}

export function getSelectedOption<
  TOption extends QuestionOptionWithKey,
>(
  responseWithCache: ResponseWithCache<
    Pick<Response, 'values'>,
    QuestionnaireWithQuestionOptionKeys<QuestionWithOptionsWhenSensible<TOption>, TOption>, 
    QuestionWithOptionsWhenSensible<TOption>
  >,
  questionKey: string
): TOption | undefined {
  const question = getQuestion(responseWithCache, questionKey);
  if (question?.__typename != 'SingleSelectQuestion') throw 'Illegal question type passed';
  const optionKey = getValue(responseWithCache, question.key);
  return question.options.find(option => option.key == optionKey);
}
