/* eslint-disable @typescript-eslint/camelcase */
import { max, clamp, last } from "lodash";
import { BaseChart, BaseSettings } from "./BaseChart";

const MILLISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;

interface SeriesData {
  timestamp: number;
  value: number;
  data_label: string;
}

interface Series {
  name: string;
  data: SeriesData[];
  visible?: boolean;
}

export interface LineChartSettings extends BaseSettings {
  overtone: string;
  critical_change: number | null;
  y_axis_label: string | null;
  series: Series[];
  minimum_timestamp?: number;
  maximum_timestamp?: number;
}

interface PlotBandSettings {
  bottomLabel?: string;
  topLabel?: string;
  lowerBound: number;
  upperBound: number;
}

interface CustomPointOptionsObject extends Highcharts.PointOptionsObject {
  plotBandSettings?: PlotBandSettings;
}

export class LineChart extends BaseChart<LineChartSettings> {
  chart() {
    return {
      defaultSeriesType: "spline",
      ignoreHiddenSeries: false,
      height: 400,
      animation: false,
    };
  }

  xAxis() {
    return {
      type: "datetime",
      tickInterval: this.calculateSeriesTickInterval(),
      lineColor: "#999",
      min: this.settings.minimum_timestamp,
      max: this.settings.maximum_timestamp,
      startOnTick: true,
      endOnTick: true,
      dateTimeLabelFormats: {
        millisecond: this.millisecondLabel(),
        second: "%H:%M",
        minute: "%H:%M",
        hour: "%H:%M",
        day: "%e. %b",
        week: "%e. %b",
        month: "%b '%y",
        year: "%Y",
      },
    };
  }

  yAxis() {
    return Object.assign({}, super.yAxis(), {
      title: { text: this.settings.y_axis_label },
      startOnTick: false,
      endOnTick: false,
      gridLineColor: "#999",
    });
  }

  series(): Highcharts.SeriesSplineOptions[] {
    return this.settings.series.map((series) => {
      return {
        type: "spline",
        name: series.name,
        data: this.formatDataSeries(series.data),
        visible: series.visible,
      };
    });
  }

  legend() {
    return { enabled: this.settings.series.length > 1 };
  }

  plotOptions(): Highcharts.PlotOptions {
    return {
      series: {
        animation: false,
        point: {
          events: {
            mouseOver: this.settings.critical_change ? this.drawPlotBands : undefined,
          },
        },
      },
    };
  }

  private drawPlotBands(this: Highcharts.Point) {
    const yAxis = this.series.chart.yAxis[0];

    // Remove pre-existing plotbands
    yAxis.removePlotBand("plotBand");
    yAxis.removePlotLine("plotLine");

    // Not an officially documented feature, but for the past 8 versions, Highcharts has maintained
    // our originally given object here, without stripping this custom key from it.
    const plotBandSettings = (this.options as CustomPointOptionsObject).plotBandSettings;

    // No plotband for the last data point
    if (this === last(this.series.data) || !plotBandSettings) {
      return null;
    }

    // Render plotband
    yAxis.addPlotBand({
      from: plotBandSettings.lowerBound,
      to: plotBandSettings.upperBound,
      color: "rgba(110, 110, 110, 0.3)",
      zIndex: 2,
      id: "plotBand",
      label: {
        text: plotBandSettings.topLabel,
        align: "right",
        verticalAlign: "top",
        x: -10,
        y: -4,
      },
    });

    yAxis.addPlotLine({
      value: plotBandSettings.lowerBound,
      width: 1,
      zIndex: 2,
      id: "plotLine",
      label: {
        text: plotBandSettings.bottomLabel,
        align: "right",
        x: -10,
        y: 11,
      },
    });
  }

  private formatDataSeries(data: SeriesData[]): CustomPointOptionsObject[] {
    return data.map((dataPoint) => {
      return {
        x: dataPoint.timestamp,
        y: dataPoint.value,
        label: dataPoint.data_label,
        plotBandSettings: this.plotBandSettings(dataPoint),
      };
    });
  }

  private plotBandSettings(dataPoint: SeriesData): PlotBandSettings | undefined {
    if (this.settings.critical_change) {
      const [bottomLabel, topLabel] = this.fetchCriticalChangeLabels();
      const lowerBound = clamp(dataPoint.value - this.settings.critical_change, this.settings.minimum_value || 0, dataPoint.value);
      const upperBound = clamp(dataPoint.value + this.settings.critical_change, 0, this.settings.maximum_value || dataPoint.value + this.settings.critical_change);

      return {
        bottomLabel: lowerBound === this.settings.minimum_value ? undefined : bottomLabel,
        topLabel: upperBound === this.settings.maximum_value ? undefined : topLabel,
        lowerBound: lowerBound,
        upperBound: upperBound,
      };
    }
  }

  private fetchCriticalChangeLabels(): string[] {
    const forTheGood = I18n.t("epd_area.outcome.crit_change_good");
    const forTheBad = I18n.t("epd_area.outcome.crit_change_bad");

    if (this.settings.overtone === "positive") {
      return [forTheBad, forTheGood];
    } else {
      return [forTheGood, forTheBad];
    }
  }

  private calculateSeriesTickInterval() {
    const maxSeriesSize = max(this.settings.series.map((series) => series.data.length));

    if (maxSeriesSize && maxSeriesSize <= 1) {
      return null;
    } else {
      max(this.settings.series.map((series) => this.calculateTickInterval(series.data)));
    }
  }

  private calculateTickInterval(data: SeriesData[]) {
    if (data.length <= 1) {
      return 0;
    }

    const timeDiff = clamp(data[data.length - 1].timestamp - data[0].timestamp, 0, MILLISECONDS_IN_A_DAY);
    const maxSeriesSize = clamp(data.length, 0, 6);
    return timeDiff / maxSeriesSize;
  }

  private millisecondLabel() {
    const maxSeriesSize = max(this.settings.series.map((series) => series.data.length));

    if (maxSeriesSize === 1) {
      return "%A %e %B %Y";
    } else {
      return "%H:%M";
    }
  }
}
