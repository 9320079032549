import * as React from "react";
import {
  Settings,
  Measurement,
  IntervalSchedulerSettings,
  DailySchedulerSettings,
  RandomSchedulerSettings,
  FixedSchedulerSettings
} from "./Settings";
import { FormControlGroup, SecondDurationField } from "components/ui/forms";
import { RandomSchedulerFields } from "./RandomSchedulerFields";
import { DailySchedulerFields } from "./DailySchedulerFields";
import { IntervalSchedulerFields } from "./IntervalSchedulerFields";
import { FixedSchedulerFields } from "./FixedSchedulerFields";
import { SchedulerSelector } from "./SchedulerSelector";

export interface Props {
  settings: Settings;
  measurements: Measurement[];
  onChange: (settings: Partial<Settings>) => void;
}

export const SchedulerSettings: React.FunctionComponent<Props> = (props) => {
  const setMeasurementAmount = (event) => props.onChange({ measurement_amount: parseInt(event.target.value) });
  const setExpires = (value) => props.onChange({ expire_meas_instances_after: value });
  const setBcc = (event) => props.onChange({ bcc: event.target.value });

  let measurementAmountLabel;
  switch (props.settings.scheduler) {
    case "IntervalScheduler":
      measurementAmountLabel = I18n.t("activerecord.attributes.protocol/settings.measurement_amount");
      break;
    case "DailyScheduler":
      measurementAmountLabel = I18n.t("activerecord.attributes.protocol/settings.measurement_amount_days");
      break;
    case "RandomScheduler":
      measurementAmountLabel = I18n.t("activerecord.attributes.protocol/settings.measurement_amount_days");
      break;
    case "FixedScheduler":
      measurementAmountLabel = I18n.t("activerecord.attributes.protocol/settings.measurement_amount_complete_schedule");
      break;
  }

  return <div>
    <SchedulerSelector scheduler={props.settings.scheduler} onChange={props.onChange} />

    {schedulerFields(props)}

    <FormControlGroup label={measurementAmountLabel}>
      <input type="number" id="meas_amount" value={props.settings.measurement_amount} onChange={setMeasurementAmount} />
    </FormControlGroup>

    <FormControlGroup label={I18n.t("activerecord.attributes.protocol/settings.expire_meas_instances_after")}>
      <SecondDurationField id="expire_meas_instances_after" value={props.settings.expire_meas_instances_after} onChange={setExpires} />
    </FormControlGroup>
  </div>;
}

const schedulerFields = (props: Props) => {
  const { settings, measurements, onChange } = props;

  switch (props.settings.scheduler) {
    case "NullScheduler": return null;
    case "IntervalScheduler": return <IntervalSchedulerFields settings={settings as IntervalSchedulerSettings} measurements={measurements} onChange={onChange} />;
    case "DailyScheduler": return <DailySchedulerFields settings={settings as DailySchedulerSettings} measurements={measurements} onChange={onChange} />;
    case "RandomScheduler": return <RandomSchedulerFields settings={settings as RandomSchedulerSettings} measurements={measurements} onChange={onChange} />;
    case "FixedScheduler": return <FixedSchedulerFields settings={settings as FixedSchedulerSettings} measurements={measurements} onChange={onChange} />;
  }
}
