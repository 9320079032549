import React from "react";
import { Designs } from "../../common/Schema";
import { Key } from "../../common/Selections";
import classNames from "classnames";

interface Props {
  name: Key;
  title: string;
  design?: Designs;
  selection: Selections<Key>;
  subGoalCount?: number;
}

// Component used for rendering goals, subgoals, and complaints.
// If this component is rendering a Goal that is selected, it also renders the `props.children`.
const Goal: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const { subGoalCount = 0 } = props;
  const isSelected = props.selection.isSelected(props.name);
  const noneSelected = props.selection.noneSelected;

  if (!isSelected && !noneSelected) return <></>;

  return (
    <>
      <div className="goal" data-testid={props.name} onClick={() => props.selection.toggle(props.name)}>
        <div className="goal-contents">
          <div className="goal-title">
            <label htmlFor={props.name} onClick={() => props.selection.toggle(props.name)}>
              {props.title}
            </label>
          </div>
          {props.design && <div className="goal-subtitle">{props.design.description}</div>}
        </div>
        <div className={classNames("goal-actions", isSelected && "selected")}>
          <input id={props.name} name={props.name} readOnly type="checkbox" checked={isSelected} />
        </div>
      </div>
      {props.children !== undefined && isSelected && subGoalCount > 0 && (
        <>
          <h3>
            Maak het doel specifieker <span className="step-count">vraag 2 / 4</span>
          </h3>
          {props.children}
        </>
      )}
    </>
  );
};

export default Goal;
