import * as React from "react";
import { filter } from "lodash";
import { Table, TableHead, TableRow, TableCell, TableBody } from "bootstrap";
import { Translate } from "components/Translate";
import { IQuestionnaire } from "models";

function matches(key, query) {
  if (key) {
    return key.toLowerCase().indexOf(query.toLowerCase()) > -1;
  } else {
    return false;
  }
}

export function filterQuestionnaires(questionnaires, query) {
  if (!query || query == "") {
    return questionnaires;
  }

  return filter(questionnaires, (questionnaire) => {
    return (
      matches(questionnaire.name, query) ||
      matches(questionnaire.key, query) ||
      matches(questionnaire.short_description, query)
    );
  });
}

interface IResultsProps {
  questionnaires: IQuestionnaire[];
  addedQuestionnaires: IQuestionnaire[];
  query: string;
  onSelect: (questionnaire: IQuestionnaire) => void;
}

function rowsForQuestionnaires(questionnaires, handleClick){
  return questionnaires.map((questionnaire) => (
    <TableRow
      key={questionnaire.id}
      id={`questionnaire-${questionnaire.id}`}
      onClick={ handleClick && ((event) => handleClick(questionnaire))}
    >
      <TableCell>{questionnaire.key}</TableCell>
      <TableCell>{questionnaire.name}</TableCell>
      <TableCell>{questionnaire.short_description}</TableCell>
    </TableRow>
  ))
}

export const Results = (props: IResultsProps) => {
  let questionnaires: IQuestionnaire[] = filterQuestionnaires(props.questionnaires, props.query);
  let addedQuestionnaires: IQuestionnaire[] = filterQuestionnaires(props.addedQuestionnaires, props.query);
  let truncated = false;

  if (questionnaires.length > 200) {
    truncated = true;
    questionnaires = questionnaires.slice(0, 49);
  }

  const handleClick = (questionnaire) => props.onSelect(questionnaire);

  return (
    <React.Fragment>
      {truncated ? <Translate content="admin_area.measured_questionnaires.too_many_results" /> : null}
      <Table hover striped>
        <TableHead>
          <TableRow>
            <TableCell header>
              <Translate content="activerecord.attributes.measured_questionnaire.key" />
            </TableCell>
            <TableCell header>
              <Translate content="activerecord.attributes.measured_questionnaire.name" />
            </TableCell>
            <TableCell header>
              <Translate content="activerecord.attributes.measured_questionnaire.short_description" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsForQuestionnaires(questionnaires, handleClick)}
        </TableBody>
      </Table>
      {(() => {
        if (addedQuestionnaires.length > 0) {
          return <Table striped>
            <TableHead>
              <TableRow>
                <TableCell header colSpan={3}>
                  <Translate content="admin_area.measured_questionnaires.already_added_questionnaires" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowsForQuestionnaires(addedQuestionnaires, null)}
            </TableBody>
          </Table>
        }
      })()}
    </React.Fragment>
  );
};
