import React from "react";
import { BlockProps } from "./ResultsSummary";

// Renders a block like:
//      91% ingevuld
//  van in totaal 70 metingen
//      tot nu toe
const PercentageFilledOutBlock: React.FunctionComponent<BlockProps> = ({ responsesWithCache }) => {
  const filledOutResponses = responsesWithCache.filter((rc) => rc.response.values.length > 0);
  const percFilledOut =
    responsesWithCache.length === 0 ? "N/A" : Math.round((100 * filledOutResponses.length) / responsesWithCache.length);

  return (
    <div className="petra-well">
      <h2>{percFilledOut}% ingevuld</h2>
      <p>van in totaal {responsesWithCache.length} metingen tot nu toe</p>
    </div>
  );
};

export default PercentageFilledOutBlock;
