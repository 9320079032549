import React, { useState } from "react";
import { List, arrayMove } from "react-movable";
import classNames from "classnames";

export interface Report {
  addToReport: (elemRef: React.RefObject<HTMLDivElement>) => void;
  renderReport: (highlightRef: React.RefObject<HTMLDivElement>, highlightLastGraph: boolean) => JSX.Element;
  renderStaticReport: () => string;
}

// Hook used to manage a report of graphs with comments and titles, and a footer comment.
export const useReport = (): Report => {
  const [report, setReport] = useState<ReportEntry[]>([]);
  const [footer, setFooter] = useState<string>("");

  // Adds a graph to the report with an empty comment (as a reportEntry).
  const addToReport = (elemRef: React.RefObject<HTMLDivElement>): void => {
    if (!elemRef.current) return;

    const reportEntry = elemRef.current.getElementsByClassName("petra-tgg-container")[0].firstElementChild?.innerHTML;
    if (reportEntry) {
      const title = elemRef.current.getElementsByClassName("petra-tg-title-title")[0].textContent ?? "";
      setReport([...report, { graph: reportEntry, comment: "", commentVisible: true, title: title }]);
    }
  };

  // Changes the comment of an existing report entry.
  const setComment = (value: string, idx: number) => {
    setReport([...report.slice(0, idx), { ...report[idx], comment: value }, ...report.slice(idx + 1)]);
  };

  // Toggles the visibility of the comment of a report entry.
  const toggleComment = (idx: number) => {
    setReport([
      ...report.slice(0, idx),
      { ...report[idx], commentVisible: !report[idx].commentVisible },
      ...report.slice(idx + 1),
    ]);
  };

  // Removes a graph from the report.
  const removeGraph = (idx: number) => {
    setReport([...report.slice(0, idx), ...report.slice(idx + 1)]);
  };

  // Renders an interactive report displayed in the slideover and used for setting comments and
  // moving graphs around.
  const renderReport = (highlightRef: React.RefObject<HTMLDivElement>, highlightLastGraph: boolean): JSX.Element => {
    return (
      <div className="petra-report">
        <List
          values={report}
          onChange={({ oldIndex, newIndex }) => setReport(arrayMove(report, oldIndex, newIndex))}
          renderList={({ children, props }) => <div {...props}>{children}</div>}
          renderItem={({ value: reportEntry, props, index: idx }) => {
            if (idx === undefined) return null;

            const isHighlighted = highlightLastGraph && idx + 1 === report.length;
            return (
              <div {...props}>
                <div className="petra">
                  <div className={classNames("petra-report", { highlight: isHighlighted })}>
                    {isHighlighted && <div ref={highlightRef} className="highlight-anchor" />}
                    <div className="pr-graph">
                      <a className="pr-remove-graph" onClick={() => removeGraph(idx)} />
                      <div className="pr-move-graph" />
                      <h6>{reportEntry.title}</h6>
                      <div className="pr-graph-container" dangerouslySetInnerHTML={{ __html: reportEntry.graph }} />
                    </div>
                    <div className="pr-comment">
                      {reportEntry.commentVisible && (
                        <>
                          <h6>Aantekening bij grafiek</h6>
                          <textarea
                            value={reportEntry.comment}
                            onChange={(e) => setComment(e.currentTarget.value, idx)}
                          />
                        </>
                      )}
                      <a className="pr-no-comment" onClick={() => toggleComment(idx)}>
                        {reportEntry.commentVisible ? "Geen aantekening nodig" : "Maak aantekening"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        />
        <div className="pr-footer">
          <h6>Algemene aantekening als afsluiter van het rapport</h6>
          <textarea value={footer} onChange={(e) => setFooter(e.currentTarget.value)} />
        </div>
      </div>
    );
  };

  // Renders a static version of the report for used in report creation on the backend.
  const renderStaticReport = (): string => {
    return `
    <div>
      ${report
        .map(
          (reportEntry) =>
            `<div class='report-block' style='margin-top: 60px'>
           <h4>${reportEntry.title}</h4>
           ${reportEntry.graph}
           ${
             reportEntry.commentVisible
               ? `<p><em>Aantekening bij bovenstaande grafiek:</em> ${reportEntry.comment}</p>`
               : ""
           }
         </div>`
        )
        .join("")}
      <div class='report-block' style='margin-top: 60px'>
        <p><em>Algemene aantekening:</em> ${footer}</p>
      </div>
    </div>`;
  };

  return {
    addToReport,
    renderReport,
    renderStaticReport,
  };
};

interface ReportEntry {
  graph: string;
  title: string;
  comment: string;
  commentVisible: boolean;
}
