import { Loader } from "components/ui/loader";
import { usePetraProjectWizardQuery } from "grapqhl";
import { mapValues, uniqBy } from "lodash";
import { ensureType } from "logic/string";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { FIXED, ONE_TIME_PER_DAY, QUESTIONNAIRE_NAMES, SEMI_RANDOM } from "../common/Constants";
import { FlagObject, TextvarObject, TextvarsValues, typedTextvars } from "../common/Flags";
import { Questions } from "../common/Graphs";
import { DEFAULT_MORNING_START_TIME } from "../common/MeasurementSchedule";
import { getFlags } from "../common/Protocols";
import { Design, PerDesign, STEPS } from "../common/Schema";
import CreateNewDiary from "./CreateNewDiary";

interface Props {
  researchProjectId: number;
}

// This component serves as a shell around the CreateNewDiary component to retrieve the flags arrays
// for the fixed, semi random, and one time per day questionaires and convert them to a format that
// TypeScript will accept.
const Wizard: React.FunctionComponent<Props> = (props) => {
  const params = useParams();
  const currentStep = ensureType(params["step"], STEPS);

  const results = usePetraProjectWizardQuery({
    variables: {
      id: props.researchProjectId.toString(),
    },
  });

  const {
    goals = [],
    subGoals = [],
    complaints = [],
    design = undefined,
    flags: initialFlags = [],
    textvars: initialTextvars = {},
    measurementAmount = -1,
    blocksPerDay = 0,
    dailyStartTime = DEFAULT_MORNING_START_TIME,
  } = useLocation<NewDiaryStateParams>().state || {};
  const newDiaryState: NewDiaryState = {
    goals: goals,
    subGoals: subGoals,
    complaints: complaints,
    design: design,
    flags: initialFlags,
    textvars: initialTextvars,
    measurementAmount: measurementAmount,
    blocksPerDay: blocksPerDay,
    dailyStartTime: dailyStartTime,
    repeatedDiary:
      goals.length > 0 &&
      subGoals.length > 0 &&
      complaints.length > 0 &&
      design !== undefined &&
      initialFlags.length > 0,
  };

  if (results.loading) {
    return <Loader />;
  }

  if (!results.data || !results.data.currentOrganization) {
    return <div>{JSON.stringify(results.error)}</div>;
  }

  const patientPhone = results.data.currentDossier?.patientRespondent?.phoneCell || "";
  const project = results.data.currentOrganization.researchProject;

  // Get flags
  const flags: PerDesign<FlagObject[]> = getFlags(project.protocols);

  // Get textvars
  const allTextvars = uniqBy(
    project.protocols.flatMap((protocol) => protocol.measurements.flatMap((measurement) => measurement.questionnaires)),
    "key"
  ).flatMap((questionnaire) => questionnaire.textvars);
  // convert graphql result to our own typed textvars
  const typedAllTextvars: TextvarObject[] = typedTextvars(allTextvars);
  // Split the textvars up into arrays (one per design).
  const textvars: PerDesign<TextvarObject[]> = mapValues(QUESTIONNAIRE_NAMES, (name) =>
    typedAllTextvars.filter((textvar) => textvar.key.startsWith(`${name}_`))
  );

  // Get questions
  const questionnairesByKey = {};
  uniqBy(
    project.protocols.flatMap((protocol) => protocol.measurements.flatMap((measurement) => measurement.questionnaires)),
    "key"
  ).forEach((questionnaire) => {
    questionnairesByKey[questionnaire.key] = questionnaire;
  });
  const questions: PerDesign<Questions> = mapValues(QUESTIONNAIRE_NAMES, (name) => questionnairesByKey[name].questions);

  // Get the protocol IDs of the first protocol within the research project that offers one such questionnaire.
  // Since we'll be overriding all the protocol settings anyways, it doesn't matter which one we choose.
  const autoProtocolIds: PerDesign<string> = {
    semiRandom: autoProtocolIdForQuestionnaire(project.protocols, QUESTIONNAIRE_NAMES[SEMI_RANDOM]),
    fixed: autoProtocolIdForQuestionnaire(project.protocols, QUESTIONNAIRE_NAMES[FIXED]),
    oneTimePerDay: autoProtocolIdForQuestionnaire(project.protocols, QUESTIONNAIRE_NAMES[ONE_TIME_PER_DAY]),
  };

  return (
    <div className="petra">
      <CreateNewDiary
        allFlags={flags}
        allTextvars={textvars}
        allQuestions={questions}
        allAutoProtocolIds={autoProtocolIds}
        researchProjectId={props.researchProjectId}
        patientPhone={patientPhone}
        currentStep={currentStep}
        newDiaryState={newDiaryState}
      />
    </div>
  );
};

interface Params {
  researchParticipationId: string;
}

// For reading parameters out of the browser history state to repeat a diary
export interface NewDiaryState {
  goals: string[];
  subGoals: string[];
  complaints: string[];
  design: Design | undefined;
  flags: string[];
  textvars: TextvarsValues;
  measurementAmount: number;
  blocksPerDay: number;
  dailyStartTime: number;
  repeatedDiary: boolean;
}

export interface NewDiaryStateParams {
  goals?: string[];
  subGoals?: string[];
  complaints?: string[];
  design?: Design | undefined;
  flags?: string[];
  textvars?: TextvarsValues;
  measurementAmount?: number;
  blocksPerDay?: number;
  dailyStartTime?: number;
  repeatedDiary?: boolean;
}

// Returns the id of the first protocol whose measurementes contain a questionnaire
// with the given key.
// Precondition: protocols is defined and there exist such petra protocols with the given questionnaireKey
const autoProtocolIdForQuestionnaire = (
  protocols: { measurements: { questionnaires: { key: string }[] }[]; autoProtocols: { nodes: { id: string }[] } }[],
  questionnaireKey: string
) => {
  const protocol = protocols?.filter((protocol) =>
    protocol.measurements.some((measurement) =>
      measurement.questionnaires.some((questionnaire) => questionnaire.key === questionnaireKey)
    )
  )[0];

  return protocol.autoProtocols.nodes[0].id;
};

export default Wizard;
