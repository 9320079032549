import { Maybe, Response } from "grapqhl";
import { arrayToSentence } from "logic/array";

import { getCheckedOptions } from "./getCheckedOptions";
import { getQuestion } from "./getQuestion";
import { getSelectedOption } from "./getSelectedOption";
import { getValue } from "./getValue";
import { ResponseWithCache } from "./ResponseWithCache";

interface QuestionWithKey {
  key: string;
}
export interface QuestionOption {
  key: string;
  description: Maybe<string>;
  value: Maybe<string>;
}

interface SelectQuestion<TQuestionOption> {
  __typename: 'SingleSelectQuestion' | 'MultiSelectQuestion';
  options: TQuestionOption[];
};

interface SimpleQuestion {
  __typename: 'TextQuestion' | 'IntegerQuestion' | 'FloatQuestion';
}

interface  UnsupportedQuestion {
  __typename: 'DatePartsQuestion';
}

type QuestionWithOptionsWhenSensible<TOption> = QuestionWithKey & (SelectQuestion<TOption>  | SimpleQuestion | UnsupportedQuestion);

interface QuestionnaireWithQuestionOptionKeys<
  TQuestion extends QuestionWithOptionsWhenSensible<TOption>,
  TOption extends QuestionOption,
> {
  key: string;
  questions: TQuestion[];
}

// DateParts: error, not yet supported, should build date-string as QuestionResponse.rb.
// MultiSelect: checked options descriptions as sentence.
// SingleSelect: selected options description or value.
// Default: Given text-value.
export function getDescriptionForAnswer<
  TOption extends QuestionOption,
>(
  responseWithCache: ResponseWithCache<
    Pick<Response, 'values'>,
    QuestionnaireWithQuestionOptionKeys<QuestionWithOptionsWhenSensible<TOption>, TOption>,
    QuestionWithOptionsWhenSensible<TOption>
  >,
  questionKey: string
): string | null {
  const question = getQuestion(responseWithCache, questionKey);
  switch (question.__typename) {
    case 'DatePartsQuestion':
      throw 'Date not yet supported';
    case 'SingleSelectQuestion': {
      const option = getSelectedOption(responseWithCache, question.key) as QuestionOption;
      return option?.description || option?.value || null;
    }
    case 'MultiSelectQuestion':
      return arrayToSentence(
        getCheckedOptions(responseWithCache, question.key)
          .map(option => (option as QuestionOption).description)
        );
    case 'TextQuestion':
    case 'IntegerQuestion':
    case 'FloatQuestion':
      return getValue(responseWithCache, question.key);
  }
}
