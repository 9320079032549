import { updateAutoProtocolsOrder } from "lib/actions";
import { SaveOrderButton, useSortable } from "logic/useSortable";
import React from "react";
import { ReactSortable } from "react-sortablejs";
import { Translate } from "../Translate";
import { Row } from "./Row";
import { AutoProtocol } from "./models";

interface Props {
  autoProtocols: AutoProtocol[];
}

export const ProtocolsTable: React.FunctionComponent<Props> = ({ autoProtocols }) => {
  const { list, setList, orderChanged, saving, persistListOrder } = useSortable<AutoProtocol>(autoProtocols);
  const saveOrder = () => persistListOrder((ids) => updateAutoProtocolsOrder(ids));

  return (
    <div>
      <SaveOrderButton orderChanged={orderChanged} saving={saving} onClick={saveOrder} />
      <table className="table sortable-table">
        <thead>
          <tr>
            <th className="span1" />
            <th className="span3">
              <Translate content="activerecord.attributes.protocol.name" />
            </th>
            <th className="span3">
              <Translate content="activerecord.attributes.protocol.teams" />
            </th>
            <th className="span1">
              <Translate content="tables.actions.header" />
            </th>
          </tr>
        </thead>

        <ReactSortable list={list} setList={setList} tag="tbody">
          {list.map((protocol) => (
            <Row key={protocol.id} autoProtocol={protocol} />
          ))}
        </ReactSortable>
      </table>
      <SaveOrderButton orderChanged={orderChanged} saving={saving} onClick={saveOrder} />
    </div>
  );
};

export default ProtocolsTable;
