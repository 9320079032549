import React from "react";
import { getValue } from "logic/ResponseWithCache";
import { ResultResponseWithCache, TabConfiguration } from "../common/Graphs";
import { Highcharts } from "../../../lib/highcharts";
import HighchartsReact from "highcharts-react-official";
import { Options, PointOptionsObject } from "highcharts";
import { uniqBy, flatten } from "lodash";
import TabGraphOptions from "./TabGraphOptions";
import MoreInformationContainer from "./MoreInformationContainer";
import { TabState } from "./useTabState";
import { localizedFormat } from "../../../logic/date";
import { average } from "./TabSituationGraph";
import { round } from "../common/Time";

interface Props {
  tabConfiguration: TabConfiguration;
  responsesWithCache: ResultResponseWithCache[];
  openToDate: (Date) => void;
  tabState: TabState;
}

// Returns an integer in the range [0,6].
const weekdayIndex = (date: Date | string): number => {
  return parseInt(localizedFormat(new Date(date), "i")) - 1;
};

interface RangesAndAverages {
  ranges: Array<Array<number | null>>;
  averages: Array<PointOptionsObject>;
}

// The function extractData turns responses into highcharts x and y data tuples.
// If a person filled had no responses with values for a certain weekday,
// a null value is inserted into the graph (which translates to a gap in the line).
const extractData = (responsesWithCache: ResultResponseWithCache[], key: string): RangesAndAverages => {
  let averages: Array<PointOptionsObject> = [];
  let ranges: Array<Array<number | null>> = [];
  const histogram = Array.from(Array(7), (): number[] => []);
  for (const rc of responsesWithCache) {
    const responseValue = getValue(rc, key);
    const date = rc.response.completedAt || rc.response.openFrom;
    if (!date) continue; // To make typescript happy

    const value = responseValue ? parseFloat(responseValue) : null;
    if (value) {
      const dayIndex = weekdayIndex(date);
      histogram[dayIndex].push(value);
    }
  }
  for (let i = 0; i < 7; i += 1) {
    averages.push({
      x: i,
      y: histogram[i].length === 0 ? null : round(average(histogram[i]), 2),
    });
    if (histogram[i].length === 0) {
      ranges.push([i, null, null]);
    } else {
      ranges.push([i, Math.min(...histogram[i]), Math.max(...histogram[i])]);
    }
  }
  return { averages, ranges };
};

const formatWeekdayIndex = (weekdayIdx: string): string => {
  switch (weekdayIdx) {
    case "0":
      return "Maandagen";
    case "1":
      return "Dinsdagen";
    case "2":
      return "Woensdagen";
    case "3":
      return "Donderdagen";
    case "4":
      return "Vrijdagen";
    case "5":
      return "Zaterdagen";
    case "6":
      return "Zondagen";
    default:
      return "";
  }
};

// Construct options for highcharts given the series as a parameter
const useHighchartsOptions = (series): Options => {
  const nrSelectedVariables = Math.floor(series.length / 2);
  return {
    chart: {
      // Fixed offset plus a number of pixels for each variable shown
      marginBottom: 50 + 20 * nrSelectedVariables,
      zoomType: "x",
    },
    credits: {
      enabled: false,
    },

    caption: {
      text: "",
    },

    title: {
      text: "",
    },

    subtitle: {
      text: "",
    },

    exporting: {
      enabled: true,
    },

    legend: {
      align: "left",
      layout: "vertical",
      floating: true,
      y: 10,
    },

    yAxis: {
      accessibility: {
        rangeDescription: "Bereik: 0 tot 100",
      },
      min: 0,
      max: 100,
      title: {
        text: "",
      },
      labels: {
        formatter: function() {
          const label = this.axis.defaultLabelFormatter.call(this);

          // Instead of 100 and 0, show "heel erg" and "helemaal niet", respectively.
          if (/^100$/.test(label)) {
            return "heel erg";
          }
          if (/^0$/.test(label)) {
            return "helemaal<br />niet";
          }
          return label;
        },
      },
    },

    xAxis: {
      title: {
        text: "",
      },
      crosshair: true,
      labels: {
        formatter: function() {
          const label = this.axis.defaultLabelFormatter.call(this);
          return formatWeekdayIndex(label);
        },
      },
    },

    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function() {
        let msg = `<span style="font-size: 11px">${formatWeekdayIndex(`${this.points?.[0]?.x}`)}</span>`;
        if (this.points === undefined) return msg;
        for (let i = 0; i < this.points.length; i += 2) {
          msg += `<br><span style='color: ${this.points?.[i]?.color}'>●</span> ${this.points?.[i]?.series?.name}: <b>${
            this.points?.[i]?.y
          }</b><br><span style='color: ${this.points?.[i]?.color}'>●</span> Uitersten: <b>${
            this.points?.[i + 1]?.point?.low
          }</b> - <b>${this.points?.[i + 1]?.point?.high}</b>`;
        }
        return msg;
      },
    },

    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 0.7,
          },
        },
        label: {
          connectorAllowed: false,
        },
      },
    },

    series: series,
  };
};

// The TabGraph component is the one that actually renders the Highcharts graph for a tab.
// A tab is part of a TabCollection component, which is part of a Graph component.
const TabGraphWeekly: React.FunctionComponent<Props> = ({ tabConfiguration, responsesWithCache, tabState }) => {
  const series = flatten(
    uniqBy(tabConfiguration.variables, "key")
      .filter((variable) => tabState.selectedVariables.isSelected(variable.key))
      .map((variable, idx) => {
        const data = extractData(responsesWithCache, variable.key);
        return [
          {
            name: variable.longDescription,
            data: data.averages,
            type: "line",
            zIndex: 1,
            marker: {
              //enabled: true,
              //radius: 2.5,
              fillColor: "white",
              lineWidth: 2,
              lineColor: Highcharts.getOptions().colors?.[idx],
            },
          },
          {
            name: `Uitersten ${variable.longDescription}`,
            data: data.ranges,
            type: "arearange",
            lineWidth: 0,
            linkedTo: ":previous",
            color: Highcharts.getOptions().colors?.[idx],
            fillOpacity: 0.3,
            zIndex: 0,
            marker: {
              enabled: false,
            },
          },
        ];
      })
  );
  const options: Options = useHighchartsOptions(series);
  return (
    <div className="petra-tab-graph">
      <div className="petra-tg-title">
        <div className="petra-tg-title-title">{tabConfiguration.title}</div>
      </div>
      <div className="petra-tg-graph">
        <div className="petra-tgg-container">
          <HighchartsReact highcharts={Highcharts} options={options} />
          <MoreInformationContainer moreInformationKey={tabConfiguration.moreInformationKey} />
        </div>
        <div className="petra-tgg-checklist">
          <h4>Items in dagboek</h4>
          <TabGraphOptions
            selectedVariables={tabState.selectedVariables}
            variables={tabConfiguration.variables}
            name={tabConfiguration.title}
          />
        </div>
      </div>
    </div>
  );
};

export default TabGraphWeekly;
