import { PetraProtocolSubscription, PetraResearchParticipation } from "../../common/Results";
import { FlagInterface } from "../../common/Flags";
import { PreviewSettings } from "../index";

export const previewDiary = (
  researchParticipation: PetraResearchParticipation,
  setPreviewSettings: (previewSettings: PreviewSettings) => void,
  flagInterface: FlagInterface | null
): void => {
  const protSub: PetraProtocolSubscription = researchParticipation.protocolSubscriptions[0];
  if (!protSub) {
    console.log("WARNING: Encountered a research participation without any protocol subscriptions in it:");
    console.log(researchParticipation);
    return;
  }
  const questionnaireKey = protSub.protocol?.measurements[0]?.questionnaires[0]?.key;
  if (!questionnaireKey) {
    console.log("WARNING: Encountered a protocol subscription without any questionnaires in it:");
    console.log(protSub);
    return;
  }
  if (!flagInterface) {
    console.log(
      "WARNING: Could not determine flag interface from protocol (probably because we couldn't figure out the design based on the questionnaire key of the first questionnaire of the first measurement):"
    );
    console.log(protSub.protocol);
    return;
  }
  const previewSettings: PreviewSettings = {
    flagInterface: flagInterface,
    questionnaireKey: questionnaireKey,
  };
  setPreviewSettings(previewSettings);
};
