import React from "react";
import { FilteredList } from "./FilteredList";

type Item = { id: number | string; key?: string; name: string; description?: string };

export type MatchFn = (item: Item, filter: string) => boolean;

interface Props {
  items: Item[];
  initialSelectedItems: Item[];
  inputName: string;
  itemRenderer: ({ item: Item, onSelectItem: OnSelectItem }) => React.ReactNode;
  matcher?: MatchFn;
}

export const ListPicker: React.FC<Props> = (props) => {
  const [selectedItems, setSelectedItems] = React.useState(props.initialSelectedItems);
  const { matcher = defaultMatcher } = props;

  const handleSelect = (itemToAdd: Item) => {
    setSelectedItems((items) => [...items, itemToAdd].sort(itemSortFn));
  };

  const handleDeselect = (itemToRemove: Item) => {
    setSelectedItems((items) => items.filter((item) => item !== itemToRemove));
  };

  const availableItems = props.items.filter((item) => !selectedItems.includes(item));

  return (
    <div className="list-picker">
      <HiddenCheckboxes inputName={props.inputName} selectedItems={selectedItems} />
      <SelectedItems selectedItems={selectedItems} handleDeselect={handleDeselect} />
      <FilteredList
        items={availableItems}
        itemRenderer={props.itemRenderer}
        onSelectItem={(item: Item) => handleSelect(item)}
        matcher={matcher}
      />
    </div>
  );
};

const HiddenCheckboxes: React.FC<{ inputName: string; selectedItems: Item[] }> = ({ inputName, selectedItems }) => {
  return (
    <div style={{ display: "none" }}>
      {selectedItems.map((item) => (
        <input name={inputName} type="checkbox" key={`selected-item-${item.id}`} value={item.id} defaultChecked />
      ))}
    </div>
  );
};

const SelectedItems: React.FC<{ selectedItems: Item[]; handleDeselect: (item: Item) => void }> = (props) => {
  return (
    <div className="selected-items">
      <ul className="unstyled">
        {props.selectedItems.length == 0 && (
          <li>
            <i>{I18n.t("none")}</i>
          </li>
        )}

        {props.selectedItems.map((item) => (
          <li key={item.id} onDoubleClick={() => props.handleDeselect(item)}>
            <span className="selected-questionnaire selected-questionnaire-info">
              {item.key ? `${item.name} [${item.key}]` : item.name}
              <button
                className="remove-badge"
                onClick={(e) => {
                  e.preventDefault();
                  props.handleDeselect(item);
                }}
                title={I18n.t("admin_area.remove")}
              >
                {"×"}
              </button>
            </span>
          </li>
        ))}
      </ul>
      <div className="clearfix" />
    </div>
  );
};

const itemSortFn = (a: Item, b: Item) => a.name.localeCompare(b.name);
const defaultMatcher: MatchFn = (item, filter) => item.name.toUpperCase().includes(filter.toUpperCase());
