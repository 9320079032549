import React from "react";

interface Item {
  id: number | string;
  name: string;
}

interface Props {
  items: Item[];
  itemRenderer: (props: { item: Item; onSelectItem: () => void }) => React.ReactNode;
  onSelectItem: (item: Item) => void;
  matcher: (item: Item, filter: string) => boolean;
}

export const FilteredList: React.FC<Props> = (props) => {
  const [filter, setFilter] = React.useState("");

  const filteredItems = filterItems(filter, props.items, props.matcher);

  return (
    <div>
      <div className="filtered-list clearfix">
        <input
          type="search"
          placeholder={I18n.t("admin_area.report_templates.type_to_filter")}
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />

        <ul>
          {filteredItems.map((item) => props.itemRenderer({ item, onSelectItem: () => props.onSelectItem(item) }))}
        </ul>
      </div>
    </div>
  );
};

function filterItems(filter: string, items: Item[], matcher: Props["matcher"]) {
  if (filter === "") {
    return items;
  }

  return items.filter((item) => matcher(item, filter));
}
