// Types and functions for handling selections
// These are based on the ahooks useSelections interface

// -------------- Type Definitions --------------

// Keys are used for flag keys, and also for the names (=keys) of selected
// goals, subgoals, and complaints.
export type Key = string;

// -------------- Functions --------------

// Returns a function that for a given array of keys, adds those keys to a
// Selections<Key> and returns the new list of selected items.
// Does not add duplicates.
// Added this function because selecting multiple items at a time is not
// provided by the ahooks useSelections library.
export const addSelection = (selection: Selections<Key>) => {
  return (keys: Key[]) => {
    const newSelectedItems = Array.from(new Set([...selection.selected, ...keys]));
    selection.setSelected(newSelectedItems);
    return newSelectedItems;
  };
};

// Returns a function that for a given array of keys, removes those keys from a
// Selections<Key> and returns the new list of selected items.
// Does nothing for given keys not in the selected list.
// Added this function because deselecting multiple items at a time is not
// provided by the ahooks useSelections library.
export const subtractSelection = (selection: Selections<Key>) => {
  return (keys: Key[]) => {
    const newSelectedItems = selection.selected.filter((key) => !keys.includes(key));
    selection.setSelected(newSelectedItems);
    return newSelectedItems;
  };
};
