import React from "react";

import { useNavigate } from "react-router-dom";
import { PetraProtocolSubscription, PetraResearchParticipation } from "../../common/Results";
import { previewDiary } from "./RecentDiaryUtils";
import { FlagInterface } from "../../common/Flags";
import useResearchParticipation from "./useResearchParticipation";
import { PreviewSettings } from "../index";
import { NewDiaryStateParams } from "../../Wizard";
import { determineDesign } from "../../common/Constants";

interface Props {
  researchParticipation: PetraResearchParticipation;
  setPreviewSettings: (previewSettings: PreviewSettings) => void;
}

// Class for rendering the buttons for a completed or stopped protocol subscription.
const CompletedButtons: React.FunctionComponent<Props> = ({ researchParticipation, setPreviewSettings }) => {
  const navigate = useNavigate();

  // Turns a research participation into a flagInterface
  const flagInterface: FlagInterface | null = useResearchParticipation(researchParticipation);

  const repeatResearchParticipation = (): void => {
    const protSub: PetraProtocolSubscription = researchParticipation.protocolSubscriptions[0];
    const protocol = protSub.protocol;
    const design = determineDesign(protocol);
    if (
      protSub.schedulerSettingsOverrides?.__typename !== "RandomSchedulerSettings" ||
      protSub.schedulerSettingsOverrides.blocksPerDay === null
    ) {
      console.error("The scheduler for this protocol is not the random scheduler or blocksPerDay was not defined.");
      return;
    }
    const { blocksPerDay, dailyStartTime } = protSub.schedulerSettingsOverrides;
    const repeatDiaryState: NewDiaryStateParams = {
      goals: [researchParticipation.settings.goal],
      subGoals: [researchParticipation.settings.subGoal],
      complaints: [researchParticipation.settings.complaint],
      design: design,
      flags: flagInterface?.flagsSelection?.selected || [],
      textvars: flagInterface?.textvarsSettings?.textvarsValues || {},
      measurementAmount: protSub.measurementAmount,
      blocksPerDay: blocksPerDay,
      dailyStartTime: dailyStartTime,
    };
    navigate("/wizard/goals", { state: repeatDiaryState });
  };

  return (
    <>
      <button onClick={() => navigate(`/results/${researchParticipation.id}`)}>
        Feedback
        <div className="button-icon summary" />
      </button>
      <button onClick={() => previewDiary(researchParticipation, setPreviewSettings, flagInterface)}>
        Dagboek
        <div className="button-icon diary" />
      </button>
      {/* Hide the reports button for now because we don't have that functionality yet.
      <button>
        Rapporten
        <div className="button-icon reports" />
      </button>
      */}
      <button onClick={repeatResearchParticipation}>
        Herhalen
        <div className="button-icon repeat" />
      </button>
    </>
  );
};

export default CompletedButtons;
