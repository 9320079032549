import { withRailsMountable } from "logic/rails_component";
import React, { useState } from "react";

interface QuestionOption {
  key: string;
  title: string;
}

interface Question {
  key: string;
  title: string;
  options: QuestionOption[];
}

interface Props {
  questions: Question[];
  questionLabel: string;
  questionField: string;
  questionValue: Question["key"] | null;
  optionLabel: string;
  optionField: string;
  optionValue: QuestionOption["key"] | null;
}

export const QuestionAndOptionSelector: React.FC<Props> = (props) => {
  const [questionKey, setQuestionKey] = useState<null | string>(props.questionValue)
  const question = questionKey && props.questions.find(q => q.key == questionKey);

  return (
    <>
      <div className="control-group select required">
        <label className="select optional control-label" htmlFor={props.questionField}>
          { props.questionLabel }
        </label>
        <div className="controls">
          <select
            className="select required"
            name={props.questionField}
            id={props.questionField}
            value={questionKey ?? undefined}
            onChange={e => setQuestionKey(e.target.value)}
          >
            <option value=""></option>
            { props.questions.map((question) => <option value={question.key}>{question.title}</option>)}
          </select>
        </div>
      </div>

      { question && <OptionSelector key={questionKey} question={question} label={props.optionLabel} fieldName={props.optionField} defaultValue={questionKey === props.questionValue ? props.optionValue : null} /> }
    </>
  );
};

const OptionSelector: React.FC<{question: Question, label: string; fieldName: string, defaultValue: string | null}> = (props) => {
  return (
      <div className="control-group select required">
        <label className="select optional control-label" htmlFor={props.fieldName}>
          { props.label }
        </label>
        <div className="controls">
          <select
            className="select required"
            name={props.fieldName}
            id={props.fieldName}
            defaultValue={props.defaultValue ?? undefined}
          >
            <option value=""></option>
            { props.question.options.map((option) => <option value={option.key}>{option.title}</option>)}
          </select>
        </div>
      </div>
  )
}

export default withRailsMountable(QuestionAndOptionSelector);
