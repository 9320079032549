import React from "react";
import classNames from "classnames";
import ActiveButtons from "./ActiveButtons";
import CompletedButtons from "./CompletedButtons";
import { PetraProtocolSubscription, PetraResearchParticipation } from "../../common/Results";
import { Loader } from "../../../ui/loader";
import { localizedFormat } from "../../../../logic/date";
import { ProtocolSubscriptionStatusEnum, ResponsesWithoutValuesQuery, useResponsesWithoutValuesQuery } from "../../../../grapqhl";
import { PreviewSettings } from "../index";
import { Design } from "../../common/Schema";
import { BEEP_LIMITS, determineDesign } from "../../common/Constants";
import { formatDatesInterval, formatTime } from "../../common/Time";

interface Props {
  researchParticipation: PetraResearchParticipation;
  setPreviewSettings: (previewSettings: PreviewSettings) => void;
}

// Class for rendering a recent PETRA diary study on the Petra overview page.
// An `active` diary is one that has status started, and this component will render a `stop` button.
// For stopped diaries, this component renders a "go to results" button which will take the
// user to the results page for this diary study.
const RecentDiary: React.FunctionComponent<Props> = ({ researchParticipation, setPreviewSettings }) => {
  const protSub: PetraProtocolSubscription = researchParticipation.protocolSubscriptions[0];

  // Don't show anything if this research participation doesn't have a protocol subscription.
  if (!protSub) {
    // We could use console.warn here, but that gives a whole stack trace which takes up many lines,
    // so until we fix this issue, this is cleaner.
    console.log("WARNING: Encountered a research participation without any protocol subscriptions in it:");
    console.log(researchParticipation);
    return null;
  }
  const protocol = protSub.protocol;
  const design = determineDesign(protocol);
  if (
    protSub.schedulerSettingsOverrides?.__typename !== "RandomSchedulerSettings" ||
    protSub.schedulerSettingsOverrides.blocksPerDay === null
  ) {
    console.error("The scheduler for this protocol is not the random scheduler or blocksPerDay was not defined.");
    return null;
  }
  const { blocksPerDay, dailyStartTime: startTime } = protSub.schedulerSettingsOverrides;
  let endTime;
  if (design) {
    // TODO: refactor this into a function to avoid duplication with code in MeasurementSchedule.tsx
    const beepLimits = BEEP_LIMITS[design].filter((beepLimit) => beepLimit.numberOfBeepsPerDay === blocksPerDay)[0];
    if (beepLimits) {
      // If we have beepLimits, the `endTime` is defined.
      endTime = startTime + beepLimits.totalBlockDuration;
    }
  }

  // Don't show anything if we don't have a questionnaire id.
  const questionnaireId = protSub.protocol?.measurements[0]?.questionnaires[0]?.id;
  if (!questionnaireId) {
    console.log("WARNING: Encountered a protocol subscription without any questionnaires in it:");
    console.log(protSub);
    return null;
  }

  const responsesQuery = useResponsesWithoutValuesQuery({
    variables: {
      questionnaireIds: [questionnaireId || ""],
      limit: 200,
      researchParticipationId: researchParticipation.id,
    },
  });

  let responses: ResponsesWithoutValues = [];
  if (!responsesQuery.loading) {
    if (!responsesQuery.data || !responsesQuery.data.currentDossier) {
      return <div>{JSON.stringify(responsesQuery.error)}</div>;
    }
    responses = responsesQuery.data.currentDossier.responses;
  }

  const renderFilledOutMessage = (): React.ReactNode => {
    const filledOutResponses: ResponsesWithoutValues = responses.filter((response) => response.completed);
    const percFilledOut =
      responses.length === 0 ? "N/A" : Math.round((100 * filledOutResponses.length) / responses.length);
    const lastFilledOutResponse: SingleResponseWithoutValues | undefined =
      filledOutResponses.length > 0 ? filledOutResponses[filledOutResponses.length - 1] : undefined;

    if (filledOutResponses.length == 0) {
      return (
        <>
          <strong>nul keer ingevuld</strong>.
        </>
      );
    }
    return (
      <>
        <strong>{filledOutResponses.length} keer ingevuld</strong>, dat is <strong>{percFilledOut}%</strong>
        {lastFilledOutResponse && lastFilledOutResponse.openFrom ? (
          <>, de laatste keer op {formatDate(lastFilledOutResponse.openFrom)}.</>
        ) : (
          <>.</>
        )}
      </>
    );
  };

  return (
    <div className={classNames("recent-diary", { active: protSub.status === ProtocolSubscriptionStatusEnum.Scheduled || protSub.status === ProtocolSubscriptionStatusEnum.Started })}>
      <div className="recent-diary-content">
        <h5>
          {researchParticipation.name} <label>{statusLabels[protSub.status]}</label>
        </h5>
        {protSub.startAt && protSub.stopAt && (
          <p>
            {blocksPerDay}x per dag {humanReadableDesign(design)} tussen{" "}
            <strong>
              {formatTime(startTime)} en {formatTime(endTime)}
            </strong>
            , {formatDatesInterval(new Date(protSub.startAt), new Date(protSub.stopAt))}.
          </p>
        )}
        <p>{!responsesQuery.loading && <>Het is {renderFilledOutMessage()}</>}</p>
        {responsesQuery.loading && <Loader />}
      </div>
      <div className="recent-diary-buttons">
        {(protSub.status === ProtocolSubscriptionStatusEnum.Scheduled || protSub.status === ProtocolSubscriptionStatusEnum.Started) && (
          <ActiveButtons researchParticipation={researchParticipation} setPreviewSettings={setPreviewSettings} />
        )}
        {protSub.status === ProtocolSubscriptionStatusEnum.Stopped && (
          <CompletedButtons researchParticipation={researchParticipation} setPreviewSettings={setPreviewSettings} />
        )}
      </div>
    </div>
  );
};

// To use "afgerond" instead of "gestopt" for stopped diary studies.
const statusLabels = {
  started: "actief",
  paused: "gepauzeerd",
  stopped: "afgerond",
  scheduled: "gepland",
  STARTED: "actief",
  PAUSED: "gepauzeerd",
  STOPPED: "afgerond",
  SCHEDULED: "gepland",
};

type ResponsesWithoutValues = NonNullable<ResponsesWithoutValuesQuery["currentDossier"]>["responses"];
type SingleResponseWithoutValues = ResponsesWithoutValues[number];

const formatDate = (date: Date): string => {
  return localizedFormat(new Date(date), "eeee dd-MM-yyyy 'om' HH:mm");
};

const humanReadableDesign = (design: Design | undefined): React.ReactNode => {
  if (!design) return null;
  switch (design) {
    case "fixed":
      return <strong>op vaste momenten</strong>;
    case "semiRandom":
      return <strong>op willekeurige momenten</strong>;
    case "oneTimePerDay":
      return null;
  }
};
export default RecentDiary;
