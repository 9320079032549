import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Key } from "../../common/Selections";
import Button from "../../common/Button";
import Flag from "./Flag";
import { MIN_SEARCH_QUERY_LENGTH } from "../../common/Constants";
import { ancestors, FlagInterface, FlagObject, matchesQuery } from "../../common/Flags";
import { useSelections } from "ahooks";
import Lightbox from "../../../Lightbox";
import MyJoyride from "../../common/MyJoyride";
import { Step } from "react-joyride";

interface Props {
  flags: FlagInterface;
}

// Renders the page for selecting flags. Also includes a search field that acts as a filter.
// Flags are displayed in a tree-like recursive structure, with their nesting defined by the
// `dependsOn` attributes of the flags.
const SelectFlags: React.FunctionComponent<Props> = (props) => {
  // The search query for filtering flags through the search field.
  const [query, setQuery] = useState<string>("");
  const [showExplanation, setShowExplanation] = useState<boolean>(false);
  const navigate = useNavigate();

  // The flags currently expanded (= folded out) on the page.
  const flagExpansion: Selections<Key> = useSelections<Key>(
    props.flags.flags.map((item) => item.key),
    []
  );

  // When loading the page or when changing the search query,
  // reset the expanded state of all flags to show all selected flags.
  // That is, all checked checkboxes are immediately visible to the user.
  useEffect(() => {
    let newExpandedFlags: string[] = [];
    for (const selectedFlag of props.flags.flagsSelection.selected) {
      newExpandedFlags = [...newExpandedFlags, ...ancestors(selectedFlag, props.flags.flags)];
    }
    newExpandedFlags = Array.from(new Set(newExpandedFlags));
    flagExpansion.setSelected(newExpandedFlags);
  }, [query]);

  // Reference to keep track of the search field for input focusing.
  const searchRef = useRef<HTMLInputElement>(null);

  // When we click the 'X' button in the search field, clear it and focus it.
  const clearAndFocusSearch = () => {
    setQuery("");
    if (searchRef.current) {
      searchRef.current.focus();
    }
  };

  // When loading the page, set focus to the search box so that
  // the user can start typing immediately.
  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
    // Scroll to the top of the page
    if ("scrollTo" in Element.prototype) {
      window.document.querySelector("#outer-main-region")?.scrollTo(0, 0);
    }
  }, []);

  // A flag is a domain if it does not depend on other flags.
  const isDomain = (flag: FlagObject) => {
    return !flag.dependsOn || flag.dependsOn.length === 0;
  };

  const renderFlag = (flag: FlagObject) => {
    return (
      <Flag
        key={flag.key}
        currentFlag={flag.key}
        flagExpansion={flagExpansion}
        flags={props.flags}
        query={query.toLowerCase()}
        matching={false}
        setShowExplanation={setShowExplanation}
      />
    );
  };

  // We render the Flag component only for the "domains", which are
  // flags that do not depend on other flags. The Flag component will render
  // the domain and its descendants if appropriate (based on the search query).
  const domains = props.flags.flags.filter((flag) => isDomain(flag));

  // Keep track of whether we should show a "search query returned no results" message
  // on the page. For this we perform a separate search that returns as soon as a match is found.
  // We cannot reuse the render flag result because we render flags separately and so
  // we don't know if any of them matched or not. (Because the filtering is done in the flag
  // component, so even if a flag doesn't match, it still calls that component)
  const anyFlagMatching = useMemo(() => {
    if (query.length < MIN_SEARCH_QUERY_LENGTH) return true;
    for (const domain of domains) {
      if (matchesQuery(query.toLowerCase(), domain.key, props.flags.flags)) {
        return true;
      }
    }
    return false;
  }, [props.flags.flags, query]);

  return (
    <>
      <MyJoyride steps={joyrideSteps} id="petra-flags" />
      <Lightbox show={showExplanation} onCloseClicked={() => setShowExplanation(false)}>
        <div className="lightbox-background">
          <div className="colored-header">
            <div className="header-close-button" onClick={() => setShowExplanation(false)} />
            <h2>Hoe formuleer je een goede vraag?</h2>
          </div>
          <div className="petra-content-inset">
            <p>Een goed dagboekitem:</p>
            <ul>
              <li>Is een statement, geen vraag</li>
              <li>Is zo concreet mogelijk</li>
              <li>Is te beantwoorden met een schaal die loopt van ‘helemaal niet’ tot ‘heel erg’</li>
              <li>Gaat over het hier en nu (op dit moment) of over de afgelopen uren (sinds het vorige meetmoment)</li>
              <li>Krijgt wisselende antwoorden per moment van de dag</li>
              <li>Bevat geen ontkenning (“ik voel me niet”)</li>
            </ul>
          </div>
        </div>
      </Lightbox>
      <div className="colored-header">
        <h2>Onderwerpen en vragen</h2>
      </div>
      <div className="petra-content">
        <h3>Stel je vragenlijst samen</h3>
        <ul>
          <li>de vragen zijn verdeeld over onderwerpen. Het onderwerp 'stemming' is verplicht.</li>
          <li>
            Vragen met het label <span className="label petra-flag-label no-margins ochtend">ochtend</span> worden elke
            ochtend gesteld
          </li>
          <li>
            Vragen met het label <span className="label petra-flag-label no-margins avond">avond</span> worden alleen
            aan het einde van de dag gesteld
          </li>
          <li>Rechts in beeld staat hoeveel vragen geselecteerd zijn</li>
        </ul>
        <h3>Kies je thema's en vragen</h3>
        <div className="search-field">
          <label>Doorzoek vragen op trefwoord</label>
          <input
            id="search"
            ref={searchRef}
            type="search"
            placeholder="begin hier met typen"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          {query.length > 0 && (
            <button className="clear-search" onClick={clearAndFocusSearch}>
              zoekveld leegmaken ✖
            </button>
          )}
        </div>
        <div className="petra-flags">
          {props.flags.flags.filter((flag) => isDomain(flag)).map((flag) => renderFlag(flag))}
          {!anyFlagMatching && <p className="no-match-found">Geen vragen passen bij de zoektermen.</p>}
        </div>
        <div className="navigation sticky">
          <Button label="Doel kiezen" onClick={() => navigate(-1)} />
          <Button label="Instellen en afronden" isDefault onClick={() => navigate("/wizard/settings")} />
        </div>
      </div>
    </>
  );
};

const joyrideSteps: Array<Step> = [
  {
    target: ".petra-flags",
    content: (
      <div className="petra">
        <h2>Het dagboek samenstellen (1/5)</h2>
        <p>
          Op dit scherm selecteer je de vragen die in het dagboek komen. Op basis van het gekozen doel zijn een aantal
          vragen alvast geselecteerd. Je kunt dit nu zelf gaan aanpassen.
        </p>
      </div>
    ),
    placement: "top",
    isFixed: true,
  },
  {
    target: ".search-field",
    content: (
      <div className="petra">
        <h2>Zoeken (2/5)</h2>
        <p>Gebruik de zoekbalk om op onderwerp te zoeken.</p>
      </div>
    ),
  },
  {
    target: ".flag",
    content: (
      <div className="petra">
        <h2>Vragen per thema (3/5)</h2>
        <p>
          De onderstaande balken zijn onderwerpen waaruit je kunt kiezen. Klap een onderwerp open om de vragen (of de
          subcategorieen) te zien.
        </p>
      </div>
    ),
    placement: "top",
    isFixed: true,
  },
  {
    target: ".rd-question-selection",
    content: (
      <div className="petra">
        <h2>Bekijk je voortgang (4/5)</h2>
        <p>
          Je ziet hier hoeveel vragen geselecteerd zijn, en hoeveel impact dat heeft. Wordt de balk oranje of rood?
          Bedenk dan of alle vragen wel echt nodig zijn.
        </p>
        <p>Je kunt ook een live-voorbeeld van het dagboek bekijken.</p>
      </div>
    ),
    placement: "left-start",
  },
  {
    target: ".roqicon-research-petra-white, .roqicon-research-petra-nextjs-white",
    content: (
      <div className="petra">
        <h2>Dagboek aanpassen (5/5)</h2>
        <p>
          Is het dagboek niet meer wat je wilt? Via de startpagina van PETRA maak je eenvoudig een nieuw dagboek,
          gebaseerd op je huidige. Zo kan je de inhoud en de intensiteit aanpassen als dat nodig is.
        </p>
      </div>
    ),
    placement: "right",
  },
];

export default SelectFlags;
