// Constants
import { BeepLimit, Design, Designs, GoalDefinitions, ItemDomain, PerDesign } from "./Schema";
import { Key } from "./Selections";
import { PetraProtocol, PetraSingleProtocol } from "./Protocols";

// The minimum length of the search query on the flags page for filtering flags.
// Queries below this length are ignored.
export const MIN_SEARCH_QUERY_LENGTH = 3;

// We create constants for all the goals so that it's easy to see in an editor
// which constants are not used, and so that we don't make accidental typos when redefining the same
// string in multiple places (i.e., we define the list of goals in one constant, and have another constant
// where we define the nesting of the goals.)

// DIARY DESIGNS
export const FIXED: Design = "fixed";
export const SEMI_RANDOM: Design = "semiRandom";
export const ONE_TIME_PER_DAY: Design = "oneTimePerDay";

// Human readable label for a design
export const DESIGN_DESCRIPTION: PerDesign<string> = {
  [FIXED]: "Op vaste momenten",
  [SEMI_RANDOM]: "Op willekeurige momenten",
  [ONE_TIME_PER_DAY]: "Alleen 's ochtends en/of 's avonds",
};

export const QUESTIONNAIRE_NAMES: PerDesign<string> = {
  [FIXED]: "ptr_fixed",
  [SEMI_RANDOM]: "ptr_semi",
  [ONE_TIME_PER_DAY]: "ptr_1xday",
};

// These match exactly the report template name in
// db/seeds/research_projects/petra/report_templates.rb
export const REPORT_TEMPLATE_NAME: string = "PETRA rapport";

export const determineDesign = (protocol: PetraProtocol | PetraSingleProtocol): Design | undefined => {
  const firstQuestionnaireKey: string = protocol.measurements[0].questionnaires[0].key;
  switch (firstQuestionnaireKey) {
    case QUESTIONNAIRE_NAMES[FIXED]:
      return FIXED;
    case QUESTIONNAIRE_NAMES[SEMI_RANDOM]:
      return SEMI_RANDOM;
    case QUESTIONNAIRE_NAMES[ONE_TIME_PER_DAY]:
      return ONE_TIME_PER_DAY;
    default:
      return undefined;
  }
};

// DESIGN COMBINATIONS
const alleenWillekeurig: Designs = {
  description: "Dit doel kan alleen op willekeurige momenten gemeten worden",
  preferredDesigns: [SEMI_RANDOM],
  alsoSupportedDesigns: [],
};
const voorkeurWillekeurig: Designs = {
  description: "Dit doel kan gemeten worden op vaste momenten, maar meten op willekeurige momenten geniet de voorkeur",
  preferredDesigns: [SEMI_RANDOM],
  alsoSupportedDesigns: [FIXED],
};
const vast1xPerDagOfWillekeurig: Designs = {
  description:
    "Dit doel kan gemeten worden op willekeurige momenten, maar meten op vaste momenten óf alleen 's ochtends en/of 's avonds meten geniet de voorkeur",
  preferredDesigns: [FIXED, ONE_TIME_PER_DAY],
  alsoSupportedDesigns: [SEMI_RANDOM],
};

const ONTDEKKEN_WANNEER_IK_KLACHTEN_ERVAAR: Key = "ontdekken_wanneer_ik_klachten_ervaar";
const OP_WELK_MOMENT_VAN_DE_DAG_OF_WEEK_HEB_IK_KLACHTEN: Key = "op_welk_moment_van_de_dag_of_week_heb_ik_klachten";
const TIJDENS_WELKE_ACTIVITEITEN_HEB_IK_KLACHTEN: Key = "tijdens_welke_activiteiten_heb_ik_klachten";
const HOEVEEL_SCHOMMELEN_MIJN_KLACHTEN: Key = "hoeveel_schommelen_mijn_klachten";
const WAT_BEINVLOEDT_MIJN_KLACHTEN: Key = "wat_beinvloedt_mijn_klachten";
const WAT_IS_DE_INVLOED_VAN_MIJN_LEEFSTIJL_EN_SLAAP_OP_MIJN_KLACHTEN: Key =
  "invloed_van_mijn_leefstijl_en_slaap_op_mijn_klachten";
const WAT_TRIGGERT_MIJN_KLACHTEN: Key = "wat_triggert_mijn_klachten";
const MONITOREN_VAN_VERANDERING_IN_KLACHTEN: Key = "monitoren_van_verandering_in_klachten";
const HOE_VERANDEREN_MIJN_KLACHTEN_IN_DE_LOOP_VAN_DE_BEHANDELING: Key =
  "hoe_veranderen_mijn_klachten_in_de_loop_van_de_behandeling";
const WAT_ZIJN_KORTDURENDE_EFFECTEN_VAN_MEDICATIE: Key = "wat_zijn_kortdurende_effecten_van_medicatie";
const WELKE_GEBEURTENISSEN_BEINVLOEDEN_MIJN_KLACHTEN: Key = "welke_gebeurtenissen_beinvloeden_mijn_klachten";
const WAT_HELPT_MIJ_OM_TE_GAAN_MET_MIJN_KLACHTEN: Key = "wat_helpt_mij_om_te_gaan_met_mijn_klachten";
const WAT_IS_DE_INVLOED_VAN_MIJN_GEDACHTEN: Key = "wat_is_de_invloed_van_mijn_gedachten";
const HOE_HANGEN_MIJN_LICHAMELIJKE_EN_PSYCHISCHE_KLACHTEN_SAMEN: Key =
  "hoe_hangen_mijn_lichamelijke_en_psychische_klachten_samen";
const HOE_IS_HET_LANGDURIG_BELOOP_VAN_MIJN_KLACHTEN: Key = "hoe_is_het_langdurig_beloop_van_mijn_klachten";

// Some aliases for syntactic sugar
const semiName = (str: string): ItemDomain => `${QUESTIONNAIRE_NAMES[SEMI_RANDOM]}_${str}`;
const fixedName = (str: string): ItemDomain => `${QUESTIONNAIRE_NAMES[FIXED]}_${str}`;
const oneXName = (str: string): ItemDomain => `${QUESTIONNAIRE_NAMES[ONE_TIME_PER_DAY]}_domein_${str}`;

// ITEM DOMAINS
// Item domains that are commented out are domains that are defined in the questionnaire, but
// currently not preselected by any of the defined goals, subgoals, complaints, or required
// item domains.
const SEMI_ACTIVITEITEN = semiName("activ");
const SEMI_GEZELSCHAP = semiName("gezel");
const SEMI_OMGEVING = semiName("omgev");
const SEMI_GEBEURTENISSEN = semiName("gebeu");
const SEMI_ETEN = semiName("eten");
const SEMI_PIEKEREN = semiName("piek");
const SEMI_ZELFBEELD = semiName("zelfb");
const SEMI_LICHAMELIJKE_KLACHTEN = semiName("lich");
const SEMI_MIDDELEN_EN_BIJWERKINGEN = semiName("midd");
const SEMI_ANGST = semiName("angst");
const SEMI_ANHEDONIE_INITIATIEF = semiName("anhe");
const SEMI_HYPOMANIE = semiName("hypo");
const SEMI_STEMMEN = semiName("stem");
const SEMI_WANEN = semiName("waan");
// const SEMI_INTRUSIES_EN_DWANG = semiName("intru");
// const SEMI_PANIEK = semiName('paniek');
// const SEMI_DEREALISATIE = semiName('dereal');
const SEMI_STRESS = semiName("strs");
const SEMI_OVERPRIKKELING = semiName("prik");
const SEMI_ONRUST_AGITATIE = semiName("agit");
const SEMI_STEMMING = semiName("domein_stemming");
const SEMI_SLAAP = semiName("slaap");
// const SEMI_POSITIVITEIT = semiName('posit')
// const SEMI_FUNCTIONEREN = semiName('func')
const SEMI_VEERKRACHT = semiName("veer");

const FIXED_ACTIVITEITEN = fixedName("activ");
const FIXED_GEZELSCHAP = fixedName("gezel");
const FIXED_OMGEVING = fixedName("omgev");
const FIXED_GEBEURTENISSEN = fixedName("gebeu");
const FIXED_ETEN = fixedName("eten");
const FIXED_PIEKEREN = fixedName("piek");
const FIXED_ZELFBEELD = fixedName("zelfb");
const FIXED_LICHAMELIJKE_KLACHTEN = fixedName("lich");
const FIXED_MIDDELEN_EN_BIJWERKINGEN = fixedName("midd");
const FIXED_ANGST = fixedName("angst");
const FIXED_ANHEDONIE_INITIATIEF = fixedName("anhe");
const FIXED_HYPOMANIE = fixedName("hypo");
const FIXED_STEMMEN = fixedName("stem");
const FIXED_WANEN = fixedName("waan");
// const FIXED_INTRUSIES_EN_DWANG = fixedName("intru");
// const FIXED_PANIEK = fixedName('paniek');
// const FIXED_DEREALISATIE = fixedName('dereal');
const FIXED_STRESS = fixedName("strs");
const FIXED_OVERPRIKKELING = fixedName("prik");
const FIXED_ONRUST_AGITATIE = fixedName("agit");
const FIXED_STEMMING = fixedName("domein_stemming");
const FIXED_SLAAP = fixedName("slaap");
// const FIXED_POSITIVITEIT = fixedName('posit')
// const FIXED_FUNCTIONEREN = fixedName('func')
const FIXED_VEERKRACHT = fixedName("veer");

const ONEX_SLAAP = oneXName("sldag"); // slaap overdag
const ONEX_STEMMING = oneXName("mood");
// const ONEX_FUNC = oneXName('func') // functioneren en herstel
const ONEX_ANGST = oneXName("angst"); // angst en vermijding
const ONEX_PSYCHOSE = oneXName("psych");
const ONEX_STRESS = oneXName("strs");
const ONEX_OVERPRIKKELING = oneXName("prik");
const ONEX_PIEKEREN = oneXName("piek"); // gedachten & piekeren
const ONEX_IMPULSIVITEIT_ONRUST = oneXName("imp");
const ONEX_INITIATIEF = oneXName("init"); // anticipatie en initiatief
// const ONEX_ZELFBEELD = oneXName('zelfb')
const ONEX_LICHAMELIJKE_KLACHTEN = oneXName("lich");
const ONEX_SOCIAAL_CONTACT = oneXName("soc");
const ONEX_GEBEURTENISSEN = oneXName("gebeu");
// const ONEX_GEDRAG = oneXName('gedr')

// Required item domains of which the dependent flags will always
// be checked (if their defaultInInterface is true) and cannot be
// unchecked by the user. There are two sets since we have two
// different questionnaires.
export const REQUIRED_ITEM_DOMAINS: PerDesign<ItemDomain[]> = {
  [SEMI_RANDOM]: [SEMI_STEMMING],
  [FIXED]: [FIXED_STEMMING],
  [ONE_TIME_PER_DAY]: [ONEX_STEMMING],
};

// A constant that provides details on the goals and subgoals.
// NOTE: only subgoals should have designs, beeps, and item domains
// attached to them.
export const GOAL_DEFINITIONS: GoalDefinitions = {
  [ONTDEKKEN_WANNEER_IK_KLACHTEN_ERVAAR]: {
    title: "Ontdekken wanneer ik klachten ervaar",
  },
  [WAT_BEINVLOEDT_MIJN_KLACHTEN]: {
    title: "Ontdekken wat mijn klachten beïnvloedt",
  },
  [MONITOREN_VAN_VERANDERING_IN_KLACHTEN]: {
    title: "Monitoren van verandering in klachten",
  },
  [HOEVEEL_SCHOMMELEN_MIJN_KLACHTEN]: {
    title: "Hoeveel schommelen mijn klachten?",
    design: voorkeurWillekeurig,
    beeps: { supported: [3, 5, 7, 10], preferred: 5 },
  },
  [OP_WELK_MOMENT_VAN_DE_DAG_OF_WEEK_HEB_IK_KLACHTEN]: {
    title: "Op welk moment van de dag of week heb ik klachten?",
    design: voorkeurWillekeurig,
    beeps: { supported: [3, 5, 7, 10], preferred: 5 },
  },
  [TIJDENS_WELKE_ACTIVITEITEN_HEB_IK_KLACHTEN]: {
    title: "Tijdens welke activiteiten heb ik klachten?",
    design: alleenWillekeurig,
    beeps: { supported: [5, 7, 10], preferred: 7 },
    item_domains: {
      [SEMI_RANDOM]: [SEMI_ACTIVITEITEN, SEMI_GEZELSCHAP, SEMI_OMGEVING],
      [FIXED]: [FIXED_ACTIVITEITEN, FIXED_GEZELSCHAP, FIXED_OMGEVING],
      [ONE_TIME_PER_DAY]: [],
    },
  },
  [WELKE_GEBEURTENISSEN_BEINVLOEDEN_MIJN_KLACHTEN]: {
    title: "Welke gebeurtenissen beïnvloeden mijn klachten?",
    design: voorkeurWillekeurig,
    beeps: { supported: [3, 5, 7, 10], preferred: 5 },
    item_domains: {
      [SEMI_RANDOM]: [SEMI_GEBEURTENISSEN],
      [FIXED]: [FIXED_GEBEURTENISSEN],
      [ONE_TIME_PER_DAY]: [ONEX_GEBEURTENISSEN],
    },
  },
  [WAT_IS_DE_INVLOED_VAN_MIJN_LEEFSTIJL_EN_SLAAP_OP_MIJN_KLACHTEN]: {
    title: "Wat is de invloed van mijn leefstijl en slaap op mijn klachten?",
    design: voorkeurWillekeurig,
    beeps: { supported: [3, 5, 7, 10], preferred: 7 },
    item_domains: {
      [SEMI_RANDOM]: [SEMI_ETEN, SEMI_ACTIVITEITEN, SEMI_SLAAP],
      [FIXED]: [FIXED_ETEN, FIXED_ACTIVITEITEN, FIXED_SLAAP],
      [ONE_TIME_PER_DAY]: [ONEX_SLAAP],
    },
  },
  [WAT_TRIGGERT_MIJN_KLACHTEN]: {
    title: "Wat triggert mijn klachten?",
    design: voorkeurWillekeurig,
    beeps: { supported: [3, 5, 7, 10], preferred: 7 },
    item_domains: {
      [SEMI_RANDOM]: [SEMI_ACTIVITEITEN, SEMI_GEBEURTENISSEN],
      [FIXED]: [FIXED_ACTIVITEITEN, FIXED_GEBEURTENISSEN],
      [ONE_TIME_PER_DAY]: [ONEX_GEBEURTENISSEN],
    },
  },
  [WAT_HELPT_MIJ_OM_TE_GAAN_MET_MIJN_KLACHTEN]: {
    title: "Wat helpt mij om te gaan met mijn klachten?",
    design: voorkeurWillekeurig,
    beeps: { supported: [3, 5, 7, 10], preferred: 7 },
    item_domains: {
      [SEMI_RANDOM]: [SEMI_ACTIVITEITEN, SEMI_GEZELSCHAP, SEMI_GEBEURTENISSEN, SEMI_VEERKRACHT],
      [FIXED]: [FIXED_ACTIVITEITEN, FIXED_GEZELSCHAP, FIXED_GEBEURTENISSEN, FIXED_VEERKRACHT],
      [ONE_TIME_PER_DAY]: [ONEX_GEBEURTENISSEN],
    },
  },
  [WAT_IS_DE_INVLOED_VAN_MIJN_GEDACHTEN]: {
    title: "Wat is de invloed van mijn gedachten?",
    design: voorkeurWillekeurig,
    beeps: { supported: [3, 5, 7, 10], preferred: 7 },
    item_domains: {
      [SEMI_RANDOM]: [SEMI_PIEKEREN, SEMI_ZELFBEELD, SEMI_GEBEURTENISSEN],
      [FIXED]: [FIXED_PIEKEREN, FIXED_ZELFBEELD, FIXED_GEBEURTENISSEN],
      [ONE_TIME_PER_DAY]: [ONEX_GEBEURTENISSEN],
    },
  },
  [HOE_HANGEN_MIJN_LICHAMELIJKE_EN_PSYCHISCHE_KLACHTEN_SAMEN]: {
    title: "Hoe hangen mijn lichamelijke en psychische klachten samen?",
    design: voorkeurWillekeurig,
    beeps: { supported: [3, 5, 7, 10], preferred: 7 },
    item_domains: {
      [SEMI_RANDOM]: [SEMI_LICHAMELIJKE_KLACHTEN],
      [FIXED]: [FIXED_LICHAMELIJKE_KLACHTEN],
      [ONE_TIME_PER_DAY]: [ONEX_LICHAMELIJKE_KLACHTEN],
    },
  },
  [HOE_IS_HET_LANGDURIG_BELOOP_VAN_MIJN_KLACHTEN]: {
    title: "Hoe is het langdurig beloop van mijn klachten?",
    design: vast1xPerDagOfWillekeurig,
    beeps: { supported: [1, 2, 3, 5, 7, 10], preferred: 3 },
  },
  [HOE_VERANDEREN_MIJN_KLACHTEN_IN_DE_LOOP_VAN_DE_BEHANDELING]: {
    title: "Hoe veranderen mijn klachten in de loop van de behandeling?",
    design: vast1xPerDagOfWillekeurig,
    beeps: { supported: [1, 2, 3, 5, 7, 10], preferred: 3 },
  },
  [WAT_ZIJN_KORTDURENDE_EFFECTEN_VAN_MEDICATIE]: {
    title: "Wat zijn kortdurende effecten van medicatie?",
    design: alleenWillekeurig,
    beeps: { supported: [5, 7, 10], preferred: 7 },
    item_domains: {
      [SEMI_RANDOM]: [SEMI_MIDDELEN_EN_BIJWERKINGEN],
      [FIXED]: [FIXED_MIDDELEN_EN_BIJWERKINGEN],
      [ONE_TIME_PER_DAY]: [],
    },
  },
};

// This constant defines goals and subgoals. The goals are the "keys",
// and each goal has a list of subgoals associated with it.
// That is, we currently have three goals. The first goal has
// four subgoals associated with it.
// This constant only expresses the dependency structure.
// More information on the goals is given by the GOALS_DEFINITIONS
// constant above.
export const GOALS = {
  [ONTDEKKEN_WANNEER_IK_KLACHTEN_ERVAAR]: [
    HOEVEEL_SCHOMMELEN_MIJN_KLACHTEN,
    OP_WELK_MOMENT_VAN_DE_DAG_OF_WEEK_HEB_IK_KLACHTEN,
    TIJDENS_WELKE_ACTIVITEITEN_HEB_IK_KLACHTEN,
    WELKE_GEBEURTENISSEN_BEINVLOEDEN_MIJN_KLACHTEN,
  ],
  [WAT_BEINVLOEDT_MIJN_KLACHTEN]: [
    WAT_IS_DE_INVLOED_VAN_MIJN_LEEFSTIJL_EN_SLAAP_OP_MIJN_KLACHTEN,
    WAT_TRIGGERT_MIJN_KLACHTEN,
    WAT_HELPT_MIJ_OM_TE_GAAN_MET_MIJN_KLACHTEN,
    WAT_IS_DE_INVLOED_VAN_MIJN_GEDACHTEN,
    HOE_HANGEN_MIJN_LICHAMELIJKE_EN_PSYCHISCHE_KLACHTEN_SAMEN,
  ],
  [MONITOREN_VAN_VERANDERING_IN_KLACHTEN]: [
    HOE_IS_HET_LANGDURIG_BELOOP_VAN_MIJN_KLACHTEN,
    HOE_VERANDEREN_MIJN_KLACHTEN_IN_DE_LOOP_VAN_DE_BEHANDELING,
    WAT_ZIJN_KORTDURENDE_EFFECTEN_VAN_MEDICATIE,
  ],
};

// Construct a list of possible subgoals used for initializing
// the subGoals useSelections object in CreateNewDiary.
const possibleSubGoals: Key[] = [];
for (const value of Object.values(GOALS)) {
  for (const subGoal of value) {
    possibleSubGoals.push(subGoal);
  }
}
export const POSSIBLE_SUB_GOALS: Key[] = possibleSubGoals;

// Construct a list of possible goals used for initializing
// the goals useSelections object in CreateNewDiary.
export const POSSIBLE_GOALS: Key[] = Object.keys(GOALS);

// The list of preferred designs for a subGoal
export const preferredDesigns = (subGoals: Selections<Key>): Design[] => {
  if (subGoals.noneSelected) return [];

  const designs = GOAL_DEFINITIONS[subGoals.selected[0]].design;
  if (designs) return designs.preferredDesigns;

  return [];
};

// The list of supported designs for a subgoal
export const supportedDesigns = (subGoals: Selections<Key>): Design[] => {
  if (subGoals.noneSelected) return [];

  const designs = GOAL_DEFINITIONS[subGoals.selected[0]].design;
  if (designs) return designs.preferredDesigns.concat(designs.alsoSupportedDesigns);

  return [];
};

// Complaints
const ANGST: Key = "angst";
const DEPRESSIE: Key = "depressie";
const HYPOMANIE: Key = "hypomanie";
const PSYCHOSE: Key = "psychose";
const STRESS: Key = "stress";
const IETS_ANDERS: Key = "iets_anders";

// After selecting a goal and subgoal, a user may select a complaint.
// This complaint is saved with the diary study, may provide a
// set of flags to additionally be checked by default, and will feature
// in the feedback part of the PETRA module to highlight certain results.
// NOTE: This needs further information when we tie in the feedback results
export const COMPLAINTS: GoalDefinitions = {
  [ANGST]: {
    title: "Angst",
    design: {
      description: "Denk aan: spanning, vermijding, paniek",
      preferredDesigns: [],
      alsoSupportedDesigns: [],
    },
    item_domains: {
      [SEMI_RANDOM]: [SEMI_ANGST],
      [FIXED]: [FIXED_ANGST],
      [ONE_TIME_PER_DAY]: [ONEX_ANGST],
    },
  },
  [DEPRESSIE]: {
    title: "Depressie",
    design: {
      description: "Denk aan: somberheid, verminderde interesse, piekeren",
      preferredDesigns: [],
      alsoSupportedDesigns: [],
    },
    item_domains: {
      [SEMI_RANDOM]: [SEMI_ANHEDONIE_INITIATIEF, SEMI_PIEKEREN],
      [FIXED]: [FIXED_ANHEDONIE_INITIATIEF, FIXED_PIEKEREN],
      [ONE_TIME_PER_DAY]: [ONEX_INITIATIEF, ONEX_PIEKEREN],
    },
  },
  [HYPOMANIE]: {
    title: "(Hypo)manie",
    design: {
      description: "Denk aan: verhoogde stemming, agitatie, slaap",
      preferredDesigns: [],
      alsoSupportedDesigns: [],
    },
    item_domains: {
      [SEMI_RANDOM]: [SEMI_HYPOMANIE, SEMI_SLAAP],
      [FIXED]: [FIXED_HYPOMANIE, FIXED_SLAAP],
      [ONE_TIME_PER_DAY]: [ONEX_SLAAP],
    },
  },
  [PSYCHOSE]: {
    title: "Psychose",
    design: {
      description: "Denk aan: stemmen horen, wanen",
      preferredDesigns: [],
      alsoSupportedDesigns: [],
    },
    item_domains: {
      [SEMI_RANDOM]: [SEMI_STEMMEN, SEMI_WANEN, SEMI_GEZELSCHAP],
      [FIXED]: [FIXED_STEMMEN, FIXED_WANEN, FIXED_GEZELSCHAP],
      [ONE_TIME_PER_DAY]: [ONEX_PSYCHOSE, ONEX_SOCIAAL_CONTACT],
    },
  },
  [STRESS]: {
    title: "Stress",
    design: {
      description: "Denk aan: agitatie, overprikkeling, spanning",
      preferredDesigns: [],
      alsoSupportedDesigns: [],
    },
    item_domains: {
      [SEMI_RANDOM]: [SEMI_ONRUST_AGITATIE, SEMI_OVERPRIKKELING, SEMI_STRESS],
      [FIXED]: [FIXED_ONRUST_AGITATIE, FIXED_OVERPRIKKELING, FIXED_STRESS],
      [ONE_TIME_PER_DAY]: [ONEX_IMPULSIVITEIT_ONRUST, ONEX_OVERPRIKKELING, ONEX_STRESS],
    },
  },
  [IETS_ANDERS]: {
    title: "Iets anders",
    design: {
      description: "",
      preferredDesigns: [],
      alsoSupportedDesigns: [],
    },
  },
};

// Construct a list of possible complaints used for initializing
// the complaints useSelections object in CreateNewDiary.
export const POSSIBLE_COMPLAINTS: Key[] = Object.keys(COMPLAINTS);

// Once a user has selected the design (fixed or semi-random) and the number of
// measurements per day (also called "beeps"), the below constant is used as a
// look-up table (by number of beeps per day) to provide durations for
// the blocks of measurements, expire time, and for the diary study itself.
// The SelectSettings component uses this information, to e.g., set the end time
// after a user changed the start time, or to check (and possibly change) the end
// date after the user changes the start date.
// Note that the `singleBlockDuration` property is necessarily derived from the
// other properties to ensure that e.g., a design with 10 measurements per day
// indeed takes up a total block duration of 15 hours.
export const BEEP_LIMITS: PerDesign<BeepLimit[]> = {
  [SEMI_RANDOM]: [
    {
      numberOfBeepsPerDay: 10,
      minimalStudyDurationInDays: 7,
      totalBlockDuration: 15 * 3600,
      singleBlockDuration: Math.floor((15 * 3600) / 10),
      minTimeBetweenBeeps: 30 * 60,
      measurementExpiresAfter: 30 * 60,
    },
    {
      numberOfBeepsPerDay: 7,
      minimalStudyDurationInDays: 14,
      totalBlockDuration: 15 * 3600,
      singleBlockDuration: Math.floor((15 * 3600) / 7),
      minTimeBetweenBeeps: 30 * 60,
      measurementExpiresAfter: 30 * 60,
    },
    {
      numberOfBeepsPerDay: 5,
      minimalStudyDurationInDays: 14,
      totalBlockDuration: 10 * 3600,
      singleBlockDuration: Math.floor((10 * 3600) / 5),
      minTimeBetweenBeeps: 30 * 60,
      measurementExpiresAfter: 30 * 60,
    },
  ],
  [FIXED]: [
    {
      numberOfBeepsPerDay: 10,
      minimalStudyDurationInDays: 7,
      totalBlockDuration: 15 * 3600,
      singleBlockDuration: Math.floor((15 * 3600) / 9),
      minTimeBetweenBeeps: 30 * 60,
      measurementExpiresAfter: 30 * 60,
    },
    {
      numberOfBeepsPerDay: 7,
      minimalStudyDurationInDays: 14,
      totalBlockDuration: 14 * 3600,
      singleBlockDuration: Math.floor((14 * 3600) / 6),
      minTimeBetweenBeeps: 3600,
      measurementExpiresAfter: 30 * 60,
    },
    {
      numberOfBeepsPerDay: 5,
      minimalStudyDurationInDays: 14,
      totalBlockDuration: 12 * 3600,
      singleBlockDuration: Math.floor((12 * 3600) / 4),
      minTimeBetweenBeeps: 3600,
      measurementExpiresAfter: 30 * 60,
    },
    {
      numberOfBeepsPerDay: 3,
      minimalStudyDurationInDays: 30,
      totalBlockDuration: 12 * 3600,
      singleBlockDuration: Math.floor((12 * 3600) / 2),
      minTimeBetweenBeeps: 2 * 3600,
      measurementExpiresAfter: 30 * 60,
    },
  ],
  [ONE_TIME_PER_DAY]: [
    {
      numberOfBeepsPerDay: 1,
      minimalStudyDurationInDays: 30,
      totalBlockDuration: 7 * 3600,
      singleBlockDuration: 7 * 3600,
      minTimeBetweenBeeps: 3 * 3600, // doesn't apply because only one block per day
      measurementExpiresAfter: 3 * 3600,
    },
    {
      numberOfBeepsPerDay: 2,
      minimalStudyDurationInDays: 30,
      totalBlockDuration: 10 * 3600,
      singleBlockDuration: 10 * 3600,
      minTimeBetweenBeeps: 3 * 3600,
      measurementExpiresAfter: 3 * 3600,
    },
  ],
};

interface PerField<T> {
  opgewekt: T;
  activiteit: T;
  gezelschap: T;
  locatie: T;
  eten: T;
  middelen: T;
  lichamelijkeKlachten: T;
  copingMetStemmen: T;
  bijwerkingen: T;
  metWieBenIk: T;
  gesprekken: T;
}

// Slider (range) fields that represent a certain property
export const LABEL_FIELDS: PerDesign<PerField<string>> = {
  [SEMI_RANDOM]: {
    opgewekt: "v_1",
    activiteit: "v_41",
    gezelschap: "v_43",
    locatie: "v_52",
    eten: "v_70",
    middelen: "v_68",
    lichamelijkeKlachten: "v_24",
    copingMetStemmen: "v_56nw",
    bijwerkingen: "v_69",
    metWieBenIk: "v_44",
    gesprekken: "v_79",
  },
  [FIXED]: {
    opgewekt: "v_1",
    activiteit: "v_39",
    gezelschap: "v_41",
    locatie: "v_49",
    eten: "v_72",
    middelen: "v_70",
    lichamelijkeKlachten: "v_60",
    copingMetStemmen: "v_55nw",
    bijwerkingen: "v_71",
    metWieBenIk: "v_42",
    gesprekken: "v_81",
  },
  [ONE_TIME_PER_DAY]: {
    opgewekt: "",
    activiteit: "",
    gezelschap: "",
    locatie: "",
    eten: "",
    middelen: "",
    lichamelijkeKlachten: "v_36",
    copingMetStemmen: "",
    bijwerkingen: "",
    metWieBenIk: "",
    gesprekken: "",
  },
};

// Define colors for the labels
export const LABEL_COLORS: PerField<string> = {
  opgewekt: "orange",
  activiteit: "light-blue",
  gezelschap: "green",
  locatie: "purple",
  eten: "gray",
  middelen: "gray",
  lichamelijkeKlachten: "gray",
  copingMetStemmen: "gray",
  bijwerkingen: "gray",
  metWieBenIk: "green",
  gesprekken: "green",
};

// Define texts for the labels
export const LABEL_TEXTS: PerField<string> = {
  opgewekt: "opgewekt",
  activiteit: "activiteit",
  gezelschap: "gezelschap",
  locatie: "locatie",
  eten: "eten",
  middelen: "middelen",
  lichamelijkeKlachten: "lichamelijke klachten",
  copingMetStemmen: "coping met stemmen",
  bijwerkingen: "bijwerkingen",
  metWieBenIk: "met wie ben ik",
  gesprekken: "gesprekken",
};

// Default options for the datepicker used to set the start and end date of the
// diary study on the SelectSettings page of the wizard for creating a new PETRA diary study.
export const DATE_PICKER_DEFAULT_OPTIONS = Object.freeze({
  dateFormat: "dd-MM-yyyy",
  showYearDropdown: true,
  showMonthDropdown: true,
});

export const INFORMED_CONSENT_QUESTIONNAIRE_KEY = "ptr_ic_alg";
