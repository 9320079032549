import React from "react";
import { Selection, SCHEMA } from "lib/non_response/schema";
import { DoNotWantNonResponseFieldset } from "components/DoNotWantNonResponseFieldset";
import { NonResponseSchemaGroupFieldset } from "components/NonResponseSchemaGroupFieldset";
import { arrayToSentence } from "logic/array";
import { uniq } from "lodash";
import { Translate } from "components/Translate";
import { stripTags } from "logic/string";
import { ConfirmDialog } from "./ConfirmDialog";

interface PendingAnswer {
  questionnaire_name: string;
}

interface Props {
  pendingAnswers: GenBackbone.Collection<PendingAnswer>;
}

const GROUP = SCHEMA.groups.didInvite;

export const ConfirmDeletePendingAnswers: React.FunctionComponent<Props> = ({ pendingAnswers }) => {
  const [selection, setSelection] = React.useState<Selection>({ option: null, otherText: null });
  const buildNonResponse = () => {
    if (selection.option) {
      return new RoQua.Models.NonResponse({
        reason_group: stripTags(GROUP.label),
        reason_code: selection.option.code,
        reason_option: stripTags(selection.option.label),
        reason_other: selection.otherText,
      });
    } else {
      return null;
    }
  };
  const submit = () => {
    RoQua.actions.deletePendingAnswers(pendingAnswers, buildNonResponse(), {
      success: () => RoQua.reactconfirm.close(),
    });
  };

  return (
    <ConfirmDialog onOk={submit}>
      <ConfirmDeleteMessage pendingAnswers={pendingAnswers} />
      <DoNotWantNonResponseFieldset checked={selection.option === null} onChange={setSelection} />
      <NonResponseSchemaGroupFieldset group={GROUP} selection={selection} onChange={setSelection} />
    </ConfirmDialog>
  );
};

const ConfirmDeleteMessage = ({ pendingAnswers }) => {
  const deletedQuestionnaires = uniq(pendingAnswers.pluck("questionnaire_name"));

  if (deletedQuestionnaires.length > 1) {
    return (
      <p>
        <Translate content="epd_area.pending_answers.confirm_remove.other" questionnaires={arrayToSentence(deletedQuestionnaires)} />
      </p>
    );
  } else {
    return (
      <p>
        <Translate content="epd_area.pending_answers.confirm_remove.one" questionnaire={deletedQuestionnaires[0]} />
      </p>
    );
  }
};
