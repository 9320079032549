import React from "react";
import { Selection } from "../lib/non_response/schema";
import { Translate } from "components/Translate";

interface Props {
  checked: boolean;
  onChange: (selected: Selection) => void;
}

export const DoNotWantNonResponseFieldset: React.FunctionComponent<Props> = ({ checked, onChange }) => {
  const handleChange = () => onChange({ option: null, otherText: null });

  return (
    <fieldset id="dont-record">
      <div>
        <label className="radio">
          <input
            name="reason_option"
            className="non-response-reason"
            type="radio"
            value="0"
            checked={checked}
            onChange={handleChange}
          />
          <span>
            <Translate content="epd_area.non_response.dont_register" />
          </span>
        </label>
      </div>
    </fieldset>
  );
};
