import React from "react";
import { getValue } from "logic/ResponseWithCache";
import { ResultResponseWithCache, TabConfiguration } from "../common/Graphs";
import { Highcharts } from "../../../lib/highcharts";
import HighchartsReact from "highcharts-react-official";
import { Options, PointOptionsObject } from "highcharts";
import { orderBy, uniqBy } from "lodash";
import TabGraphOptions from "./TabGraphOptions";
import MoreInformationContainer from "./MoreInformationContainer";
import { TabState } from "./useTabState";
import { PARSEABLE_DATE_FORMAT } from "../common/Time";
import { isOneTimePerDayQuestion } from "../common/Flags";

interface Props {
  tabConfiguration: TabConfiguration;
  responsesWithCache: ResultResponseWithCache[];
  openToDate: (Date) => void;
  tabState: TabState;
  blocksPerDay: number;
}

// The TabGraph component is the one that actually renders the Highcharts graph for a tab.
// A tab is part of a TabCollection component, which is part of a Graph component.
const TabGraph: React.FunctionComponent<Props> = ({
  tabConfiguration,
  responsesWithCache,
  openToDate,
  tabState,
  blocksPerDay,
}) => {
  const series = uniqBy(tabConfiguration.variables, "key")
    .filter((variable) => tabState.selectedVariables.isSelected(variable.key))
    .map((variable) => {
      return {
        name: variable.longDescription,
        data: extractData(responsesWithCache, variable.key, blocksPerDay),
        type: "line",
        lineWidth: 1,
        marker: {
          enabled: true,
          radius: 2.5,
        },
        cursor: "pointer",
        point: {
          events: {
            click: function() {
              openToDate(new Date(Highcharts.dateFormat(PARSEABLE_DATE_FORMAT, this.x)));
            },
          },
        },
      };
    });
  const options: Options = useHighchartsOptions(series);
  return (
    <div className="petra-tab-graph">
      <div className="petra-tg-title">
        <div className="petra-tg-title-title">{tabConfiguration.title}</div>
      </div>
      <div className="petra-tg-graph">
        <div className="petra-tgg-container">
          <HighchartsReact highcharts={Highcharts} options={options} />
          <MoreInformationContainer moreInformationKey={tabConfiguration.moreInformationKey} />
        </div>
        <div className="petra-tgg-checklist">
          <h4>Items in dagboek</h4>
          <TabGraphOptions
            selectedVariables={tabState.selectedVariables}
            variables={tabConfiguration.variables}
            name={tabConfiguration.title}
          />
        </div>
      </div>
    </div>
  );
};

// Returns the number of points in `points` at offset `offset` modulo `blocksPerDay` that have a non-null `y` value.
export const numberOfFilledOutResponsesAtOffset = (
  points: Highcharts.PointOptionsObject[],
  blocksPerDay: number,
  offset: number
): number => {
  let count = 0;
  for (let idx = offset; idx < points.length; idx += blocksPerDay) {
    if (points[idx].y !== null) {
      count += 1;
    }
  }
  return count;
};

// Returns the offset (0 <= offset < blocksPerDay) that contains the most filled in values for question with key `key`.
// In theory, with questions that are asked once per day and no missing responses, only one offset should have filled in
// values, and all the others should have no filled in values.
// Precondition: the `points` array is sorted by completedAt/openFrom date.
export const findBestOffset = (points: Highcharts.PointOptionsObject[], blocksPerDay: number): number => {
  let maxNrFilledOutResponses = -1;
  let maxIdx = 0;
  for (let idx = 0; idx < blocksPerDay; idx += 1) {
    const numberOfFilledOutResponses = numberOfFilledOutResponsesAtOffset(points, blocksPerDay, idx);
    if (numberOfFilledOutResponses > maxNrFilledOutResponses) {
      maxIdx = idx;
      maxNrFilledOutResponses = numberOfFilledOutResponses;
    }
  }
  return maxIdx;
};

// Removes all the null values except the ones that are at index `offset` modulo `blocksPerDay`.
// In other words, when blocksPerDay is 5, and offset is 0, it turns this:
// 56.7 null null null null 76.8 null null null null 98.9 null null
// into:
// 56.7 76.8 98.9
// (example is simplified to only show the y value of the point)
export const filterOutNullsExceptAtOffset = (
  points: Highcharts.PointOptionsObject[],
  offset: number,
  blocksPerDay: number
): Highcharts.PointOptionsObject[] => {
  const result: Highcharts.PointOptionsObject[] = [];
  for (let idx = 0; idx < points.length; idx += 1) {
    // In case for whatever reason our assumptions were wrong (which could theoretically happen if for whatever reason a
    // response is missing from the responses returned by graphql), and this caused us to have filled out responses
    // that are off the optimum index, then still include them, because otherwise we're throwing away filled out values.
    if (idx % blocksPerDay === offset || points[idx].y !== null) {
      result.push(points[idx]);
    }
  }
  return result;
};

// The function extractData turns responses into highcharts x and y data tuples.
// For questionnaires missed (= their values attribute is empty), we take the openFrom date
// as the date (x) component. For filled out questionnaires, we use the completedAt value
// as date component. Since questions can be optionally filled out, we have a special
// case for when the questionnaire was filled out, but the given question key was not.
export const extractData = (responsesWithCache: ResultResponseWithCache[], key: string, blocksPerDay: number) => {
  let result: Array<PointOptionsObject> = [];
  for (const rc of responsesWithCache) {
    const responseValue = getValue(rc, key);
    const date = rc.response.completedAt || rc.response.openFrom;
    if (!date) continue; // To make typescript happy

    const value = responseValue ? parseFloat(responseValue) : null;
    result.push({
      x: new Date(date).valueOf(),
      y: value,
    });
  }
  result = orderBy(result, ["x"], ["asc"]);
  // If we have a question that is asked only once per day, and we're not missing any responses,
  // remove the null values that are not at the offset (modulo blocksPerDay) with the most filled out values.
  if (isOneTimePerDayQuestion(key, responsesWithCache) && responsesWithCache.length === result.length) {
    const bestOffset = findBestOffset(result, blocksPerDay);
    result = filterOutNullsExceptAtOffset(result, bestOffset, blocksPerDay);
  }
  return result;
};

// Construct options for highcharts given the series as a parameter
export const useHighchartsOptions = (series): Options => {
  const nrSelectedVariables = series.length;
  return {
    chart: {
      // Fixed offset plus a number of pixels for each variable shown
      marginBottom: 50 + 20 * nrSelectedVariables,
      zoomType: "x",
    },
    credits: {
      enabled: false,
    },

    caption: {
      text: "",
    },

    title: {
      text: "",
    },

    subtitle: {
      text: "",
    },

    exporting: {
      enabled: true,
    },

    legend: {
      align: "left",
      layout: "vertical",
      floating: true,
      y: 10,
    },

    yAxis: {
      accessibility: {
        rangeDescription: "Bereik: 0 tot 100",
      },
      min: 0,
      max: 100,
      title: {
        text: "",
      },
      labels: {
        formatter: function() {
          const label = this.axis.defaultLabelFormatter.call(this);

          // Instead of 100 and 0, show "heel erg" and "helemaal niet", respectively.
          if (/^100$/.test(label)) {
            return "heel erg";
          }
          if (/^0$/.test(label)) {
            return "helemaal<br />niet";
          }
          return label;
        },
      },
    },

    xAxis: {
      type: "datetime",
      alternateGridColor: "#f5f5f5",
      title: {
        text: "",
      },
    },

    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 0.7,
          },
        },
        label: {
          connectorAllowed: false,
        },
      },
    },

    series: series,
  };
};

export default TabGraph;
