import React from "react";
import classNames from "classnames";

interface Props {
  allSelected: boolean;
  selectAll: () => void;
  unSelectAll: () => void;
}

// Display a select all toggle
const SelectAll: React.FunctionComponent<Props> = ({ allSelected, selectAll, unSelectAll }) => {
  return (
    <div className="petra-select-all" onClick={allSelected ? unSelectAll : selectAll}>
      <div className={classNames("petra-select-container", { checked: allSelected })}>
        <div className="petra-select-knob" />
      </div>
      <div className="petra-select-label">{allSelected ? "Alle vragen geselecteerd" : "Selecteer alle vragen"}</div>
    </div>
  );
};

export default SelectAll;
