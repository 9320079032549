import React from "react";
import { Highcharts } from "lib/highcharts";
import { BaseSettings } from "./BaseChart";
import HighchartsReact from "highcharts-react-official";
import { LineChart, LineChartSettings } from "./LineChart";
import { MultiScoreChart, MultiScoreChartSettings } from "./MultiScoreChart";
import { ColumnChartSettings, ColumnChart } from "./ColumnChart";
import { CustomChart, CustomChartSettings } from "./CustomChart";

interface Props {
  renderer: "LineChart" | "MultiScoreChart" | "ColumnChart" | "CustomChart";
  settings: BaseSettings;
}

export const ChartRenderer: React.FunctionComponent<Props> = ({ renderer, settings }) => {
  let options;

  switch (renderer) {
    case "LineChart": {
      const chart = new LineChart(settings as LineChartSettings);
      options = chart.chartSettings();
      break;
    }
    case "MultiScoreChart": {
      const chart = new MultiScoreChart(settings as MultiScoreChartSettings);
      options = chart.chartSettings();
      break;
    }
    case "ColumnChart": {
      const chart = new ColumnChart(settings as ColumnChartSettings);
      options = chart.chartSettings();
      break;
    }
    case "CustomChart": {
      const chart = new CustomChart(settings as CustomChartSettings);
      options = chart.chartSettings();
      break;
    }
  }

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default ChartRenderer;
