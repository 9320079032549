import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Goal from "./Goal";
import { Key } from "../../common/Selections";
import Button from "../../common/Button";
import {
  COMPLAINTS,
  DESIGN_DESCRIPTION,
  FIXED,
  GOAL_DEFINITIONS,
  GOALS,
  ONE_TIME_PER_DAY,
  preferredDesigns,
  SEMI_RANDOM,
  supportedDesigns,
} from "../../common/Constants";
import classNames from "classnames";
import { MeasurementSchedule } from "../../common/MeasurementSchedule";
import Lightbox from "../../../Lightbox";
import { Design } from "../../common/Schema";
import MyJoyride from "../../common/MyJoyride";
import { Step } from "react-joyride";

interface Props {
  goals: Selections<Key>;
  subGoals: Selections<Key>;
  complaints: Selections<Key>;
  schedule: MeasurementSchedule;
}

// Renders the page for selecting a goal, subgoal, complaint, and diary
// study design (fixed or semi-random). The user first has to select a goal,
// then a subgoal, then a complaint, and then a design (in that order).
// Only when all four of these items are selected, will the button to
// go to the next page in the wizard be enabled.
// As soon as a goal is selected, the other goals are no longer shown.
// This is to keep the interface clutter to a minimum. To select a different
// goal, the user must first unselect the current goal. The same decluttering
// is applied for selecting a subgoal or complaint.
// Note that we can only select at most one goal, one subgoal, one complaint,
// and one design (e.g., we do not support selecting multiple goals).
const SelectGoals: React.FunctionComponent<Props> = (props) => {
  const [explainDesign, setExplainDesign] = useState<Design | null>(null);

  const navigate = useNavigate();

  // Render a subgoal, allowing the user to select it. We reuse Goal component for this.
  const renderSubGoal = (subGoal: Key) => {
    const goalProps = { ...GOAL_DEFINITIONS[subGoal], name: subGoal };
    return <Goal {...goalProps} key={subGoal} selection={props.subGoals} />;
  };

  // Render a goal and its subgoals, allowing the user to select a goal and its subgoals.
  // The subgoals (passed as `props.children`) are only shown for the currently selected goal.
  const renderGoal = (goal: Key) => {
    const subGoals = GOALS[goal];
    const goalProps = { ...GOAL_DEFINITIONS[goal], name: goal };
    return (
      <Goal {...goalProps} key={goal} selection={props.goals} subGoalCount={subGoals.length}>
        {subGoals.map((subGoal) => renderSubGoal(subGoal))}
      </Goal>
    );
  };

  // Render a complaint, again reusing the Goal component.
  const renderComplaint = (complaint: Key) => {
    const complaintProps = { ...COMPLAINTS[complaint], name: complaint };
    return <Goal {...complaintProps} key={complaint} selection={props.complaints} />;
  };

  // Returns true if the currently selected goal has subgoals. Currently,
  // all goals have subgoals. But in theory, goals can have an empty list of subgoals.
  const firstSelectedGoalHasSubGoals = () => {
    return GOALS[props.goals.selected[0]].length > 0;
  };

  // Offers a variable explanation hint for the scheduler preview, depending
  // on the currently selected design.
  const designExplanation = () => {
    switch (props.schedule.design) {
      case FIXED:
        return <>De bruine stipjes staan voor de meetmomenten.</>;
      case SEMI_RANDOM:
        return <>De bruine balken staan voor de tijdspannes.</>;
      case ONE_TIME_PER_DAY:
        return (
          <>
            Hieronder zie je een voorbeeld van je dag als het dagboek alleen 's ochtends en/of 's avonds uitgevraagd
            wordt.
          </>
        );
    }
  };

  const handlePreferredLabelClick = (design: Design) => {
    setExplainDesign(design);
  };

  // Allow a user to change the design. By default (i.e., when selecting a subgoal),
  // the recommended design is selected for them.
  const renderDesign = () => {
    return (
      <>
        <Lightbox show={!!explainDesign} onCloseClicked={() => setExplainDesign(null)}>
          <div className="lightbox-background">
            <div className="colored-header">
              <div className="header-close-button" onClick={() => setExplainDesign(null)} />
              <h2>Extra uitleg</h2>
            </div>
            <div className="petra-content-inset">
              {explainDesign === SEMI_RANDOM && (
                <>
                  <p>
                    Voor dit doel is het belangrijk om een goed beeld te krijgen van wat je voelt en meemaakt. Dat kan
                    sterk wisselen per moment van de dag. Daarom meten we dat het liefst op tijdstippen die per dag net
                    een beetje wisselen. Juist omdat je het tijdstip van de metingen niet kunt voorspellen, krijg je het
                    beste inzicht in jouw ervaringen.
                  </p>
                  <p>
                    Sommige mensen vinden die onvoorspelbaarheid vervelend. Dan kan je kiezen voor vaste tijdstippen.
                    Bedenk dan wel dat de dagboekjes een minder betrouwbaar beeld geven van je ervaringen.
                  </p>
                </>
              )}
              {explainDesign === FIXED && (
                <>
                  <p>
                    Voor dit doel is het belangrijk dat er langdurig gemeten wordt hoe het met je gaat. Bijvoorbeeld
                    enkele weken of maanden tijdens een behandeling. De meeste mensen vinden het dan prettig om op vaste
                    tijdstippen dagboekjes in te vullen.
                  </p>
                </>
              )}
              {explainDesign === ONE_TIME_PER_DAY && (
                <>
                  <p>
                    Voor dit doel is het belangrijk dat er langdurig gemeten wordt hoe het met je gaat. Bijvoorbeeld
                    enkele weken of maanden tijdens een behandeling. Het is voldoende om dit één keer per dag in te
                    vullen.
                  </p>
                </>
              )}
            </div>
          </div>
        </Lightbox>
        <div className="content-card">
          <h3>
            Wil je de vragen willekeurig of op vaste momenten ontvangen? <span className="step-count">vraag 4 / 4</span>
          </h3>
          <div className="petra-field-with-example">
            <div className="petra-field contains-petra-arrow">
              <div className="petra-hint">
                Je ontvangt via sms een uitnodiging om het dagboek in te vullen. Deze smsjes kunnen op vaste momenten
                verstuurd worden, of tussen bepaalde tijden (dus op willekeurige momenten). {designExplanation()} Het
                onderstaande dagschema is een voorbeeld. Bij de instellingen in stap 3 kan je de gewenste tijden
                aangeven.
              </div>
              {supportedDesigns(props.subGoals).map((design) => (
                <div key={design}>
                  <input
                    type="radio"
                    id={`dagschema-radio-${design}`}
                    name="dagschema-radio"
                    checked={props.schedule.design === design}
                    onChange={() => props.schedule.setDesign(design)}
                  />
                  <label className="radio" htmlFor={`dagschema-radio-${design}`}>
                    {DESIGN_DESCRIPTION[design]}{" "}
                    {preferredDesigns(props.subGoals).includes(design) && (
                      <label className="preferred" onClick={() => handlePreferredLabelClick(design)}>
                        voorkeur
                      </label>
                    )}
                  </label>
                </div>
              ))}
              <div className="petra-hint">
                Het onderstaande dagschema is een voorbeeld. Bij de instellingen in stap 3 kan je de gewenste tijden
                aangeven.
              </div>
              <div className="petra-arrow-down green" />
              <div className="petra-arrow-down white" />
            </div>
            <div className="petra-example">
              <p>Dat leidt tot het volgende dagschema</p>
              <div className={classNames("dagschema", props.schedule.design)} />
            </div>
          </div>
        </div>
      </>
    );
  };

  // Render the complaints, allowing users to select them.
  const renderComplaints = () => {
    return (
      <div className="content-card">
        <h3>
          Op welk klachtenbeeld wil je je focussen? <span className="step-count">vraag 3 / 4</span>
        </h3>
        <div className="goals">{Object.keys(COMPLAINTS).map((complaint) => renderComplaint(complaint))}</div>
      </div>
    );
  };

  // Returns the reason why the "go to next page" button is disabled.
  // When a user has not yet selected a goal, subgoal, complaint and design,
  // the "go to next page" button is disabled and shows the reason why.
  const renderDisabledTip = () => {
    if (props.goals.noneSelected) {
      return "Kies eerst een doel";
    }
    if (firstSelectedGoalHasSubGoals() && props.subGoals.noneSelected) {
      return "Specificeer eerst het doel";
    }
    if (props.complaints.noneSelected) {
      return "Kies eerst een klacht";
    }
    return undefined;
  };

  return (
    <>
      <MyJoyride steps={joyrideSteps} id="petra-goals" />
      <div className="colored-header">
        <h2>Welkom bij PETRA</h2>
      </div>
      <fieldset className="petra-content">
        <h3>Stel hier snel en simpel je eigen dagboekstudie samen</h3>
        <ul>
          <li>Bepaal met welk doel en welke focus je een dagboek wilt invullen.</li>
          <li>PETRA doet een suggestie voor de vragen. Pas ze aan naar eigen inzicht.</li>
          <li>Rechts in beeld zie je hoe ver je bent met het samenstellen van het dagboek.</li>
        </ul>
        <h3>
          Wat is het doel van de dagboekstudie? <span className="step-count">vraag 1 / 4</span>
        </h3>
        <div className="goals">
          {Object.keys(GOALS).map((goal) => renderGoal(goal))}
          {!props.subGoals.noneSelected && renderComplaints()}
          {!props.subGoals.noneSelected && !props.complaints.noneSelected && renderDesign()}
        </div>
        <div className="navigation">
          <Button label="Terug naar start" onClick={() => navigate(-1)} />
          <Button
            label="Kies de vragen"
            isDefault
            disabled={
              props.goals.noneSelected ||
              (firstSelectedGoalHasSubGoals() && props.subGoals.noneSelected) ||
              props.complaints.noneSelected
            }
            disabledTip={renderDisabledTip()}
            onClick={() => navigate("/wizard/flags")}
          />
        </div>
      </fieldset>
    </>
  );
};

const joyrideSteps: Array<Step> = [
  {
    target: ".colored-header",
    content: (
      <div className="petra">
        <h2>Welkom, dit is PETRA (1/3)</h2>
        <p>
          Met PETRA kan je zelf dagboeken samenstellen (of een bestaande aanpassen). Op dit scherm kies je het doel van
          je dagboek.
        </p>
      </div>
    ),
  },
  {
    target: ".receipt-step-title",
    content: (
      <div className="petra">
        <h2>Hoe ver ben je? (2/3)</h2>
        <p>In deze kolom zie je bij welke stap je bent, en wat je nog moet doen voordat het dagboek klaar is.</p>
        <p>De verschillende stappen kan je open- en dichtklappen</p>
      </div>
    ),
    placement: "left-start",
  },
  {
    target: ".goals",
    content: (
      <div className="petra">
        <h2>Kies je doel (3/3)</h2>
        <p>
          Een dagboek begint met een doel: wat wil je bereiken? Je kiest eerst een globaal doel. Daarna maak je het doel
          specifieker. Ook kies je op welk klachtenbeeld je je wilt focussen. We zetten op basis hiervan een aantal
          vragen voor je klaar. In de volgende stap kan je vragen toepassen of weglaten. Kies tot slot of je het dagboek
          op vaste of willekeurige momenten wilt ontvangen.
        </p>
      </div>
    ),
    placement: "top",
  },
];

export default SelectGoals;
