import React from "react";
import { FormControlGroup, SecondDurationField } from "components/ui/forms";
import { MeasurementSelector } from "./MeasurementSelector";
import { Measurement, IntervalSchedulerSettings } from "./Settings";

const SECONDS_IN_DAY = 24*60*60

export interface Props {
  settings: IntervalSchedulerSettings;
  measurements: Measurement[];
  onChange: (settings: Partial<IntervalSchedulerSettings>) => void;
}

export const IntervalSchedulerFields: React.FunctionComponent<Props> = (props) => {
  const setInterval = (e: React.ChangeEvent<HTMLInputElement>) => props.onChange({ interval: parseInt(e.target.value) * SECONDS_IN_DAY });
  const setMeasurement = (measurementId: number) => props.onChange({ measurement_id: measurementId });
  const intervalInDays = (props.settings.interval || 0) / SECONDS_IN_DAY;
  return <div>
    <FormControlGroup label={I18n.t("activerecord.attributes.protocol/settings.interval")}>
      <input type="number" id="interval" value={intervalInDays} onChange={setInterval} />
      <span>({I18n.pluralize(intervalInDays, "datetime.distance_in_words.x_days")})</span>
    </FormControlGroup>
    <FormControlGroup label={I18n.t('activerecord.attributes.protocol/settings.measurement_id')}>
      <MeasurementSelector measurements={props.measurements} value={props.settings.measurement_id} onChange={setMeasurement} />
    </FormControlGroup>
  </div>;
};
