import * as React from "react";
import classNames from "classnames";
import { omit } from "lodash";

export interface ITableProps {
  striped?: boolean;
  border?: boolean;
  hover?: boolean;
  condensed?: boolean;
}

export const Table: React.FunctionComponent<
  ITableProps & React.HTMLProps<HTMLTableElement>
> = (props) => {
  const className = classNames("table", {
    "table-striped": props.striped,
    "table-bordered": props.border,
    "table-hover": props.hover,
    "table-condensed": props.condensed,
  });

  return <table className={className}>{props.children}</table>;
};

export interface ITableHeadProps {}

export const TableHead: React.FunctionComponent<
  ITableHeadProps & React.HTMLProps<HTMLTableSectionElement>
> = (props) => {
  return <thead>{props.children}</thead>;
};

export interface ITableBodyProps {}

export const TableBody: React.FunctionComponent<
  ITableBodyProps & React.HTMLProps<HTMLTableSectionElement>
> = (props) => {
  return <tbody>{props.children}</tbody>;
};

export interface ITableRowProps {
  highlight?: "success" | "error" | "warning" | "info";
}

export const TableRow: React.FunctionComponent<
  ITableRowProps & React.HTMLProps<HTMLTableRowElement>
> = (props) => {
  const { highlight, ...rest } = props;
  const className = classNames(props.className, highlight);
  return (
    <tr className={className} {...rest}>
      {props.children}
    </tr>
  );
};

export interface ITableCellProps {
  header?: boolean;
  colSpan?: number;
}

export const TableCell: React.FunctionComponent<
  ITableCellProps & React.HTMLProps<HTMLTableCellElement>
> = (props) => {
  if (props.header) {
    return <th colSpan={props.colSpan}>{props.children}</th>;
  } else {
    return <td colSpan={props.colSpan}>{props.children}</td>;
  }
};
