import * as React from "react";
import { useTouchNotificationPrintedAtMutation } from "grapqhl";
import { Translate } from "components/Translate";
import { withRailsMountable } from "logic/rails_component";
import classNames from "classnames";

interface Props {
  notificationId: string;
}

const NotificationPrintButton: React.FunctionComponent<Props> = (props) => {
  const [touchNotificationPrintedAt, {called, loading}] = useTouchNotificationPrintedAtMutation({
    variables: {input: {notificationId: props.notificationId}},
  });

  const doPrint = () => {
    touchNotificationPrintedAt();
    let printable = $(".printable-document") as any;
    printable.print_area();
  };

  const buttonClasses = classNames("print-invite btn btn-default", {
    "called": called,
    "loading": loading
  });

  return (
    <React.Fragment>
      <a className={buttonClasses} onClick={doPrint} href="#">
        <i className="icon-print icon-black "/> <Translate content="epd_area.notifications.actions.print"/>
      </a>
    </React.Fragment>
  );
};

const NotificationPrintButtonRails = withRailsMountable(NotificationPrintButton);
export default NotificationPrintButtonRails;
