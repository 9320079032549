import Appsignal from "@appsignal/javascript";
import { plugin } from "@appsignal/plugin-path-decorator";

export const defaultTags = () => {
  return {
    agent: navigator.userAgent,
    platform: navigator.platform,
    vendor: navigator.vendor,
    screenWidth: screen.width.toString(),
    screenHeight: screen.height.toString(),

    professionalId: window['RoQua']?.professionalId ?? ""
  };
}

// Sends an error to appsignal with some extra info on the browser
// Also show a flash message when ShouldNotHappenError error or flash passed.
export const sendError = (error: Error, {flash}: {flash?: string} = {}) => {
  if (window['RoQua'] && (flash || error instanceof RoQua.ShouldNotHappenError)) {
    RoQua.showFlash({state: "error", message: flash || I18n.t("generic_error")})
  }

  console.log(error);

  appsignal.sendError(error, (span) => {
    span.setTags({
      ...defaultTags(),
    })
  })
}

export const initializeAppsignal = (key: string) => {
  const appsignal = new Appsignal({key: key});
  appsignal.use(plugin());

  window.onerror = (_message, _filename, _lineno, _colno, error) => {
    if (error) {
      sendError(error);
    }
  };

  window.onunhandledrejection = (promiseRejectionEvent) => {
    const error = promiseRejectionEvent.reason;

    sendError(error);
  }

  // # Report all jQuery AJAX errors to Appsignal
  if (window['jQuery']) {
    jQuery(document).ajaxError((_event, _xhr, settings, error) => {
      const { url, type } = settings
      appsignal.sendError(new Error(`${type} ${url} failed: ${error}`));
    });
  }

  return appsignal;
}

export let appsignal: Appsignal;

window.addEventListener('DOMContentLoaded', () => {
  if (!appsignal) {
    const pushKey = (document.querySelector("meta[name=appsignal_push_key]") as HTMLMetaElement | null)?.content;
    if (pushKey) {
      appsignal = initializeAppsignal(pushKey);
    }
  }
});
