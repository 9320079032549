import { range } from "lodash";
import { Highcharts } from "../../lib/highcharts";
import { tooltipFormatter } from "./GenericTooltip";

export interface BaseSettings {
  title: string;
  subtitle: string;
  chart_type: string;
  minimum_value?: number | null;
  maximum_value?: number | null;
  tick_interval?: number;
  tick_positions?: number[];
  radar?: boolean;
  plotbands?: any;
  plotlines?: any;
}

export class BaseChart<T extends BaseSettings> {
  settings: T;

  constructor(settings: T) {
    this.settings = settings;
  }

  chart(): Highcharts.ChartOptions {
    return {
      type: this.settings.chart_type,
      animation: false,
    };
  }

  annotations(): Highcharts.AnnotationsOptions[] {
    return [];
  }

  title(): Highcharts.TitleOptions {
    return { text: this.settings.title };
  }

  subtitle(): Highcharts.SubtitleOptions {
    return { text: this.settings.subtitle };
  }

  tickPositioner(min: number, max: number): number[] {
    const numberOfTicks = this.settings.radar ? 5 : 10;

    const interval = Math.ceil((max - min) / numberOfTicks);
    const ticks = range(0, 10) // range() is exclusive of the last number
      .map((i: number) => min + i * interval)
      .filter((i: number) => i < max);

    if ((max - ticks[ticks.length - 1]) / interval < 0.3) {
      ticks.pop();
    }

    ticks.push(max);
    return ticks;
  }

  xAxis() {
    return {};
  }

  yAxis(): Highcharts.YAxisOptions {
    let tickPositions: number[] | undefined;

    if (this.settings.tick_positions)
      tickPositions = this.settings.tick_positions;
    else if (!this.settings.tick_interval && this.settings.maximum_value) {
      tickPositions = this.tickPositioner(this.settings.minimum_value || 0, this.settings.maximum_value);
    }

    const addPlotlineStyle = (plotline) => {
      return { ...plotline, width: 1, zIndex: 3 };
    };

    return {
      min: this.settings.minimum_value,
      max: this.settings.maximum_value,
      tickInterval: this.settings.tick_interval,
      tickPositions: tickPositions,
      plotLines: this.settings.plotlines?.map(addPlotlineStyle),
      plotBands: this.settings.plotbands,
    };
  }

  tooltip(): Highcharts.TooltipOptions {
    return {
      useHTML: true,
      formatter: tooltipFormatter,
    };
  }

  legend(): Highcharts.LegendOptions {
    return {};
  }

  pane(): Highcharts.PaneOptions {
    return {};
  }

  credits(): Highcharts.CreditsOptions {
    return { enabled: false };
  }

  series(): Highcharts.SeriesOptionsType[] {
    return [];
  }

  plotOptions(): Highcharts.PlotOptions {
    return {
      series: {
        animation: false,
      },
    };
  }

  chartSettings(includeCallbacks = true): Highcharts.Options {
    return {
      chart: this.chart(),
      annotations: this.annotations(),
      title: this.title(),
      subtitle: this.subtitle(),
      xAxis: this.xAxis(),
      yAxis: this.yAxis(),
      tooltip: includeCallbacks ? this.tooltip() : undefined,
      legend: this.legend(),
      pane: this.pane(),
      credits: this.credits(),
      series: this.series(),
      plotOptions: this.plotOptions(),
      exporting: {
        fallbackToExportServer: false,
        buttons: {
          contextButton: {
            enabled: true,
            menuItems: ["downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"],
          },
        },
      },
    };
  }
}
