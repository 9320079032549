import React from "react";
import ReactPiwik from "react-router-piwik";

type Category =
  | "Petra - Compose - Open question category"
  | "Petra - Compose - Close question category"
  | "Petra - Compose - Toggle all questions on"
  | "Petra - Compose - Toggle all questions off"
  | "Petra - Compose - Show preview"
  | "Petra - Start diary"
  | "Petra - results - Open context drawer"
  | "Petra - Add graph to report"
  | "Petra - Create report";

interface Props {
  category: Category;
  action?: string;
  name: string;
}

export const trackEvent = ({ category, action = "Clicked", name }: Props) => {
  ReactPiwik.push([
    "trackEvent",
    category, // event category
    name, // action                  (name and action are switched on purpose. name gives more
    action, // event name             information, so we want to mention it first.)
  ]);
};
