import React, { useState } from "react";
import { useSelections } from "ahooks";
import { uniq } from "lodash";
import { PlotVariable } from "../common/Graphs";

export interface TabState {
  startDate: Date | null;
  setStartDate: (date: Date | null) => void;
  endDate: Date | null;
  setEndDate: (date: Date | null) => void;
  showMeans: boolean;
  setShowMeans: (show: boolean) => void;
  showMoments: boolean;
  setShowMoments: (show: boolean) => void;
  selectedVariables: Selections<string>;
}

// The useTabState hook is used to transfer the state of a tab between components.
// This includes the variables selected, date range, and so on. Extracting this
// functionality in a separate component that is kept in a higher component
// allows us to keep the same tabState when changing between tabs in a graph.
// This way, the user's selections are not reset and they can quickly compare
// different plotted variables against each other.
export const useTabState = (initialVariables: PlotVariable[]): TabState => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showMoments, setShowMoments] = useState<boolean>(true);
  const [showMeans, setShowMeans] = useState<boolean>(true);
  const selectedVariables: Selections<string> = useSelections<string>(
    uniq(initialVariables.map((variable) => variable.key)),
    uniq(initialVariables.filter((variable) => variable.initiallySelected).map((variable) => variable.key))
  );
  return {
    startDate: startDate,
    setStartDate: setStartDate,
    endDate: endDate,
    setEndDate: setEndDate,
    showMeans: showMeans,
    setShowMeans: setShowMeans,
    showMoments: showMoments,
    setShowMoments: setShowMoments,
    selectedVariables: selectedVariables,
  };
};
