import React from "react";
import { DurationFieldProps } from "components/ui/forms";
import { ButtonLink } from "components/ui/buttons";
import { Icon } from "components/ui/icon";
import { MeasurementSelector } from "./MeasurementSelector";
import { Measurement, ScheduleItem } from "./Settings";

interface Props {
  measurements: Measurement[];
  item: ScheduleItem;
  onChange: (item: ScheduleItem) => void;
  onRemove: () => void;
  durationComponentClass: React.FunctionComponent<DurationFieldProps>;
}

export const ScheduleItemRow: React.FunctionComponent<Props> = (props) => {
  const setOffset = (offset) => props.onChange(Object.assign(props.item, { offset: offset }));
  const setMeasurement = (measurementId) => props.onChange(Object.assign(props.item, { measurement_id: measurementId }));
  const durationField = React.createElement(
    props.durationComponentClass,
    {id: 'offset', value: props.item.offset, onChange: setOffset}
  );
  return <div>
    {durationField}
    <MeasurementSelector measurements={props.measurements} value={props.item.measurement_id} onChange={setMeasurement} />
    <ButtonLink onClick={props.onRemove}><Icon name="trash" /></ButtonLink>
  </div>;
};
