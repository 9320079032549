import React, { useMemo, useState, useEffect } from "react";
import { ResultResponseWithCache } from "../common/Graphs";
import { extractData, useHighchartsOptions } from "./TabGraph";
import { Highcharts } from "../../../lib/highcharts";
import HighchartsReact from "highcharts-react-official";
import { Options } from "highcharts";
import { FilteredDatum, SelectedWord } from "./TabWordCloud";
import { startOfDay, endOfDay } from "date-fns";
import { localizedFormat } from "../../../logic/date";
import classNames from "classnames";
import { Design } from "../common/Schema";
import { LABEL_FIELDS } from "../common/Constants";

interface Props {
  filteredData: FilteredDatum[];
  word: SelectedWord;
  setSelectedWord: (word: SelectedWord | undefined) => void;
  responsesWithCache: ResultResponseWithCache[];
  design: Design;
}

const WordCloudDetails: React.FC<Props> = ({ filteredData, word, setSelectedWord, responsesWithCache, design }) => {
  const [date, setDate] = useState<Date | undefined>(undefined);

  useEffect(() => {
    if (!filteredData) {
      setDate(undefined);
    } else if (filteredData.length > 1) {
      // if we have multiple words, hide the graph
      setDate(undefined);
    } else {
      // if we have a single new word, show the graph
      setDate(filteredData[0].dateObj);
    }
  }, [filteredData]);

  const moodQuestion = LABEL_FIELDS[design].opgewekt;

  const options: Options | null = useMemo(() => {
    // Mood question is defined for the semi-random questionnaire and the fixed questionnaire,
    // but not for the one time per day questionnaire.
    if (date === undefined || word === undefined || moodQuestion == "") return null;

    const filteredResponses = filterResponses(date, responsesWithCache);
    const series = [
      {
        name: "Op dit moment voel ik me opgewekt",
        data: extractData(filteredResponses, moodQuestion, 1), // blocks per day not used as v_1 is not a 1x per day question.
        type: "line",
        lineWidth: 1,
        marker: {
          enabled: true,
          radius: 2.5,
        },
        cursor: "pointer",
      },
    ];
    // Override some options
    let myOptions = useHighchartsOptions(series);
    if (myOptions.chart == undefined) {
      myOptions.chart = {};
    }
    myOptions.chart.height = 200;
    if (myOptions.exporting == undefined) {
      myOptions.exporting = {};
    }
    myOptions.exporting.enabled = false;
    return myOptions;
  }, [date, responsesWithCache, word]);

  return (
    <div className="contains-petra-arrow" style={{ display: "inline-block", width: "50%" }}>
      <div className="petra-arrow-left green" />
      <div className="petra-wordcloud-details">
        <div className="petra-wordcloud-scroll-container">
          <h5>
            {word.word} ({word.times}x)
          </h5>
          <div className="petra-wordcloud-close" onClick={() => setSelectedWord(undefined)}>
            sluiten
          </div>
          <hr />
          {filteredData.map((filteredDatum) => {
            return (
              <React.Fragment key={`${filteredDatum.date}-${filteredDatum.text}`}>
                <div
                  className={classNames("petra-wordcloud-comment", { highlighted: date === filteredDatum.dateObj })}
                  onClick={() => setDate(filteredDatum.dateObj)}
                >
                  <div className="petra-wordcloud-date">{filteredDatum.date}</div>
                  <div className="petra-wordcloud-text">{filteredDatum.text}</div>
                </div>
                <hr />
              </React.Fragment>
            );
          })}
        </div>
      </div>
      {date !== undefined && options !== null && (
        <div>
          <div className="petra-wordcloud-lineplot">
            <p>Stemming op {localizedFormat(date, "eeee dd-MM-yyyy - HH:mm")}</p>
            <HighchartsReact highcharts={Highcharts} options={options} />
          </div>
        </div>
      )}
    </div>
  );
};

const filterResponses = (date: Date, responsesWithCache: ResultResponseWithCache[]): ResultResponseWithCache[] => {
  const dayStart = startOfDay(date);
  const dayEnd = endOfDay(date);
  const result: ResultResponseWithCache[] = [];
  for (const rc of responsesWithCache) {
    const curDate = new Date(rc.response.completedAt || rc.response.openFrom);

    if (curDate >= dayStart && curDate <= dayEnd) {
      result.push(rc);
    }
  }
  return result;
};

export default WordCloudDetails;
