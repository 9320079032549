import React, { useState } from "react";
import { FlagInterface } from "../Flags";
import { Design } from "../Schema";
import { QUESTIONNAIRE_NAMES } from "../Constants";
import { trackEvent } from "../Matomo";
import PreviewQuestionnaireLightbox from "./PreviewQuestionnaireLightbox";

interface Props {
  flags: FlagInterface;
  design: Design;
}

// Render a "Preview this diary" button for on the select settings and select flags pages of the wizard.
const PreviewQuestionnaireButton: React.FunctionComponent<Props> = (props: Props) => {
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const questionnaireKey = QUESTIONNAIRE_NAMES[props.design];

  const onButtonClicked = () => {
    trackEvent({ category: "Petra - Compose - Show preview", name: questionnaireKey });
    setShowPreview(true);
  };

  return (
    <>
      {showPreview && (
        <PreviewQuestionnaireLightbox
          previewShown={showPreview}
          hidePreview={() => setShowPreview(false)}
          questionnaireKey={questionnaireKey}
          flags={props.flags}
        />
      )}
      <button onClick={onButtonClicked}>Bekijk dit dagboek</button>
    </>
  );
};

export default PreviewQuestionnaireButton;
