import { Maybe, ResponseValue } from "grapqhl";

type QuestionHash<TQuestion> = {[key: string]: TQuestion};

export interface QuestionnaireWithQuestionsWithKeys<TQuestion> {
  questions: TQuestion[]
};

export interface ResponseWithCache<TResponse, TQuestionnaire extends QuestionnaireWithQuestionsWithKeys<TQuestion>, TQuestion> {
  response: TResponse;
  questionnaire: TQuestionnaire;
  valuesHash?:  {[key: string]: Maybe<string>};
  questionsHash?: QuestionHash<TQuestion>;
}

export interface QuestionWithKey {
  key: string;
  __typename: string;
};

export function generateQuestionsHash<TQuestion extends QuestionWithKey>(questions: TQuestion[]): QuestionHash<TQuestion> {
  return Object.fromEntries(questions.map((question) => [question.key, question]));
};

export function generateValuesHash(values: ResponseValue[]): {[key: string]: Maybe<string>} {
  return Object.fromEntries(values.map((value) => [value.key, value.value]))
}
