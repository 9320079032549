import { Response } from "grapqhl";

import { getQuestion } from "./getQuestion";
import { getValue } from "./getValue";
import { ResponseWithCache } from "./ResponseWithCache";

interface QuestionWithKey {
  key: string;
}
interface QuestionOptionWithKey {
  key: string;
}
interface MultiSelectQuestion<TQuestionOption> {
  __typename: 'MultiSelectQuestion';
  options: TQuestionOption[];
};
interface UnsupportedQuestion {
  __typename: 'TextQuestion' | 'DatePartsQuestion' | 'IntegerQuestion' | 'FloatQuestion' | 'SingleSelectQuestion';
}

type QuestionWithOptionsWhenSensible<TOption> = QuestionWithKey & (MultiSelectQuestion<TOption> | UnsupportedQuestion);

interface QuestionnaireWithQuestionOptionKeys<
  TQuestion extends QuestionWithOptionsWhenSensible<TOption>,
  TOption extends QuestionOptionWithKey,
> {
  key: string;
  questions: TQuestion[];
}

export function getCheckedOptions<
  TOption extends QuestionOptionWithKey,
>(
  responseWithCache: ResponseWithCache<
    Pick<Response, 'values'>,
    QuestionnaireWithQuestionOptionKeys<QuestionWithOptionsWhenSensible<TOption>, TOption>,
    QuestionWithOptionsWhenSensible<TOption>
  >,
  questionKey: string
): TOption[] {
  const question = getQuestion(responseWithCache, questionKey);
  if (question.__typename != 'MultiSelectQuestion') throw 'Illegal question type passed';
  return question.options.filter(option => getValue(responseWithCache, option.key) == '1')
}
