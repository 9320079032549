import * as React from "react";
import { Translate } from "../Translate";
import { withRailsMountable } from "logic/rails_component";
import { format, add } from 'date-fns'
import { DateTimeSelect } from "components/DateTimeSelect";
import { DateSelect } from "components/DateSelect";

interface Props {
  schedulerInterval: number;
  measurementAmount?: number;
  isDailyScheduler?: boolean;
  startAtTimestamp: number; // unix timestamp
}

export const RunningTime: React.FC<Props> = ({
                                               startAtTimestamp,
                                               measurementAmount,
                                               schedulerInterval,
                                               isDailyScheduler = false
                                             }) => {
  const [startAt, setStartAt] = React.useState(new Date(startAtTimestamp * 1000));

  return (
    <div>
      <input type='hidden'
             name='protocol_subscription[start_at]'
             value={startAt.toISOString()}/>
      <StartAt handleChange={setStartAt}
               startAt={startAt}
               isDailyScheduler={isDailyScheduler}/>
      {measurementAmount && <MeasurementAmount
        schedulerInterval={schedulerInterval}
        measurementAmount={measurementAmount}
        startAt={startAt}
        isDailyScheduler={isDailyScheduler}/>}
    </div>
  );
}

interface StartAtProps {
  startAt: Date;
  handleChange: React.Dispatch<React.SetStateAction<Date>>;
  isDailyScheduler: boolean;
}

const StartAt: React.FC<StartAtProps> = ({ startAt, handleChange, isDailyScheduler }) => {
  const inputAttributes = {
    name: 'protocol_subscription_start_at',
    date: startAt,
    disabled: false,
    onChange: handleChange,
    t: I18n.t
  };

  let startAtInput;
  if (isDailyScheduler) {
    startAtInput = <DateSelect {...inputAttributes} />
  } else {
    startAtInput = <DateTimeSelect {...inputAttributes} />
  }

  return (
    <div className="control-group datetime">
      <label className="datetime control-label"
             htmlFor="protocol_subscription_start_at">
        <Translate content="activerecord.attributes.protocol_subscription.start_at"/>
      </label>
      <div className="controls">{startAtInput}</div>
    </div>
  );
}

interface MeasurementAmountProps {
  schedulerInterval: number;
  startAt: Date;
  measurementAmount?: number;
  isDailyScheduler: boolean;
}

const MeasurementAmount: React.FC<MeasurementAmountProps> = ({
                                                               schedulerInterval,
                                                               startAt,
                                                               measurementAmount: measurementAmountDefault,
                                                               isDailyScheduler
                                                             }) => {
  const [measurementAmount, setMeasurementAmount] = React.useState(measurementAmountDefault);
  const updateMeasurementAmount = (e) => {
    const rawMeasurementAmount = e.target.value;
    const measurementAmount = parseInt(rawMeasurementAmount, 10);
    // not allowing 0 is by design
    if (!isNaN(measurementAmount) && isFinite(measurementAmount) && measurementAmount > 0) {
      setMeasurementAmount(measurementAmount);
    } else {
      setMeasurementAmount(undefined);
    }
  }
  const resetInCaseOfUndefined = () => {
    if (measurementAmount == undefined) {
      // reset to default
      setMeasurementAmount(measurementAmountDefault);
    }
  };
  let controlLabelText;
  if (isDailyScheduler) {
    controlLabelText = I18n.t("protocol_subscription.measured_days_amount");
  } else {
    controlLabelText = I18n.t("protocol_subscription.measurement_amount");
  }

  const renderLastPreparationDate = () => {
    if (measurementAmount && measurementAmount >= 1) {
      // convert to days to account for DST
      const daysPerInterval = schedulerInterval / (24 * 3600.0);
      const lastDay = add(startAt, {days: daysPerInterval * (measurementAmount - 1)});
      const formattedLastPreparationDate = format(lastDay, 'dd-MM-yyyy');
      return (
        <Translate
          content="protocol_subscription.last_measurement_on"
          date={formattedLastPreparationDate}/>
      );
    } else {
      return (
        <span style={{ color: 'red' }}>
          <Translate content="protocol_subscription.measurement_amount_required" measurement_type={controlLabelText}/>
        </span>
      );
    }
  }

  return (
    <div className="control-group text-and-input">
      <label className="datetime control-label" htmlFor="protocol_subscription[measurement_amount]">
        {controlLabelText}
      </label>
      <div className="controls">
        <input className="string span1 optional"
               name="protocol_subscription[measurement_amount]"
               type="number"
               pattern="\d+"
               value={measurementAmount || ""} // not allowing 0 is by design
               onChange={updateMeasurementAmount}
               onBlur={resetInCaseOfUndefined}
        />
        <span className="text">{renderLastPreparationDate()}</span>
      </div>
    </div>
  );
}

export default withRailsMountable(RunningTime);