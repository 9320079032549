import React from "react";

interface Props {
  icon: String;
}

export const TimelineRowIconSmall: React.FC<Props> = ({ icon }) => {
  return (
    <span className="timeline-row-icon-container timeline-row-icon-small">
      <i className={`icon roqicon-${icon}`} />
    </span>
  )
}
