import { BeepLimit, Design, TimeOffset } from "./Schema";
import { useState, useEffect } from "react";
import { BEEP_LIMITS, SEMI_RANDOM } from "./Constants";
import { addDays } from "date-fns";

// Wrapper object for wrapping variables and functions used to determine
// the measurement schedule for the diary study.
// Only one MeasurementSchedule exists, and it is constructed in the
// CreateNewDiary component.
// It is used in the `SelectSettings` component to pass the entire measurement
// schedule as a single object down to the `SchedulerPreview` component.
// `fixedPreferred` denotes if the current subgoal selection
// prefers a fixed or design or not.
export interface MeasurementSchedule {
  design: Design;
  setDesign: (design: Design) => void;
  startTime: TimeOffset;
  setStartTime: (time: TimeOffset) => void;
  startDate: Date;
  setStartDate: (date: Date) => void;
  endTime: TimeOffset | undefined;
  beepLimits: BeepLimit | undefined;
  endDate: Date;
  setEndDate: (date: Date) => void;
  measurementsPerDay: number;
  setMeasurementsPerDay: (measNr: number) => void;
  diaryName: string;
  setDiaryName: (name: string) => void;
}

export const DEFAULT_MORNING_START_TIME: TimeOffset = 7 * 3600 + 30 * 60; // 07:30
export const DEFAULT_EVENING_START_TIME: TimeOffset = 17 * 3600 + 30 * 60; // 17:30

interface Props {
  design: Design | undefined;
  measurementAmount: number;
  blocksPerDay: number;
  dailyStartTime: number;
}

export const useMeasurementSchedule = (props: Props): MeasurementSchedule => {
  // Whether the user has selected a fixed, semi-random, or one time per day design for the diary study.
  // A user chooses one of these designs for their diary study after first selecting a goal and subgoal.
  // Some subgoals may only allow for a specific design, in which case selecting the other option is not
  // available to the user.
  // Note that this variable expresses the selection of the user, and is not to be confused
  // with the `preferredDesigns` setting. The `preferredDesigns` settings merely indicate
  // what we think are good designs, but the user may opt for another other design if
  // it is allowed  by the design specifications defined by the subgoal in `GOAL_DEFINITIONS`.
  const [design, setDesign] = useState<Design>(props.design ?? SEMI_RANDOM);

  // The start time of the diary study. This is when the first measurement on each
  // day may arrive.
  const [startTime, setStartTime] = useState<TimeOffset>(props.dailyStartTime);

  // The start date of the diary study. We force a minimum of tomorrow, so that
  // users cannot set diary studies to start today.
  const initialStartDate: Date = addDays(new Date(), 1);
  const [startDate, setStartDate] = useState<Date>(initialStartDate);

  // The end date of the diary study. Some diary study designs enforce a minimum
  // number of days for the associated diary study protocol. For these cases, we have
  // useEffects in place (in the `SelectSettings` component) to ensure that the
  // duration between startDate and endDate is always valid for the selected
  // diary study design. These same limits are enforced by the datepicker component
  // that is used to set the end date.
  const [endDate, setEndDate] = useState<Date>(addDays(initialStartDate, props.measurementAmount));

  // The number of measurements per day. See the `BEEP_LIMITS` constant in Constants.tsx
  // for the list of diary study designs that expresses the possible values for this variable.
  const [measurementsPerDay, setMeasurementsPerDay] = useState<number>(props.blocksPerDay);

  // The name of the diary.
  const [diaryName, setDiaryName] = useState<string>("");

  // Calculate the BeepLimits settings (i.e., minimal study duration, total block
  // duration, single block duration, minimal time between blocks and measurement expire time)
  // for the current diary study settings (e.g., fixed or semi-random, and the number of
  // measurements per day). It can be undefined because we initialize
  // `measurementsPerDay` as 0, which is not a valid setting in any design. And we don't
  // set a valid setting until we have chosen a subGoal (which defines a preferred amount
  // of measurements per day). When first selecting a subGoal, the measurementsPerDay is
  // set to the preferred setting for that subGoal (this is not done here because it's not
  // a clean look to have the measurement schedule dependent on the subGoal), after which
  // the user can change it to their preference.
  const beepLimits = BEEP_LIMITS[design].filter((beepLimit) => beepLimit.numberOfBeepsPerDay === measurementsPerDay)[0];

  // The end time of the diary study. This is when the last measurement on each day
  // may arrive. The end time is not configurable by the user, only the start time is.
  // The end time is calculated by adding the `totalBlockduration` for the selected
  // design to the `startTime`. The endTime is undefined if the beepLimits is undefined
  // (see explanation above).
  let endTime;
  if (beepLimits) {
    // If we have beepLimits, the `endTime` is defined.
    endTime = startTime + beepLimits.totalBlockDuration;
  }

  useEffect(() => {
    if (beepLimits) {
      // If we have beeplimits, we know the minimum duration in days for the study
      // and can ensure that the `endDate` does not break this rule.
      const minimumDate = addDays(startDate, beepLimits.minimalStudyDurationInDays);
      if (endDate < minimumDate) {
        setEndDate(minimumDate);
      }
    }
  }, [beepLimits, startDate, endDate]);

  return {
    design: design,
    setDesign: setDesign,
    startTime: startTime,
    setStartTime: setStartTime,
    startDate: startDate,
    setStartDate: setStartDate,
    endTime: endTime,
    beepLimits: beepLimits,
    endDate: endDate,
    setEndDate: setEndDate,
    measurementsPerDay: measurementsPerDay,
    setMeasurementsPerDay: setMeasurementsPerDay,
    diaryName: diaryName,
    setDiaryName: setDiaryName,
  };
};
