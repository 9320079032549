import Highcharts from "highcharts";
import more from "highcharts/highcharts-more";
import annotations from "highcharts/modules/annotations";
import exporting from "highcharts/modules/exporting";
import offline from "highcharts/modules/offline-exporting";

// This is a horrible API style, but we have to deal with it.
more(Highcharts);
annotations(Highcharts);
exporting(Highcharts);
offline(Highcharts);

Highcharts.setOptions({
  lang: {
    loading: "Laden...",
    months: ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"],
    shortMonths: ["jan", "feb", "mrt", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"],
    weekdays: ["Zondag", "Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag"],
    decimalPoint: ",",
    resetZoom: "Reset zoom",
    resetZoomTitle: "Reset zoom level 1:1",
    thousandsSep: "",
    // downloadButton: "Klik hier om de grafiek als plaatje op te slaan.",
  },
  time: {
    // when useUTC is true highcharts renders axis tickmarks in utc time
    // when false axis tickmarks use the browser's local time
    useUTC: false,
  },
  plotOptions: {
    series: {
      animation: false,
    },
  },
  chart: {
    animation: false,
  },
});

export { Highcharts };
