import { Icon } from "components/ui/icon";
import * as React from "react";
import { AdministrativeGenderEnum, CurrentDossierInfoQuery } from "../../grapqhl";
import { DossierDetail } from "./DossierDetail";
import { EditButton } from "./EditButton";
import { RefreshButton } from "./RefreshButton";

function getGenderString(gender: AdministrativeGenderEnum | null | undefined) {
  if (gender === AdministrativeGenderEnum.Male) {
    return I18n.t("patient.gender.male");
  } else if (gender === AdministrativeGenderEnum.Female) {
    return I18n.t("patient.gender.female");
  } else {
    return null;
  }
}

interface Props {
  dossier: CurrentDossierInfoQuery["dossier"];
  epdNextUrl: string;
}

export const DossierInfo: React.FunctionComponent<Props> = ({ dossier, epdNextUrl }) => {
  if (!dossier) {
    return null;
  }

  const person = dossier.patientRespondent;
  const name = person.formalName === dossier.epdId ? "(geen naam bekend)" : person.formalName;

  return (
    <div id="patient-details">
      <div className="patient-details-icon">
        <Icon name="user" color="white" />
      </div>

      <div className="patient-details-details">
        <DossierDetail>
          <span className="patient-name">{name}</span>
        </DossierDetail>
        <div style={{ display: "flex", gap: "0.25rem" }}>
          <DossierDetail label={I18n.t("activerecord.attributes.dossier.epd_id_short")}>{dossier.epdId}</DossierDetail>
          {" | "}
          <DossierDetail label={I18n.t("activerecord.attributes.dossier.birthdate_short")}>
            {person.birthdate ? I18n.l("date.formats.dashed", person.birthdate) : I18n.t("patient.details.unknown")}
          </DossierDetail>
        </div>
      </div>

      <div className="patient-details-buttons">
        <EditButton epdNextUrl={epdNextUrl} />
        <RefreshButton dossier={dossier} />
      </div>

      <DeceasedLabel deceased={person.deceased} />
    </div>
  );
};

const DeceasedLabel: React.FunctionComponent<{ deceased: boolean }> = ({ deceased }) => {
  if (deceased) {
    return (
      <DossierDetail>
        <span className="deceased">{I18n.t("activerecord.attributes.dossier.deceased")}</span>
      </DossierDetail>
    );
  } else {
    return null;
  }
};
