import { ExecutionResult, QueryResult } from "@apollo/react-common";
import { Loader } from "components/ui/loader";
import { Maybe, UserError } from "grapqhl";
import { ShouldNotHappenError } from "lib/errors";
import React from "react";

export function assumeSuccess<TData>(results: QueryResult<TData>, callback: (data: TData) => React.ReactElement | null) {
  if (results.loading) {
    return <Loader />;
  }

  if (results.error) {
    throw new ShouldNotHappenError(results.error.message);
  }

  if (!results.data) {
    if (results.networkStatus) {
      throw new ShouldNotHappenError(`No error, not loading, but no data. This should not happen.`);
    } else {
      // This happens when a query has a `skip` option that is true.
      return null;
    }
  }

  return callback(results.data);
}

// Running out of names, sorry.
interface StandardizedMutationResultResult {
  result: Maybe<any>;
  errors?: Array<UserError>;
}

interface StandardizedMutationResult {
  mutation: Maybe<StandardizedMutationResultResult>;
}

// It would be great if this function could also unwrap the "result" nesting here, but I haven't found a way.
// The problem is that TData[TKey] is
export async function assumeMutated<TData extends StandardizedMutationResult>(resultPromise: Promise<ExecutionResult<TData>>): Promise<NonNullable<NonNullable<TData["mutation"]>["result"]>> {
  const results = await resultPromise;
  if (results.errors) {
    throw new ShouldNotHappenError(results.errors.map(e => e.message).join(". "));
  }

  if (!results.data) {
      throw new ShouldNotHappenError(`No error, but no data. This should not happen.`);
  }

  const mutationResult = results.data["mutation"];

  if (!mutationResult) {
    throw new ShouldNotHappenError("Mutation returned no data");
  }

  if (mutationResult["errors"] !== undefined && mutationResult["errors"].length > 0) {
    throw new ShouldNotHappenError(mutationResult["errors"].map(e => e.message).join(". "));
  }

  if (!mutationResult["result"]) {
    throw new ShouldNotHappenError("Mutation returned no results");
  } else {
    // For the compiler, mutationResult is a TData[TKey] here, which isn't known to be nullable, so the
    // compiler doesn't (or can't) automatically strip the nullability off here, as of Typescript 4.4.
    //
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return mutationResult["result"];
  }
}
