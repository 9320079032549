import React from "react";
import { PlotVariable } from "../common/Graphs";

interface Props {
  selectedVariables: Selections<string>;
  variable: PlotVariable;
  singleSelect: boolean;
  name: string;
}

// Render a checkbox or radio for a variable. Allow its state to be toggled
// within the context of the given Selections<string> collection.
// This component can render either as a checkbox or as a radio,
// based on the `singleSelect` property.
const VariableSelector: React.FunctionComponent<Props> = ({ selectedVariables, variable, singleSelect, name }) => {
  const isSelected = selectedVariables.isSelected(variable.key);

  const toggleItem = () => {
    selectedVariables.toggle(variable.key);
  };

  const selectItem = () => {
    // No toggling
    selectedVariables.setSelected([variable.key]);
  };

  const renderCheckbox = () => {
    return (
      <>
        <input type="checkbox" checked={isSelected} id={`checkbox-${name}-${variable.key}`} onChange={toggleItem} />
        <label className="checkbox white smaller" htmlFor={`checkbox-${name}-${variable.key}`}>
          {variable.shortDescription}
        </label>
      </>
    );
  };

  const renderRadio = () => {
    return (
      <>
        <input
          type="radio"
          checked={isSelected}
          name={`radio-${name}`}
          id={`radio-${name}-${variable.key}`}
          onChange={selectItem}
        />
        <label className="radio white smaller" htmlFor={`radio-${name}-${variable.key}`}>
          {variable.shortDescription}
        </label>
      </>
    );
  };

  if (singleSelect) return renderRadio();
  return renderCheckbox();
};

export default VariableSelector;
