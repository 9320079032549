import React, { useMemo, useState } from "react";
import { useReportTemplatesQuery, useResearchParticipationQuery, useResponsesQuery } from "grapqhl";
import { Loader } from "components/ui/loader";
import { createResponsesWithCache } from "logic/ResponseWithCache";
import { useNavigate, useParams } from "react-router-dom";
import Graph from "./Graph";
import { Questions, Responses, ResultResponseWithCache, ResultQuestionnaire } from "../common/Graphs";
import { addTopDomains, categorizePlottableAnswers, FlagObject, substituteTextvars, typedFlags } from "../common/Flags";
import { formatDatesInterval, formatDatesNumberOfDays } from "../common/Time";
import { COMPLAINTS, determineDesign, GOAL_DEFINITIONS, LABEL_FIELDS, REPORT_TEMPLATE_NAME } from "../common/Constants";
import Lightbox from "../../Lightbox";
import Video from "../common/Video";
import ResultsSlideOver from "./ResultsSlideOver";
import ResultsSummary from "./Summary/ResultsSummary";
import { Step } from "react-joyride";
import MyJoyride from "../common/MyJoyride";
import Button from "../common/Button";
import { useReport } from "./useReport";
import ReportSlideOver from "./ReportSlideOver";

interface Props {
  researchProjectId: number;
}

// Format goal, subgoal, and complaint on one line. Support the case
// where the settings object was empty.
const formatGoals = (settings) => {
  let result: string[] = [];
  if (settings.complaint) {
    result.push(COMPLAINTS[settings.complaint].title);
  }
  if (settings.goal) {
    result.push(GOAL_DEFINITIONS[settings.goal].title);
  }
  if (settings.subGoal) {
    result.push(GOAL_DEFINITIONS[settings.subGoal].title);
  }
  if (result.length === 0) {
    return "onbekend";
  }
  return result.join(" - ");
};

// Show the basic results page for a research participation
const Results: React.FunctionComponent<Props> = (_props) => {
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [slideOverOpen, setSlideOverOpen] = useState<boolean>(false);
  const [reportOpen, setReportOpen] = useState<boolean>(false);
  const [highlightLastGraph, setHighlightLastGraph] = useState<boolean>(false);
  const [contextDate, setContextDate] = useState<Date | null>(null);
  const { addToReport, renderReport, renderStaticReport } = useReport();
  const navigate = useNavigate();
  const { id: researchParticipationId } = useParams();

  if (researchParticipationId === undefined) throw "Results misses id";

  const participationQuery = useResearchParticipationQuery({
    variables: {
      id: researchParticipationId,
    },
  });

  const researchParticipation = participationQuery.data?.currentDossier?.researchParticipation;
  const currentDossierId = participationQuery.data?.currentDossier?.id;
  const protSub = researchParticipation?.protocolSubscriptions[0];
  const questionnaireId = protSub?.protocol?.measurements[0]?.questionnaires[0]?.id;

  // The skip is needed to force that we only want to run this query
  // once we have the results from running the other query (in particular,
  // the questionnaire ID).
  const responsesQuery = useResponsesQuery({
    variables: {
      questionnaireIds: [questionnaireId || ""],
      limit: 200,
      researchParticipationId: researchParticipationId,
    },
    skip: !questionnaireId,
  });

  // Get a list of IDs and names of available report templates so we can find
  // the ID of a PETRA report template for which we know the name.
  const reportTemplatesQuery = useReportTemplatesQuery();

  const questionnaire: ResultQuestionnaire | undefined = protSub?.protocol.measurements[0].questionnaires[0];
  const responses: Responses | undefined = responsesQuery?.data?.currentDossier?.responses;

  const responsesWithCache = useMemo<ResultResponseWithCache[] | null>(() => {
    if (!questionnaire || !responses) return null;
    return createResponsesWithCache(responses, questionnaire);
  }, [questionnaire, responses]); // ref stays the same between renders, only interested in change  from null to something.

  if (responsesQuery.loading || participationQuery.loading || !responses || !responsesWithCache) {
    return <Loader />;
  }
  if (!participationQuery.data || !participationQuery.data.currentDossier || !currentDossierId) {
    return <div>{JSON.stringify(participationQuery.error)}</div>;
  }
  if (!responsesQuery.data || !responsesQuery.data.currentDossier) {
    return <div>{JSON.stringify(responsesQuery.error)}</div>;
  }
  if (!researchParticipation) {
    return <div>Error: Research participation with id {researchParticipationId} not found</div>;
  }
  if (!questionnaire) {
    return <div>Error: No questionnaire found</div>;
  }
  if (!protSub) {
    return <div>Error: No protocol subscription found</div>;
  }
  const protocol = protSub.protocol;
  const design = determineDesign(protocol);
  if (!design) {
    // This will never run because we have a PETRA questionnaire. But in `findDesign`, we use
    // a .find which can return undefined, so after this if-statement we always have a `design`.
    return <div>Error: No design found</div>;
  }
  if (
    protSub.schedulerSettingsOverrides?.__typename !== "RandomSchedulerSettings" ||
    protSub.schedulerSettingsOverrides.blocksPerDay === null
  ) {
    return <div>The scheduler for this protocol is not the random scheduler or blocksPerDay was not defined.</div>;
  }
  const { blocksPerDay } = protSub.schedulerSettingsOverrides;
  const questions: Questions = questionnaire.questions;
  const typedAllFlags: FlagObject[] = typedFlags(protSub.protocol.measurements[0].questionnaires[0].flags);
  const someResponsesFilledOut = responses.some((response) => response.completed);

  // Variables for the report
  let reportTemplateId: null | string = null;
  const reportTemplates = reportTemplatesQuery.data?.currentOrganization?.reportTemplates;
  if (!reportTemplatesQuery.loading && reportTemplates !== undefined) {
    // We allow the reportTemplateId to be null because we don't want to break the entire
    // results page if the PETRA report template is missing (we want to break only the
    // functionality of creating a report in that case).
    reportTemplateId =
      reportTemplates.find((reportTemplate) => reportTemplate.name === REPORT_TEMPLATE_NAME)?.id ?? null;
  }
  const responseIds = responses.filter((response) => response.completedAt).map((response) => response.id);

  // Add top domain for each flag.
  addTopDomains(typedAllFlags);
  // Determine the variables to show in the graphs.
  // Note that we read the available questions directly from the questionnaire definition,
  // where we use the title as the "long description" and the context_free_title as the "short description"
  // in the graph. We only show items that are not complex values (i.e., they don't contain "{") and have at
  // least one non-null value in the given responses. We show them categorized by their "top domain," which
  // is their highest ancestor. Since flags can have multiple ancestors, they can appear under multiple
  // top domains. If this is the case, their state is kept in sync.
  let variables = categorizePlottableAnswers(typedAllFlags, questions, responses);

  // Replace {{eigen_vraag1}} etc. in the long descriptions.
  variables = substituteTextvars(variables, protSub.textvars, questionnaire.key);

  // Open the slideover to a specific date
  const openToDate = (date: Date) => {
    setContextDate(date);
    setSlideOverOpen(true);
  };

  // Add to report and open the results slideover
  const addToAndOpenReport = (elemRef: React.RefObject<HTMLDivElement>): void => {
    addToReport(elemRef);
    setHighlightLastGraph(true);
    setReportOpen(true);
  };

  // Renders the main overview page of Petra.
  // On this page, users can either click on the button to start a new diary study,
  // stop one of the ongoing diary studies from the list of diary studies,
  // or view the feedback for one of the completed diary studies.
  return (
    <div className="petra">
      <MyJoyride steps={joyrideSteps} id="petra-results" />
      <ReportSlideOver
        isOpen={reportOpen}
        setOpen={setReportOpen}
        renderReport={renderReport}
        renderStaticReport={renderStaticReport}
        highlightLastGraph={highlightLastGraph}
        reportTemplateId={reportTemplateId}
        dossierId={currentDossierId}
        researchParticipationId={researchParticipationId}
        responseIds={responseIds}
        design={design}
      />
      <ResultsSlideOver
        isOpen={slideOverOpen}
        setOpen={setSlideOverOpen}
        responsesWithCache={responsesWithCache}
        openToDate={contextDate}
      />
      <div className="main-only results">
        <div className="petra-main">
          <Lightbox show={showVideo} onCloseClicked={() => setShowVideo(false)}>
            <div className="lightbox-background">
              <div className="colored-header">
                <div className="header-close-button" onClick={() => setShowVideo(false)} />
                <h2>Demo - resultaten</h2>
              </div>
              <div className="video-header">Resultaten (anderhalve minuut)</div>
              <Video width="640" height="360" name="PETRA_Feedbackmodule" />
            </div>
          </Lightbox>
          <div className="colored-header">
            <h2>
              Dagboek ‘{researchParticipation.name}’, {formatDatesInterval(protSub.startAt, protSub.stopAt)}{" "}
              {formatDatesNumberOfDays(protSub.startAt, protSub.stopAt)}
            </h2>
          </div>
          <div className="petra-content">
            <div className="navigation">
              <h3>Gekozen doel: {formatGoals(researchParticipation.settings)}</h3>
            </div>
            {!someResponsesFilledOut && (
              <div className="attention-well">
                <h3>Nog geen resultaten om te bekijken</h3>
                <p>
                  We willen je graag een paar grafieken laten zien, maar we hebben voor dit dagboek nog geen antwoorden
                  gevonden. Als het dagboek een keer ingevuld is kunnen we je resultaten laten zien.
                </p>
                <div className="video-header">Wat mag je van de resultaten verwachten? (anderhalve minuut)</div>
                <Video width="399" height="224" name="PETRA_Feedbackmodule" />
                <div className="navigation">
                  <Button label="Terug naar overzicht" onClick={() => navigate(-1)} />
                </div>
              </div>
            )}
            {someResponsesFilledOut && (
              <>
                <ResultsSummary responsesWithCache={responsesWithCache} />
                <div className="petra-field">
                  <h3>Resultaten per onderwerp</h3>
                  <div className="row-fluid limited-row">
                    <div className="span6">
                      <p className="limited-width">
                        De feedback is verdeeld over onderwerpen. Bij elk onderwerp zie je een grafiek over de relevante
                        dagboekvragen. Wil je een grafiek opslaan? Klik dan op ‘voeg toe aan rapport’ onder de grafiek.
                        Je voegt dan een plaatje van de grafiek (eventueel met notitie) toe aan een rapport.
                      </p>
                    </div>
                    <div className="span6">
                      <p className="limited-width">
                        Als je klaar bent kan je het rapport opslaan als pdf en uitgeprint meegegeven. Wil je weten wat
                        je met deze gegevens kunt?{" "}
                        <span className="petra-link" onClick={() => setShowVideo(true)}>
                          Bekijk dan een demo-film
                        </span>{" "}
                        van ongeveer anderhalve minuut over onze resultaten.
                      </p>
                    </div>
                  </div>
                </div>
                <Graph
                  title="Stemming en klachten"
                  responsesWithCache={responsesWithCache}
                  addToReport={addToAndOpenReport}
                  tabConfigurations={[
                    {
                      type: "lineplot",
                      label: "Tijdlijn",
                      title: "Beloop van stemming en klachten",
                      variables: variables,
                      moreInformationKey: "stemming",
                    },
                    {
                      type: "weekly-patterns",
                      label: "Weekpatronen",
                      title: "Gemiddelden en uitersten per dag van de week",
                      variables: variables,
                      moreInformationKey: "stemming-daily-and-weekly",
                    },
                    {
                      type: "daily-patterns",
                      label: "Dagpatronen",
                      title: "Gemiddelden en uitersten per moment op de dag",
                      variables: variables,
                      moreInformationKey: "stemming-daily-and-weekly",
                    },
                  ]}
                  openToDate={openToDate}
                  blocksPerDay={blocksPerDay}
                  design={design}
                />
                {design && (
                  <Graph
                    title="Situatie"
                    responsesWithCache={responsesWithCache}
                    addToReport={addToAndOpenReport}
                    initiallyExpanded={false}
                    tabConfigurations={[
                      {
                        type: "situationplot",
                        label: "Activiteit",
                        title: "tijdens activiteiten",
                        labelField: LABEL_FIELDS[design].activiteit,
                        variables: variables,
                        moreInformationKey: "situatie-activiteit",
                        alwaysShown: true,
                      },
                      {
                        type: "situationplot",
                        label: "Gezelschap",
                        title: "tijdens gezelschap",
                        labelField: LABEL_FIELDS[design].gezelschap,
                        variables: variables,
                        moreInformationKey: "situatie-gezelschap",
                        alwaysShown: true,
                      },
                      {
                        type: "situationplot",
                        label: "Locatie",
                        title: "tijdens locatie",
                        labelField: LABEL_FIELDS[design].locatie,
                        variables: variables,
                        moreInformationKey: "situatie-locatie",
                        alwaysShown: true,
                      },
                      {
                        type: "situationplot",
                        label: "Eten",
                        title: "tijdens eten",
                        labelField: LABEL_FIELDS[design].eten,
                        variables: variables,
                        moreInformationKey: "situatie-eten",
                      },
                      {
                        type: "situationplot",
                        label: "Middelen",
                        title: "tijdens middelen",
                        labelField: LABEL_FIELDS[design].middelen,
                        variables: variables,
                        moreInformationKey: "situatie-middelen",
                      },
                      {
                        type: "situationplot",
                        label: "Lichamelijke klachten",
                        title: "tijdens lichamelijke klachten",
                        labelField: LABEL_FIELDS[design].lichamelijkeKlachten,
                        variables: variables,
                        moreInformationKey: "situatie-lichamelijke-klachten",
                      },
                      {
                        type: "situationplot",
                        label: "Coping met stemmen",
                        title: "tijdens coping met stemmen",
                        labelField: LABEL_FIELDS[design].copingMetStemmen,
                        variables: variables,
                        moreInformationKey: "situatie-coping-met-stemmen",
                      },
                      {
                        type: "situationplot",
                        label: "Bijwerkingen",
                        title: "tijdens bijwerkingen",
                        labelField: LABEL_FIELDS[design].bijwerkingen,
                        variables: variables,
                        moreInformationKey: "situatie-bijwerkingen",
                      },
                      {
                        type: "situationplot",
                        label: "Met wie ben ik",
                        title: "tijdens met wie ben ik",
                        labelField: LABEL_FIELDS[design].metWieBenIk,
                        variables: variables,
                        moreInformationKey: "situatie-met-wie-ben-ik",
                      },
                      {
                        type: "situationplot",
                        label: "Gesprekken",
                        title: "tijdens gesprekken",
                        labelField: LABEL_FIELDS[design].gesprekken,
                        variables: variables,
                        moreInformationKey: "situatie-gesprekken",
                      },
                    ]}
                    openToDate={openToDate}
                    blocksPerDay={blocksPerDay}
                    design={design}
                  />
                )}
                <Graph
                  title="Gebeurtenissen en vrije invoer"
                  responsesWithCache={responsesWithCache}
                  addToReport={addToAndOpenReport}
                  initiallyExpanded={false}
                  tabConfigurations={[
                    {
                      type: "wordcloud",
                      label: "Vrije invoer",
                      title: "De meestgebruikte woorden bij de vrije opmerkingen",
                      variables: [],
                      moreInformationKey: "wordcloud",
                    },
                  ]}
                  openToDate={openToDate}
                  blocksPerDay={blocksPerDay}
                  design={design}
                />
                <Button
                  label="Toon rapport"
                  className="float-right further-down"
                  isDefault
                  onClick={() => {
                    setHighlightLastGraph(false);
                    setReportOpen(true);
                  }}
                />
                {/* The clear is needed because the show report button sits on its own row as a float,
                    and is otherwise not included in height calculations and then can fall off the page. */}
                <div style={{ clear: "both" }} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const joyrideSteps: Array<Step> = [
  {
    target: ".petra-wells",
    content: (
      <div className="petra">
        <h2>Een dagboek in beeld (1/3)</h2>
        <p>Het dagboek is (gedeeltelijk) ingevuld. En nu? Deze pagina bestaat uit een aantal gedeeltes.</p>
        <p>
          Boven in beeld staan een aantal cijfers die de afgelopen periode samenvatten. Belangrijk: hoe vaak is het
          dagboek eigenlijk ingevuld?
        </p>
      </div>
    ),
  },
  {
    target: ".petra-graph",
    content: (
      <div className="petra">
        <h2>Samenvatting per thema (2/3)</h2>
        <p>
          Elk groene kader staat voor een thema. Het eerste kader focust bijvoorbeeld op stemming en klachten. Klik op
          een kader voor meer informatie.
        </p>
      </div>
    ),
    placement: "top",
    isFixed: true,
  },
  {
    target: ".petra-tab-graph",
    content: (
      <div className="petra">
        <h2>Grafieken: hoe werkt dat? (3/3)</h2>
        <p>
          Boven een grafiek staan tabjes voor andere weergaves. Rechts staan items die je kunt weergeven in de grafiek.
        </p>
        <p>Onder de grafiek staan de legenda en meer uitleg.</p>
        <p>Je kan klikken op de punten van de grafiek voor gedetailleerde informatie.</p>
      </div>
    ),
    placement: "top",
  },
];

export default Results;
