import React, { useState } from "react";
import { TextvarObject, TextvarsSettings } from "../../common/Flags";

interface Props {
  textvar: TextvarObject;
  textvarsSettings: TextvarsSettings;
  flagDependsOn: string[];
  isSelected: boolean;
  toggleFlag: () => void;
}

const Textvar: React.FunctionComponent<Props> = ({
  textvar,
  textvarsSettings,
  flagDependsOn,
  isSelected,
  toggleFlag,
}) => {
  // The placeholder is used as a placeholder only. The prefix is part of the stored textvar.
  const questionFormats: QuestionFormat[] = [{ prefix: "Op dit moment", placeholder: "voel ik me..." }];
  // Add a special format for questions that are asked only once per day
  if (flagDependsOn.includes(LAST_MEASUREMENT_OF_DAY_FLAG)) {
    questionFormats.push({ prefix: "Vandaag", placeholder: "heb ik..." });
  } else if (!flagDependsOn.includes(FIRST_MEASUREMENT_OF_DAY_FLAG)) {
    // For questions asked more than once per day (i.e., those that do not depend on the
    // first measurement of day flag nor on the last measurement of day flag, we are interested in
    // what happened since the previous measurement.
    questionFormats.push({ prefix: "Sinds het vorige meetmoment", placeholder: "heb ik..." });
  }
  // Used to build regex of the possible prefixes
  const PREFIX_OPTIONS_STRING = questionFormats.map((questionFormat) => questionFormat.prefix).join("|");

  // Initialize this value to be the prefix of the given textvar.
  // If the given textvar is empty, initialize this value to the default (first) prefix
  const [selectValue, setSelectValue] = useState<string>(
    textvarsSettings.textvarsValues[textvar.key]?.replace(new RegExp(`^(${PREFIX_OPTIONS_STRING}) .*$`), "$1") ||
      questionFormats[0].prefix
  );
  // Initialize this value to the contents of the textvar except the prefix. Because in the UI,
  // the prefix is part of a dropdown, so we cut it off here.
  // This value defaults to an empty value if the given textvar is empty.
  const [inputText, setInputText] = useState<string>(
    textvarsSettings.textvarsValues[textvar.key]?.replace(new RegExp(`^(${PREFIX_OPTIONS_STRING}) `), "") || ""
  );

  const changeSelectValue = (e) => {
    setSelectValue(e.currentTarget.value);
    const newTextvarsValues = { ...textvarsSettings.textvarsValues };
    newTextvarsValues[textvar.key] = `${e.currentTarget.value} ${inputText}`;
    textvarsSettings.setTextvarsValues(newTextvarsValues);
  };

  const changeInputText = (e) => {
    const newValue = e.currentTarget.value;
    setInputText(newValue);
    const newTextvarsValues = { ...textvarsSettings.textvarsValues };
    newTextvarsValues[textvar.key] = `${selectValue} ${newValue}`;
    textvarsSettings.setTextvarsValues(newTextvarsValues);
    // select the flag if there is a value, otherwise deselect the flag.
    if (newValue && newValue.length > 0) {
      if (!isSelected) toggleFlag();
    } else {
      if (isSelected) toggleFlag();
    }
  };

  let placeholder: string | undefined = undefined;
  if (inputText.length === 0) {
    placeholder = questionFormats.find((questionFormat) => questionFormat.prefix === selectValue)?.placeholder;
  }

  return (
    <div className="textvar-select">
      <select onChange={changeSelectValue} value={selectValue}>
        {questionFormats.map((questionFormat) => (
          <option key={questionFormat.prefix} value={questionFormat.prefix}>
            {questionFormat.prefix} ...
          </option>
        ))}
      </select>
      <input type="text" value={inputText} onChange={changeInputText} placeholder={placeholder} />
    </div>
  );
};

interface QuestionFormat {
  prefix: string;
  placeholder: string;
}

const LAST_MEASUREMENT_OF_DAY_FLAG = "last_measurement_of_day";
const FIRST_MEASUREMENT_OF_DAY_FLAG = "first_measurement_of_day";

export default Textvar;
