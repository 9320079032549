import React from "react";
import { NonResponseGroup, Selection } from "lib/non_response/schema";

interface Props {
  group: NonResponseGroup;
  selection: Selection;
  onChange: (newselection: Selection) => void;
}

export const NonResponseSchemaGroupFieldset: React.FunctionComponent<Props> = ({ group, selection, onChange }) => {
  return (
    <fieldset>
      <legend>{group.label}</legend>

      {group.options.map((option) => (
        <OptionRadio key={option.code} option={option} checked={option == selection.option} onChange={onChange} />
      ))}

      {group.otherOption && <OtherOption option={group.otherOption} selection={selection} onChange={onChange} />}
    </fieldset>
  );
};

const OptionRadio = ({ option, checked, onChange }) => {
  const selectOption = () => onChange({ option: option, otherText: null });

  return (
    <div>
      <label className="radio">
        <input name="reason_option" className="non-response-reason" type="radio" value={option.code} checked={checked} onChange={selectOption} />
        <span dangerouslySetInnerHTML={{ __html: option.label }} />
      </label>
    </div>
  );
};

const OtherOption = ({ option, selection, onChange }) => {
  const [otherText, setOtherText] = React.useState<string>(selection.otherText);
  const selectOption = () => onChange({ option, otherText });
  const setReasonOther = (event) => {
    setOtherText(event.target.value);
    onChange({ option, otherText: event.target.value });
  };

  return (
    <div>
      <label className="radio">
        <input name="reason_option" className="non-response-reason" type="radio" value={option.code} checked={option === selection.option} onChange={selectOption} />
        <span dangerouslySetInnerHTML={{ __html: option.label }} />
      </label>
      <textarea name="reason_other" className="span12" rows={3} value={otherText || ""} onChange={setReasonOther} />
    </div>
  );
};
