import React from "react";
import Highcharts from "highcharts";
import { Timestamp, isMidnight } from "logic/date";
import { renderReactToString } from "./ReactToString";

function formatTimestamp(timestamp: Timestamp) {
  if (isMidnight(timestamp)) {
    return I18n.l("time.formats.date", timestamp);
  } else {
    return I18n.l("time.formats.long", timestamp);
  }
}

interface Props {
  series: Pick<Highcharts.Series, "name">;
  point: Pick<Highcharts.Point, "options">;
  color: string;
  x?: Date;
  y?: number;
}

export const GenericTooltip: React.FunctionComponent<Props> = (props) => {
  const timestamp = props.x ? formatTimestamp(props.x) : "";
  const label = props.point.options.label;
  const seriesName = props.series.name + ":";

  return (
    <div>
      <span style={{ fontSize: "10px" }}>{timestamp}</span>
      <br />
      <span style={{ fontSize: "10px" }}>{label}</span>
      <br />
      <span style={{ color: props.color }}>{seriesName}</span>
      <b>{props.y}</b>
      <br />
    </div>
  );
};

// Highcharts will rebind this to the chart instance, which means that `this` will point
// to an object containing all the settings needed to format a tooltip.
//
// NOTE: This needs to explicitly be *function()* and not an arrow. The arrow would
// prevent Highcharts from rebinding _this_ to the chart.
export function tooltipFormatter() {
  const props = Object.assign({}, this);
  const element = <GenericTooltip {...props} />;
  return renderReactToString(element);
}
