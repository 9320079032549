import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { DataTable } from "components/ui/datatable";
import { zipObject } from "lodash";
import { withRailsMountable } from "logic/rails_component";
import React from "react";

interface Props {
  headers: string[];
  rows: string[];
}

export const ManagementInformationTable: React.FC<Props> = (props) => {
  const rows = props.rows.map((row, idx) => ({ id: idx, ...zipObject(props.headers, row) }));

  const columns = props.headers.map((header) => ({
    accessorKey: header,
    header: header,
  }));

  return <DataTable data={rows} columns={columns} />;
};

export default withRailsMountable(ManagementInformationTable);
