import React, { useMemo } from "react";
import { Key } from "../../common/Selections";
import classNames from "classnames";
import {
  cleanupString,
  determineSelected,
  flagHasImpact,
  FlagInterface,
  flagLabels,
  textVarsForFlag,
  toggleFlag,
} from "../../common/Flags";
import Tooltip from "react-tooltip-lite";
import Textvar from "./Textvar";

interface Props {
  currentFlag: Key;
  flagExpansion: Selections<Key>;
  flags: FlagInterface;
  matching: boolean;
  query: string;
  currentlyMatching: boolean;
  setShowExplanation: (boolean) => void;
}

// Check if the flag description contains a tooltip listing.
// If so, split the string up into description and examples.
// Otherwise, return undefined.
const extractTooltipExamples = (str) => {
  const tipParts = str.match(/^([^\[\]]+) \[([^\[\]]+)]$/);
  if (!tipParts || tipParts.length < 3) return undefined;

  return { description: tipParts[1], examples: tipParts[2].split(", ") };
};

// A flag without children is rendered as a simple checkbox. If the current flag matches
// the search query, we add the `currently-matching` class, which in petra.sass is defined to
// show the flag description in boldface.
// props.matching is a boolean that is true iff any ancestor of this flag matches
// the search query. It is false for domains (= top-level flags).
// props.currentlyMatching is true if the current flag matches the search query.
const FlagWithoutChildren: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const currentFlag = useMemo(() => props.flags.flags.filter((flag) => flag.key === props.currentFlag)[0], [
    props.flags,
    props.currentFlag,
  ]);
  const isSelected = determineSelected(currentFlag, props.flags.flagsSelection, props.flags.flags);

  const renderFlagDescription = (str) => {
    const { description, examples } = extractTooltipExamples(str) || {};
    if (!description) return <>{cleanupString(str)}</>;

    return (
      <Tooltip
        padding={20}
        useDefaultStyles
        styles={{ display: "inline-block" }}
        content={
          <ul>
            {examples.map((option) => (
              <li key={option}>{option}</li>
            ))}
          </ul>
        }
      >
        <span className="has-petra-tooltip" onClick={(e) => e.preventDefault()}>
          {cleanupString(description)}
        </span>
      </Tooltip>
    );
  };

  const renderTextVars = () => {
    const renderedTextvars = textVarsForFlag(currentFlag, props.flags);
    if (renderedTextvars.length === 0) return <></>;
    return renderedTextvars.map((renderedTextvar) => (
      <Textvar
        key={renderedTextvar.key}
        textvar={renderedTextvar}
        textvarsSettings={props.flags.textvarsSettings}
        flagDependsOn={currentFlag.dependsOn}
        isSelected={isSelected}
        toggleFlag={() => toggleFlag(props.currentFlag, props.flags.flagsSelection, props.flags.flags)}
      />
    ));
  };

  const renderFlagLabels = () => {
    const labels: string[] = flagLabels(currentFlag);
    return labels.map((label) => (
      <span className={classNames("label petra-flag-label", label)} key={label}>
        {label}
      </span>
    ));
  };

  // If a flag has no impact, render it as a disabled checkbox.
  return (
    <div
      className={classNames({
        "default-in-interface-true": currentFlag.defaultInInterface,
        disabled: !flagHasImpact(currentFlag),
      })}
    >
      <input
        type="checkbox"
        checked={isSelected}
        onChange={() => toggleFlag(props.currentFlag, props.flags.flagsSelection, props.flags.flags)}
        id={props.currentFlag}
      />
      <label
        className={classNames("checkbox", props.currentlyMatching && "currently-matching")}
        htmlFor={props.currentFlag}
      >
        {renderFlagLabels()}
        {renderFlagDescription(currentFlag.description)}
      </label>
      {renderTextVars()}
    </div>
  );
};

export default FlagWithoutChildren;
