import classNames from "classnames";
import { Translate } from "components/Translate";
import { useRevokeTokenMutation } from "grapqhl";
import * as React from "react";

const confirm = (func: () => void, message: string) => {
  RoQua.showConfirmDialog({
    message: message,
    ok: (dialog) => {
      func();
      dialog.close();
    },
  });
};

export const TokenManager = ({ respondent }) => {
  // Put the existing token's key into state, so that we can reference it in a flash message
  // if it gets revoked.
  const [currentKey, setOldKey] = React.useState(respondent.token.key);

  const onCompleted = (data) => {
    const newToken = data.revokeToken.result.respondent.token;
    RoQua.showFlash({
      state: "success",
      message: I18n.t("epd_area.app.revoke_token_success", { old_code: currentKey, new_code: newToken.key }),
    });

    // Update the state for the next revocation call's flash message.
    setOldKey(newToken.key);

    // TODO: Remove when pending answers component migrates to Apollo
    const backboneToken = RoQua.tokens.models.find((t) => t.get("respondent_type") == respondent.type + "_version");
    if (backboneToken) {
      backboneToken.set({ id: newToken.id, key: newToken.key });
    }
  };

  const onError = () => {
    RoQua.showFlash({ state: "error", message: I18n.t("epd_area.app.revoke_token_already_done") });
  };

  const [revokeToken, { loading }] = useRevokeTokenMutation({
    variables: { tokenId: respondent.token.id },
    onCompleted: onCompleted,
    onError: onError,
  });
  const respondentType = I18n.t(respondent.type + "_version", { scope: "questionnaire.respondent_types" });
  const confirmMessage = I18n.t("epd_area.app.revoke_token_confirmation", { code_type: respondentType.toLowerCase() });

  return (
    <div className={classNames("row-fluid", respondent.type)}>
      <div className="span3 row-fluid">
        <div className="span6">{respondentType}</div>
        <div className="span6">{respondent.token.key}</div>
      </div>
      <div className="span9">
        <a href="#" onClick={() => confirm(revokeToken, confirmMessage)} className="revoke">
          <Translate content="epd_area.app.revoke_token" />
        </a>
      </div>
    </div>
  );
};
