import React from "react";
import { Translate } from "./Translate";

interface Callbacks {
  success: () => void;
}

interface Props {
  images: {
    label: string; // link name for image
    src: string;
  }[];
  interval: number;  // ms to show each image.
  startPlaying?: boolean;
}

export const CspDnaCarousel: React.FC<Props> = ({ images, interval, startPlaying = true }) => {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [auto, setAuto] = React.useState(startPlaying);

  const currentImage = images[currentIndex];

  let timeout;
  if (auto) {
    timeout = setTimeout(() => { setCurrentIndex((currentIndex + 1) % images.length) }, interval);
  }

  const selectSlide = (e: React.MouseEvent<HTMLButtonElement>, idx: number) => {
    e.preventDefault();
    clearTimeout(timeout);
    setAuto(false);
    setCurrentIndex(idx);
  }

  const next = (e: React.MouseEvent<HTMLButtonElement>) => {
    selectSlide(e, (currentIndex + 1) % images.length);
  }

  const previous = (e) => {
    selectSlide(e, currentIndex == 0 ? images.length - 1 : (currentIndex - 1) % images.length);
  }

  const toggleAuto = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (auto) clearTimeout(timeout);
    setAuto(!auto);
  }

  return (
    <div className="csp-dna-carousel">
      <img src={currentImage.src} style={{width: "100%" }} data-testid="image" />
      <div className="controls">
        <ul>
          <li key="prev"><button onClick={previous}>≺</button></li>
          { images.map(({label, src}, idx) => (
              <li key={idx} className={idx == currentIndex ? 'active' : ''}>
                <button onClick={(e) => selectSlide(e, idx)}>{label}</button>
              </li>
            ))
          }
          <li key="next"><button onClick={next}>≻</button></li>
          <li key="pause">
            <button onClick={toggleAuto}>{ auto ?  "⏸" : "▶️" }</button>
          </li>
        </ul>
      </div>
    </div>
  );
};
