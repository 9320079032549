import * as React from "react";
import classNames from "classnames";

interface Props {
  position: "left" | "right";
  onClick: () => void;
}

export const OverlayTab: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const className = classNames("overlay-tab", props.position)
  return <ul className={className}>
    <li><a href="#" onClick={props.onClick}>{props.children}</a></li>
  </ul>
}

