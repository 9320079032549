import React from "react";
import AceEditor from "react-ace";
import { Ace } from "ace-builds";
import { ReactAceEditor } from "./ReactAceEditor";

// allow ReactAceEditor to autocomplete nodes and classes e.g. node0.node1.node2.attr
import { configureTreeCompleter, TreeCompleterConfig } from "../logic/aceTreeCompleter";


type LegacyBlock = {id: string; type: "legacy"; template: string};
type LiquidBlock = {id: string; type: "liquid"; template: string};
type Block = LegacyBlock | LiquidBlock;

interface Props {
  content: string;
  inputName: string;
  treeCompleterConfig?: TreeCompleterConfig;
}

export const ReportTemplateContentEditor: React.FunctionComponent<Props> = (props) => {
  const [content, setContent] = React.useState(props.content);
  const reactEditor = React.useRef<AceEditor>(null);

  React.useEffect(() => {
    if (reactEditor.current && props.treeCompleterConfig) {
      const editor = reactEditor.current.editor as Ace.Editor;
      configureTreeCompleter(editor, props.treeCompleterConfig);
    }
  }, []);

  return (
    <div>
      <input name={props.inputName} type="hidden" value={content} />

      <ReactAceEditor
        sourcecode={content}
        lintWarnings={[]}
        onChange={setContent}
        editorRef={reactEditor}
        enableAutocompletion={true}
      />
    </div>
  )
}

export default ReportTemplateContentEditor;
