import React from "react";
import { getDescriptionForAnswer } from "logic/ResponseWithCache";
import { ResultResponseWithCache, SingleResponse } from "../common/Graphs";
import { LABEL_COLORS, LABEL_FIELDS, LABEL_TEXTS, MIN_SEARCH_QUERY_LENGTH } from "../common/Constants";
import { compact, minBy } from "lodash";
import classNames from "classnames";
import { localizedFormat } from "../../../logic/date";
import { Design } from "../common/Schema";
import { findDesign, freeTextFromResponse, queryMatchStringFor } from "../common/Results";
import { cleanupString } from "../common/Flags";

const formatDate = (date) => {
  return localizedFormat(new Date(date), "eeee dd-MM-yyyy - HH:mm");
};

const completedInDateRange = (response: SingleResponse, startDate: Date | null, endDate: Date | null) => {
  if (!response.completed) return false;

  return (
    (!startDate || !response.completedAt || new Date(response.completedAt) >= startDate) &&
    (!endDate || !response.completedAt || new Date(response.completedAt) <= endDate)
  );
};

const labelsFromResponse = (design: Design, responseWithCache: ResultResponseWithCache) => {
  const labelFields = LABEL_FIELDS[design];
  return compact(
    Object.entries(labelFields).map((entry) => {
      const label = entry[0];
      const qKey = entry[1];

      // if the question is not in the questionnaire
      if (qKey.length == 0) return null;

      let value = getDescriptionForAnswer(responseWithCache, qKey);
      if (!value) return null;

      // For opgewekt we want to indicate that the score is out of a 100.
      if (label === "opgewekt") {
        value = `opgewekt: ${parseInt(value)}/100`;
      }

      // Differentiate the gray labels by putting the label text in front of them
      return (
        <span key={label} className={classNames("petra-label", LABEL_COLORS[label])}>
          {LABEL_COLORS[label] === "gray" && `${LABEL_TEXTS[label]}:`} {value.toLowerCase()}
        </span>
      );
    })
  );
};

// Add gray labels for all single and multi select questions that weren't already included
// as colored labels.
const extraLabelsFromResponse = (design: Design, responseWithCache: ResultResponseWithCache) => {
  const coloredLabelKeys = Object.values(LABEL_FIELDS[design]);
  return compact(
    responseWithCache.questionnaire.questions
      .filter((question) => ["MULTI_SELECT", "SINGLE_SELECT"].includes(question.type))
      .map((question) => {
        if (coloredLabelKeys.includes(question.key)) return null;

        let value = getDescriptionForAnswer(responseWithCache, question.key);
        if (!value) return null;

        const label = cleanupString(question.contextFreeTitle.toLowerCase());
        return (
          <span key={label} className="petra-label gray">
            {label}: {value.toLowerCase()}
          </span>
        );
      })
  );
};

const renderResponseSummary = (
  responseWithCache: ResultResponseWithCache,
  query: string,
  design: Design,
  highlightedResponseId: string,
  highlightRef: React.RefObject<HTMLDivElement>
) => {
  const freeText = freeTextFromResponse(responseWithCache, ", ");
  // In addition to searching/filtering on free text in responses, allow for both the label
  // and the value of single- and multi-select options to be searched. Including the label (=contextFreeTitle)
  // of a multi-select question allows us to use the slideover to quickly filter for all responses
  // that have at least one option checked for a certain question, by typing in (part of) the contextFreeTitle
  // for that question in the search field.
  const queryMatchString = queryMatchStringFor(responseWithCache, ", ");
  if (query && query.length >= MIN_SEARCH_QUERY_LENGTH && !queryMatchString.toLowerCase().includes(query)) {
    return null;
  }

  const labels = labelsFromResponse(design, responseWithCache);
  const extraLabels = extraLabelsFromResponse(design, responseWithCache);

  // If this response did not contain an answer to any label question and also
  // did not contain any answer to any free text question, then the summary
  // would be empty, so just don't show it.
  if (!freeText && labels.length === 0) return null;

  const highlightResponse = responseWithCache.response.id === highlightedResponseId;

  return (
    <div
      className={classNames("response-summary", { highlight: highlightResponse })}
      key={responseWithCache.response.id}
    >
      {highlightResponse && <div ref={highlightRef} className="highlight-anchor" />}
      <div className="rs-title">{formatDate(responseWithCache.response.completedAt)}</div>
      <div>
        {labels}
        {extraLabels}
      </div>
      {freeText && <p>{freeText}</p>}
    </div>
  );
};

interface Props {
  responsesWithCache: ResultResponseWithCache[];
  query: string;
  startDate: Date | null;
  endDate: Date | null;
  openToDate: Date | null;
  highlightRef: React.RefObject<HTMLDivElement>;
}

// ResponsesSummary renders a list of summarized results.
const ResponsesSummary: React.FunctionComponent<Props> = (props) => {
  if (props.responsesWithCache.length == 0) return null;
  const design: Design | undefined = findDesign(props.responsesWithCache[0].questionnaire);
  if (!design) return <div className="responses-summary" />;

  const responsesInRange = props.responsesWithCache.filter((rc) =>
    completedInDateRange(rc.response, props.startDate, props.endDate)
  );
  const highlightedResponseId = determineHighlightedResponseId(props.openToDate, responsesInRange);
  const responseSummaries = compact(
    responsesInRange.map((rc) =>
      renderResponseSummary(rc, props.query, design, highlightedResponseId, props.highlightRef)
    )
  );

  return (
    <div className="responses-summary">
      {responseSummaries}
      {responseSummaries.length === 0 && props.query && props.query.length >= MIN_SEARCH_QUERY_LENGTH && (
        <p>
          <em>Geen meetmomenten passen bij de zoektermen.</em>
        </p>
      )}
    </div>
  );
};

// Determines which response (if any) lies closest to the openToDate
const determineHighlightedResponseId = (
  openToDate: Date | null,
  responsesWithCache: ResultResponseWithCache[]
): string => {
  if (!openToDate) return "-1";

  // some number that is higher than the difference between two dates
  const infDuration = new Date().getTime();
  const openToDateTime = openToDate.getTime();
  return (
    minBy(responsesWithCache, (rc: ResultResponseWithCache) => {
      if (!rc.response.completedAt) return infDuration;

      return Math.abs(openToDateTime - new Date(rc.response.completedAt).getTime());
    })?.response?.id || "-1"
  );
};

export default ResponsesSummary;
