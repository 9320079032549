import React from "react";
import { IProtocol } from "models";
import { useSortable, SaveOrderButton } from "logic/useSortable";
import { Translate } from "./Translate";
import { Row } from "./ProtocolsTable/Row";
import { ReactSortable } from "react-sortablejs";
import { updateProtocolsOrder } from "lib/actions";

interface Props {
  protocols: IProtocol[];
}

export const ProtocolsTable: React.FunctionComponent<Props> = ({ protocols }) => {
  const { list, setList, orderChanged, saving, persistListOrder } = useSortable<IProtocol>(protocols);
  const saveOrder = () => persistListOrder((ids) => updateProtocolsOrder(ids));

  return (
    <div>
      <SaveOrderButton orderChanged={orderChanged} saving={saving} onClick={saveOrder} />
      <table className="table sortable-table">
        <thead>
          <tr>
            <th className="span1" />
            <th className="span3">
              <Translate content="activerecord.attributes.protocol.name" />
            </th>
            <th className="span3">
              <Translate content="activerecord.attributes.protocol.measurements" />
            </th>
            <th className="span2">
              <Translate content="activerecord.attributes.protocol.teams" />
            </th>
            <th className="span2">
              <Translate content="activerecord.attributes.protocol.auto_protocols" />
            </th>
            <th className="span1">
              <Translate content="tables.actions.header" />
            </th>
          </tr>
        </thead>

        <ReactSortable list={list} setList={setList} tag="tbody">
          {list.map((protocol) => (
            <Row key={protocol.id} protocol={protocol} />
          ))}
        </ReactSortable>
      </table>
      <SaveOrderButton orderChanged={orderChanged} saving={saving} onClick={saveOrder} />
    </div>
  );
};

export default ProtocolsTable;
