import { XHRCallbacks } from "lib/actions";
import { Settings } from "./Settings";

export const updateAutoProtocolSettings = (autoProtocolId, settings: Settings, callbacks: XHRCallbacks = {}) => {
  return jQuery.ajax(RoQua.adminPath(["auto_protocols", autoProtocolId, "settings"]), {
    type: "patch",
    dataType: "json",
    contentType: "application/json",
    data: JSON.stringify({ auto_protocol: { settings: settings } }),
    success: (response) => {
      RoQua.showFlash({ state: "success", message: I18n.t("admin_area.auto_protocols.changes_saved") });
      callbacks.success && callbacks.success();
    },
    error: (response) => {
      const errors = JSON.parse(response.responseText).errors.auto_protocol.settings;
      RoQua.showFlash({
        state: "error",
        message: I18n.t("admin_area.auto_protocols.changes_failed", { errors: errors.join(", ") }),
      });
      callbacks.error && callbacks.error();
    },
    complete: () => callbacks.complete && callbacks.complete(),
  });
};
