import React from "react";
import { ResultResponseWithCache, selectedOptionsAsArray, TabConfiguration } from "../common/Graphs";
import { Highcharts } from "../../../lib/highcharts";
import HighchartsReact from "highcharts-react-official";
import { Options, PointOptionsObject } from "highcharts";
import { uniqBy, orderBy, difference } from "lodash";
import TabGraphOptions from "./TabGraphOptions";
import MoreInformationContainer from "./MoreInformationContainer";
import { allSelectOptions } from "../common/Results";
import { localizedFormat } from "../../../logic/date";
import { PARSEABLE_DATE_FORMAT, round } from "../common/Time";
import TabSituationGraphControls from "./TabSituationGraphControls";
import { TabState } from "./useTabState";
import { getValue } from "../../../logic/ResponseWithCache";

interface Props {
  tabConfiguration: TabConfiguration;
  responsesWithCache: ResultResponseWithCache[];
  openToDate: (Date) => void;
  tabState: TabState;
}

// The TabSituationGraph component is the one that actually renders the Highcharts situation graph for a tab.
// A tab is part of a TabCollection component, which is part of a Graph component.
const TabSituationGraph: React.FunctionComponent<Props> = ({
  tabConfiguration,
  responsesWithCache,
  openToDate,
  tabState,
}) => {
  const labelField = tabConfiguration.labelField;
  if (!labelField) return null;

  const selectedVariable = uniqBy(tabConfiguration.variables, "key").filter((variable) =>
    tabState.selectedVariables.isSelected(variable.key)
  )[0];

  // Turn responses into series data for the Highcharts chart.
  const data = extractData(responsesWithCache, selectedVariable.key, labelField, tabState.startDate, tabState.endDate);
  const yAxisCategories: string[] = data.categories;
  const series: Highcharts.SeriesOptionsType[] = [];
  if (tabState.showMeans) {
    series.push({
      name: "gemiddelde van alle meetmomenten",
      data: data.averages,
      type: "scatter",
      marker: {
        enabled: true,
        symbol: "diamond",
        fillColor: "darkblue",
        radius: 10,
      },
    });
  }
  if (tabState.showMoments) {
    series.push({
      name: "meetmoment",
      data: data.data,
      type: "scatter",
      marker: {
        enabled: true,
        symbol: "circle",
        fillColor: "#699ce6",
        radius: 5,
      },
      cursor: "pointer",
      point: {
        events: {
          click: function() {
            // To fix a weird bug that Highcharts would try to fun this function for the other series.
            // This as any.z is needed because regular points are not expected to have a z-coordinate,
            // but we do, because we use a third "coordinate" to store the date of a measurement.
            if (!(this as any).z) return;

            openToDate(new Date(Highcharts.dateFormat(PARSEABLE_DATE_FORMAT, (this as any).z)));
          },
        },
      },
    });
  }

  const titlePrefix = selectedVariable?.shortDescription ?? "Stemming";
  const subTitle = selectedVariable?.longDescription ?? "";
  // captionCategories are the categories not used in the responses. Those are displayed in the
  // bottom right side of the graph.
  const captionCategories: string[] =
    responsesWithCache.length > 0
      ? difference(allSelectOptions(labelField, responsesWithCache[0].questionnaire), yAxisCategories)
      : [];
  const options: Options = useHighchartsOptions(
    series,
    yAxisCategories,
    tabConfiguration.label,
    subTitle,
    captionCategories
  );
  return (
    <>
      <TabSituationGraphControls responsesWithCache={responsesWithCache} tabState={tabState} />
      <div className="petra-tab-graph">
        <div className="petra-tg-title">
          <div className="petra-tg-title-title">
            {titlePrefix} {tabConfiguration.title}
          </div>
        </div>
        <div className="petra-tg-graph">
          <div className="petra-tgg-container">
            <HighchartsReact highcharts={Highcharts} options={options} />
            <MoreInformationContainer moreInformationKey={tabConfiguration.moreInformationKey} />
          </div>
          <div className="petra-tgg-checklist">
            <h4>Items in dagboek</h4>
            <TabGraphOptions
              selectedVariables={tabState.selectedVariables}
              variables={tabConfiguration.variables}
              singleSelect
              name={tabConfiguration.title}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const average = (data) => {
  const sum = data.reduce(function(sum, value) {
    return sum + value;
  }, 0);

  return sum / data.length;
};

interface OrderIndex {
  index: number;
  value: number;
}

interface SortedAveragesResult {
  averages: Array<number | [number | string, number | null] | null | PointOptionsObject>;
  categories: string[];
}

// Sort the values in result by increasing average value.
const sortByIncreasingAverageValue = (
  result: Array<number | [number | string, number | null] | null | PointOptionsObject>,
  lookupTable: string[]
): SortedAveragesResult => {
  // We sort the y-axis (the answer options) by ascending average value of all responses that
  // included this answer.
  const averages: number[] = [];
  for (let i = 0; i < lookupTable.length; i += 1) {
    // Push the average for each set of points that shares the same point.y value (an index into the lookupTable).
    averages.push(
      average(result.filter((point: PointOptionsObject) => point.y === i).map((point: PointOptionsObject) => point.x))
    );
  }

  // Put each average in a tuple with an increasing index.
  let orderIndex: OrderIndex[] = [];
  averages.forEach((element, index) => orderIndex.push({ index: index, value: element }));

  // Sort these tuples by increasing value.
  orderIndex = orderBy(orderIndex, ["value"], ["asc"]);
  // Determine the sorted order of indexes.
  const orderedIndex = orderIndex.map((obj) => obj.index);

  // Use the ordered indexes to create a new array of sorted category names.
  const newCategories: string[] = [];
  orderedIndex.forEach((idx) => newCategories.push(lookupTable[idx]));

  // Change the lookupTable index to map where in the sorted index the lookupTable index was found.
  // This is now an the index into the newCategories string array, which is the categories sorted
  // by increasing average value.
  result.forEach((point: PointOptionsObject) => {
    point.y = orderedIndex.findIndex((num) => num === point.y);
  });

  // Return the averages so they can be plotted as a separate series.
  const sortedAverages = orderIndex.map((obj, idx) => {
    return { x: round(obj.value, 2), y: idx, description: newCategories[idx], label: "Gemiddelde" };
  });

  return { averages: sortedAverages, categories: newCategories };
};

// The function extractData turns responses into highcharts x and y data tuples.
// For questionnaires missed (= their values attribute is empty), we take the openFrom date
// as the date (x) component. For filled out questionnaires, we use the completedAt value
// as date component. Since questions can be optionally filled out, we have a special
// case for when the questionnaire was filled out, but the given question key was not.
const extractData = (
  responsesWithCache: ResultResponseWithCache[],
  key: string,
  labelField: string,
  startDate: Date | null,
  endDate: Date | null
) => {
  // Highcharts definition of the the series tuples type.
  let result: Array<number | [number | string, number | null] | null | PointOptionsObject> = [];
  // Keep track of the unique answers we've seen for the given question.
  const lookupTable: string[] = [];
  for (const rc of responsesWithCache) {
    const responseValue = getValue(rc, key);
    // This says it's a date, but it's actually a string. So convert it to date here.
    const date = new Date(rc.response.completedAt || rc.response.openFrom);

    // Filter by date
    if ((startDate && date < startDate) || (endDate && date > endDate)) continue;

    const value = responseValue ? parseFloat(responseValue) : null;
    // we need both values to plot a point in the scatter plot
    if (value === null) continue;

    const secondValues = selectedOptionsAsArray(rc, labelField);
    if (secondValues.length === 0) continue;

    // If we have multiple answers selected, add each one as an individual point.
    secondValues.forEach((splitSecondValue) => {
      if (!splitSecondValue) return true; // == continue

      if (!lookupTable.includes(splitSecondValue)) {
        lookupTable.push(splitSecondValue);
      }
      // We display answer options in the y-axis, so those need to be mapped from numbers to answers.
      const secondMappedValue: number = lookupTable.findIndex((str) => str === splitSecondValue);
      result.push({
        x: value,
        y: secondMappedValue,
        z: date.valueOf(),
        description: secondValues.join(", "),
        label: localizedFormat(date, "eeee dd-MM-yyyy - HH:mm"),
      });
    });
  }

  const { categories, averages } = sortByIncreasingAverageValue(result, lookupTable);
  return { data: result, categories: categories, averages: averages };
};

// Construct options for highcharts given the series as a parameter
const useHighchartsOptions = (
  series: Highcharts.SeriesOptionsType[],
  categories: string[],
  label: string,
  subTitle: string,
  captionCategories: string[]
): Options => {
  const nrSelectedVariables = series.length;
  // The bottom margin of the graph is determined automatically based on what takes up more vertical space:
  // the legend or the list of unused options.
  const bottomMargin = 50 + Math.max(20 * nrSelectedVariables, 24 + 16 * captionCategories.length);
  return {
    chart: {
      // Fixed offset plus a number of pixels for each variable shown
      marginBottom: bottomMargin,
      height: 50 * categories.length + 9 + 33 + bottomMargin /* 33 = subtitle height */,
      type: "scatter",
    },
    credits: {
      enabled: false,
    },

    caption: {
      align: "right",
      useHTML: true,
      text:
        captionCategories.length > 0
          ? `<strong>Niet voorgekomen:</strong><ul class="default" style="max-width: calc(100% - 250px); margin-bottom: 0;">${captionCategories
              .map((category) => `<li style="line-height: 1rem;">${category}</li>`)
              .join("")}</ul>`
          : "",
    },

    title: {
      text: "",
    },

    subtitle: {
      text: subTitle,
    },

    exporting: {
      enabled: true,
      buttons: {
        contextButton: {
          menuItems: ["downloadPDF", "downloadPNG", "printChart"],
        },
      },
    },

    legend: {
      align: "left",
      layout: "vertical",
      floating: true,
      y: -bottomMargin + 100,
    },

    yAxis: {
      title: {
        text: "",
      },
      categories: categories.map((category) => category.replace(/ \/ /gi, " /<br />")),
    },

    xAxis: {
      accessibility: {
        rangeDescription: "Bereik: 0 tot 100",
      },
      min: 0,
      max: 100,
      title: {
        text: "",
      },
      labels: {
        formatter: function() {
          // Replace the first and last numbers with labels.
          if (this.value === 100) {
            return "heel<br>erg";
          }
          if (this.value === 0) {
            return "helemaal<br>niet";
          }
          return this.axis.defaultLabelFormatter.call(this);
        },
      },
      plotLines: [
        {
          color: "#e6e6e6",
          width: 1,
          value: 0,
          dashStyle: "Dash",
        },
        {
          color: "#e6e6e6",
          width: 1,
          value: 25,
          dashStyle: "Dash",
        },
        {
          color: "#e6e6e6",
          width: 1,
          value: 50,
          dashStyle: "Dash",
        },
        {
          color: "#e6e6e6",
          width: 1,
          value: 75,
          dashStyle: "Dash",
        },
        {
          color: "#e6e6e6",
          width: 1,
          value: 100,
          dashStyle: "Dash",
        },
      ],
    },

    tooltip: {
      outside: true,
    },

    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 0.7,
          },
        },
        tooltip: {
          headerFormat: "",
          pointFormat: `<b>{point.label}</b><br />{series.name}: {point.x}<br />${label}: {point.description}`,
        },
      },
    },

    series: series,
  };
};

export default TabSituationGraph;
