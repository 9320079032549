import Backbone from "backbone";
import React from "react";
import { parseISO, format } from "date-fns";
import { todayOrYesterday } from 'logic/date';
import { Timeline } from "lib/timeline";

export class BackboneNotification extends Backbone.Model {
  timestampForSortingMem: Date|null;

  constructor(attributes) {
    super(attributes);
    this.on("change", () => { this.timestampForSortingMem = null });
  }

  timestampForSorting() {
    return this.timestampForSortingMem != null
       ? this.timestampForSortingMem
       : (this.timestampForSortingMem = parseISO(this.get('created_at')));
  }

  events() {
    return [
      { eventName: 'sent_at',
        timestamp: this.get('sent_at')},
      { eventName: 'downloaded_at',
        timestamp: this.get('downloaded_at')},
      { eventName: 'printed_at',
        timestamp: this.get('printed_at')}
    ];
  }

  lastEvent() {
    var availableEvents = this.events().filter((event) => event.timestamp);
    var sortedEvents = availableEvents.sort((event, otherEvent) =>
      event.timestamp.localeCompare(otherEvent.timestamp)
    );
    return sortedEvents.pop();
  }

  lastEventMessage() {
    var lastEvent = this.lastEvent();
    if (!lastEvent) return;

    var date = parseISO(lastEvent.timestamp)
    var message = I18n.t(lastEvent.eventName, {
      scope: "epd_area.notifications.events",
      todayOrYesterday: todayOrYesterday(date),
      time: format(date, "yyyy-MM-dd HH:mm") }
    );

    return (
      <span>
        {message}
      </span>
    );
  }

  isSelected() {
    return (this.collection as any as Timeline)?.selected?.notification_ids?.includes(this.id) || false
  }
}
