import React, { useMemo, useEffect } from "react";
import { ReceiptStepSelection, Step, STEPS } from "../../common/Schema";
import ReceiptStep from "./ReceiptStep";
import { COMPLAINTS, FIXED, GOAL_DEFINITIONS, ONE_TIME_PER_DAY, SEMI_RANDOM } from "../../common/Constants";
import { FlagInterface, uniqueSelectedFlagCount } from "../../common/Flags";
import { Key } from "../../common/Selections";
import { dropRightWhile } from "lodash";
import { useSelections } from "ahooks";
import { MeasurementSchedule } from "../../common/MeasurementSchedule";
import PreviewQuestionnaireButton from "../../common/Previewer/PreviewQuestionnaireButton";

interface Props {
  goals: Selections<Key>;
  subGoals: Selections<Key>;
  complaints: Selections<Key>;
  flags: FlagInterface;
  currentStep: Step;
  schedule: MeasurementSchedule;
}

// The Receipt is the orange view on the right side of the screen that
// serves to summarize and show information, but does not take user input
// (except for expanding/collapsing submenus).
const Receipt: React.FunctionComponent<Props> = (props) => {
  // The expanded receipt steps are steps that are currently expanded in the receipt. Only the
  // current or previous receipt steps can be expanded (i.e., future receipt steps cannot be expanded).
  const expandedReceiptSteps: Selections<Step> = useSelections<Step>(STEPS, ["goals"]);

  // only upto current step. even when going back.
  const stepsCanBeExpanded = dropRightWhile(STEPS, (step) => step !== props.currentStep);

  //  reset expanded steps after each step-change to only current step.
  useEffect(() => {
    expandedReceiptSteps.setSelected([props.currentStep]);
  }, [props.currentStep]);

  // We can only expand the submenu for pages that we have already seen.
  const canBeExpanded = (step) => {
    return stepsCanBeExpanded.includes(step);
  };

  // Returns if the given step is currently expanded in the Receipt.
  // Steps that cannot be expanded obviously are not expanded.
  const isExpanded = (step) => {
    return canBeExpanded(step) && expandedReceiptSteps.isSelected(step);
  };

  // Toggle an expansion uses the generic `toggle` function from ahooks useSelections.
  const toggleExpansion = (step) => {
    expandedReceiptSteps.toggle(step);
    return expandedReceiptSteps.selected;
  };

  // Wrap the functions dealing with expansion receipt steps in a wrapper object
  // so they can easily be passed down to the ReceiptStep component.
  const receiptStepSelection: ReceiptStepSelection = {
    canBeExpanded: canBeExpanded,
    isExpanded: isExpanded,
    toggleExpansion: toggleExpansion,
  };

  // For the receipt step that shows the number of selected flags, we cache the calculation of this number,
  // as it is an operation with O(n^2) worst case time complexity, with n the number of flags.
  const selectedFlagCount = useMemo(() => uniqueSelectedFlagCount(props.flags.flagsSelection, props.flags.flags), [
    props.flags.flagsSelection,
    props.flags.flags,
  ]);

  const trafficLightPercentage = () => {
    // NOTE: 45 questions per day doesn't matter which questionnaire.
    return Math.min(100, Math.round((100.0 * selectedFlagCount) / 45));
  };

  return (
    <>
      <div className="colored-header">
        <h4>Stel dagboek samen in 3 stappen</h4>
      </div>
      <ReceiptStep step="goals" title="Kies een doel" receiptStepSelection={receiptStepSelection}>
        <div className="receipt-details">
          {props.goals.selected.length === 0 && props.subGoals.selected.length === 0 && (
            <div>
              <em>Nog geen keuze gemaakt</em>
            </div>
          )}
          {props.goals.selected.map((name) => (
            <div key={name}>{GOAL_DEFINITIONS[name].title}</div>
          ))}
          {props.subGoals.selected.map((name) => (
            <div key={name}>{GOAL_DEFINITIONS[name].title}</div>
          ))}
          {props.complaints.selected.map((name) => (
            <div key={name}>{COMPLAINTS[name].title}</div>
          ))}
        </div>
      </ReceiptStep>
      <ReceiptStep step="flags" title="Kies onderwerpen en vragen" receiptStepSelection={receiptStepSelection}>
        <div className="receipt-details without-padding">
          <div className="rd-question-selection">
            {selectedFlagCount} {selectedFlagCount == 1 ? "vraag" : "vragen"} geselecteerd{" "}
            {[FIXED, SEMI_RANDOM].includes(props.schedule.design) &&
              (selectedFlagCount <= 10
                ? "(laag)"
                : selectedFlagCount < 25
                ? "(gemiddeld)"
                : selectedFlagCount < 40
                ? "(vrij lang)"
                : "(te lang)")}
            {[ONE_TIME_PER_DAY].includes(props.schedule.design) &&
              (selectedFlagCount <= 15
                ? "(laag)"
                : selectedFlagCount < 30
                ? "(gemiddeld)"
                : selectedFlagCount < 40
                ? "(vrij lang)"
                : "(te lang)")}
          </div>
          <div className="rd-question-load-indicator-bar">
            <div
              className="rd-tl-bar"
              style={{ width: `${100 - trafficLightPercentage()}%`, marginLeft: `${trafficLightPercentage()}%` }}
            />
          </div>
        </div>
      </ReceiptStep>
      <ReceiptStep step="settings" title="Instellingen en afronden" receiptStepSelection={receiptStepSelection}>
        <div className="receipt-details">
          {/* TODO: show the status of filling out the settings page here */}
          <div>0 / 6 ingevuld</div>
        </div>
      </ReceiptStep>
      <div className="colored-footer" />
      {["flags", "settings"].includes(props.currentStep) && (
        <PreviewQuestionnaireButton flags={props.flags} design={props.schedule.design} />
      )}
    </>
  );
};

export default Receipt;
