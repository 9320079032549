import React from "react";
import { getValue } from "logic/ResponseWithCache";
import { ResultResponseWithCache, TabConfiguration } from "../common/Graphs";
import { Highcharts } from "../../../lib/highcharts";
import HighchartsReact from "highcharts-react-official";
import { Options, PointOptionsObject } from "highcharts";
import { uniqBy, flatten, orderBy, sortBy } from "lodash";
import TabGraphOptions from "./TabGraphOptions";
import MoreInformationContainer from "./MoreInformationContainer";
import { TabState } from "./useTabState";
import { average } from "./TabSituationGraph";
import { round } from "../common/Time";
import { startOfDay } from "date-fns";

interface Props {
  tabConfiguration: TabConfiguration;
  responsesWithCache: ResultResponseWithCache[];
  openToDate: (Date) => void;
  tabState: TabState;
}

interface RangesAndAverages {
  ranges: Array<Array<number | null>>;
  averages: Array<PointOptionsObject>;
}

// Constant used to determine in how many bins to split up the measurements
const HALF_A_BIN = 3600 * 1000;

// The function extractData turns responses into highcharts x and y data tuples.
const extractData = (responsesWithCache: ResultResponseWithCache[], key: string): RangesAndAverages => {
  let averages: Array<PointOptionsObject> = [];
  let ranges: Array<Array<number | null>> = [];
  const startOfToday = startOfDay(new Date()).getTime();
  const histogram = {};
  for (const rc of responsesWithCache) {
    const responseValue = getValue(rc, key);
    // We use the completedAt value so that the values calculated
    // here match those shown in other tabs (e.g., the timeline).
    const date = rc.response.completedAt || rc.response.openFrom;

    const value = responseValue ? parseFloat(responseValue) : null;
    if (value) {
      const actualDate = new Date(date);
      // Axes labels are plotted in the local timezone, so if we leave out startOfToday and just have
      // an offset of hours and minutes, it will be added to 1 january 1970 UTC, which means that all
      // displayed times are then off by one hour. If we use a fixed offset of +3600 here instead of
      // startOfToday, then viewing the website from a different timezone will cause the displayed times
      // to again be incorrect.
      const timeOfDay = startOfToday + actualDate.getTime() - startOfDay(actualDate).getTime();
      // const timeOfDayKey = `${timeOfDay}`;
      const timeOfDayKey = `${Math.floor(timeOfDay / (2 * HALF_A_BIN))}`;
      if (histogram[timeOfDayKey] === undefined) {
        histogram[timeOfDayKey] = [];
      }
      histogram[timeOfDayKey].push(value);
    }
  }
  for (const timeOfDayKey in histogram) {
    // const timeOfDay = parseInt(timeOfDayKey);
    // put the point in the middle (add an hour)
    const timeOfDay = parseInt(timeOfDayKey) * 2 * HALF_A_BIN + HALF_A_BIN;
    averages.push({
      x: timeOfDay,
      y: round(average(histogram[timeOfDayKey]), 2),
    });
    ranges.push([timeOfDay, Math.min(...histogram[timeOfDayKey]), Math.max(...histogram[timeOfDayKey])]);
  }
  averages = orderBy(averages, ["x"], ["asc"]);
  ranges = sortBy(ranges, [(entry) => entry[0]]);
  return { averages, ranges };
};

// Construct options for highcharts given the series as a parameter
const useHighchartsOptions = (series): Options => {
  const nrSelectedVariables = Math.floor(series.length / 2);
  return {
    chart: {
      // Fixed offset plus a number of pixels for each variable shown
      marginBottom: 50 + 20 * nrSelectedVariables,
      zoomType: "x",
    },
    credits: {
      enabled: false,
    },

    caption: {
      text: "",
    },

    title: {
      text: "",
    },

    subtitle: {
      text: "",
    },

    exporting: {
      enabled: true,
    },

    legend: {
      align: "left",
      layout: "vertical",
      floating: true,
      y: 10,
    },

    yAxis: {
      accessibility: {
        rangeDescription: "Bereik: 0 tot 100",
      },
      min: 0,
      max: 100,
      title: {
        text: "",
      },
      labels: {
        formatter: function() {
          const label = this.axis.defaultLabelFormatter.call(this);

          // Instead of 100 and 0, show "heel erg" and "helemaal niet", respectively.
          if (/^100$/.test(label)) {
            return "heel erg";
          }
          if (/^0$/.test(label)) {
            return "helemaal<br />niet";
          }
          return label;
        },
      },
    },

    xAxis: {
      alternateGridColor: "#f5f5f5",
      type: "datetime",
      dateTimeLabelFormats: {
        day: "%H:%M",
      },
      title: {
        text: "",
      },
      crosshair: true,
    },

    tooltip: {
      shared: true,
      useHTML: true,
      formatter: function() {
        if (this.points?.[0]?.x === undefined) return "";
        let msg = `<span style="font-size: 11px">${Highcharts.dateFormat(
          "%H:%M",
          this.points?.[0]?.x - HALF_A_BIN
        )} - ${Highcharts.dateFormat("%H:%M", this.points?.[0]?.x + HALF_A_BIN)}</span>`;
        if (this.points === undefined) return msg;
        for (let i = 0; i < this.points.length; i += 2) {
          msg += `<br><span style='color: ${this.points?.[i]?.color}'>●</span> ${this.points?.[i]?.series?.name}: <b>${
            this.points?.[i]?.y
          }</b><br><span style='color: ${this.points?.[i]?.color}'>●</span> Uitersten: <b>${
            this.points?.[i + 1]?.point?.low
          }</b> - <b>${this.points?.[i + 1]?.point?.high}</b>`;
        }
        return msg;
      },
    },

    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 0.7,
          },
        },
        label: {
          connectorAllowed: false,
        },
      },
    },

    series: series,
  };
};

// The TabGraph component is the one that actually renders the Highcharts graph for a tab.
// A tab is part of a TabCollection component, which is part of a Graph component.
const TabGraphDaily: React.FunctionComponent<Props> = ({ tabConfiguration, responsesWithCache, tabState }) => {
  const series = flatten(
    uniqBy(tabConfiguration.variables, "key")
      .filter((variable) => tabState.selectedVariables.isSelected(variable.key))
      .map((variable, idx) => {
        const data = extractData(responsesWithCache, variable.key);
        return [
          {
            name: variable.longDescription,
            data: data.averages,
            type: "line",
            zIndex: 1,
            marker: {
              //enabled: true,
              //radius: 2.5,
              fillColor: "white",
              lineWidth: 2,
              lineColor: Highcharts.getOptions().colors?.[idx],
            },
          },
          {
            name: `Uitersten ${variable.longDescription}`,
            data: data.ranges,
            type: "arearange",
            lineWidth: 0,
            linkedTo: ":previous",
            color: Highcharts.getOptions().colors?.[idx],
            fillOpacity: 0.3,
            zIndex: 0,
            marker: {
              enabled: false,
            },
          },
        ];
      })
  );
  const options: Options = useHighchartsOptions(series);
  return (
    <div className="petra-tab-graph">
      <div className="petra-tg-title">
        <div className="petra-tg-title-title">{tabConfiguration.title}</div>
      </div>
      <div className="petra-tg-graph">
        <div className="petra-tgg-container">
          <HighchartsReact highcharts={Highcharts} options={options} />
          <MoreInformationContainer moreInformationKey={tabConfiguration.moreInformationKey} />
        </div>
        <div className="petra-tgg-checklist">
          <h4>Items in dagboek</h4>
          <TabGraphOptions
            selectedVariables={tabState.selectedVariables}
            variables={tabConfiguration.variables}
            name={tabConfiguration.title}
          />
        </div>
      </div>
    </div>
  );
};

export default TabGraphDaily;
