import * as React from "react";
import { Translate } from "../Translate";
import { ButtonLink } from "../ui/buttons";
import { NotificationsSettings } from "./NotificationSettings";
import { SchedulerSettings } from "./SchedulerSettings";
import { Measurement, Settings } from "./Settings";
import { updateAutoProtocolSettings } from "./actions";

interface Props {
  autoProtocolId: number;
  settings: Settings;
  measurements: Measurement[];
}

interface State {
  settings: Settings;
}

function initialMeasurementId(props: Props) {
  if (props.settings.measurement_id) return props.settings.measurement_id;
  if (props.measurements.length > 0) return props.measurements[0].id;
  return null;
}

export const AutoProtocolSettings: React.FC<Props> = (props) => {
  const [settings, setSettings] = React.useState<Settings>(
    Object.assign({}, props.settings, { measurement_id: initialMeasurementId(props) })
  );

  function handleChange(settings: Partial<Settings>) {
    setSettings((oldSettings) => Object.assign({}, oldSettings, settings));
  }

  function submit() {
    updateAutoProtocolSettings(props.autoProtocolId, settings);
  }

  if (props.measurements.length === 0) {
    return (
      <div>
        <span>
          <Translate content="admin_area.protocols.before_configuring_create_a_measurement.first_part" />{" "}
          <a href={`/admin/protocols/${props.autoProtocolId}/measurements/new`}>
            <Translate content="admin_area.protocols.before_configuring_create_a_measurement.link" />
          </a>
        </span>
      </div>
    );
  }

  return (
    <div id="automatic_protocol_settings">
      <SchedulerSettings
        settings={settings}
        onChange={(settings) => handleChange(settings)}
        measurements={props.measurements}
      />
      <NotificationsSettings
        notifications={settings.notifications}
        onChange={(notifications) => handleChange({ notifications: notifications })}
      />
      <div className="form-actions">
        <ButtonLink onClick={() => submit()}>Opslaan</ButtonLink>
      </div>
    </div>
  );
};

export default AutoProtocolSettings;
