import { createColumnHelper, Table } from "@tanstack/react-table";
import { ButtonLink } from "components/ui/buttons";
import { DataTable } from "components/ui/datatable";
import { useDossierTableQuery } from "grapqhl";
import { ensureDate } from "logic/date";
import { withRailsMountable } from "logic/rails_component";
import React from "react";

interface Props {
  organizationKey: string;
}

export const DossiersTable: React.FC<Props> = ({ organizationKey }) => {
  const results = useDossierTableQuery({ variables: { organizationKey } });

  if (results.loading || !results.data) {
    return <div>Loading</div>;
  }

  const rows = results.data.organization.internallyManagedDossiers.map((dossier) => ({
    id: dossier.id,
    epdId: dossier.epdId,
    tester: dossier.tester,
    createdAt: ensureDate(dossier.createdAt),
    firstname: dossier.patientRespondent.firstname,
    lastname: dossier.patientRespondent.lastname,
    email: dossier.patientRespondent.email,
    phoneCell: dossier.patientRespondent.phoneCell,
    ourGender: dossier.demographics.ourGender,
    ourBirthdate: ensureDate(dossier.demographics.ourBirthdate),
    lastResponse: ensureDate(dossier.stats.lastCompletedResponse),
    expiredResponses: dossier.stats.expiredResponses,
    completedResponses: dossier.stats.completedResponses,
    totalResponses: dossier.stats.totalResponses,
    metadata: dossier.metadata,
  }));

  const columnHelper = createColumnHelper<typeof rows[number]>();

  const dataTableColumns = [
    columnHelper.accessor("epdId", {
      size: 150,
      header: I18n.t("activerecord.attributes.dossier.epd_id"),
      meta: { sticky: true, enableGlobalFilter: true },
      enableHiding: false,
      cell: (params) => (
        <span>
          {params.getValue()}
          {params.row.original.tester && (
            <span style={{ marginLeft: "0.5em" }} className="badge">
              Tester
            </span>
          )}
        </span>
      ),
    }),
    columnHelper.group({
      id: "naw",
      header: I18n.t("admin_area.dossiers.table.naw"),
      columns: [
        columnHelper.accessor("firstname", {
          header: I18n.t("activerecord.attributes.respondent.firstname"),
          size: 150,
          meta: { enableGlobalFilter: true },
        }),
        columnHelper.accessor("lastname", {
          header: I18n.t("activerecord.attributes.respondent.lastname"),
          size: 150,
          meta: { enableGlobalFilter: true },
        }),
        columnHelper.accessor("email", {
          header: I18n.t("activerecord.attributes.respondent.email"),
          size: 150,
          meta: { enableGlobalFilter: true },
        }),
        columnHelper.accessor("phoneCell", {
          header: I18n.t("activerecord.attributes.respondent.phone_cell"),
          size: 150,
          meta: { enableGlobalFilter: true },
        }),
      ],
    }),
    columnHelper.group({
      header: I18n.t("admin_area.dossiers.table.demographics"),
      columns: [
        columnHelper.accessor("ourGender", {
          header: I18n.t("admin_area.dossiers.table.our_gender"),
          size: 100,
          cell: (info) => {
            switch (info.getValue()) {
              case "MALE":
                return I18n.t("demographics.gender.male");
              case "FEMALE":
                return I18n.t("demographics.gender.female");
              case "ALL_GENDERS":
                return I18n.t("demographics.gender.all_genders");
              default:
                return null;
            }
          },
        }),
        columnHelper.accessor("ourBirthdate", {
          header: I18n.t("admin_area.dossiers.table.our_birthdate"),
          size: 100,
          cell: (info) => I18n.l("time.formats.date", info.getValue()),
        }),
      ],
    }),
    columnHelper.accessor("createdAt", {
      header: I18n.t("activerecord.attributes.dossier.created_at"),
      size: 150,
      cell: (info) => I18n.l("time.formats.date", info.getValue()),
    }),
    columnHelper.group({
      header: "Stats",
      columns: [
        columnHelper.accessor("lastResponse", {
          header: I18n.t("admin_area.dossiers.table.last_response"),
          size: 150,
          cell: (info) => I18n.l("time.formats.date", info.getValue()),
        }),
        columnHelper.accessor("expiredResponses", {
          header: I18n.t("admin_area.dossiers.table.expired_responses"),
          size: 80,
        }),
        columnHelper.accessor("completedResponses", {
          header: I18n.t("admin_area.dossiers.table.completed_responses"),
          size: 80,
        }),
        columnHelper.accessor("totalResponses", {
          header: I18n.t("admin_area.dossiers.table.total_responses"),
          size: 80,
        }),
      ],
    }),
    columnHelper.group({
      header: "Extra velden",
      columns: results.data.organization.dossierMetadataFieldDefinitions.map((fieldDefinition) =>
        columnHelper.accessor((row) => (row.metadata ?? {})[fieldDefinition.key] ?? null, {
          id: fieldDefinition.label,
          header: fieldDefinition.label,
          meta: { enableGlobalFilter: true },
        })
      ),
    }),
    columnHelper.display({
      id: "actions",
      size: 100,
      enableHiding: false,
      meta: { sticky: true },
      cell: (info) => {
        return (
          <ButtonLink className="whitespace-nowrap" href={`/admin/dossiers/${info.row.original.id}/open_in_epd`}>
            Open EPD
          </ButtonLink>
        );
      },
    }),
  ];

  return (
    <DataTable
      data={rows}
      columns={dataTableColumns}
      initialState={{
        columnVisibility: { naw: false, firstname: false, lastname: false, email: false, phoneCell: false },
      }}
      columnVisibilityControls={columnVisibilityControls}
    />
  );
};

// Assumption for this function: only ONE level of groups above the columns
// Initial visibility state must set both the group header and the individual columns
// of that group to the same visibility status, otherwise things are out of sync since
// we only toggle.
function columnVisibilityControls<TData>(table: Table<TData>) {
  const column = table.getAllColumns().filter((column) => column.depth === 0);
  const hideableColumnGroups = column.filter((column) => column.getCanHide());
  const controls = hideableColumnGroups.map((column) => {
    const value = column.getIsVisible();
    const handleClick = () => {
      column.toggleVisibility();
      column.columns.forEach((x) => x.toggleVisibility());
    };
    return (
      <div key={column.id}>
        <label>
          <input type="checkbox" checked={value} onChange={() => handleClick()} /> {column.columnDef.header}
        </label>
      </div>
    );
  });

  return <div className="flex gap-4">{controls}</div>;
}

export default withRailsMountable(DossiersTable);
