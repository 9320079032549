import React from "react";
import { Translate } from "./Translate";

interface Callbacks {
  success: () => void;
}

interface Props {
  onOk: (callbacks: Callbacks) => void;
  header?: string;
}

export const ConfirmDialog: React.FC<Props> = ({ onOk, header = I18n.t("dialog.confirm"), children }) => {
  const [syncing, setSyncing] = React.useState(false);
  const closeDialog = () => !syncing && RoQua.reactconfirm.close();
  const okClicked = () => {
    if (!syncing) {
      setSyncing(true);
      onOk({ success: () => setSyncing(false) });
    }
  };

  return (
    <div>
      <div className="modal">
        <div className="modal-header">
          <button className="close" onClick={closeDialog}>
            ×
          </button>
          <h3>{header}</h3>
        </div>

        <div className="modal-body">{children}</div>

        <div className="modal-footer">
          <button className="btn cancel" onClick={closeDialog} disabled={syncing}>
            <Translate content="dialog.cancel" />
          </button>
          <button className="btn btn-primary ok" onClick={okClicked} disabled={syncing}>
            {syncing ? <Translate content="dialog.loading" /> : <Translate content="dialog.ok" />}{" "}
          </button>
        </div>
      </div>

      <div className="modal-backdrop" onClick={closeDialog} />
    </div>
  );
};
