import React from "react";

interface Props {
  className?: string;
  title?: string;
  onRemove?: () => void;
}

export const Badge: React.FunctionComponent<React.PropsWithChildren<Props>> = ({ children, className, title, onRemove }) => {
  return (
    <span className={className}>
      <span title={title} data-placement="bottom">
        {children}
      </span>
      {onRemove && (
        <button className="remove-badge" onClick={onRemove}>
          {"\u00d7"}
        </button>
      )}
    </span>
  );
};
