import React from "react";
import { ReceiptStepSelection, Step, STEPS } from "../../common/Schema";
import classNames from "classnames";

interface Props {
  step: Step;
  title: string;
  receiptStepSelection: ReceiptStepSelection;
}

// Renders a step of a receipt. The provided `props.children` are only shown for
// steps that are expanded. A previous or current (i.e., not future) step can be
// expanded or collapsed by clicking anywhere on the receiptstep in the receipt.
const ReceiptStep: React.FunctionComponent<React.PropsWithChildren<Props>> = (props) => {
  const isExpanded = props.receiptStepSelection.isExpanded(props.step);
  const canBeExpanded = props.receiptStepSelection.canBeExpanded(props.step);

  let onClick = () => {};
  if (canBeExpanded) onClick = () => props.receiptStepSelection.toggleExpansion(props.step);

  return (
    <div
      className={classNames("receipt-step", canBeExpanded && "can-be-expanded")}
      onClick={onClick}
      data-testid={`receipt-step-${props.step}`}
    >
      <div className="receipt-step-header">
        <div className="receipt-step-title">{`${STEPS.indexOf(props.step) + 1}. ${props.title}`}</div>
        {canBeExpanded && <div className={classNames("receipt-step-expansion-arrow", isExpanded && "expanded")} />}
      </div>
      {isExpanded && <div className="receipt-step-expansion-body">{props.children}</div>}
    </div>
  );
};

export default ReceiptStep;
