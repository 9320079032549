import * as React from "react";
import { Translate } from "components/Translate";

export const NoTokensView = () => {
  return (
    <div className="no-tokens">
      <Translate content="epd_area.app.no_tokens" />
    </div>
  );
};
