import * as React from "react";
import classNames from "classnames";
import { tsPropertySignature } from "@babel/types";

export interface IButtonProps {
  highlight?:
    | "primary"
    | "info"
    | "success"
    | "warning"
    | "danger"
    | "inverse"
    | "link";
  size?: "large" | "small" | "mini";
  block?: boolean;
  type?: "button" | "submit";
}

export const Button: React.FunctionComponent<
  IButtonProps & React.HTMLProps<HTMLButtonElement>
> = (props) => {
  const { className, highlight, size, block, type, ...rest } = props;

  const btnClassName = classNames(
    className,
    "btn",
    highlight ? `btn-${highlight}` : null,
    size ? `btn-${size}` : null,
    block ? "btn-block" : null
  );

  return (
    <button type={type || "button"} className={btnClassName} {...rest}>
      {props.children}
    </button>
  );
};
