import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: string;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: Date;
  /** Represents untyped JSON */
  JSON: any;
};

export type Account = {
  __typename?: 'Account';
  id: Scalars['ID'];
};

export type Activity = {
  __typename?: 'Activity';
  action: Scalars['String'];
  actor: Maybe<ActivityActor>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  subject: Maybe<ActivitySubject>;
};

export type ActivityActor = Dossier | Professional;

export type ActivitySubject = NonResponse | Notification | ProtocolSubscription | Report | ReportTemplate | Response;

/**
 * Simplified gender as outlined by HL7 and FIHR standards. Used because wherever questionnaires
 * come with defined norm groups, those are usually only specified for male or female.
 *
 * https://www.hl7.org/fhir/patient.html#gender
 */
export enum AdministrativeGenderEnum {
  Female = 'FEMALE',
  Male = 'MALE',
  Unknown = 'UNKNOWN'
}

export type ApiSession = {
  __typename?: 'ApiSession';
  dossier: Maybe<Dossier>;
  dossierId: Maybe<Scalars['ID']>;
  environment: Scalars['String'];
  id: Maybe<Scalars['ID']>;
  loggedIn: Scalars['Boolean'];
  navbarGroups: Array<NavbarGroup>;
  organization: Maybe<Organization>;
  organizationId: Maybe<Scalars['ID']>;
  type: ApiSessionTypeEnum;
  user: Maybe<ApiSessionUser>;
  /** For respondent sessions, is this a verified (properly authenticated) session, or was it just logged in with the token? */
  verified: Maybe<Scalars['Boolean']>;
};

export enum ApiSessionTypeEnum {
  /**
   * Logged in to the Admin frontend, as a professional. Has access to modify
   * organization-wide models like Protocol, but not to information inside dossiers.
   */
  AdminArea = 'ADMIN_AREA',
  /**
   * Logged in to the back office, as a RoQua employee. Can list and create
   * organizations, but not access information inside them.
   */
  BackOfficeArea = 'BACK_OFFICE_AREA',
  /** Logged in as a client to complete questionnnaire. Has access to modify some models inside a specific dossier. */
  ClientArea = 'CLIENT_AREA',
  /**
   * Logged in to the EPD frontend, as a professional. Has access to a specific
   * dossier only, and read-only access to organization-wide models.
   */
  EpdArea = 'EPD_AREA',
  /**
   * Logged in to the management interface. Can list and create organizations that
   * you have a membership to, but not access dossiers.
   */
  ManageArea = 'MANAGE_AREA',
  /** Not logged in */
  NotLoggedIn = 'NOT_LOGGED_IN'
}

export type ApiSessionUser = Account | Dossier | Professional | Respondent;

export type AutoProtocol = {
  __typename?: 'AutoProtocol';
  /** If false, the protocol doesn't show up in the interface. */
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  /** The name of the protocol, as shown when preparing responses. */
  name: Scalars['String'];
  position: Scalars['Int'];
  protocol: Protocol;
  /** The types of respondents that can be subscribed to this protocol. */
  subscribableRespondentTypes: Array<RespondentTypeEnum>;
  teams: Array<Team>;
};

/** The connection type for AutoProtocol. */
export type AutoProtocolConnection = {
  __typename?: 'AutoProtocolConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<AutoProtocolEdge>>>;
  /** A list of nodes. */
  nodes: Array<AutoProtocol>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type AutoProtocolEdge = {
  __typename?: 'AutoProtocolEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<AutoProtocol>;
};

export type AutoProtocolSearchEntry = SearchEntry & {
  __typename?: 'AutoProtocolSearchEntry';
  body: Maybe<Scalars['String']>;
  crumbs: Array<Scalars['String']>;
  id: Scalars['ID'];
  listed: Scalars['Boolean'];
  searchableId: Scalars['ID'];
  title: Scalars['String'];
};

export type BarChart = QuestionnaireOutcomeChartInterface & {
  __typename?: 'BarChart';
  chartType: Maybe<Scalars['String']>;
  key: Scalars['String'];
  plotbands: Maybe<Array<Plotband>>;
  plotlines: Maybe<Array<Plotline>>;
  plottables: Array<Plottable>;
  tickInterval: Maybe<Scalars['Float']>;
  title: Maybe<Scalars['String']>;
  yCategories: Maybe<Array<Scalars['String']>>;
  yRange: Maybe<Range>;
  yRangeCategories: Maybe<Array<YRangeCategory>>;
};

export enum ChartTonalityEnum {
  /** Higher is better */
  HigherIsBetter = 'HIGHER_IS_BETTER',
  /** Lower is better */
  LowerIsBetter = 'LOWER_IS_BETTER'
}

export type DailySchedulerSettings = SchedulerSettingsInterface & {
  __typename?: 'DailySchedulerSettings';
  /** Seconds since midnight for first invite, schedule can contain offsets that's added to this. */
  dailyStartTime: Scalars['Int'];
};

export type DataRemovalRequest = {
  __typename?: 'DataRemovalRequest';
  action: DataRemovalRequestActionEnum;
  createdAt: Scalars['ISO8601DateTime'];
  enactedOn: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

/** What type of action to take when enacting this request */
export enum DataRemovalRequestActionEnum {
  Anonymization = 'anonymization',
  Deletion = 'deletion'
}

/** The connection type for DataRemovalRequest. */
export type DataRemovalRequestConnection = {
  __typename?: 'DataRemovalRequestConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<DataRemovalRequestEdge>>>;
  /** A list of nodes. */
  nodes: Array<DataRemovalRequest>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Autogenerated input type of DataRemovalRequestCreate */
export type DataRemovalRequestCreateInput = {
  action: DataRemovalRequestActionEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  dossierId: Scalars['ID'];
};

/** Autogenerated return type of DataRemovalRequestCreate. */
export type DataRemovalRequestCreatePayload = {
  __typename?: 'DataRemovalRequestCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<DataRemovalRequestCreateResult>;
};

export type DataRemovalRequestCreateResult = {
  __typename?: 'DataRemovalRequestCreateResult';
  dataRemovalRequest: DataRemovalRequest;
};

/** An edge in a connection. */
export type DataRemovalRequestEdge = {
  __typename?: 'DataRemovalRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<DataRemovalRequest>;
};

export type DatePartsAnswer = ResponseAnswerInterface & {
  __typename?: 'DatePartsAnswer';
  action: Maybe<ResponseOutcomeActionEnum>;
  /**
   * Date/time formatted as '1 maart 2020 10:15' (with month name localized). If
   * one component has no answer, everything after will be ignored. So if e.g. only
   * the month and the year was given during entry, this will return 'maart 2020'.
   */
  answerText: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAnswered: Scalars['Boolean'];
  question: DatePartsQuestion;
};

export type DatePartsQuestion = QuestionInterface & {
  __typename?: 'DatePartsQuestion';
  contextFreeTitle: Scalars['String'];
  key: Scalars['String'];
  title: Maybe<Scalars['String']>;
  type: QuestionTypeEnum;
};

/**
 * Demographics are used for calculating response outcome. The model maintains independent
 * values for a value given via the RoQua UI, and for one that we have read from the EPD.
 *
 * There are also fields that will automatically read the one that should be used. You probably
 * want to read those fields. The independent `our_` and `epd_` fields exist primarily for our
 * own interface, so that we can show which values are set and whether there is an override
 * in place.
 */
export type Demographics = {
  __typename?: 'Demographics';
  birthdate: Maybe<Scalars['ISO8601Date']>;
  epdBirthdate: Maybe<Scalars['ISO8601Date']>;
  epdGender: Maybe<NormGenderEnum>;
  gender: Maybe<NormGenderEnum>;
  id: Scalars['ID'];
  ourBirthdate: Maybe<Scalars['ISO8601Date']>;
  ourGender: Maybe<NormGenderEnum>;
};

export type DemographicsPatch = {
  ourBirthdate?: Maybe<Scalars['ISO8601Date']>;
  ourGender?: Maybe<NormGenderEnum>;
};

/** Autogenerated input type of DemographicsUpdate */
export type DemographicsUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  dossierId: Scalars['ID'];
  ourBirthdate?: Maybe<Scalars['ISO8601Date']>;
  ourGender?: Maybe<NormGenderEnum>;
};

/** Autogenerated return type of DemographicsUpdate. */
export type DemographicsUpdatePayload = {
  __typename?: 'DemographicsUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<DemographicsUpdateResult>;
};

export type DemographicsUpdateResult = {
  __typename?: 'DemographicsUpdateResult';
  demographics: Demographics;
};

export type Dossier = {
  __typename?: 'Dossier';
  activities: Array<Activity>;
  createdAt: Scalars['ISO8601DateTime'];
  dataRemovalRequests: DataRemovalRequestConnection;
  demographics: Demographics;
  epdId: Scalars['String'];
  fillOutTasks: Array<FillOutTask>;
  id: Scalars['ID'];
  metadata: Maybe<Scalars['JSON']>;
  notification: Notification;
  notificationPreview: NotificationPreview;
  notifications: Array<Notification>;
  patientRespondent: Respondent;
  protocolSubscription: ProtocolSubscription;
  protocolSubscriptions: ProtocolSubscriptionConnection;
  report: Report;
  reportPreview: ReportPreview;
  reports: Array<Report>;
  researchParticipation: ResearchParticipation;
  researchParticipations: Array<ResearchParticipation>;
  /** questionnaires dossier has completed answers for, ordered alphanumerically */
  respondedQuestionnaires: Array<Questionnaire>;
  respondent: Maybe<Respondent>;
  respondents: Array<Respondent>;
  response: Response;
  responses: Array<Response>;
  selfRegistration: Maybe<SelfRegistration>;
  stats: DossierStats;
  syncStatusMessage: Maybe<Scalars['String']>;
  /** Test dossiers should be ignored when calculating statistics */
  tester: Scalars['Boolean'];
};


export type DossierActivitiesArgs = {
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type DossierDataRemovalRequestsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


export type DossierNotificationArgs = {
  id: Scalars['ID'];
};


export type DossierNotificationPreviewArgs = {
  editMode: Maybe<Scalars['Boolean']>;
  notificationTemplateId: Scalars['ID'];
};


export type DossierNotificationsArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type DossierProtocolSubscriptionArgs = {
  id: Scalars['ID'];
};


export type DossierProtocolSubscriptionsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  statuses: Maybe<Array<ProtocolSubscriptionStatusEnum>>;
};


export type DossierReportArgs = {
  id: Scalars['ID'];
};


export type DossierReportPreviewArgs = {
  editMode: Maybe<Scalars['Boolean']>;
  reportTemplateId: Scalars['ID'];
  responseIds: Maybe<Array<Scalars['ID']>>;
};


export type DossierReportsArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type DossierResearchParticipationArgs = {
  id: Scalars['ID'];
  researchProjectId: Maybe<Scalars['ID']>;
};


export type DossierResearchParticipationsArgs = {
  limit?: Maybe<Scalars['Int']>;
  researchProjectId: Maybe<Scalars['ID']>;
};


export type DossierRespondentArgs = {
  id: Maybe<Scalars['ID']>;
  type: Maybe<RespondentTypeEnum>;
};


export type DossierRespondentsArgs = {
  type: Maybe<RespondentTypeEnum>;
};


export type DossierResponseArgs = {
  id: Scalars['ID'];
};


export type DossierResponsesArgs = {
  limit?: Maybe<Scalars['Int']>;
  mostRecentOnly?: Maybe<Scalars['Boolean']>;
  protocolSubscriptionId: Maybe<Scalars['ID']>;
  questionnaireIds: Maybe<Array<Scalars['ID']>>;
  questionnaireKeys: Maybe<Array<Scalars['String']>>;
  researchParticipationId: Maybe<Scalars['ID']>;
  respondentId: Maybe<Scalars['ID']>;
  respondentType: Maybe<RespondentTypeEnum>;
  status: Maybe<ResponseStatusEnum>;
};

/** Autogenerated input type of DossierCreateInternallyManaged */
export type DossierCreateInternallyManagedInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  demographics?: Maybe<DemographicsPatch>;
  epdId: Scalars['String'];
  organizationId: Scalars['ID'];
  patientRespondent?: Maybe<RespondentPatch>;
};

/** Autogenerated return type of DossierCreateInternallyManaged. */
export type DossierCreateInternallyManagedPayload = {
  __typename?: 'DossierCreateInternallyManagedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<DossierCreateInternallyManagedResult>;
};

export type DossierCreateInternallyManagedResult = {
  __typename?: 'DossierCreateInternallyManagedResult';
  dossier: Dossier;
};

export type DossierMetadataFieldDefinition = {
  __typename?: 'DossierMetadataFieldDefinition';
  id: Scalars['ID'];
  key: Scalars['String'];
  label: Scalars['ID'];
  required: Scalars['Boolean'];
  visible: Scalars['Boolean'];
};

export type DossierStats = {
  __typename?: 'DossierStats';
  abortedResponses: Maybe<Scalars['Int']>;
  completedResponses: Maybe<Scalars['Int']>;
  expiredResponses: Maybe<Scalars['Int']>;
  lastCompletedResponse: Maybe<Scalars['ISO8601DateTime']>;
  openResponses: Maybe<Scalars['Int']>;
  pendingResponses: Maybe<Scalars['Int']>;
  scheduledResponses: Maybe<Scalars['Int']>;
  totalNotifications: Maybe<Scalars['Int']>;
  totalProtocolSubscriptions: Maybe<Scalars['Int']>;
  totalResponses: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of DossierUpdate */
export type DossierUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  dossierId: Scalars['ID'];
  /**
   * Replaces existing metadata completely (does not merge). Passing null sets that
   * on the dossier field, which semantically means that the dossier no longer
   * participates in metadata, which might behave differently from an empty set of
   * metadata in the future.
   */
  metadata?: Maybe<Scalars['JSON']>;
};

/** Autogenerated return type of DossierUpdate. */
export type DossierUpdatePayload = {
  __typename?: 'DossierUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<DossierUpdateResult>;
};

export type DossierUpdateResult = {
  __typename?: 'DossierUpdateResult';
  dossier: Dossier;
};

export enum EmailSuppressionStatusEnum {
  BounceRule = 'BOUNCE_RULE',
  Compliance = 'COMPLIANCE',
  ListUnsubscribe = 'LIST_UNSUBSCRIBE',
  ManuallyAdded = 'MANUALLY_ADDED',
  NotSuppressed = 'NOT_SUPPRESSED',
  Other = 'OTHER',
  ServiceError = 'SERVICE_ERROR',
  SpamComplaint = 'SPAM_COMPLAINT',
  UnsubscribeLink = 'UNSUBSCRIBE_LINK'
}

export type FhirEndpoint = {
  __typename?: 'FhirEndpoint';
  id: Scalars['ID'];
  url: Scalars['String'];
};

export type FhirObservation = ResponsePrefillerInterface & {
  __typename?: 'FhirObservation';
  createdAt: Scalars['ISO8601DateTime'];
  fieldKey: Scalars['String'];
  id: Scalars['ID'];
  result: ResponsePrefillerResult;
  updatedAt: Scalars['ISO8601DateTime'];
};


export type FhirObservationResultArgs = {
  dossierId: Scalars['ID'];
};

export type FillOutMoment = {
  __typename?: 'FillOutMoment';
  id: Scalars['ID'];
  open: Scalars['Boolean'];
  openFrom: Scalars['ISO8601DateTime'];
  pendingResponses: Array<PendingResponse>;
};

export type FillOutRequest = {
  __typename?: 'FillOutRequest';
  id: Scalars['ID'];
  responses: Array<Response>;
};

/** Autogenerated input type of FillOutRequestCreate */
export type FillOutRequestCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Dossier to create fill out request for */
  dossierId: Scalars['ID'];
  /** Style of invitation to send */
  invite?: Maybe<InviteTypeEnum>;
  /** Notification template */
  notificationTemplateId?: Maybe<Scalars['ID']>;
  /** If set, the request opens at this time. Email invitation will send at this time if specified. */
  openFrom?: Maybe<Scalars['ISO8601DateTime']>;
  /** If present, after this number of days a reminder will be sent. (Only applies to EMAIL invite type) */
  reminderAfterDays?: Maybe<Scalars['Int']>;
  /** Type of respondent for session */
  respondentType: RespondentTypeEnum;
  /** Questionnaires to prepare */
  responsePreparations: Array<ResponsePreparationAttributes>;
  /** Team to associate responses with */
  teamId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of FillOutRequestCreate. */
export type FillOutRequestCreatePayload = {
  __typename?: 'FillOutRequestCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<FillOutRequestCreateResult>;
};

export type FillOutRequestCreateResult = {
  __typename?: 'FillOutRequestCreateResult';
  /** Fill out request created */
  fillOutRequest: FillOutRequest;
  /** In case of invite set to LETTER, this is the notification that was created. */
  notification: Maybe<Notification>;
};

/** Autogenerated input type of FillOutSessionCreateDirect */
export type FillOutSessionCreateDirectInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Dossier to create fill out session for */
  dossierId: Scalars['ID'];
  filledOutAt: Scalars['ISO8601Date'];
  /** Measurement */
  measurementId: Scalars['ID'];
  /** Default respondent_type for responses (might be overridden by measured_questionnaire) */
  respondentType: RespondentTypeEnum;
  /** List of what to prepare */
  responsePreparations: Array<ResponsePreparationAttributes>;
  setting?: Maybe<FillOutSettingEnum>;
  /** Team to associate responses with */
  teamId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of FillOutSessionCreateDirect. */
export type FillOutSessionCreateDirectPayload = {
  __typename?: 'FillOutSessionCreateDirectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<FillOutSessionCreateDirectResult>;
};

export type FillOutSessionCreateDirectResult = {
  __typename?: 'FillOutSessionCreateDirectResult';
  /** URL to navigate to to begin this session */
  url: Scalars['String'];
};

/** Autogenerated input type of FillOutSessionCreateForTask */
export type FillOutSessionCreateForTaskInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Where to redirect in between each questionnaire */
  progressUrl?: Maybe<Scalars['String']>;
  /** Respondent to create fill out session for */
  respondentId: Scalars['ID'];
  /** Where to redirect after all questionnaires have been completed */
  returnUrl: Scalars['String'];
};

/** Autogenerated return type of FillOutSessionCreateForTask. */
export type FillOutSessionCreateForTaskPayload = {
  __typename?: 'FillOutSessionCreateForTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<FillOutSessionCreateForTaskResult>;
};

export type FillOutSessionCreateForTaskResult = {
  __typename?: 'FillOutSessionCreateForTaskResult';
  /** URL to navigate to to begin this session */
  url: Scalars['String'];
};

/** Autogenerated input type of FillOutSessionCreateSelfInitiable */
export type FillOutSessionCreateSelfInitiableInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Where to redirect in between each questionnaire */
  progressUrl?: Maybe<Scalars['String']>;
  /** Respondent to create fill out session for */
  respondentId: Scalars['ID'];
  /** Where to redirect after all questionnaires have been completed */
  returnUrl: Scalars['String'];
  selfInitiableMeasuredQuestionnaireIds: Array<Scalars['ID']>;
};

/** Autogenerated return type of FillOutSessionCreateSelfInitiable. */
export type FillOutSessionCreateSelfInitiablePayload = {
  __typename?: 'FillOutSessionCreateSelfInitiablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<FillOutSessionCreateSelfInitiableResult>;
};

export type FillOutSessionCreateSelfInitiableResult = {
  __typename?: 'FillOutSessionCreateSelfInitiableResult';
  /** URL to navigate to to begin this session */
  url: Scalars['String'];
};

export enum FillOutSettingEnum {
  Interview = 'INTERVIEW',
  Paper = 'PAPER'
}

export type FillOutTask = {
  __typename?: 'FillOutTask';
  fillOutMoments: Array<FillOutMoment>;
  hasPendingResponses: Scalars['Boolean'];
  id: Scalars['ID'];
  respondent: Respondent;
  token: Token;
};

export type FixedSchedulerSettings = SchedulerSettingsInterface & {
  __typename?: 'FixedSchedulerSettings';
  /** Seconds since midnight for first invite, schedule can contain offsets that's added to this. */
  dailyStartTime: Scalars['Int'];
};

/**
 * A flag allows to show or hide questions.
 * There are three description attributes with an implicit meaning:
 * - If only description is set, the flag should be shown in a checkbox style.
 * - If only description_true/false are set, the flag should be shown in a radio style,
 *   with a true and a false option (and an unset option).
 * - If all descriptions are set, the flag should be shown in a toggle style,
 *   with description_true/false as button-labels and description next to the button.
 */
export type Flag = {
  __typename?: 'Flag';
  /** default value for interface, does nothing server-side */
  defaultInInterface: Maybe<Scalars['Boolean']>;
  /** all dependee flags must be triggered before this flag can be triggered */
  dependsOn: Maybe<Array<Scalars['String']>>;
  /** description for the flag itself */
  description: Maybe<Scalars['String']>;
  /** description for the false option of this flag */
  descriptionFalse: Maybe<Scalars['String']>;
  /** description for the true option for this flag */
  descriptionTrue: Maybe<Scalars['String']>;
  hidesQuestions: Array<Scalars['String']>;
  /** for apollo cache, just the key again, which is glonablly unique. */
  id: Scalars['ID'];
  /** flags set by the system, not the user (gender, first_measurement_of_day, ...) */
  internal: Scalars['Boolean'];
  /** e.g. nesda_smokes */
  key: Scalars['String'];
  showsQuestions: Array<Scalars['String']>;
  /** questions shown/hidden if flag value this value. */
  triggerOn: Scalars['Boolean'];
};

export type FlagValue = {
  __typename?: 'FlagValue';
  /** Flag key, can be used to look up the flag in the questionnaire. */
  key: Scalars['String'];
  /** See Flag#trigger_on to see which value show or hides questions. */
  value: Maybe<Scalars['Boolean']>;
};

export type FloatAnswer = ResponseAnswerInterface & {
  __typename?: 'FloatAnswer';
  action: Maybe<ResponseOutcomeActionEnum>;
  /** The value formatted as a string, with a decimal period. If the question has a unit, it is appended to the value. */
  answerText: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAnswered: Scalars['Boolean'];
  question: FloatQuestion;
};

export type FloatQuestion = QuestionInterface & {
  __typename?: 'FloatQuestion';
  contextFreeTitle: Scalars['String'];
  key: Scalars['String'];
  title: Maybe<Scalars['String']>;
  type: QuestionTypeEnum;
};



export type IntegerAnswer = ResponseAnswerInterface & {
  __typename?: 'IntegerAnswer';
  action: Maybe<ResponseOutcomeActionEnum>;
  /** The value formatted as a string. If the question has a unit, it is appended to the value. */
  answerText: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isAnswered: Scalars['Boolean'];
  question: IntegerQuestion;
};

export type IntegerQuestion = QuestionInterface & {
  __typename?: 'IntegerQuestion';
  contextFreeTitle: Scalars['String'];
  key: Scalars['String'];
  title: Maybe<Scalars['String']>;
  type: QuestionTypeEnum;
};

export type IntervalSchedulerAttributes = {
  interval?: Maybe<Scalars['Int']>;
};

export type IntervalSchedulerSettings = SchedulerSettingsInterface & {
  __typename?: 'IntervalSchedulerSettings';
  /** Seconds since midnight for first invite, schedule can contain offsets that's added to this. */
  dailyStartTime: Scalars['Int'];
  interval: Maybe<Scalars['Int']>;
};

export enum InviteTypeEnum {
  Email = 'EMAIL',
  Letter = 'LETTER',
  NoInvite = 'NO_INVITE'
}


export type LineChart = QuestionnaireOutcomeChartInterface & {
  __typename?: 'LineChart';
  baseline: Maybe<Scalars['JSON']>;
  chartType: Maybe<Scalars['String']>;
  clinicallyRelevantChange: Maybe<Scalars['Float']>;
  key: Scalars['String'];
  plotbands: Maybe<Array<Plotband>>;
  plotlines: Maybe<Array<Plotline>>;
  plottables: Array<Plottable>;
  tickInterval: Maybe<Scalars['Float']>;
  title: Maybe<Scalars['String']>;
  tonality: Maybe<ChartTonalityEnum>;
  yCategories: Maybe<Array<Scalars['String']>>;
  yRange: Maybe<Range>;
  yRangeCategories: Maybe<Array<YRangeCategory>>;
};

/** Autogenerated input type of Logout */
export type LogoutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of Logout. */
export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<LogoutResult>;
};

export type LogoutResult = {
  __typename?: 'LogoutResult';
  session: ApiSession;
};

export type MeasuredQuestionnaire = {
  __typename?: 'MeasuredQuestionnaire';
  /** If this is set, the completer of the response will always be set to this value. */
  actualRespondentType: Maybe<RespondentTypeEnum>;
  id: Scalars['ID'];
  measurement: Measurement;
  /** override_name || questionnaire.name */
  name: Scalars['String'];
  /** Override for questionnaire.name under the measurement. */
  overrideName: Maybe<Scalars['String']>;
  /** Override for questionnaire.short_description under the measurement. */
  overrideShortDescription: Maybe<Scalars['String']>;
  /** If true, when selecting questionnaires this will be preselected, unless there is a recent answer (according to timespan) */
  preselected: Scalars['Boolean'];
  questionnaire: Questionnaire;
  /** The respondent types under whose tabs this questionnaire should be completable. */
  respondentTypes: Array<RespondentTypeEnum>;
  responseCallbacks: Array<ResponseCallbackInterface>;
  responsePrefillers: Array<ResponsePrefillerInterface>;
  /** override_short_description || questionnaire.short_description */
  shortDescription: Maybe<Scalars['String']>;
  /** Responses completed in this period (before the current time) will be considered recent */
  timespan: Maybe<Timespan>;
};

export type Measurement = {
  __typename?: 'Measurement';
  /** If false, the measurement is not listed when preparing responses. */
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  measuredQuestionnaires: Array<MeasuredQuestionnaire>;
  /** The name of the measurement, as shown when preparing responses. */
  name: Scalars['String'];
  /** If true, this measurement will not be selectable for manually preparing questionnaires */
  onlyForAutomation: Scalars['Boolean'];
  protocol: Protocol;
  questionnaires: Array<Questionnaire>;
  respondentTypes: Array<RespondentTypeEnum>;
  /** If true, show the UI for creating SelfInitiableMeasuredQuestionnaires when looking at this measurement */
  showSelfInitiableUi: Scalars['Boolean'];
};


export type MeasurementMeasuredQuestionnairesArgs = {
  ids: Maybe<Array<Scalars['ID']>>;
  respondentType: Maybe<RespondentTypeEnum>;
};

export type MeasurementSearchEntry = SearchEntry & {
  __typename?: 'MeasurementSearchEntry';
  body: Maybe<Scalars['String']>;
  crumbs: Array<Scalars['String']>;
  id: Scalars['ID'];
  listed: Scalars['Boolean'];
  searchableId: Scalars['ID'];
  title: Scalars['String'];
};

export type MultiSelectAnswer = ResponseAnswerInterface & {
  __typename?: 'MultiSelectAnswer';
  action: Maybe<ResponseOutcomeActionEnum>;
  id: Scalars['ID'];
  isAnswered: Scalars['Boolean'];
  question: MultiSelectQuestion;
  selectedOptions: Array<QuestionOption>;
};

export type MultiSelectQuestion = QuestionInterface & QuestionWithOptionsInterface & {
  __typename?: 'MultiSelectQuestion';
  contextFreeTitle: Scalars['String'];
  key: Scalars['String'];
  options: Array<QuestionOption>;
  title: Maybe<Scalars['String']>;
  type: QuestionTypeEnum;
  uncheckAllOption: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a new data removal request */
  dataRemovalRequestCreate: Maybe<DataRemovalRequestCreatePayload>;
  demographicsUpdate: Maybe<DemographicsUpdatePayload>;
  /** Create a dossier */
  dossierCreateInternallyManaged: Maybe<DossierCreateInternallyManagedPayload>;
  dossierUpdate: Maybe<DossierUpdatePayload>;
  /** Create a fill out request for completing non-professional questionnaires via token login */
  fillOutRequestCreate: Maybe<FillOutRequestCreatePayload>;
  /** Initiate a fill out session for completing non-professional questionnaires now */
  fillOutSessionCreateDirect: Maybe<FillOutSessionCreateDirectPayload>;
  /** Initiate a fill out session for pending questionnaires now */
  fillOutSessionCreateForTask: Maybe<FillOutSessionCreateForTaskPayload>;
  /** Initiate a fill out session for self-initiable questionnaires now */
  fillOutSessionCreateSelfInitiable: Maybe<FillOutSessionCreateSelfInitiablePayload>;
  logout: Maybe<LogoutPayload>;
  /** Create a non response */
  nonResponseCreate: Maybe<NonResponseCreatePayload>;
  /** Create a notification for the given respondent. */
  notificationCreate: Maybe<NotificationCreatePayload>;
  /**
   * Update the logged-in professional's current team relation. This affects which
   * protocols and other organization-level resources they will be shown.
   */
  professionalSetTeam: Maybe<ProfessionalSetTeamPayload>;
  /** Update the logged-in professional. */
  professionalUpdate: Maybe<ProfessionalUpdatePayload>;
  /** Create a protocol subscription */
  protocolSubscriptionCreate: Maybe<ProtocolSubscriptionCreatePayload>;
  /** Stop a protocol subscription */
  protocolSubscriptionStop: Maybe<ProtocolSubscriptionStopPayload>;
  refreshDossier: Maybe<RefreshDossierPayload>;
  /**
   * Create a report with given HTML content. RoQua will automatically generate a
   * PDF version of the report as well. Note that HTML will be sanitized.
   */
  reportCreateForHtml: Maybe<ReportCreateForHtmlPayload>;
  /** Create a research participation */
  researchParticipationCreate: Maybe<ResearchParticipationCreatePayload>;
  /**
   * Create a respondent for the session's dossier. Returns existing if the
   * respondent for the given type already exists, but this behaviour WILL change
   * when we enable support for multiple respondents per type.
   */
  respondentCreate: Maybe<RespondentCreatePayload>;
  /** Update a respondent belonging to the session's dossier */
  respondentUpdate: Maybe<RespondentUpdatePayload>;
  revokeToken: Maybe<RevokeTokenPayload>;
  /** Removes a self initiable measured questionnaire */
  selfInitiableMeasuredQuestionnaireDelete: Maybe<SelfInitiableMeasuredQuestionnaireDeletePayload>;
  /** Create a self initiable measured questionnaire */
  selfInitiableMeasuredQuestionnairesCreate: Maybe<SelfInitiableMeasuredQuestionnairesCreatePayload>;
  /** Create a self-registration for a page */
  selfRegistrationCreate: Maybe<SelfRegistrationCreatePayload>;
  /** Sends a login link to the email address for a self-registration */
  selfRegistrationRequestLoginEmail: Maybe<SelfRegistrationRequestLoginEmailPayload>;
  /** Update a team */
  teamUpdate: Maybe<TeamUpdatePayload>;
  touchNotificationPrintedAt: Maybe<TouchNotificationPrintedAtPayload>;
};


export type MutationDataRemovalRequestCreateArgs = {
  input: DataRemovalRequestCreateInput;
};


export type MutationDemographicsUpdateArgs = {
  input: DemographicsUpdateInput;
};


export type MutationDossierCreateInternallyManagedArgs = {
  input: DossierCreateInternallyManagedInput;
};


export type MutationDossierUpdateArgs = {
  input: DossierUpdateInput;
};


export type MutationFillOutRequestCreateArgs = {
  input: FillOutRequestCreateInput;
};


export type MutationFillOutSessionCreateDirectArgs = {
  input: FillOutSessionCreateDirectInput;
};


export type MutationFillOutSessionCreateForTaskArgs = {
  input: FillOutSessionCreateForTaskInput;
};


export type MutationFillOutSessionCreateSelfInitiableArgs = {
  input: FillOutSessionCreateSelfInitiableInput;
};


export type MutationLogoutArgs = {
  input: LogoutInput;
};


export type MutationNonResponseCreateArgs = {
  input: NonResponseCreateInput;
};


export type MutationNotificationCreateArgs = {
  input: NotificationCreateInput;
};


export type MutationProfessionalSetTeamArgs = {
  input: ProfessionalSetTeamInput;
};


export type MutationProfessionalUpdateArgs = {
  input: ProfessionalUpdateInput;
};


export type MutationProtocolSubscriptionCreateArgs = {
  input: ProtocolSubscriptionCreateInput;
};


export type MutationProtocolSubscriptionStopArgs = {
  input: ProtocolSubscriptionStopInput;
};


export type MutationRefreshDossierArgs = {
  input: RefreshDossierInput;
};


export type MutationReportCreateForHtmlArgs = {
  input: ReportCreateForHtmlInput;
};


export type MutationResearchParticipationCreateArgs = {
  input: ResearchParticipationCreateInput;
};


export type MutationRespondentCreateArgs = {
  input: RespondentCreateInput;
};


export type MutationRespondentUpdateArgs = {
  input: RespondentUpdateInput;
};


export type MutationRevokeTokenArgs = {
  input: RevokeTokenInput;
};


export type MutationSelfInitiableMeasuredQuestionnaireDeleteArgs = {
  input: SelfInitiableMeasuredQuestionnaireDeleteInput;
};


export type MutationSelfInitiableMeasuredQuestionnairesCreateArgs = {
  input: SelfInitiableMeasuredQuestionnairesCreateInput;
};


export type MutationSelfRegistrationCreateArgs = {
  input: SelfRegistrationCreateInput;
};


export type MutationSelfRegistrationRequestLoginEmailArgs = {
  input: SelfRegistrationRequestLoginEmailInput;
};


export type MutationTeamUpdateArgs = {
  input: TeamUpdateInput;
};


export type MutationTouchNotificationPrintedAtArgs = {
  input: TouchNotificationPrintedAtInput;
};

export type NavbarGroup = {
  __typename?: 'NavbarGroup';
  items: Array<NavbarItem>;
};

export type NavbarItem = {
  __typename?: 'NavbarItem';
  href: Scalars['String'];
  icon: Scalars['String'];
  label: Scalars['String'];
  tooltip: Maybe<Scalars['String']>;
};

export type NonResponse = {
  __typename?: 'NonResponse';
  dossier: Dossier;
  id: Scalars['ID'];
  nonResponseAt: Scalars['ISO8601DateTime'];
  reasonCode: NonResponseReasonCodeEnum;
  reasonGroup: NonResponseReasonGroupEnum;
  reasonOption: Scalars['String'];
  reasonOther: Maybe<Scalars['String']>;
  responses: Array<Response>;
};

/** Autogenerated input type of NonResponseCreate */
export type NonResponseCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  dossierId: Scalars['ID'];
  measuredQuestionnaireIds: Array<Scalars['ID']>;
  nonResponseAt: Scalars['ISO8601DateTime'];
  reasonCode: NonResponseReasonCodeEnum;
  reasonOther?: Maybe<Scalars['String']>;
  respondentType: RespondentTypeEnum;
  teamId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of NonResponseCreate. */
export type NonResponseCreatePayload = {
  __typename?: 'NonResponseCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<NonResponseCreateResult>;
};

export type NonResponseCreateResult = {
  __typename?: 'NonResponseCreateResult';
  nonResponse: NonResponse;
};

export enum NonResponseReasonCodeEnum {
  DidInviteDoesNotReturnData = 'DID_INVITE_DOES_NOT_RETURN_DATA',
  DidInviteIncorrectCompletion = 'DID_INVITE_INCORRECT_COMPLETION',
  DidInviteNoshow = 'DID_INVITE_NOSHOW',
  DidInviteNotAble = 'DID_INVITE_NOT_ABLE',
  DidInviteNotAbleDueToIllness = 'DID_INVITE_NOT_ABLE_DUE_TO_ILLNESS',
  DidInviteNotAbleDueToLanguage = 'DID_INVITE_NOT_ABLE_DUE_TO_LANGUAGE',
  DidInviteRefuses = 'DID_INVITE_REFUSES',
  DidNotInviteNotAble = 'DID_NOT_INVITE_NOT_ABLE',
  DidNotInviteNotAbleDueToIllness = 'DID_NOT_INVITE_NOT_ABLE_DUE_TO_ILLNESS',
  DidNotInviteNotAbleDueToLanguage = 'DID_NOT_INVITE_NOT_ABLE_DUE_TO_LANGUAGE',
  DidNotInviteNoActionFromProfessional = 'DID_NOT_INVITE_NO_ACTION_FROM_PROFESSIONAL',
  DidNotInviteNoAssessableTreatment = 'DID_NOT_INVITE_NO_ASSESSABLE_TREATMENT',
  DidNotInviteOtherReason = 'DID_NOT_INVITE_OTHER_REASON',
  DidNotInviteTreatmentEndByPatientUnilaterally = 'DID_NOT_INVITE_TREATMENT_END_BY_PATIENT_UNILATERALLY',
  DidNotInviteTreatmentEndIrregularly = 'DID_NOT_INVITE_TREATMENT_END_IRREGULARLY',
  DidNotInviteTreatmentEndPatientDeceased = 'DID_NOT_INVITE_TREATMENT_END_PATIENT_DECEASED'
}

export enum NonResponseReasonGroupEnum {
  DidInvite = 'DID_INVITE',
  DidNotInvite = 'DID_NOT_INVITE'
}

/** Full list of genders that RoQua supports for outcome calculations. */
export enum NormGenderEnum {
  AllGenders = 'ALL_GENDERS',
  Female = 'FEMALE',
  Male = 'MALE'
}

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  printedAt: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of NotificationCreate */
export type NotificationCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Notification template to use */
  notificationTemplateId: Scalars['ID'];
  /** ID for the respondent to notify */
  respondentId: Scalars['ID'];
  /** Form field data */
  variables?: Maybe<Scalars['JSON']>;
};

/** Autogenerated return type of NotificationCreate. */
export type NotificationCreatePayload = {
  __typename?: 'NotificationCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<NotificationCreateResult>;
};

export type NotificationCreateResult = {
  __typename?: 'NotificationCreateResult';
  notification: Notification;
};

export type NotificationEventPreview = {
  __typename?: 'NotificationEventPreview';
  contentLong: Scalars['String'];
  contentShort: Scalars['String'];
  from: Scalars['String'];
  id: Scalars['ID'];
  subject: Scalars['String'];
  to: Scalars['String'];
};

export type NotificationPreview = {
  __typename?: 'NotificationPreview';
  id: Scalars['ID'];
  opened: NotificationEventPreview;
  reminder: NotificationEventPreview;
};

export type NotificationTemplate = {
  __typename?: 'NotificationTemplate';
  id: Scalars['ID'];
  limitToProtocols: Scalars['Boolean'];
  name: Scalars['String'];
  protocol: Protocol;
  protocols: Array<Protocol>;
  /** @deprecated is now an array, use that instead */
  respondentType: RespondentTypeEnum;
  respondentTypes: Array<RespondentTypeEnum>;
  team: Team;
  teams: Array<Team>;
};


export type NotificationTemplateProtocolArgs = {
  id: Scalars['ID'];
};


export type NotificationTemplateProtocolsArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type NotificationTemplateTeamArgs = {
  id: Scalars['ID'];
};


export type NotificationTemplateTeamsArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export enum NotificationTemplateContentLengthEnum {
  Long = 'LONG',
  Short = 'SHORT'
}

export type NotificationTemplateSearchEntry = SearchEntry & {
  __typename?: 'NotificationTemplateSearchEntry';
  body: Maybe<Scalars['String']>;
  crumbs: Array<Scalars['String']>;
  id: Scalars['ID'];
  listed: Scalars['Boolean'];
  searchableId: Scalars['ID'];
  title: Scalars['String'];
};

export type NullSchedulerSettings = SchedulerSettingsInterface & {
  __typename?: 'NullSchedulerSettings';
  /** Seconds since midnight for first invite, schedule can contain offsets that's added to this. */
  dailyStartTime: Scalars['Int'];
};

export type OmniFolder = {
  __typename?: 'OmniFolder';
  id: Scalars['ID'];
  name: Scalars['String'];
  omniItems: Array<OmniItem>;
  parentId: Maybe<Scalars['ID']>;
};

export type OmniItem = {
  __typename?: 'OmniItem';
  id: Scalars['ID'];
  item: OmniItemItem;
};

export type OmniItemItem = Measurement | NotificationTemplate | ReportTemplate;

export type OrgSettings = {
  __typename?: 'OrgSettings';
  /** When true, user must have a team before the protocol list is displayed. */
  requireTeamBeforeSelectingProtocol: Scalars['Boolean'];
  /** When set, prepared responses will expire after this amount of seconds. */
  responsesExpireAfter: Maybe<Scalars['Int']>;
  /** When true, questionnaires for professionals are put into a FillOutTask just like for other respondents. */
  treatProfessionalAsRegularRespondent: Scalars['Boolean'];
};

export type Organization = {
  __typename?: 'Organization';
  autoProtocol: AutoProtocol;
  autoProtocols: AutoProtocolConnection;
  dossierMetadataFieldDefinitions: Array<DossierMetadataFieldDefinition>;
  epdNextUrl: Scalars['String'];
  fhirEndpoint: FhirEndpoint;
  fhirEndpoints: Array<FhirEndpoint>;
  id: Scalars['ID'];
  internallyManagedDossiers: Array<Dossier>;
  key: Scalars['String'];
  measurement: Measurement;
  measurements: Array<Measurement>;
  notificationTemplate: NotificationTemplate;
  notificationTemplates: Array<NotificationTemplate>;
  omniFolders: Array<OmniFolder>;
  professional: Professional;
  professionals: Array<Professional>;
  protocol: Protocol;
  protocols: Array<Protocol>;
  questionnaire: Maybe<Questionnaire>;
  questionnaires: Array<Questionnaire>;
  reportTemplate: ReportTemplate;
  reportTemplates: Array<ReportTemplate>;
  researchProject: ResearchProject;
  researchProjects: Array<ResearchProject>;
  searchEntries: Array<SearchEntry>;
  settings: OrgSettings;
  team: Team;
  teams: Array<Team>;
  url: Scalars['String'];
};


export type OrganizationAutoProtocolArgs = {
  id: Scalars['ID'];
};


export type OrganizationAutoProtocolsArgs = {
  active?: Maybe<Scalars['Boolean']>;
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  hideUnlessSubscribed?: Maybe<Scalars['Boolean']>;
  last: Maybe<Scalars['Int']>;
};


export type OrganizationDossierMetadataFieldDefinitionsArgs = {
  visible: Maybe<Scalars['Boolean']>;
};


export type OrganizationFhirEndpointArgs = {
  id: Scalars['ID'];
};


export type OrganizationFhirEndpointsArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type OrganizationMeasurementArgs = {
  id: Scalars['ID'];
};


export type OrganizationMeasurementsArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type OrganizationNotificationTemplateArgs = {
  id: Scalars['ID'];
};


export type OrganizationNotificationTemplatesArgs = {
  requireContent: Maybe<Array<NotificationTemplateContentLengthEnum>>;
  respondentType: Maybe<RespondentTypeEnum>;
  teamId: Maybe<Scalars['ID']>;
};


export type OrganizationProfessionalArgs = {
  id: Scalars['ID'];
};


export type OrganizationProfessionalsArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type OrganizationProtocolArgs = {
  id: Scalars['ID'];
};


export type OrganizationProtocolsArgs = {
  active?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  teamId: Maybe<Scalars['ID']>;
};


export type OrganizationQuestionnaireArgs = {
  id: Maybe<Scalars['ID']>;
  key: Maybe<Scalars['String']>;
};


export type OrganizationReportTemplateArgs = {
  id: Scalars['ID'];
};


export type OrganizationReportTemplatesArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type OrganizationResearchProjectArgs = {
  id: Scalars['ID'];
};


export type OrganizationResearchProjectsArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type OrganizationTeamArgs = {
  id: Scalars['ID'];
};


export type OrganizationTeamsArgs = {
  limit?: Maybe<Scalars['Int']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['String']>;
};

export type PendingResponse = {
  __typename?: 'PendingResponse';
  createdAt: Scalars['ISO8601DateTime'];
  duplicate: Scalars['Boolean'];
  id: Scalars['ID'];
  openFrom: Scalars['ISO8601DateTime'];
  openTill: Maybe<Scalars['ISO8601DateTime']>;
  questionnaire: Questionnaire;
  response: Response;
};

export type Plotband = {
  __typename?: 'Plotband';
  color: Scalars['String'];
  range: Range;
};

export type Plotline = {
  __typename?: 'Plotline';
  color: Scalars['String'];
  value: Scalars['Float'];
};

export type Plottable = {
  __typename?: 'Plottable';
  global: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  label: Maybe<Scalars['String']>;
  plottedKey: Scalars['String'];
};

export type PriorResponse = ResponsePrefillerInterface & {
  __typename?: 'PriorResponse';
  createdAt: Scalars['ISO8601DateTime'];
  fieldKey: Scalars['String'];
  id: Scalars['ID'];
  result: ResponsePrefillerResult;
  updatedAt: Scalars['ISO8601DateTime'];
};


export type PriorResponseResultArgs = {
  dossierId: Scalars['ID'];
};

export type Professional = {
  __typename?: 'Professional';
  epdId: Scalars['String'];
  firstname: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastname: Maybe<Scalars['String']>;
  preferences: ProfessionalPreferences;
  team: Maybe<Team>;
  username: Maybe<Scalars['String']>;
};

export type ProfessionalPreferences = {
  __typename?: 'ProfessionalPreferences';
  /** If true, expand the EPD-area sidebar on page load. */
  expandedEpdNavbar: Maybe<Scalars['Boolean']>;
  /** If true, show option values in EPD-area outcome pages. */
  showOptionValues: Maybe<Scalars['Boolean']>;
};

export type ProfessionalPreferencesInput = {
  expandedEpdNavbar?: Maybe<Scalars['Boolean']>;
  showOptionValues?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of ProfessionalSetTeam */
export type ProfessionalSetTeamInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** ID for the professional */
  professionalId: Scalars['ID'];
  /** ID for the team, to be used for filtering various model lists. May be passed in as null to reset to no team. */
  teamId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of ProfessionalSetTeam. */
export type ProfessionalSetTeamPayload = {
  __typename?: 'ProfessionalSetTeamPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<ProfessionalSetTeamResult>;
};

export type ProfessionalSetTeamResult = {
  __typename?: 'ProfessionalSetTeamResult';
  professional: Professional;
  session: ApiSession;
};

/** Autogenerated input type of ProfessionalUpdate */
export type ProfessionalUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Preferences for this professional. Inputs not given are left untouched. */
  preferences?: Maybe<ProfessionalPreferencesInput>;
  /** ID for the professional */
  professionalId: Scalars['ID'];
};

/** Autogenerated return type of ProfessionalUpdate. */
export type ProfessionalUpdatePayload = {
  __typename?: 'ProfessionalUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<ProfessionalUpdateResult>;
};

export type ProfessionalUpdateResult = {
  __typename?: 'ProfessionalUpdateResult';
  session: ApiSession;
};

export type Protocol = {
  __typename?: 'Protocol';
  /** If false, the protocol doesn't show up in the interface. */
  active: Scalars['Boolean'];
  autoProtocols: AutoProtocolConnection;
  id: Scalars['ID'];
  measurements: Array<Measurement>;
  /** The name of the protocol, as shown when preparing responses. */
  name: Scalars['String'];
  position: Scalars['Int'];
  teams: Array<Team>;
};


export type ProtocolAutoProtocolsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


export type ProtocolMeasurementsArgs = {
  onlyForAutomation?: Maybe<Scalars['Boolean']>;
};

export type ProtocolSearchEntry = SearchEntry & {
  __typename?: 'ProtocolSearchEntry';
  body: Maybe<Scalars['String']>;
  crumbs: Array<Scalars['String']>;
  id: Scalars['ID'];
  listed: Scalars['Boolean'];
  searchableId: Scalars['ID'];
  title: Scalars['String'];
};

export type ProtocolSubscription = {
  __typename?: 'ProtocolSubscription';
  autoProtocol: AutoProtocol;
  createdAt: Scalars['ISO8601DateTime'];
  /**
   * Seconds since midnight for first invite, schedule can contain offsets that's added to this.
   * @deprecated Moved to scheduler settings overrides
   */
  dailyStartTime: Scalars['Int'];
  /** Values for flags, added to answers created under this sequence. */
  flags: Array<FlagValue>;
  id: Scalars['ID'];
  /** Number of days on which the patient is invited */
  measurementAmount: Scalars['Int'];
  protocol: Protocol;
  /** When null, profess subscription */
  respondent: Maybe<Respondent>;
  respondentType: RespondentTypeEnum;
  /** scheduler settings overrides */
  schedulerSettingsOverrides: Maybe<SchedulerSettingsInterface>;
  startAt: Scalars['ISO8601DateTime'];
  status: ProtocolSubscriptionStatusEnum;
  stopAt: Maybe<Scalars['ISO8601DateTime']>;
  /** Values for textvars, added to answers created under this sequence. */
  textvars: Array<TextvarValue>;
  unsubscribeReason: Maybe<ProtocolSubscriptionUnsubscribeReasonEnum>;
};

/** The connection type for ProtocolSubscription. */
export type ProtocolSubscriptionConnection = {
  __typename?: 'ProtocolSubscriptionConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ProtocolSubscriptionEdge>>>;
  /** A list of nodes. */
  nodes: Array<ProtocolSubscription>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Autogenerated input type of ProtocolSubscriptionCreate */
export type ProtocolSubscriptionCreateInput = {
  attributes: ProtocolSubscriptionPatch;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  dossierId: Scalars['ID'];
  respondentId?: Maybe<Scalars['ID']>;
};

/** Autogenerated return type of ProtocolSubscriptionCreate. */
export type ProtocolSubscriptionCreatePayload = {
  __typename?: 'ProtocolSubscriptionCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<ProtocolSubscriptionCreateResult>;
};

export type ProtocolSubscriptionCreateResult = {
  __typename?: 'ProtocolSubscriptionCreateResult';
  protocolSubscription: Maybe<ProtocolSubscription>;
};

/** An edge in a connection. */
export type ProtocolSubscriptionEdge = {
  __typename?: 'ProtocolSubscriptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<ProtocolSubscription>;
};

/** Attributes for creating a protocol subscription */
export type ProtocolSubscriptionPatch = {
  autoProtocolId: Scalars['ID'];
  dailyStartTime?: Maybe<Scalars['Int']>;
  flags?: Maybe<Array<ResponseFlagAttributes>>;
  measurementAmount?: Maybe<Scalars['Int']>;
  researchParticipationId?: Maybe<Scalars['ID']>;
  schedulerSettingOverrides?: Maybe<SchedulerAttributes>;
  startAt?: Maybe<Scalars['ISO8601DateTime']>;
  teamId?: Maybe<Scalars['ID']>;
  textvars?: Maybe<Array<ResponseTextvarAttributes>>;
};

export enum ProtocolSubscriptionStatusEnum {
  Paused = 'PAUSED',
  Scheduled = 'SCHEDULED',
  Started = 'STARTED',
  Stopped = 'STOPPED'
}

/** Autogenerated input type of ProtocolSubscriptionStop */
export type ProtocolSubscriptionStopInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  protocolSubscriptionId: Scalars['ID'];
  unsubscribeReason?: Maybe<ProtocolSubscriptionUnsubscribeReasonEnum>;
};

/** Autogenerated return type of ProtocolSubscriptionStop. */
export type ProtocolSubscriptionStopPayload = {
  __typename?: 'ProtocolSubscriptionStopPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<ProtocolSubscriptionStopResult>;
};

export type ProtocolSubscriptionStopResult = {
  __typename?: 'ProtocolSubscriptionStopResult';
  protocolSubscription: ProtocolSubscription;
};

export enum ProtocolSubscriptionUnsubscribeReasonEnum {
  Deceased = 'DECEASED',
  EndOfSubscription = 'END_OF_SUBSCRIPTION',
  Unspecified = 'UNSPECIFIED',
  UnsubscribedByLinkInNotification = 'UNSUBSCRIBED_BY_LINK_IN_NOTIFICATION',
  UnsubscribedByProfessional = 'UNSUBSCRIBED_BY_PROFESSIONAL',
  UnsubscribedByRespondent = 'UNSUBSCRIBED_BY_RESPONDENT'
}

/** The query root of this schema */
export type Query = {
  __typename?: 'Query';
  /**
   * Find the current dossier as dictated by your session.
   * @deprecated Use dossier(id: ...) instead, to guard against unnoticed session changes
   */
  currentDossier: Maybe<Dossier>;
  /**
   * Find the current organization as dictated by your session.
   * @deprecated Use organization(id: ..) instead, to guard against unnoticed session changes
   */
  currentOrganization: Maybe<Organization>;
  /** Find a dossier by ID */
  dossier: Dossier;
  /** Find an organization by either ID or key */
  organization: Organization;
  /** Find a respondent by ID */
  respondent: Respondent;
  /** Find a self-registration page */
  selfRegistrationPage: SelfRegistrationPage;
  /** Your current session with the API */
  session: ApiSession;
};


/** The query root of this schema */
export type QueryDossierArgs = {
  id: Maybe<Scalars['ID']>;
};


/** The query root of this schema */
export type QueryOrganizationArgs = {
  id: Maybe<Scalars['ID']>;
  key: Maybe<Scalars['String']>;
};


/** The query root of this schema */
export type QueryRespondentArgs = {
  id: Scalars['ID'];
};


/** The query root of this schema */
export type QuerySelfRegistrationPageArgs = {
  slug: Scalars['String'];
};

export type QuestionInterface = {
  contextFreeTitle: Scalars['String'];
  key: Scalars['String'];
  title: Maybe<Scalars['String']>;
  type: QuestionTypeEnum;
};

export type QuestionOption = {
  __typename?: 'QuestionOption';
  description: Maybe<Scalars['String']>;
  key: Scalars['String'];
  value: Maybe<Scalars['String']>;
};

export enum QuestionTypeEnum {
  /** A datetime where only a part of the components are required */
  DateParts = 'DATE_PARTS',
  Float = 'FLOAT',
  Integer = 'INTEGER',
  MultiSelect = 'MULTI_SELECT',
  SingleSelect = 'SINGLE_SELECT',
  Text = 'TEXT'
}

export type QuestionWithOptionsInterface = {
  options: Array<QuestionOption>;
};

export type Questionnaire = {
  __typename?: 'Questionnaire';
  active: Scalars['Boolean'];
  flags: Array<Flag>;
  id: Scalars['ID'];
  /**
   * Key identifying the questionnaire in RoQua. Most places where you can provide
   * a questionnaire's ID, you can also pass in its key instead. Keys are the same
   * between staging and production, whereas IDs might differ.
   */
  key: Scalars['String'];
  license: QuestionnaireLicenseEnum;
  measuredQuestionnaires: Array<MeasuredQuestionnaire>;
  /** The name of the questionnaire, as shown when preparing responses. */
  name: Scalars['String'];
  outcome: QuestionnaireOutcome;
  /** Questionnaire definition for @roqua/quby-frontend lib */
  qubyFrontendV2: Scalars['JSON'];
  questions: Array<QuestionInterface>;
  /** Short description of the questionnaire */
  shortDescription: Maybe<Scalars['String']>;
  textvars: Array<Textvar>;
};

export type QuestionnaireById = {
  questionnaireId: Scalars['ID'];
};

export type QuestionnaireByKey = {
  questionnaireKey: Scalars['ID'];
};

export type QuestionnaireByMeasuredQuestionnaireId = {
  measuredQuestionnaireId: Scalars['ID'];
};

export enum QuestionnaireLicenseEnum {
  Deprecated = 'DEPRECATED',
  Free = 'FREE',
  PayPerCompletion = 'PAY_PER_COMPLETION',
  Private = 'PRIVATE',
  Unknown = 'UNKNOWN'
}

export type QuestionnaireOutcome = {
  __typename?: 'QuestionnaireOutcome';
  charts: Array<QuestionnaireOutcomeChartInterface>;
  description: Maybe<Scalars['String']>;
};

export type QuestionnaireOutcomeChartInterface = {
  chartType: Maybe<Scalars['String']>;
  key: Scalars['String'];
  plotbands: Maybe<Array<Plotband>>;
  plotlines: Maybe<Array<Plotline>>;
  plottables: Array<Plottable>;
  tickInterval: Maybe<Scalars['Float']>;
  title: Maybe<Scalars['String']>;
  yCategories: Maybe<Array<Scalars['String']>>;
  yRange: Maybe<Range>;
  yRangeCategories: Maybe<Array<YRangeCategory>>;
};

/**
 * This is a kind-of union in the spirit of
 * https://github.com/graphql/graphql-wg/blob/main/rfcs/InputUnion.md#-7-tagged-type
 * It will be replaced by whatever the final implementation of that RFC is.
 */
export type QuestionnaireReference = {
  byMeasuredQuestionnaireId?: Maybe<QuestionnaireByMeasuredQuestionnaireId>;
  byQuestionnaireId?: Maybe<QuestionnaireById>;
  byQuestionnaireKey?: Maybe<QuestionnaireByKey>;
};

export type QuestionnaireSearchEntry = SearchEntry & {
  __typename?: 'QuestionnaireSearchEntry';
  body: Maybe<Scalars['String']>;
  crumbs: Array<Scalars['String']>;
  id: Scalars['ID'];
  inUse: Scalars['Boolean'];
  listed: Scalars['Boolean'];
  searchableId: Scalars['ID'];
  title: Scalars['String'];
};

export type RadarChart = QuestionnaireOutcomeChartInterface & {
  __typename?: 'RadarChart';
  chartType: Maybe<Scalars['String']>;
  key: Scalars['String'];
  plotbands: Maybe<Array<Plotband>>;
  plotlines: Maybe<Array<Plotline>>;
  plottables: Array<Plottable>;
  tickInterval: Maybe<Scalars['Float']>;
  title: Maybe<Scalars['String']>;
  yCategories: Maybe<Array<Scalars['String']>>;
  yRange: Maybe<Range>;
  yRangeCategories: Maybe<Array<YRangeCategory>>;
};

export type RandomSchedulerAttributes = {
  blockDuration?: Maybe<Scalars['Int']>;
  blocksPerDay?: Maybe<Scalars['Int']>;
  minTimeBetweenBeeps?: Maybe<Scalars['Int']>;
};

export type RandomSchedulerSettings = SchedulerSettingsInterface & {
  __typename?: 'RandomSchedulerSettings';
  blockDuration: Maybe<Scalars['Int']>;
  blocksPerDay: Maybe<Scalars['Int']>;
  /** Seconds since midnight for first invite, schedule can contain offsets that's added to this. */
  dailyStartTime: Scalars['Int'];
  minTimeBetweenBeeps: Maybe<Scalars['Int']>;
};

export type Range = {
  __typename?: 'Range';
  /** The lower bound of the range (inclusive), null means open-ended */
  begin: Maybe<Scalars['Float']>;
  /** The upper bound of the range, null means open-ended */
  end: Maybe<Scalars['Float']>;
  /** Whether the upper bound is excluded from the range */
  excludeEnd: Maybe<Scalars['Boolean']>;
};

/** Autogenerated input type of RefreshDossier */
export type RefreshDossierInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  dossierId: Scalars['ID'];
};

/** Autogenerated return type of RefreshDossier. */
export type RefreshDossierPayload = {
  __typename?: 'RefreshDossierPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<RefreshDossierResult>;
};

export type RefreshDossierResult = {
  __typename?: 'RefreshDossierResult';
  dossier: Dossier;
};

export type Report = {
  __typename?: 'Report';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  pdfUrl: Maybe<Scalars['String']>;
  unsafeHtml: Maybe<Scalars['String']>;
};

/** The connection type for Report. */
export type ReportConnection = {
  __typename?: 'ReportConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ReportEdge>>>;
  /** A list of nodes. */
  nodes: Array<Report>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Autogenerated input type of ReportCreateForHtml */
export type ReportCreateForHtmlInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** ID for the dossier this report belongs to */
  dossierId: Scalars['ID'];
  /** Name for the report, if not given the name of the template will be used */
  name?: Maybe<Scalars['String']>;
  /** Report template this report is based on */
  reportTemplateId: Scalars['ID'];
  /** Participation to link this report to */
  researchParticipationId?: Maybe<Scalars['ID']>;
  /** Responses referenced by this report */
  responseIds: Array<Scalars['ID']>;
  /** The rendered HTML body for this report. Will be sanitized for display. */
  unsafeHtml: Scalars['String'];
  /** Form field data */
  variables?: Maybe<Scalars['JSON']>;
};

/** Autogenerated return type of ReportCreateForHtml. */
export type ReportCreateForHtmlPayload = {
  __typename?: 'ReportCreateForHtmlPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<ReportCreateForHtmlResult>;
};

export type ReportCreateForHtmlResult = {
  __typename?: 'ReportCreateForHtmlResult';
  report: Report;
};

/** An edge in a connection. */
export type ReportEdge = {
  __typename?: 'ReportEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Report>;
};

export type ReportPreview = {
  __typename?: 'ReportPreview';
  blocks: Array<ReportPreviewBlock>;
  id: Scalars['ID'];
};

export type ReportPreviewBlock = ReportPreviewChartBlock | ReportPreviewCssBlock | ReportPreviewHtmlBlock;

export type ReportPreviewChartBlock = {
  __typename?: 'ReportPreviewChartBlock';
  data: Scalars['JSON'];
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type ReportPreviewCssBlock = {
  __typename?: 'ReportPreviewCssBlock';
  css: Scalars['String'];
  id: Scalars['ID'];
};

export type ReportPreviewHtmlBlock = {
  __typename?: 'ReportPreviewHtmlBlock';
  html: Scalars['String'];
  id: Scalars['ID'];
};

export type ReportTemplate = {
  __typename?: 'ReportTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  questionnaire: Questionnaire;
  questionnaires: Array<Questionnaire>;
  team: Team;
  teams: Array<Team>;
};


export type ReportTemplateQuestionnaireArgs = {
  id: Scalars['ID'];
};


export type ReportTemplateQuestionnairesArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type ReportTemplateTeamArgs = {
  id: Scalars['ID'];
};


export type ReportTemplateTeamsArgs = {
  limit?: Maybe<Scalars['Int']>;
};

export type ReportTemplateSearchEntry = SearchEntry & {
  __typename?: 'ReportTemplateSearchEntry';
  body: Maybe<Scalars['String']>;
  crumbs: Array<Scalars['String']>;
  id: Scalars['ID'];
  listed: Scalars['Boolean'];
  searchableId: Scalars['ID'];
  title: Scalars['String'];
};

export type ResearchParticipation = {
  __typename?: 'ResearchParticipation';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  protocolSubscription: ProtocolSubscription;
  protocolSubscriptions: Array<ProtocolSubscription>;
  report: Report;
  reports: Array<Report>;
  researchProject: ResearchProject;
  /** ResearchProject dependent settings, for use by the specific front-end for that project. Not used in the backend. */
  settings: Scalars['JSON'];
};


export type ResearchParticipationProtocolSubscriptionArgs = {
  id: Scalars['ID'];
};


export type ResearchParticipationProtocolSubscriptionsArgs = {
  limit?: Maybe<Scalars['Int']>;
};


export type ResearchParticipationReportArgs = {
  id: Scalars['ID'];
};


export type ResearchParticipationReportsArgs = {
  limit?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of ResearchParticipationCreate */
export type ResearchParticipationCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  dossierId: Scalars['ID'];
  /** Name for this participation */
  name?: Maybe<Scalars['String']>;
  researchProjectId: Scalars['ID'];
  /** ResearchProject dependent settings, for use by the specific front-end for that project. Not used in the backend. */
  settings?: Maybe<Scalars['JSON']>;
  subscriptions?: Maybe<Array<ProtocolSubscriptionPatch>>;
};

/** Autogenerated return type of ResearchParticipationCreate. */
export type ResearchParticipationCreatePayload = {
  __typename?: 'ResearchParticipationCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<ResearchParticipationCreateResult>;
};

export type ResearchParticipationCreateResult = {
  __typename?: 'ResearchParticipationCreateResult';
  researchParticipation: ResearchParticipation;
  subscriptions: Array<ProtocolSubscription>;
};

export type ResearchProject = {
  __typename?: 'ResearchProject';
  /** If false, the project doesn't show up in the interface. */
  active: Scalars['Boolean'];
  /** Description for the project */
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  /** The name of the project */
  name: Scalars['String'];
  protocols: Array<Protocol>;
};

export type ResearchProjectSearchEntry = SearchEntry & {
  __typename?: 'ResearchProjectSearchEntry';
  body: Maybe<Scalars['String']>;
  crumbs: Array<Scalars['String']>;
  id: Scalars['ID'];
  listed: Scalars['Boolean'];
  searchableId: Scalars['ID'];
  title: Scalars['String'];
};

/**
 * Respondents are people that fill out questionnaires. It's the 'you' in "How
 * are you?" and "How is your child/patient?" on a questionnaire. The one who
 * enters the data into RoQua is not always the respondent.
 *
 * The default respondent is the patient, but there’s also profess, parent,
 * second_parent, teacher and caregiver. second_parent will be converted into
 * just parent in the future.
 *
 * There may be more than one respondent per type.
 */
export type Respondent = {
  __typename?: 'Respondent';
  administrativeGender: Maybe<AdministrativeGenderEnum>;
  birthdate: Maybe<Scalars['ISO8601Date']>;
  deceased: Scalars['Boolean'];
  email: Maybe<Scalars['String']>;
  /** Makes a call to an external service for each respondent */
  emailSuppressionStatus: EmailSuppressionStatusEnum;
  fillOutTask: FillOutTask;
  firstname: Maybe<Scalars['String']>;
  formalName: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isEditable: Scalars['Boolean'];
  isEmailEditable: Scalars['Boolean'];
  /** Non-identifying name for the respondent (e.g. "mother", "history teacher"). */
  label: Maybe<Scalars['String']>;
  lastSyncAt: Maybe<Scalars['ISO8601DateTime']>;
  lastSyncFailure: Maybe<Scalars['ISO8601DateTime']>;
  lastSyncSuccess: Maybe<Scalars['ISO8601DateTime']>;
  lastname: Maybe<Scalars['String']>;
  /** Roepnaam, a Dutch thing which has no specific English counterpart. */
  nickname: Maybe<Scalars['String']>;
  phoneCell: Maybe<Scalars['String']>;
  protocolSubscription: ProtocolSubscription;
  protocolSubscriptions: ProtocolSubscriptionConnection;
  report: Report;
  reports: ReportConnection;
  responses: ResponseConnection;
  sbgCode: RespondentSbgCodeEnum;
  selfInitiableMeasuredQuestionnaires: SelfInitiableMeasuredQuestionnaireConnection;
  syncStatus: RespondentSyncStatusEnum;
  syncWarnings: Maybe<Array<Scalars['String']>>;
  token: Token;
  /** Type of respondent, this dictates what types of questionnaires they can be presented. */
  type: RespondentTypeEnum;
};


/**
 * Respondents are people that fill out questionnaires. It's the 'you' in "How
 * are you?" and "How is your child/patient?" on a questionnaire. The one who
 * enters the data into RoQua is not always the respondent.
 *
 * The default respondent is the patient, but there’s also profess, parent,
 * second_parent, teacher and caregiver. second_parent will be converted into
 * just parent in the future.
 *
 * There may be more than one respondent per type.
 */
export type RespondentProtocolSubscriptionArgs = {
  id: Scalars['ID'];
};


/**
 * Respondents are people that fill out questionnaires. It's the 'you' in "How
 * are you?" and "How is your child/patient?" on a questionnaire. The one who
 * enters the data into RoQua is not always the respondent.
 *
 * The default respondent is the patient, but there’s also profess, parent,
 * second_parent, teacher and caregiver. second_parent will be converted into
 * just parent in the future.
 *
 * There may be more than one respondent per type.
 */
export type RespondentProtocolSubscriptionsArgs = {
  active?: Scalars['Boolean'];
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


/**
 * Respondents are people that fill out questionnaires. It's the 'you' in "How
 * are you?" and "How is your child/patient?" on a questionnaire. The one who
 * enters the data into RoQua is not always the respondent.
 *
 * The default respondent is the patient, but there’s also profess, parent,
 * second_parent, teacher and caregiver. second_parent will be converted into
 * just parent in the future.
 *
 * There may be more than one respondent per type.
 */
export type RespondentReportArgs = {
  id: Scalars['ID'];
};


/**
 * Respondents are people that fill out questionnaires. It's the 'you' in "How
 * are you?" and "How is your child/patient?" on a questionnaire. The one who
 * enters the data into RoQua is not always the respondent.
 *
 * The default respondent is the patient, but there’s also profess, parent,
 * second_parent, teacher and caregiver. second_parent will be converted into
 * just parent in the future.
 *
 * There may be more than one respondent per type.
 */
export type RespondentReportsArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};


/**
 * Respondents are people that fill out questionnaires. It's the 'you' in "How
 * are you?" and "How is your child/patient?" on a questionnaire. The one who
 * enters the data into RoQua is not always the respondent.
 *
 * The default respondent is the patient, but there’s also profess, parent,
 * second_parent, teacher and caregiver. second_parent will be converted into
 * just parent in the future.
 *
 * There may be more than one respondent per type.
 */
export type RespondentResponsesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
  pending: Maybe<Scalars['Boolean']>;
};


/**
 * Respondents are people that fill out questionnaires. It's the 'you' in "How
 * are you?" and "How is your child/patient?" on a questionnaire. The one who
 * enters the data into RoQua is not always the respondent.
 *
 * The default respondent is the patient, but there’s also profess, parent,
 * second_parent, teacher and caregiver. second_parent will be converted into
 * just parent in the future.
 *
 * There may be more than one respondent per type.
 */
export type RespondentSelfInitiableMeasuredQuestionnairesArgs = {
  after: Maybe<Scalars['String']>;
  before: Maybe<Scalars['String']>;
  first: Maybe<Scalars['Int']>;
  last: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of RespondentCreate */
export type RespondentCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  dossierId: Scalars['ID'];
  type: RespondentTypeEnum;
};

/** Autogenerated return type of RespondentCreate. */
export type RespondentCreatePayload = {
  __typename?: 'RespondentCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<RespondentCreateResult>;
};

export type RespondentCreateResult = {
  __typename?: 'RespondentCreateResult';
  respondent: Respondent;
};

export type RespondentPatch = {
  administrativeGender?: Maybe<AdministrativeGenderEnum>;
  birthdate?: Maybe<Scalars['ISO8601Date']>;
  email?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phoneCell?: Maybe<Scalars['String']>;
};

export enum RespondentSbgCodeEnum {
  /** Patient */
  Sbg_01 = 'SBG_01',
  /** Professional */
  Sbg_02 = 'SBG_02',
  /** Parent */
  Sbg_04 = 'SBG_04',
  /** Teacher */
  Sbg_06 = 'SBG_06',
  /** Caregiver */
  Sbg_07 = 'SBG_07'
}

export enum RespondentSyncStatusEnum {
  /** Synchronization is currently happening */
  Syncing = 'SYNCING',
  /** Synchronization failed, see the sync_warnings field for more information */
  SyncFailed = 'SYNC_FAILED',
  /** Synchronization is scheduled and should process soon */
  SyncPending = 'SYNC_PENDING',
  /** The last synchronization was successful */
  SyncSucceeded = 'SYNC_SUCCEEDED',
  /** The respondent has not been synced before */
  Unsynced = 'UNSYNCED'
}

export enum RespondentTypeEnum {
  /** Only valid for fill out sessions, you can't actually create a Respondent with this type */
  AdHocProfessional = 'ad_hoc_professional',
  Caregiver = 'caregiver',
  Parent = 'parent',
  Patient = 'patient',
  /** Only valid at some organizations, you might get an error if you attempt to use this. */
  Profess = 'profess',
  SecondParent = 'second_parent',
  Teacher = 'teacher'
}

/** Autogenerated input type of RespondentUpdate */
export type RespondentUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  patch: RespondentPatch;
  respondentId: Scalars['ID'];
};

/** Autogenerated return type of RespondentUpdate. */
export type RespondentUpdatePayload = {
  __typename?: 'RespondentUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<RespondentUpdateResult>;
};

export type RespondentUpdateResult = {
  __typename?: 'RespondentUpdateResult';
  respondent: Respondent;
};

export type Response = {
  __typename?: 'Response';
  active: Scalars['Boolean'];
  /**
   * All answers for this response. Will be empty (but not null) when the response
   * is not completed. Whereas the values field gives you the raw data, this
   * presents a rich linked data structure with direct access to the question and
   * the selected option description etc.
   */
  answers: Array<ResponseAnswerInterface>;
  completed: Scalars['Boolean'];
  /** Indicates when the response was completed, or null if this response is not yet completed. */
  completedAt: Maybe<Scalars['ISO8601DateTime']>;
  /** Indicates if the questionnaire for this response is present multiple times in the fill out task, with the same flags. */
  duplicate: Scalars['Boolean'];
  id: Scalars['ID'];
  /**
   * Indicates when the response becomes completable, or null if this response is
   * not only completable within a specific time window.
   */
  openFrom: Scalars['ISO8601DateTime'];
  /**
   * Indicates when the response expires and is no longer completable, or null if
   * this response is not only completable within a specific time window.
   */
  openTill: Maybe<Scalars['ISO8601DateTime']>;
  /** Outcome elements. */
  outcome: ResponseOutcome;
  questionnaire: Questionnaire;
  respondent: Maybe<Respondent>;
  /** @deprecated Confusing name, should not be used externally, expect this to be renamed */
  respondentType: Maybe<RespondentTypeEnum>;
  /** Response score outcome represented as objects supplying keys, labels and values together */
  scoreObjects: Array<ResponseScore>;
  status: Maybe<ResponseStatusEnum>;
  /**
   * Keys and values for every question in the questionnaire. Will be empty (but
   * not null) when the response is not completed. For Single select questions, the
   * value is the key of the option.
   */
  values: Array<ResponseValue>;
};

export type ResponseAnswerInterface = {
  action: Maybe<ResponseOutcomeActionEnum>;
  id: Scalars['ID'];
  isAnswered: Scalars['Boolean'];
  question: QuestionInterface;
};

export type ResponseCallbackInterface = {
  description: Scalars['String'];
};

/** The connection type for Response. */
export type ResponseConnection = {
  __typename?: 'ResponseConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<ResponseEdge>>>;
  /** A list of nodes. */
  nodes: Array<Response>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type ResponseEdge = {
  __typename?: 'ResponseEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<Response>;
};

export type ResponseFlagAttributes = {
  key: Scalars['String'];
  value: Scalars['Boolean'];
};

export type ResponseOutcome = {
  __typename?: 'ResponseOutcome';
  /** Whether the calculated outcome requires extra attention or whether it is alarming. Null if neither applies. */
  action: Maybe<ResponseOutcomeActionEnum>;
  /** Array containing all question keys giving rise to the alarm flag. */
  alarm: Array<Scalars['String']>;
  /** Array containing all question keys giving rise to the attention flag. */
  attention: Array<Scalars['String']>;
  /**
   * String indicating the percentage a questionnaire has been completed. Either
   * ‘100%’ or null when a questionnaire is fully completed, depending on whether
   * the questionnaire has a definition for ‘completeness’.
   */
  complete: Maybe<Scalars['String']>;
};

export enum ResponseOutcomeActionEnum {
  /** Alarming */
  Alarm = 'alarm',
  /** Requires extra attention */
  Attention = 'attention'
}

export type ResponsePrefillerInterface = {
  createdAt: Scalars['ISO8601DateTime'];
  fieldKey: Scalars['String'];
  id: Scalars['ID'];
  result: ResponsePrefillerResult;
  updatedAt: Scalars['ISO8601DateTime'];
};


export type ResponsePrefillerInterfaceResultArgs = {
  dossierId: Scalars['ID'];
};

export type ResponsePrefillerResult = ResponsePrefillerResultError | ResponsePrefillerResultNoData | ResponsePrefillerResultSuccess;

export type ResponsePrefillerResultError = {
  __typename?: 'ResponsePrefillerResultError';
  error: Scalars['String'];
  status: Scalars['String'];
};

export type ResponsePrefillerResultNoData = {
  __typename?: 'ResponsePrefillerResultNoData';
  status: Scalars['String'];
};

export type ResponsePrefillerResultSuccess = {
  __typename?: 'ResponsePrefillerResultSuccess';
  status: Scalars['String'];
  timestamp: Scalars['ISO8601DateTime'];
  value: Scalars['String'];
};

export type ResponsePreparationAttributes = {
  answerValue?: Maybe<Scalars['JSON']>;
  questionnaireReference: QuestionnaireReference;
};

export type ResponseScore = {
  __typename?: 'ResponseScore';
  /** The key of the score in the context of its questionnaire */
  key: Scalars['String'];
  /** A description of the score */
  label: Scalars['String'];
  /** Objects describing the actual value, derived values and interpretations for this score */
  subscores: Array<ResponseSubscore>;
};

export enum ResponseStatusEnum {
  /**
   * Filling out the questionnaire has been aborted, forcibly saved with only
   * partial completion. To be fully completed at a later time.
   */
  Aborted = 'aborted',
  /** This response has been completed. */
  Completed = 'completed',
  /** Current time later than openTill. No longer completable. */
  Expired = 'expired',
  /** Deleted record. */
  Inactive = 'inactive',
  /** This response is completable right now. */
  Open = 'open',
  /**
   * This response is scheduled to be completed at a later time. Cannot be
   * completed right now, and visiting its URL will not result in this response
   * being presented. See open_from and open_till attributes for the time window
   * when this response will be open.
   */
  Scheduled = 'scheduled',
  /** Dangling record, neither pending nor completed nor expired. */
  Unattached = 'unattached'
}

export type ResponseSubscore = {
  __typename?: 'ResponseSubscore';
  /**
   * Shortened key for this subscore in the context of this questionnaire used in
   * exports, excluding the questionnaire key part.
   */
  exportKey: Scalars['String'];
  /** Key of this subscore in the context of the score. The subscore with key 'value' is the main score value. */
  key: Scalars['String'];
  /** Description of the subscore in the context of the score, like 'Waarde'('Value') or 'Interpretatie'('Interpretation') */
  label: Scalars['String'];
  /** The value of this subscore. Might be a number or a text interpretation. */
  value: Maybe<Scalars['String']>;
};

export type ResponseTextvarAttributes = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ResponseValue = {
  __typename?: 'ResponseValue';
  /** Question key, can be used to look up the question in the questionnaire. */
  key: Scalars['String'];
  /** The answer to this question given by the completer. */
  value: Maybe<Scalars['String']>;
};

/** Autogenerated input type of RevokeToken */
export type RevokeTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  tokenId: Scalars['ID'];
};

/** Autogenerated return type of RevokeToken. */
export type RevokeTokenPayload = {
  __typename?: 'RevokeTokenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<RevokeTokenResult>;
};

export type RevokeTokenResult = {
  __typename?: 'RevokeTokenResult';
  respondent: Respondent;
  token: Token;
};

export type SchedulerAttributes = {
  intervalSchedulerSettings?: Maybe<IntervalSchedulerAttributes>;
  randomSchedulerSettings?: Maybe<RandomSchedulerAttributes>;
};

export type SchedulerSettingsInterface = {
  /** Seconds since midnight for first invite, schedule can contain offsets that's added to this. */
  dailyStartTime: Scalars['Int'];
};

/** Something that could appear in an organization-level search */
export type SearchEntry = {
  body: Maybe<Scalars['String']>;
  crumbs: Array<Scalars['String']>;
  id: Scalars['ID'];
  listed: Scalars['Boolean'];
  searchableId: Scalars['ID'];
  title: Scalars['String'];
};

export type SelfInitiableMeasuredQuestionnaire = {
  __typename?: 'SelfInitiableMeasuredQuestionnaire';
  id: Scalars['ID'];
  measuredQuestionnaire: MeasuredQuestionnaire;
  respondent: Respondent;
};

/** The connection type for SelfInitiableMeasuredQuestionnaire. */
export type SelfInitiableMeasuredQuestionnaireConnection = {
  __typename?: 'SelfInitiableMeasuredQuestionnaireConnection';
  /** A list of edges. */
  edges: Maybe<Array<Maybe<SelfInitiableMeasuredQuestionnaireEdge>>>;
  /** A list of nodes. */
  nodes: Array<SelfInitiableMeasuredQuestionnaire>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** Autogenerated input type of SelfInitiableMeasuredQuestionnaireDelete */
export type SelfInitiableMeasuredQuestionnaireDeleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  selfInitiableMeasuredQuestionnaireId: Scalars['ID'];
};

/** Autogenerated return type of SelfInitiableMeasuredQuestionnaireDelete. */
export type SelfInitiableMeasuredQuestionnaireDeletePayload = {
  __typename?: 'SelfInitiableMeasuredQuestionnaireDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<SelfInitiableMeasuredQuestionnaireDeleteResult>;
};

export type SelfInitiableMeasuredQuestionnaireDeleteResult = {
  __typename?: 'SelfInitiableMeasuredQuestionnaireDeleteResult';
  selfInitiableMeasuredQuestionnaire: SelfInitiableMeasuredQuestionnaire;
};

/** An edge in a connection. */
export type SelfInitiableMeasuredQuestionnaireEdge = {
  __typename?: 'SelfInitiableMeasuredQuestionnaireEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Maybe<SelfInitiableMeasuredQuestionnaire>;
};

/** Autogenerated input type of SelfInitiableMeasuredQuestionnairesCreate */
export type SelfInitiableMeasuredQuestionnairesCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  measuredQuestionnaireIds: Array<Scalars['ID']>;
  respondentId: Scalars['ID'];
};

/** Autogenerated return type of SelfInitiableMeasuredQuestionnairesCreate. */
export type SelfInitiableMeasuredQuestionnairesCreatePayload = {
  __typename?: 'SelfInitiableMeasuredQuestionnairesCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<SelfInitiableMeasuredQuestionnairesCreateResult>;
};

export type SelfInitiableMeasuredQuestionnairesCreateResult = {
  __typename?: 'SelfInitiableMeasuredQuestionnairesCreateResult';
  selfInitiableMeasuredQuestionnaires: Array<SelfInitiableMeasuredQuestionnaire>;
};

export type SelfRegistration = {
  __typename?: 'SelfRegistration';
  id: Scalars['ID'];
  selfRegistrationPage: SelfRegistrationPage;
};

/** Autogenerated input type of SelfRegistrationCreate */
export type SelfRegistrationCreateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  demographicsAttributes?: Maybe<Scalars['JSON']>;
  email: Scalars['String'];
  metadataAttributes?: Maybe<Scalars['JSON']>;
  respondentAttributes?: Maybe<Scalars['JSON']>;
  selfRegistrationPageId: Scalars['ID'];
};

/** Autogenerated return type of SelfRegistrationCreate. */
export type SelfRegistrationCreatePayload = {
  __typename?: 'SelfRegistrationCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<SelfRegistrationCreateResult>;
};

export type SelfRegistrationCreateResult = {
  __typename?: 'SelfRegistrationCreateResult';
  success: Scalars['Boolean'];
};

export type SelfRegistrationPage = {
  __typename?: 'SelfRegistrationPage';
  demographicsFields: Array<SelfRegistrationPageField>;
  id: Scalars['ID'];
  intro: Scalars['String'];
  logoUrl: Maybe<Scalars['String']>;
  metadataFields: Array<SelfRegistrationPageField>;
  respondentFields: Array<SelfRegistrationPageField>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type SelfRegistrationPageField = {
  __typename?: 'SelfRegistrationPageField';
  key: Scalars['String'];
  required: Scalars['Boolean'];
};

/** Autogenerated input type of SelfRegistrationRequestLoginEmail */
export type SelfRegistrationRequestLoginEmailInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  selfRegistrationPageId: Scalars['ID'];
};

/** Autogenerated return type of SelfRegistrationRequestLoginEmail. */
export type SelfRegistrationRequestLoginEmailPayload = {
  __typename?: 'SelfRegistrationRequestLoginEmailPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<SelfRegistrationRequestLoginEmailResult>;
};

export type SelfRegistrationRequestLoginEmailResult = {
  __typename?: 'SelfRegistrationRequestLoginEmailResult';
  success: Scalars['Boolean'];
};

export type SingleSelectAnswer = ResponseAnswerInterface & {
  __typename?: 'SingleSelectAnswer';
  action: Maybe<ResponseOutcomeActionEnum>;
  id: Scalars['ID'];
  isAnswered: Scalars['Boolean'];
  question: SingleSelectQuestion;
  selectedOption: Maybe<QuestionOption>;
};

export type SingleSelectQuestion = QuestionInterface & QuestionWithOptionsInterface & {
  __typename?: 'SingleSelectQuestion';
  contextFreeTitle: Scalars['String'];
  key: Scalars['String'];
  options: Array<QuestionOption>;
  title: Maybe<Scalars['String']>;
  type: QuestionTypeEnum;
};

export type Team = {
  __typename?: 'Team';
  emailFrom: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TeamPatch = {
  emailFrom?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of TeamUpdate */
export type TeamUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  patch: TeamPatch;
  teamId: Scalars['ID'];
};

/** Autogenerated return type of TeamUpdate. */
export type TeamUpdatePayload = {
  __typename?: 'TeamUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<TeamUpdateResult>;
};

export type TeamUpdateResult = {
  __typename?: 'TeamUpdateResult';
  team: Team;
};

export type TextAnswer = ResponseAnswerInterface & {
  __typename?: 'TextAnswer';
  action: Maybe<ResponseOutcomeActionEnum>;
  /** The value entered by the user. */
  answerText: Scalars['String'];
  id: Scalars['ID'];
  isAnswered: Scalars['Boolean'];
  question: TextQuestion;
};

export type TextQuestion = QuestionInterface & {
  __typename?: 'TextQuestion';
  contextFreeTitle: Scalars['String'];
  key: Scalars['String'];
  title: Maybe<Scalars['String']>;
  type: QuestionTypeEnum;
};

export type Textvar = {
  __typename?: 'Textvar';
  /** default value when the textvar is not specified */
  default: Maybe<Scalars['Boolean']>;
  /** flags must be triggered before this textvar is active (used in interface only) */
  dependsOnFlag: Maybe<Scalars['String']>;
  /** description for the textvar itself */
  description: Maybe<Scalars['String']>;
  /** for apollo cache, just the key again, which is globally unique. */
  id: Scalars['ID'];
  /** e.g. questionnaire1_eigen_vraag1 */
  key: Scalars['String'];
};

export type TextvarValue = {
  __typename?: 'TextvarValue';
  /** Textvar key, can be used to look up the textvar in the questionnaire. */
  key: Scalars['String'];
  /** Value used when displaying the questionnaire or results. */
  value: Maybe<Scalars['String']>;
};

export type Timespan = {
  __typename?: 'Timespan';
  unit: TimespanUnitEnum;
  value: Scalars['Int'];
};

export enum TimespanUnitEnum {
  Days = 'DAYS',
  Hours = 'HOURS',
  Months = 'MONTHS',
  Weeks = 'WEEKS'
}

export type Token = {
  __typename?: 'Token';
  id: Scalars['ID'];
  key: Scalars['String'];
  respondent: Respondent;
};

/** Autogenerated input type of TouchNotificationPrintedAt */
export type TouchNotificationPrintedAtInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  notificationId: Scalars['ID'];
};

/** Autogenerated return type of TouchNotificationPrintedAt. */
export type TouchNotificationPrintedAtPayload = {
  __typename?: 'TouchNotificationPrintedAtPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId: Maybe<Scalars['String']>;
  errors: Array<UserError>;
  result: Maybe<TouchNotificationPrintedAtResult>;
};

export type TouchNotificationPrintedAtResult = {
  __typename?: 'TouchNotificationPrintedAtResult';
  notification: Notification;
};

/** A user-readable error */
export type UserError = {
  __typename?: 'UserError';
  message: Scalars['String'];
  path: Maybe<Array<Scalars['String']>>;
};

export type WhenAcceptedPrepareOnAcceptMeasurement = ResponseCallbackInterface & {
  __typename?: 'WhenAcceptedPrepareOnAcceptMeasurement';
  acceptOptionValue: Scalars['String'];
  acceptQuestionKey: Scalars['String'];
  description: Scalars['String'];
  measurementToPrepare: Measurement;
};

export type WhenAcceptedStartAutomaticProtocol = ResponseCallbackInterface & {
  __typename?: 'WhenAcceptedStartAutomaticProtocol';
  acceptOptionValue: Scalars['String'];
  acceptQuestionKey: Scalars['String'];
  autoProtocolToStart: Protocol;
  dailyStartTimeKey: Maybe<Scalars['String']>;
  description: Scalars['String'];
};

export type YRangeCategory = {
  __typename?: 'YRangeCategory';
  label: Scalars['String'];
  range: Range;
};

export type DossierTableQueryVariables = Exact<{
  organizationKey: Scalars['String'];
}>;


export type DossierTableQuery = (
  { __typename?: 'Query' }
  & { organization: (
    { __typename?: 'Organization' }
    & { dossierMetadataFieldDefinitions: Array<(
      { __typename?: 'DossierMetadataFieldDefinition' }
      & Pick<DossierMetadataFieldDefinition, 'id' | 'key' | 'label'>
    )>, internallyManagedDossiers: Array<(
      { __typename?: 'Dossier' }
      & Pick<Dossier, 'id' | 'epdId' | 'createdAt' | 'tester' | 'metadata'>
      & { stats: (
        { __typename?: 'DossierStats' }
        & Pick<DossierStats, 'totalResponses' | 'expiredResponses' | 'completedResponses' | 'lastCompletedResponse'>
      ), patientRespondent: (
        { __typename?: 'Respondent' }
        & Pick<Respondent, 'id' | 'firstname' | 'lastname' | 'email' | 'phoneCell'>
      ), demographics: (
        { __typename?: 'Demographics' }
        & Pick<Demographics, 'id' | 'ourGender' | 'ourBirthdate'>
      ) }
    )> }
  ) }
);

export type DossierPatientInfoFragment = (
  { __typename?: 'Dossier' }
  & Pick<Dossier, 'id' | 'epdId' | 'syncStatusMessage'>
  & { patientRespondent: (
    { __typename?: 'Respondent' }
    & Pick<Respondent, 'id' | 'formalName' | 'administrativeGender' | 'birthdate' | 'deceased' | 'syncStatus' | 'syncWarnings' | 'lastSyncSuccess'>
  ) }
);

export type CurrentDossierInfoQueryVariables = Exact<{
  organizationKey: Scalars['String'];
  dossierId: Scalars['ID'];
}>;


export type CurrentDossierInfoQuery = (
  { __typename?: 'Query' }
  & { organization: (
    { __typename?: 'Organization' }
    & Pick<Organization, 'epdNextUrl'>
  ), dossier: (
    { __typename?: 'Dossier' }
    & DossierPatientInfoFragment
  ) }
);

export type RefreshDossierMutationVariables = Exact<{
  input: RefreshDossierInput;
}>;


export type RefreshDossierMutation = (
  { __typename?: 'Mutation' }
  & { refreshDossier: Maybe<(
    { __typename?: 'RefreshDossierPayload' }
    & { result: Maybe<(
      { __typename?: 'RefreshDossierResult' }
      & { dossier: (
        { __typename?: 'Dossier' }
        & DossierPatientInfoFragment
      ) }
    )> }
  )> }
);

export type SelfInitiableMeasuredQuestionnairesForDossierQueryVariables = Exact<{
  dossierId: Scalars['ID'];
}>;


export type SelfInitiableMeasuredQuestionnairesForDossierQuery = (
  { __typename?: 'Query' }
  & { dossier: (
    { __typename?: 'Dossier' }
    & Pick<Dossier, 'id'>
    & { respondents: Array<(
      { __typename?: 'Respondent' }
      & RespondentWithSelfInitiableMeasuredQuestionnairesFragment
    )> }
  ) }
);

export type DeleteSelfInitiableMeasuredQuestionnaireMutationVariables = Exact<{
  input: SelfInitiableMeasuredQuestionnaireDeleteInput;
}>;


export type DeleteSelfInitiableMeasuredQuestionnaireMutation = (
  { __typename?: 'Mutation' }
  & { selfInitiableMeasuredQuestionnaireDelete: Maybe<(
    { __typename?: 'SelfInitiableMeasuredQuestionnaireDeletePayload' }
    & { result: Maybe<(
      { __typename?: 'SelfInitiableMeasuredQuestionnaireDeleteResult' }
      & { selfInitiableMeasuredQuestionnaire: (
        { __typename?: 'SelfInitiableMeasuredQuestionnaire' }
        & Pick<SelfInitiableMeasuredQuestionnaire, 'id'>
      ) }
    )> }
  )> }
);

export type RespondentWithSelfInitiableMeasuredQuestionnairesFragment = (
  { __typename?: 'Respondent' }
  & Pick<Respondent, 'id' | 'label' | 'type'>
  & { selfInitiableMeasuredQuestionnaires: (
    { __typename?: 'SelfInitiableMeasuredQuestionnaireConnection' }
    & { nodes: Array<(
      { __typename?: 'SelfInitiableMeasuredQuestionnaire' }
      & SelfInitiableMeasuredQuestionnaireFragment
    )> }
  ) }
);

export type SelfInitiableMeasuredQuestionnaireFragment = (
  { __typename?: 'SelfInitiableMeasuredQuestionnaire' }
  & Pick<SelfInitiableMeasuredQuestionnaire, 'id'>
  & { measuredQuestionnaire: (
    { __typename?: 'MeasuredQuestionnaire' }
    & Pick<MeasuredQuestionnaire, 'id'>
    & { questionnaire: (
      { __typename?: 'Questionnaire' }
      & Pick<Questionnaire, 'name'>
    ) }
  ) }
);

export type ManageTokensQueryVariables = Exact<{ [key: string]: never; }>;


export type ManageTokensQuery = (
  { __typename?: 'Query' }
  & { currentDossier: Maybe<(
    { __typename?: 'Dossier' }
    & Pick<Dossier, 'id'>
    & { respondents: Array<(
      { __typename?: 'Respondent' }
      & Pick<Respondent, 'id' | 'type'>
      & { token: (
        { __typename?: 'Token' }
        & Pick<Token, 'id' | 'key'>
      ) }
    )> }
  )> }
);

export type RevokeTokenMutationVariables = Exact<{
  tokenId: Scalars['ID'];
}>;


export type RevokeTokenMutation = (
  { __typename?: 'Mutation' }
  & { revokeToken: Maybe<(
    { __typename?: 'RevokeTokenPayload' }
    & { result: Maybe<(
      { __typename?: 'RevokeTokenResult' }
      & { respondent: (
        { __typename?: 'Respondent' }
        & Pick<Respondent, 'id'>
        & { token: (
          { __typename?: 'Token' }
          & Pick<Token, 'id' | 'key'>
        ) }
      ) }
    )> }
  )> }
);

export type TouchNotificationPrintedAtMutationVariables = Exact<{
  input: TouchNotificationPrintedAtInput;
}>;


export type TouchNotificationPrintedAtMutation = (
  { __typename?: 'Mutation' }
  & { touchNotificationPrintedAt: Maybe<(
    { __typename?: 'TouchNotificationPrintedAtPayload' }
    & { result: Maybe<(
      { __typename?: 'TouchNotificationPrintedAtResult' }
      & { notification: (
        { __typename?: 'Notification' }
        & Pick<Notification, 'printedAt'>
      ) }
    )>, errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message' | 'path'>
    )> }
  )> }
);

export type ResearchParticipationsListQueryVariables = Exact<{
  researchProjectId: Scalars['ID'];
}>;


export type ResearchParticipationsListQuery = (
  { __typename?: 'Query' }
  & { currentDossier: Maybe<(
    { __typename?: 'Dossier' }
    & Pick<Dossier, 'id'>
    & { patientRespondent: (
      { __typename?: 'Respondent' }
      & Pick<Respondent, 'id' | 'phoneCell'>
    ), researchParticipations: Array<(
      { __typename?: 'ResearchParticipation' }
      & Pick<ResearchParticipation, 'id' | 'name' | 'settings' | 'createdAt'>
      & { protocolSubscriptions: Array<(
        { __typename?: 'ProtocolSubscription' }
        & Pick<ProtocolSubscription, 'id' | 'createdAt' | 'startAt' | 'stopAt' | 'status' | 'measurementAmount'>
        & { protocol: (
          { __typename?: 'Protocol' }
          & Pick<Protocol, 'id' | 'name'>
          & { measurements: Array<(
            { __typename?: 'Measurement' }
            & Pick<Measurement, 'id'>
            & { questionnaires: Array<(
              { __typename?: 'Questionnaire' }
              & Pick<Questionnaire, 'id' | 'key'>
              & { flags: Array<(
                { __typename?: 'Flag' }
                & Pick<Flag, 'id' | 'defaultInInterface' | 'dependsOn' | 'description' | 'hidesQuestions' | 'key' | 'showsQuestions' | 'internal'>
              )> }
            )> }
          )> }
        ), flags: Array<(
          { __typename?: 'FlagValue' }
          & Pick<FlagValue, 'key' | 'value'>
        )>, textvars: Array<(
          { __typename?: 'TextvarValue' }
          & Pick<TextvarValue, 'key' | 'value'>
        )>, schedulerSettingsOverrides: Maybe<(
          { __typename?: 'DailySchedulerSettings' }
          & Pick<DailySchedulerSettings, 'dailyStartTime'>
        ) | (
          { __typename?: 'FixedSchedulerSettings' }
          & Pick<FixedSchedulerSettings, 'dailyStartTime'>
        ) | (
          { __typename?: 'IntervalSchedulerSettings' }
          & Pick<IntervalSchedulerSettings, 'dailyStartTime'>
        ) | (
          { __typename?: 'NullSchedulerSettings' }
          & Pick<NullSchedulerSettings, 'dailyStartTime'>
        ) | (
          { __typename?: 'RandomSchedulerSettings' }
          & Pick<RandomSchedulerSettings, 'blocksPerDay' | 'dailyStartTime'>
        )> }
      )> }
    )> }
  )> }
);

export type ResearchParticipationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResearchParticipationQuery = (
  { __typename?: 'Query' }
  & { currentDossier: Maybe<(
    { __typename?: 'Dossier' }
    & Pick<Dossier, 'id'>
    & { researchParticipation: (
      { __typename?: 'ResearchParticipation' }
      & Pick<ResearchParticipation, 'id' | 'name' | 'createdAt' | 'settings'>
      & { protocolSubscriptions: Array<(
        { __typename?: 'ProtocolSubscription' }
        & Pick<ProtocolSubscription, 'id' | 'startAt' | 'stopAt' | 'status'>
        & { flags: Array<(
          { __typename?: 'FlagValue' }
          & Pick<FlagValue, 'key' | 'value'>
        )>, textvars: Array<(
          { __typename?: 'TextvarValue' }
          & Pick<TextvarValue, 'key' | 'value'>
        )>, protocol: (
          { __typename?: 'Protocol' }
          & Pick<Protocol, 'id'>
          & { measurements: Array<(
            { __typename?: 'Measurement' }
            & Pick<Measurement, 'id'>
            & { questionnaires: Array<(
              { __typename?: 'Questionnaire' }
              & Pick<Questionnaire, 'id' | 'key'>
              & { flags: Array<(
                { __typename?: 'Flag' }
                & Pick<Flag, 'id' | 'defaultInInterface' | 'dependsOn' | 'description' | 'hidesQuestions' | 'key' | 'showsQuestions' | 'internal'>
              )>, textvars: Array<(
                { __typename?: 'Textvar' }
                & Pick<Textvar, 'id' | 'key' | 'description' | 'default' | 'dependsOnFlag'>
              )>, questions: Array<(
                { __typename: 'DatePartsQuestion' }
                & Pick<DatePartsQuestion, 'contextFreeTitle' | 'title' | 'key' | 'type'>
              ) | (
                { __typename: 'FloatQuestion' }
                & Pick<FloatQuestion, 'contextFreeTitle' | 'title' | 'key' | 'type'>
              ) | (
                { __typename: 'IntegerQuestion' }
                & Pick<IntegerQuestion, 'contextFreeTitle' | 'title' | 'key' | 'type'>
              ) | (
                { __typename: 'MultiSelectQuestion' }
                & Pick<MultiSelectQuestion, 'uncheckAllOption' | 'contextFreeTitle' | 'title' | 'key' | 'type'>
                & { options: Array<(
                  { __typename?: 'QuestionOption' }
                  & Pick<QuestionOption, 'key' | 'value' | 'description'>
                )> }
              ) | (
                { __typename: 'SingleSelectQuestion' }
                & Pick<SingleSelectQuestion, 'contextFreeTitle' | 'title' | 'key' | 'type'>
                & { options: Array<(
                  { __typename?: 'QuestionOption' }
                  & Pick<QuestionOption, 'key' | 'value' | 'description'>
                )> }
              ) | (
                { __typename: 'TextQuestion' }
                & Pick<TextQuestion, 'contextFreeTitle' | 'title' | 'key' | 'type'>
              )> }
            )> }
          )> }
        ), schedulerSettingsOverrides: Maybe<{ __typename?: 'DailySchedulerSettings' } | { __typename?: 'FixedSchedulerSettings' } | { __typename?: 'IntervalSchedulerSettings' } | { __typename?: 'NullSchedulerSettings' } | (
          { __typename?: 'RandomSchedulerSettings' }
          & Pick<RandomSchedulerSettings, 'blocksPerDay'>
        )> }
      )> }
    ) }
  )> }
);

export type PetraProjectWizardQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PetraProjectWizardQuery = (
  { __typename?: 'Query' }
  & { currentDossier: Maybe<(
    { __typename?: 'Dossier' }
    & Pick<Dossier, 'id'>
    & { patientRespondent: (
      { __typename?: 'Respondent' }
      & Pick<Respondent, 'id' | 'phoneCell'>
    ) }
  )>, currentOrganization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
    & { researchProject: (
      { __typename?: 'ResearchProject' }
      & Pick<ResearchProject, 'id' | 'name'>
      & { protocols: Array<(
        { __typename?: 'Protocol' }
        & Pick<Protocol, 'id'>
        & { autoProtocols: (
          { __typename?: 'AutoProtocolConnection' }
          & { nodes: Array<(
            { __typename?: 'AutoProtocol' }
            & Pick<AutoProtocol, 'id'>
          )> }
        ), measurements: Array<(
          { __typename?: 'Measurement' }
          & Pick<Measurement, 'id'>
          & { questionnaires: Array<(
            { __typename?: 'Questionnaire' }
            & Pick<Questionnaire, 'id' | 'key'>
            & { flags: Array<(
              { __typename?: 'Flag' }
              & Pick<Flag, 'id' | 'defaultInInterface' | 'dependsOn' | 'description' | 'hidesQuestions' | 'key' | 'showsQuestions' | 'internal'>
            )>, textvars: Array<(
              { __typename?: 'Textvar' }
              & Pick<Textvar, 'id' | 'key' | 'description' | 'default' | 'dependsOnFlag'>
            )>, questions: Array<(
              { __typename?: 'DatePartsQuestion' }
              & Pick<DatePartsQuestion, 'contextFreeTitle' | 'title' | 'key'>
            ) | (
              { __typename?: 'FloatQuestion' }
              & Pick<FloatQuestion, 'contextFreeTitle' | 'title' | 'key'>
            ) | (
              { __typename?: 'IntegerQuestion' }
              & Pick<IntegerQuestion, 'contextFreeTitle' | 'title' | 'key'>
            ) | (
              { __typename?: 'MultiSelectQuestion' }
              & Pick<MultiSelectQuestion, 'contextFreeTitle' | 'title' | 'key'>
            ) | (
              { __typename?: 'SingleSelectQuestion' }
              & Pick<SingleSelectQuestion, 'contextFreeTitle' | 'title' | 'key'>
            ) | (
              { __typename?: 'TextQuestion' }
              & Pick<TextQuestion, 'contextFreeTitle' | 'title' | 'key'>
            )> }
          )> }
        )> }
      )> }
    ) }
  )> }
);

export type ResponsesQueryVariables = Exact<{
  questionnaireIds: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  limit: Maybe<Scalars['Int']>;
  researchParticipationId: Maybe<Scalars['ID']>;
}>;


export type ResponsesQuery = (
  { __typename?: 'Query' }
  & { currentDossier: Maybe<(
    { __typename?: 'Dossier' }
    & Pick<Dossier, 'id'>
    & { responses: Array<(
      { __typename?: 'Response' }
      & Pick<Response, 'id' | 'status' | 'active' | 'completed' | 'completedAt' | 'openFrom'>
      & { values: Array<(
        { __typename?: 'ResponseValue' }
        & Pick<ResponseValue, 'key' | 'value'>
      )> }
    )> }
  )> }
);

export type ResponsesWithoutValuesQueryVariables = Exact<{
  questionnaireIds: Maybe<Array<Scalars['ID']> | Scalars['ID']>;
  limit: Maybe<Scalars['Int']>;
  researchParticipationId: Maybe<Scalars['ID']>;
}>;


export type ResponsesWithoutValuesQuery = (
  { __typename?: 'Query' }
  & { currentDossier: Maybe<(
    { __typename?: 'Dossier' }
    & Pick<Dossier, 'id'>
    & { responses: Array<(
      { __typename?: 'Response' }
      & Pick<Response, 'id' | 'status' | 'active' | 'completed' | 'completedAt' | 'openFrom'>
    )> }
  )> }
);

export type ReportTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ReportTemplatesQuery = (
  { __typename?: 'Query' }
  & { currentOrganization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
    & { reportTemplates: Array<(
      { __typename?: 'ReportTemplate' }
      & Pick<ReportTemplate, 'id' | 'name'>
    )> }
  )> }
);

export type PetraInformedConsentsQueryVariables = Exact<{
  questionnaireKey: Scalars['String'];
}>;


export type PetraInformedConsentsQuery = (
  { __typename?: 'Query' }
  & { currentDossier: Maybe<(
    { __typename?: 'Dossier' }
    & Pick<Dossier, 'id'>
    & { responses: Array<(
      { __typename?: 'Response' }
      & Pick<Response, 'id' | 'status'>
    )> }
  )> }
);

export type QubyFrontendV2QueryVariables = Exact<{
  questionnaireKey: Scalars['String'];
}>;


export type QubyFrontendV2Query = (
  { __typename?: 'Query' }
  & { currentOrganization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
    & { questionnaire: Maybe<(
      { __typename?: 'Questionnaire' }
      & Pick<Questionnaire, 'id' | 'qubyFrontendV2'>
    )> }
  )> }
);

export type ResearchParticipationCreateMutationVariables = Exact<{
  input: ResearchParticipationCreateInput;
}>;


export type ResearchParticipationCreateMutation = (
  { __typename?: 'Mutation' }
  & { researchParticipationCreate: Maybe<(
    { __typename?: 'ResearchParticipationCreatePayload' }
    & { result: Maybe<(
      { __typename?: 'ResearchParticipationCreateResult' }
      & { researchParticipation: (
        { __typename?: 'ResearchParticipation' }
        & Pick<ResearchParticipation, 'id' | 'name'>
      ), subscriptions: Array<(
        { __typename?: 'ProtocolSubscription' }
        & Pick<ProtocolSubscription, 'id'>
      )> }
    )>, errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'path' | 'message'>
    )> }
  )> }
);

export type ProtocolSubscriptionStopMutationVariables = Exact<{
  input: ProtocolSubscriptionStopInput;
}>;


export type ProtocolSubscriptionStopMutation = (
  { __typename?: 'Mutation' }
  & { protocolSubscriptionStop: Maybe<(
    { __typename?: 'ProtocolSubscriptionStopPayload' }
    & { result: Maybe<(
      { __typename?: 'ProtocolSubscriptionStopResult' }
      & { protocolSubscription: (
        { __typename?: 'ProtocolSubscription' }
        & Pick<ProtocolSubscription, 'id' | 'status'>
      ) }
    )>, errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message' | 'path'>
    )> }
  )> }
);

export type ReportCreateMutationVariables = Exact<{
  input: ReportCreateForHtmlInput;
}>;


export type ReportCreateMutation = (
  { __typename?: 'Mutation' }
  & { reportCreateForHtml: Maybe<(
    { __typename?: 'ReportCreateForHtmlPayload' }
    & { result: Maybe<(
      { __typename?: 'ReportCreateForHtmlResult' }
      & { report: (
        { __typename?: 'Report' }
        & Pick<Report, 'id' | 'name'>
      ) }
    )>, errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'message' | 'path'>
    )> }
  )> }
);

export type PetraStartInformedConsentMutationVariables = Exact<{
  input: FillOutRequestCreateInput;
}>;


export type PetraStartInformedConsentMutation = (
  { __typename?: 'Mutation' }
  & { fillOutRequestCreate: Maybe<(
    { __typename?: 'FillOutRequestCreatePayload' }
    & { result: Maybe<(
      { __typename?: 'FillOutRequestCreateResult' }
      & { fillOutRequest: (
        { __typename?: 'FillOutRequest' }
        & Pick<FillOutRequest, 'id'>
        & { responses: Array<(
          { __typename?: 'Response' }
          & Pick<Response, 'id'>
        )> }
      ) }
    )>, errors: Array<(
      { __typename?: 'UserError' }
      & Pick<UserError, 'path' | 'message'>
    )> }
  )> }
);

export type CurrentProfessionalFieldsFragment = (
  { __typename?: 'Professional' }
  & Pick<Professional, 'id'>
  & { team: Maybe<(
    { __typename?: 'Team' }
    & TeamFieldsFragment
  )> }
);

export type TeamsForOrganizationQueryVariables = Exact<{ [key: string]: never; }>;


export type TeamsForOrganizationQuery = (
  { __typename?: 'Query' }
  & { currentOrganization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
    & { teams: Array<(
      { __typename?: 'Team' }
      & TeamFieldsFragment
    )> }
  )> }
);

export type CurrentTeamQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentTeamQuery = (
  { __typename?: 'Query' }
  & { currentOrganization: Maybe<(
    { __typename?: 'Organization' }
    & Pick<Organization, 'id'>
    & { settings: (
      { __typename?: 'OrgSettings' }
      & Pick<OrgSettings, 'requireTeamBeforeSelectingProtocol'>
    ) }
  )>, session: (
    { __typename?: 'ApiSession' }
    & CurrentTeamFragment
  ) }
);

export type SwitchTeamMutationVariables = Exact<{
  input: ProfessionalSetTeamInput;
}>;


export type SwitchTeamMutation = (
  { __typename?: 'Mutation' }
  & { professionalSetTeam: Maybe<(
    { __typename?: 'ProfessionalSetTeamPayload' }
    & { result: Maybe<(
      { __typename?: 'ProfessionalSetTeamResult' }
      & { session: (
        { __typename?: 'ApiSession' }
        & CurrentTeamFragment
      ) }
    )> }
  )> }
);

export type CurrentTeamFragment = (
  { __typename?: 'ApiSession' }
  & Pick<ApiSession, 'id'>
  & { user: Maybe<{ __typename?: 'Account' } | { __typename?: 'Dossier' } | (
    { __typename?: 'Professional' }
    & CurrentProfessionalFieldsFragment
  ) | { __typename?: 'Respondent' }> }
);

export type TeamFieldsFragment = (
  { __typename?: 'Team' }
  & Pick<Team, 'id' | 'name'>
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { session: (
    { __typename?: 'ApiSession' }
    & Pick<ApiSession, 'id' | 'loggedIn' | 'type'>
    & { user: Maybe<{ __typename?: 'Account' } | { __typename?: 'Dossier' } | (
      { __typename?: 'Professional' }
      & CurrentProfessionalFieldsFragment
    ) | { __typename?: 'Respondent' }> }
  ) }
);

export const DossierPatientInfoFragmentDoc = gql`
    fragment DossierPatientInfo on Dossier {
  id
  epdId
  syncStatusMessage
  patientRespondent {
    id
    formalName
    administrativeGender
    birthdate
    deceased
    syncStatus
    syncWarnings
    lastSyncSuccess
  }
}
    `;
export const SelfInitiableMeasuredQuestionnaireFragmentDoc = gql`
    fragment SelfInitiableMeasuredQuestionnaire on SelfInitiableMeasuredQuestionnaire {
  id
  measuredQuestionnaire {
    id
    questionnaire {
      name
    }
  }
}
    `;
export const RespondentWithSelfInitiableMeasuredQuestionnairesFragmentDoc = gql`
    fragment RespondentWithSelfInitiableMeasuredQuestionnaires on Respondent {
  id
  label
  type
  selfInitiableMeasuredQuestionnaires {
    nodes {
      ...SelfInitiableMeasuredQuestionnaire
    }
  }
}
    ${SelfInitiableMeasuredQuestionnaireFragmentDoc}`;
export const TeamFieldsFragmentDoc = gql`
    fragment TeamFields on Team {
  id
  name
}
    `;
export const CurrentProfessionalFieldsFragmentDoc = gql`
    fragment CurrentProfessionalFields on Professional {
  id
  team {
    ...TeamFields
  }
}
    ${TeamFieldsFragmentDoc}`;
export const CurrentTeamFragmentDoc = gql`
    fragment CurrentTeam on ApiSession {
  id
  user {
    ... on Professional {
      ...CurrentProfessionalFields
    }
  }
}
    ${CurrentProfessionalFieldsFragmentDoc}`;
export const DossierTableDocument = gql`
    query DossierTable($organizationKey: String!) {
  organization(key: $organizationKey) {
    dossierMetadataFieldDefinitions(visible: true) {
      id
      key
      label
    }
    internallyManagedDossiers {
      id
      epdId
      createdAt
      tester
      metadata
      stats {
        totalResponses
        expiredResponses
        completedResponses
        lastCompletedResponse
      }
      patientRespondent {
        id
        firstname
        lastname
        email
        phoneCell
      }
      demographics {
        id
        ourGender
        ourBirthdate
      }
    }
  }
}
    `;
export type DossierTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DossierTableQuery, DossierTableQueryVariables>, 'query'> & ({ variables: DossierTableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DossierTableComponent = (props: DossierTableComponentProps) => (
      <ApolloReactComponents.Query<DossierTableQuery, DossierTableQueryVariables> query={DossierTableDocument} {...props} />
    );
    

/**
 * __useDossierTableQuery__
 *
 * To run a query within a React component, call `useDossierTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useDossierTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDossierTableQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *   },
 * });
 */
export function useDossierTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DossierTableQuery, DossierTableQueryVariables>) {
        return ApolloReactHooks.useQuery<DossierTableQuery, DossierTableQueryVariables>(DossierTableDocument, baseOptions);
      }
export function useDossierTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DossierTableQuery, DossierTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DossierTableQuery, DossierTableQueryVariables>(DossierTableDocument, baseOptions);
        }
export type DossierTableQueryHookResult = ReturnType<typeof useDossierTableQuery>;
export type DossierTableLazyQueryHookResult = ReturnType<typeof useDossierTableLazyQuery>;
export type DossierTableQueryResult = ApolloReactCommon.QueryResult<DossierTableQuery, DossierTableQueryVariables>;
export const CurrentDossierInfoDocument = gql`
    query CurrentDossierInfo($organizationKey: String!, $dossierId: ID!) {
  organization(key: $organizationKey) {
    epdNextUrl
  }
  dossier(id: $dossierId) {
    ...DossierPatientInfo
  }
}
    ${DossierPatientInfoFragmentDoc}`;
export type CurrentDossierInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CurrentDossierInfoQuery, CurrentDossierInfoQueryVariables>, 'query'> & ({ variables: CurrentDossierInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CurrentDossierInfoComponent = (props: CurrentDossierInfoComponentProps) => (
      <ApolloReactComponents.Query<CurrentDossierInfoQuery, CurrentDossierInfoQueryVariables> query={CurrentDossierInfoDocument} {...props} />
    );
    

/**
 * __useCurrentDossierInfoQuery__
 *
 * To run a query within a React component, call `useCurrentDossierInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentDossierInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentDossierInfoQuery({
 *   variables: {
 *      organizationKey: // value for 'organizationKey'
 *      dossierId: // value for 'dossierId'
 *   },
 * });
 */
export function useCurrentDossierInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentDossierInfoQuery, CurrentDossierInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentDossierInfoQuery, CurrentDossierInfoQueryVariables>(CurrentDossierInfoDocument, baseOptions);
      }
export function useCurrentDossierInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentDossierInfoQuery, CurrentDossierInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentDossierInfoQuery, CurrentDossierInfoQueryVariables>(CurrentDossierInfoDocument, baseOptions);
        }
export type CurrentDossierInfoQueryHookResult = ReturnType<typeof useCurrentDossierInfoQuery>;
export type CurrentDossierInfoLazyQueryHookResult = ReturnType<typeof useCurrentDossierInfoLazyQuery>;
export type CurrentDossierInfoQueryResult = ApolloReactCommon.QueryResult<CurrentDossierInfoQuery, CurrentDossierInfoQueryVariables>;
export const RefreshDossierDocument = gql`
    mutation RefreshDossier($input: RefreshDossierInput!) {
  refreshDossier(input: $input) {
    result {
      dossier {
        ...DossierPatientInfo
      }
    }
  }
}
    ${DossierPatientInfoFragmentDoc}`;
export type RefreshDossierMutationFn = ApolloReactCommon.MutationFunction<RefreshDossierMutation, RefreshDossierMutationVariables>;
export type RefreshDossierComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RefreshDossierMutation, RefreshDossierMutationVariables>, 'mutation'>;

    export const RefreshDossierComponent = (props: RefreshDossierComponentProps) => (
      <ApolloReactComponents.Mutation<RefreshDossierMutation, RefreshDossierMutationVariables> mutation={RefreshDossierDocument} {...props} />
    );
    

/**
 * __useRefreshDossierMutation__
 *
 * To run a mutation, you first call `useRefreshDossierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshDossierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshDossierMutation, { data, loading, error }] = useRefreshDossierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefreshDossierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RefreshDossierMutation, RefreshDossierMutationVariables>) {
        return ApolloReactHooks.useMutation<RefreshDossierMutation, RefreshDossierMutationVariables>(RefreshDossierDocument, baseOptions);
      }
export type RefreshDossierMutationHookResult = ReturnType<typeof useRefreshDossierMutation>;
export type RefreshDossierMutationResult = ApolloReactCommon.MutationResult<RefreshDossierMutation>;
export type RefreshDossierMutationOptions = ApolloReactCommon.BaseMutationOptions<RefreshDossierMutation, RefreshDossierMutationVariables>;
export const SelfInitiableMeasuredQuestionnairesForDossierDocument = gql`
    query SelfInitiableMeasuredQuestionnairesForDossier($dossierId: ID!) {
  dossier(id: $dossierId) {
    id
    respondents {
      ...RespondentWithSelfInitiableMeasuredQuestionnaires
    }
  }
}
    ${RespondentWithSelfInitiableMeasuredQuestionnairesFragmentDoc}`;
export type SelfInitiableMeasuredQuestionnairesForDossierComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SelfInitiableMeasuredQuestionnairesForDossierQuery, SelfInitiableMeasuredQuestionnairesForDossierQueryVariables>, 'query'> & ({ variables: SelfInitiableMeasuredQuestionnairesForDossierQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SelfInitiableMeasuredQuestionnairesForDossierComponent = (props: SelfInitiableMeasuredQuestionnairesForDossierComponentProps) => (
      <ApolloReactComponents.Query<SelfInitiableMeasuredQuestionnairesForDossierQuery, SelfInitiableMeasuredQuestionnairesForDossierQueryVariables> query={SelfInitiableMeasuredQuestionnairesForDossierDocument} {...props} />
    );
    

/**
 * __useSelfInitiableMeasuredQuestionnairesForDossierQuery__
 *
 * To run a query within a React component, call `useSelfInitiableMeasuredQuestionnairesForDossierQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfInitiableMeasuredQuestionnairesForDossierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfInitiableMeasuredQuestionnairesForDossierQuery({
 *   variables: {
 *      dossierId: // value for 'dossierId'
 *   },
 * });
 */
export function useSelfInitiableMeasuredQuestionnairesForDossierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SelfInitiableMeasuredQuestionnairesForDossierQuery, SelfInitiableMeasuredQuestionnairesForDossierQueryVariables>) {
        return ApolloReactHooks.useQuery<SelfInitiableMeasuredQuestionnairesForDossierQuery, SelfInitiableMeasuredQuestionnairesForDossierQueryVariables>(SelfInitiableMeasuredQuestionnairesForDossierDocument, baseOptions);
      }
export function useSelfInitiableMeasuredQuestionnairesForDossierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SelfInitiableMeasuredQuestionnairesForDossierQuery, SelfInitiableMeasuredQuestionnairesForDossierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SelfInitiableMeasuredQuestionnairesForDossierQuery, SelfInitiableMeasuredQuestionnairesForDossierQueryVariables>(SelfInitiableMeasuredQuestionnairesForDossierDocument, baseOptions);
        }
export type SelfInitiableMeasuredQuestionnairesForDossierQueryHookResult = ReturnType<typeof useSelfInitiableMeasuredQuestionnairesForDossierQuery>;
export type SelfInitiableMeasuredQuestionnairesForDossierLazyQueryHookResult = ReturnType<typeof useSelfInitiableMeasuredQuestionnairesForDossierLazyQuery>;
export type SelfInitiableMeasuredQuestionnairesForDossierQueryResult = ApolloReactCommon.QueryResult<SelfInitiableMeasuredQuestionnairesForDossierQuery, SelfInitiableMeasuredQuestionnairesForDossierQueryVariables>;
export const DeleteSelfInitiableMeasuredQuestionnaireDocument = gql`
    mutation DeleteSelfInitiableMeasuredQuestionnaire($input: SelfInitiableMeasuredQuestionnaireDeleteInput!) {
  selfInitiableMeasuredQuestionnaireDelete(input: $input) {
    result {
      selfInitiableMeasuredQuestionnaire {
        id
      }
    }
  }
}
    `;
export type DeleteSelfInitiableMeasuredQuestionnaireMutationFn = ApolloReactCommon.MutationFunction<DeleteSelfInitiableMeasuredQuestionnaireMutation, DeleteSelfInitiableMeasuredQuestionnaireMutationVariables>;
export type DeleteSelfInitiableMeasuredQuestionnaireComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteSelfInitiableMeasuredQuestionnaireMutation, DeleteSelfInitiableMeasuredQuestionnaireMutationVariables>, 'mutation'>;

    export const DeleteSelfInitiableMeasuredQuestionnaireComponent = (props: DeleteSelfInitiableMeasuredQuestionnaireComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteSelfInitiableMeasuredQuestionnaireMutation, DeleteSelfInitiableMeasuredQuestionnaireMutationVariables> mutation={DeleteSelfInitiableMeasuredQuestionnaireDocument} {...props} />
    );
    

/**
 * __useDeleteSelfInitiableMeasuredQuestionnaireMutation__
 *
 * To run a mutation, you first call `useDeleteSelfInitiableMeasuredQuestionnaireMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSelfInitiableMeasuredQuestionnaireMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSelfInitiableMeasuredQuestionnaireMutation, { data, loading, error }] = useDeleteSelfInitiableMeasuredQuestionnaireMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteSelfInitiableMeasuredQuestionnaireMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSelfInitiableMeasuredQuestionnaireMutation, DeleteSelfInitiableMeasuredQuestionnaireMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSelfInitiableMeasuredQuestionnaireMutation, DeleteSelfInitiableMeasuredQuestionnaireMutationVariables>(DeleteSelfInitiableMeasuredQuestionnaireDocument, baseOptions);
      }
export type DeleteSelfInitiableMeasuredQuestionnaireMutationHookResult = ReturnType<typeof useDeleteSelfInitiableMeasuredQuestionnaireMutation>;
export type DeleteSelfInitiableMeasuredQuestionnaireMutationResult = ApolloReactCommon.MutationResult<DeleteSelfInitiableMeasuredQuestionnaireMutation>;
export type DeleteSelfInitiableMeasuredQuestionnaireMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSelfInitiableMeasuredQuestionnaireMutation, DeleteSelfInitiableMeasuredQuestionnaireMutationVariables>;
export const ManageTokensDocument = gql`
    query ManageTokens {
  currentDossier {
    id
    respondents {
      id
      type
      token {
        id
        key
      }
    }
  }
}
    `;
export type ManageTokensComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ManageTokensQuery, ManageTokensQueryVariables>, 'query'>;

    export const ManageTokensComponent = (props: ManageTokensComponentProps) => (
      <ApolloReactComponents.Query<ManageTokensQuery, ManageTokensQueryVariables> query={ManageTokensDocument} {...props} />
    );
    

/**
 * __useManageTokensQuery__
 *
 * To run a query within a React component, call `useManageTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useManageTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManageTokensQuery({
 *   variables: {
 *   },
 * });
 */
export function useManageTokensQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ManageTokensQuery, ManageTokensQueryVariables>) {
        return ApolloReactHooks.useQuery<ManageTokensQuery, ManageTokensQueryVariables>(ManageTokensDocument, baseOptions);
      }
export function useManageTokensLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ManageTokensQuery, ManageTokensQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ManageTokensQuery, ManageTokensQueryVariables>(ManageTokensDocument, baseOptions);
        }
export type ManageTokensQueryHookResult = ReturnType<typeof useManageTokensQuery>;
export type ManageTokensLazyQueryHookResult = ReturnType<typeof useManageTokensLazyQuery>;
export type ManageTokensQueryResult = ApolloReactCommon.QueryResult<ManageTokensQuery, ManageTokensQueryVariables>;
export const RevokeTokenDocument = gql`
    mutation RevokeToken($tokenId: ID!) {
  revokeToken(input: {tokenId: $tokenId}) {
    result {
      respondent {
        id
        token {
          id
          key
        }
      }
    }
  }
}
    `;
export type RevokeTokenMutationFn = ApolloReactCommon.MutationFunction<RevokeTokenMutation, RevokeTokenMutationVariables>;
export type RevokeTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RevokeTokenMutation, RevokeTokenMutationVariables>, 'mutation'>;

    export const RevokeTokenComponent = (props: RevokeTokenComponentProps) => (
      <ApolloReactComponents.Mutation<RevokeTokenMutation, RevokeTokenMutationVariables> mutation={RevokeTokenDocument} {...props} />
    );
    

/**
 * __useRevokeTokenMutation__
 *
 * To run a mutation, you first call `useRevokeTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeTokenMutation, { data, loading, error }] = useRevokeTokenMutation({
 *   variables: {
 *      tokenId: // value for 'tokenId'
 *   },
 * });
 */
export function useRevokeTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevokeTokenMutation, RevokeTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<RevokeTokenMutation, RevokeTokenMutationVariables>(RevokeTokenDocument, baseOptions);
      }
export type RevokeTokenMutationHookResult = ReturnType<typeof useRevokeTokenMutation>;
export type RevokeTokenMutationResult = ApolloReactCommon.MutationResult<RevokeTokenMutation>;
export type RevokeTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<RevokeTokenMutation, RevokeTokenMutationVariables>;
export const TouchNotificationPrintedAtDocument = gql`
    mutation TouchNotificationPrintedAt($input: TouchNotificationPrintedAtInput!) {
  touchNotificationPrintedAt(input: $input) {
    result {
      notification {
        printedAt
      }
    }
    errors {
      message
      path
    }
  }
}
    `;
export type TouchNotificationPrintedAtMutationFn = ApolloReactCommon.MutationFunction<TouchNotificationPrintedAtMutation, TouchNotificationPrintedAtMutationVariables>;
export type TouchNotificationPrintedAtComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TouchNotificationPrintedAtMutation, TouchNotificationPrintedAtMutationVariables>, 'mutation'>;

    export const TouchNotificationPrintedAtComponent = (props: TouchNotificationPrintedAtComponentProps) => (
      <ApolloReactComponents.Mutation<TouchNotificationPrintedAtMutation, TouchNotificationPrintedAtMutationVariables> mutation={TouchNotificationPrintedAtDocument} {...props} />
    );
    

/**
 * __useTouchNotificationPrintedAtMutation__
 *
 * To run a mutation, you first call `useTouchNotificationPrintedAtMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTouchNotificationPrintedAtMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [touchNotificationPrintedAtMutation, { data, loading, error }] = useTouchNotificationPrintedAtMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTouchNotificationPrintedAtMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TouchNotificationPrintedAtMutation, TouchNotificationPrintedAtMutationVariables>) {
        return ApolloReactHooks.useMutation<TouchNotificationPrintedAtMutation, TouchNotificationPrintedAtMutationVariables>(TouchNotificationPrintedAtDocument, baseOptions);
      }
export type TouchNotificationPrintedAtMutationHookResult = ReturnType<typeof useTouchNotificationPrintedAtMutation>;
export type TouchNotificationPrintedAtMutationResult = ApolloReactCommon.MutationResult<TouchNotificationPrintedAtMutation>;
export type TouchNotificationPrintedAtMutationOptions = ApolloReactCommon.BaseMutationOptions<TouchNotificationPrintedAtMutation, TouchNotificationPrintedAtMutationVariables>;
export const ResearchParticipationsListDocument = gql`
    query ResearchParticipationsList($researchProjectId: ID!) {
  currentDossier {
    id
    patientRespondent {
      id
      phoneCell
    }
    researchParticipations(researchProjectId: $researchProjectId) {
      id
      name
      settings
      protocolSubscriptions {
        id
        createdAt
        protocol {
          id
          name
          measurements {
            id
            questionnaires {
              id
              key
              flags {
                id
                defaultInInterface
                dependsOn
                description
                hidesQuestions
                key
                showsQuestions
                internal
              }
            }
          }
        }
        startAt
        stopAt
        status
        flags {
          key
          value
        }
        textvars {
          key
          value
        }
        schedulerSettingsOverrides {
          dailyStartTime
          ... on RandomSchedulerSettings {
            blocksPerDay
          }
        }
        measurementAmount
      }
      createdAt
    }
  }
}
    `;
export type ResearchParticipationsListComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ResearchParticipationsListQuery, ResearchParticipationsListQueryVariables>, 'query'> & ({ variables: ResearchParticipationsListQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ResearchParticipationsListComponent = (props: ResearchParticipationsListComponentProps) => (
      <ApolloReactComponents.Query<ResearchParticipationsListQuery, ResearchParticipationsListQueryVariables> query={ResearchParticipationsListDocument} {...props} />
    );
    

/**
 * __useResearchParticipationsListQuery__
 *
 * To run a query within a React component, call `useResearchParticipationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useResearchParticipationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResearchParticipationsListQuery({
 *   variables: {
 *      researchProjectId: // value for 'researchProjectId'
 *   },
 * });
 */
export function useResearchParticipationsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResearchParticipationsListQuery, ResearchParticipationsListQueryVariables>) {
        return ApolloReactHooks.useQuery<ResearchParticipationsListQuery, ResearchParticipationsListQueryVariables>(ResearchParticipationsListDocument, baseOptions);
      }
export function useResearchParticipationsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResearchParticipationsListQuery, ResearchParticipationsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResearchParticipationsListQuery, ResearchParticipationsListQueryVariables>(ResearchParticipationsListDocument, baseOptions);
        }
export type ResearchParticipationsListQueryHookResult = ReturnType<typeof useResearchParticipationsListQuery>;
export type ResearchParticipationsListLazyQueryHookResult = ReturnType<typeof useResearchParticipationsListLazyQuery>;
export type ResearchParticipationsListQueryResult = ApolloReactCommon.QueryResult<ResearchParticipationsListQuery, ResearchParticipationsListQueryVariables>;
export const ResearchParticipationDocument = gql`
    query ResearchParticipation($id: ID!) {
  currentDossier {
    id
    researchParticipation(id: $id) {
      id
      name
      createdAt
      settings
      protocolSubscriptions {
        id
        flags {
          key
          value
        }
        textvars {
          key
          value
        }
        protocol {
          id
          measurements {
            id
            questionnaires {
              id
              key
              flags {
                id
                defaultInInterface
                dependsOn
                description
                hidesQuestions
                key
                showsQuestions
                internal
              }
              textvars {
                id
                key
                description
                default
                dependsOnFlag
              }
              questions {
                __typename
                contextFreeTitle
                title
                key
                type
                ... on QuestionWithOptionsInterface {
                  options {
                    key
                    value
                    description
                  }
                }
                ... on MultiSelectQuestion {
                  uncheckAllOption
                }
              }
            }
          }
        }
        startAt
        stopAt
        status
        schedulerSettingsOverrides {
          ... on RandomSchedulerSettings {
            blocksPerDay
          }
        }
      }
    }
  }
}
    `;
export type ResearchParticipationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ResearchParticipationQuery, ResearchParticipationQueryVariables>, 'query'> & ({ variables: ResearchParticipationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ResearchParticipationComponent = (props: ResearchParticipationComponentProps) => (
      <ApolloReactComponents.Query<ResearchParticipationQuery, ResearchParticipationQueryVariables> query={ResearchParticipationDocument} {...props} />
    );
    

/**
 * __useResearchParticipationQuery__
 *
 * To run a query within a React component, call `useResearchParticipationQuery` and pass it any options that fit your needs.
 * When your component renders, `useResearchParticipationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResearchParticipationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResearchParticipationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResearchParticipationQuery, ResearchParticipationQueryVariables>) {
        return ApolloReactHooks.useQuery<ResearchParticipationQuery, ResearchParticipationQueryVariables>(ResearchParticipationDocument, baseOptions);
      }
export function useResearchParticipationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResearchParticipationQuery, ResearchParticipationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResearchParticipationQuery, ResearchParticipationQueryVariables>(ResearchParticipationDocument, baseOptions);
        }
export type ResearchParticipationQueryHookResult = ReturnType<typeof useResearchParticipationQuery>;
export type ResearchParticipationLazyQueryHookResult = ReturnType<typeof useResearchParticipationLazyQuery>;
export type ResearchParticipationQueryResult = ApolloReactCommon.QueryResult<ResearchParticipationQuery, ResearchParticipationQueryVariables>;
export const PetraProjectWizardDocument = gql`
    query PetraProjectWizard($id: ID!) {
  currentDossier {
    id
    patientRespondent {
      id
      phoneCell
    }
  }
  currentOrganization {
    id
    researchProject(id: $id) {
      id
      name
      protocols {
        id
        autoProtocols {
          nodes {
            id
          }
        }
        measurements {
          id
          questionnaires {
            id
            key
            flags {
              id
              defaultInInterface
              dependsOn
              description
              hidesQuestions
              key
              showsQuestions
              internal
            }
            textvars {
              id
              key
              description
              default
              dependsOnFlag
            }
            questions {
              contextFreeTitle
              title
              key
            }
          }
        }
      }
    }
  }
}
    `;
export type PetraProjectWizardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PetraProjectWizardQuery, PetraProjectWizardQueryVariables>, 'query'> & ({ variables: PetraProjectWizardQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PetraProjectWizardComponent = (props: PetraProjectWizardComponentProps) => (
      <ApolloReactComponents.Query<PetraProjectWizardQuery, PetraProjectWizardQueryVariables> query={PetraProjectWizardDocument} {...props} />
    );
    

/**
 * __usePetraProjectWizardQuery__
 *
 * To run a query within a React component, call `usePetraProjectWizardQuery` and pass it any options that fit your needs.
 * When your component renders, `usePetraProjectWizardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePetraProjectWizardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePetraProjectWizardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PetraProjectWizardQuery, PetraProjectWizardQueryVariables>) {
        return ApolloReactHooks.useQuery<PetraProjectWizardQuery, PetraProjectWizardQueryVariables>(PetraProjectWizardDocument, baseOptions);
      }
export function usePetraProjectWizardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PetraProjectWizardQuery, PetraProjectWizardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PetraProjectWizardQuery, PetraProjectWizardQueryVariables>(PetraProjectWizardDocument, baseOptions);
        }
export type PetraProjectWizardQueryHookResult = ReturnType<typeof usePetraProjectWizardQuery>;
export type PetraProjectWizardLazyQueryHookResult = ReturnType<typeof usePetraProjectWizardLazyQuery>;
export type PetraProjectWizardQueryResult = ApolloReactCommon.QueryResult<PetraProjectWizardQuery, PetraProjectWizardQueryVariables>;
export const ResponsesDocument = gql`
    query Responses($questionnaireIds: [ID!], $limit: Int, $researchParticipationId: ID) {
  currentDossier {
    id
    responses(
      questionnaireIds: $questionnaireIds
      limit: $limit
      researchParticipationId: $researchParticipationId
    ) {
      id
      status
      active
      completed
      completedAt
      openFrom
      values {
        key
        value
      }
    }
  }
}
    `;
export type ResponsesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ResponsesQuery, ResponsesQueryVariables>, 'query'>;

    export const ResponsesComponent = (props: ResponsesComponentProps) => (
      <ApolloReactComponents.Query<ResponsesQuery, ResponsesQueryVariables> query={ResponsesDocument} {...props} />
    );
    

/**
 * __useResponsesQuery__
 *
 * To run a query within a React component, call `useResponsesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResponsesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResponsesQuery({
 *   variables: {
 *      questionnaireIds: // value for 'questionnaireIds'
 *      limit: // value for 'limit'
 *      researchParticipationId: // value for 'researchParticipationId'
 *   },
 * });
 */
export function useResponsesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResponsesQuery, ResponsesQueryVariables>) {
        return ApolloReactHooks.useQuery<ResponsesQuery, ResponsesQueryVariables>(ResponsesDocument, baseOptions);
      }
export function useResponsesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResponsesQuery, ResponsesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResponsesQuery, ResponsesQueryVariables>(ResponsesDocument, baseOptions);
        }
export type ResponsesQueryHookResult = ReturnType<typeof useResponsesQuery>;
export type ResponsesLazyQueryHookResult = ReturnType<typeof useResponsesLazyQuery>;
export type ResponsesQueryResult = ApolloReactCommon.QueryResult<ResponsesQuery, ResponsesQueryVariables>;
export const ResponsesWithoutValuesDocument = gql`
    query ResponsesWithoutValues($questionnaireIds: [ID!], $limit: Int, $researchParticipationId: ID) {
  currentDossier {
    id
    responses(
      questionnaireIds: $questionnaireIds
      limit: $limit
      researchParticipationId: $researchParticipationId
    ) {
      id
      status
      active
      completed
      completedAt
      openFrom
    }
  }
}
    `;
export type ResponsesWithoutValuesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ResponsesWithoutValuesQuery, ResponsesWithoutValuesQueryVariables>, 'query'>;

    export const ResponsesWithoutValuesComponent = (props: ResponsesWithoutValuesComponentProps) => (
      <ApolloReactComponents.Query<ResponsesWithoutValuesQuery, ResponsesWithoutValuesQueryVariables> query={ResponsesWithoutValuesDocument} {...props} />
    );
    

/**
 * __useResponsesWithoutValuesQuery__
 *
 * To run a query within a React component, call `useResponsesWithoutValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResponsesWithoutValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResponsesWithoutValuesQuery({
 *   variables: {
 *      questionnaireIds: // value for 'questionnaireIds'
 *      limit: // value for 'limit'
 *      researchParticipationId: // value for 'researchParticipationId'
 *   },
 * });
 */
export function useResponsesWithoutValuesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResponsesWithoutValuesQuery, ResponsesWithoutValuesQueryVariables>) {
        return ApolloReactHooks.useQuery<ResponsesWithoutValuesQuery, ResponsesWithoutValuesQueryVariables>(ResponsesWithoutValuesDocument, baseOptions);
      }
export function useResponsesWithoutValuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResponsesWithoutValuesQuery, ResponsesWithoutValuesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResponsesWithoutValuesQuery, ResponsesWithoutValuesQueryVariables>(ResponsesWithoutValuesDocument, baseOptions);
        }
export type ResponsesWithoutValuesQueryHookResult = ReturnType<typeof useResponsesWithoutValuesQuery>;
export type ResponsesWithoutValuesLazyQueryHookResult = ReturnType<typeof useResponsesWithoutValuesLazyQuery>;
export type ResponsesWithoutValuesQueryResult = ApolloReactCommon.QueryResult<ResponsesWithoutValuesQuery, ResponsesWithoutValuesQueryVariables>;
export const ReportTemplatesDocument = gql`
    query ReportTemplates {
  currentOrganization {
    id
    reportTemplates {
      id
      name
    }
  }
}
    `;
export type ReportTemplatesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ReportTemplatesQuery, ReportTemplatesQueryVariables>, 'query'>;

    export const ReportTemplatesComponent = (props: ReportTemplatesComponentProps) => (
      <ApolloReactComponents.Query<ReportTemplatesQuery, ReportTemplatesQueryVariables> query={ReportTemplatesDocument} {...props} />
    );
    

/**
 * __useReportTemplatesQuery__
 *
 * To run a query within a React component, call `useReportTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportTemplatesQuery, ReportTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<ReportTemplatesQuery, ReportTemplatesQueryVariables>(ReportTemplatesDocument, baseOptions);
      }
export function useReportTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportTemplatesQuery, ReportTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReportTemplatesQuery, ReportTemplatesQueryVariables>(ReportTemplatesDocument, baseOptions);
        }
export type ReportTemplatesQueryHookResult = ReturnType<typeof useReportTemplatesQuery>;
export type ReportTemplatesLazyQueryHookResult = ReturnType<typeof useReportTemplatesLazyQuery>;
export type ReportTemplatesQueryResult = ApolloReactCommon.QueryResult<ReportTemplatesQuery, ReportTemplatesQueryVariables>;
export const PetraInformedConsentsDocument = gql`
    query PetraInformedConsents($questionnaireKey: String!) {
  currentDossier {
    id
    responses(questionnaireKeys: [$questionnaireKey]) {
      id
      status
    }
  }
}
    `;
export type PetraInformedConsentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PetraInformedConsentsQuery, PetraInformedConsentsQueryVariables>, 'query'> & ({ variables: PetraInformedConsentsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PetraInformedConsentsComponent = (props: PetraInformedConsentsComponentProps) => (
      <ApolloReactComponents.Query<PetraInformedConsentsQuery, PetraInformedConsentsQueryVariables> query={PetraInformedConsentsDocument} {...props} />
    );
    

/**
 * __usePetraInformedConsentsQuery__
 *
 * To run a query within a React component, call `usePetraInformedConsentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePetraInformedConsentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePetraInformedConsentsQuery({
 *   variables: {
 *      questionnaireKey: // value for 'questionnaireKey'
 *   },
 * });
 */
export function usePetraInformedConsentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PetraInformedConsentsQuery, PetraInformedConsentsQueryVariables>) {
        return ApolloReactHooks.useQuery<PetraInformedConsentsQuery, PetraInformedConsentsQueryVariables>(PetraInformedConsentsDocument, baseOptions);
      }
export function usePetraInformedConsentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PetraInformedConsentsQuery, PetraInformedConsentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PetraInformedConsentsQuery, PetraInformedConsentsQueryVariables>(PetraInformedConsentsDocument, baseOptions);
        }
export type PetraInformedConsentsQueryHookResult = ReturnType<typeof usePetraInformedConsentsQuery>;
export type PetraInformedConsentsLazyQueryHookResult = ReturnType<typeof usePetraInformedConsentsLazyQuery>;
export type PetraInformedConsentsQueryResult = ApolloReactCommon.QueryResult<PetraInformedConsentsQuery, PetraInformedConsentsQueryVariables>;
export const QubyFrontendV2Document = gql`
    query QubyFrontendV2($questionnaireKey: String!) {
  currentOrganization {
    id
    questionnaire(key: $questionnaireKey) {
      id
      qubyFrontendV2
    }
  }
}
    `;
export type QubyFrontendV2ComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<QubyFrontendV2Query, QubyFrontendV2QueryVariables>, 'query'> & ({ variables: QubyFrontendV2QueryVariables; skip?: boolean; } | { skip: boolean; });

    export const QubyFrontendV2Component = (props: QubyFrontendV2ComponentProps) => (
      <ApolloReactComponents.Query<QubyFrontendV2Query, QubyFrontendV2QueryVariables> query={QubyFrontendV2Document} {...props} />
    );
    

/**
 * __useQubyFrontendV2Query__
 *
 * To run a query within a React component, call `useQubyFrontendV2Query` and pass it any options that fit your needs.
 * When your component renders, `useQubyFrontendV2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQubyFrontendV2Query({
 *   variables: {
 *      questionnaireKey: // value for 'questionnaireKey'
 *   },
 * });
 */
export function useQubyFrontendV2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<QubyFrontendV2Query, QubyFrontendV2QueryVariables>) {
        return ApolloReactHooks.useQuery<QubyFrontendV2Query, QubyFrontendV2QueryVariables>(QubyFrontendV2Document, baseOptions);
      }
export function useQubyFrontendV2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QubyFrontendV2Query, QubyFrontendV2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QubyFrontendV2Query, QubyFrontendV2QueryVariables>(QubyFrontendV2Document, baseOptions);
        }
export type QubyFrontendV2QueryHookResult = ReturnType<typeof useQubyFrontendV2Query>;
export type QubyFrontendV2LazyQueryHookResult = ReturnType<typeof useQubyFrontendV2LazyQuery>;
export type QubyFrontendV2QueryResult = ApolloReactCommon.QueryResult<QubyFrontendV2Query, QubyFrontendV2QueryVariables>;
export const ResearchParticipationCreateDocument = gql`
    mutation ResearchParticipationCreate($input: ResearchParticipationCreateInput!) {
  researchParticipationCreate(input: $input) {
    result {
      researchParticipation {
        id
        name
      }
      subscriptions {
        id
      }
    }
    errors {
      path
      message
    }
  }
}
    `;
export type ResearchParticipationCreateMutationFn = ApolloReactCommon.MutationFunction<ResearchParticipationCreateMutation, ResearchParticipationCreateMutationVariables>;
export type ResearchParticipationCreateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResearchParticipationCreateMutation, ResearchParticipationCreateMutationVariables>, 'mutation'>;

    export const ResearchParticipationCreateComponent = (props: ResearchParticipationCreateComponentProps) => (
      <ApolloReactComponents.Mutation<ResearchParticipationCreateMutation, ResearchParticipationCreateMutationVariables> mutation={ResearchParticipationCreateDocument} {...props} />
    );
    

/**
 * __useResearchParticipationCreateMutation__
 *
 * To run a mutation, you first call `useResearchParticipationCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResearchParticipationCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [researchParticipationCreateMutation, { data, loading, error }] = useResearchParticipationCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResearchParticipationCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResearchParticipationCreateMutation, ResearchParticipationCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<ResearchParticipationCreateMutation, ResearchParticipationCreateMutationVariables>(ResearchParticipationCreateDocument, baseOptions);
      }
export type ResearchParticipationCreateMutationHookResult = ReturnType<typeof useResearchParticipationCreateMutation>;
export type ResearchParticipationCreateMutationResult = ApolloReactCommon.MutationResult<ResearchParticipationCreateMutation>;
export type ResearchParticipationCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<ResearchParticipationCreateMutation, ResearchParticipationCreateMutationVariables>;
export const ProtocolSubscriptionStopDocument = gql`
    mutation ProtocolSubscriptionStop($input: ProtocolSubscriptionStopInput!) {
  protocolSubscriptionStop(input: $input) {
    result {
      protocolSubscription {
        id
        status
      }
    }
    errors {
      message
      path
    }
  }
}
    `;
export type ProtocolSubscriptionStopMutationFn = ApolloReactCommon.MutationFunction<ProtocolSubscriptionStopMutation, ProtocolSubscriptionStopMutationVariables>;
export type ProtocolSubscriptionStopComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ProtocolSubscriptionStopMutation, ProtocolSubscriptionStopMutationVariables>, 'mutation'>;

    export const ProtocolSubscriptionStopComponent = (props: ProtocolSubscriptionStopComponentProps) => (
      <ApolloReactComponents.Mutation<ProtocolSubscriptionStopMutation, ProtocolSubscriptionStopMutationVariables> mutation={ProtocolSubscriptionStopDocument} {...props} />
    );
    

/**
 * __useProtocolSubscriptionStopMutation__
 *
 * To run a mutation, you first call `useProtocolSubscriptionStopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProtocolSubscriptionStopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [protocolSubscriptionStopMutation, { data, loading, error }] = useProtocolSubscriptionStopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProtocolSubscriptionStopMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProtocolSubscriptionStopMutation, ProtocolSubscriptionStopMutationVariables>) {
        return ApolloReactHooks.useMutation<ProtocolSubscriptionStopMutation, ProtocolSubscriptionStopMutationVariables>(ProtocolSubscriptionStopDocument, baseOptions);
      }
export type ProtocolSubscriptionStopMutationHookResult = ReturnType<typeof useProtocolSubscriptionStopMutation>;
export type ProtocolSubscriptionStopMutationResult = ApolloReactCommon.MutationResult<ProtocolSubscriptionStopMutation>;
export type ProtocolSubscriptionStopMutationOptions = ApolloReactCommon.BaseMutationOptions<ProtocolSubscriptionStopMutation, ProtocolSubscriptionStopMutationVariables>;
export const ReportCreateDocument = gql`
    mutation ReportCreate($input: ReportCreateForHtmlInput!) {
  reportCreateForHtml(input: $input) {
    result {
      report {
        id
        name
      }
    }
    errors {
      message
      path
    }
  }
}
    `;
export type ReportCreateMutationFn = ApolloReactCommon.MutationFunction<ReportCreateMutation, ReportCreateMutationVariables>;
export type ReportCreateComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ReportCreateMutation, ReportCreateMutationVariables>, 'mutation'>;

    export const ReportCreateComponent = (props: ReportCreateComponentProps) => (
      <ApolloReactComponents.Mutation<ReportCreateMutation, ReportCreateMutationVariables> mutation={ReportCreateDocument} {...props} />
    );
    

/**
 * __useReportCreateMutation__
 *
 * To run a mutation, you first call `useReportCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportCreateMutation, { data, loading, error }] = useReportCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportCreateMutation, ReportCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportCreateMutation, ReportCreateMutationVariables>(ReportCreateDocument, baseOptions);
      }
export type ReportCreateMutationHookResult = ReturnType<typeof useReportCreateMutation>;
export type ReportCreateMutationResult = ApolloReactCommon.MutationResult<ReportCreateMutation>;
export type ReportCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportCreateMutation, ReportCreateMutationVariables>;
export const PetraStartInformedConsentDocument = gql`
    mutation PetraStartInformedConsent($input: FillOutRequestCreateInput!) {
  fillOutRequestCreate(input: $input) {
    result {
      fillOutRequest {
        id
        responses {
          id
        }
      }
    }
    errors {
      path
      message
    }
  }
}
    `;
export type PetraStartInformedConsentMutationFn = ApolloReactCommon.MutationFunction<PetraStartInformedConsentMutation, PetraStartInformedConsentMutationVariables>;
export type PetraStartInformedConsentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PetraStartInformedConsentMutation, PetraStartInformedConsentMutationVariables>, 'mutation'>;

    export const PetraStartInformedConsentComponent = (props: PetraStartInformedConsentComponentProps) => (
      <ApolloReactComponents.Mutation<PetraStartInformedConsentMutation, PetraStartInformedConsentMutationVariables> mutation={PetraStartInformedConsentDocument} {...props} />
    );
    

/**
 * __usePetraStartInformedConsentMutation__
 *
 * To run a mutation, you first call `usePetraStartInformedConsentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePetraStartInformedConsentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [petraStartInformedConsentMutation, { data, loading, error }] = usePetraStartInformedConsentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePetraStartInformedConsentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PetraStartInformedConsentMutation, PetraStartInformedConsentMutationVariables>) {
        return ApolloReactHooks.useMutation<PetraStartInformedConsentMutation, PetraStartInformedConsentMutationVariables>(PetraStartInformedConsentDocument, baseOptions);
      }
export type PetraStartInformedConsentMutationHookResult = ReturnType<typeof usePetraStartInformedConsentMutation>;
export type PetraStartInformedConsentMutationResult = ApolloReactCommon.MutationResult<PetraStartInformedConsentMutation>;
export type PetraStartInformedConsentMutationOptions = ApolloReactCommon.BaseMutationOptions<PetraStartInformedConsentMutation, PetraStartInformedConsentMutationVariables>;
export const TeamsForOrganizationDocument = gql`
    query teamsForOrganization {
  currentOrganization {
    id
    teams(limit: 100000) {
      ...TeamFields
    }
  }
}
    ${TeamFieldsFragmentDoc}`;
export type TeamsForOrganizationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamsForOrganizationQuery, TeamsForOrganizationQueryVariables>, 'query'>;

    export const TeamsForOrganizationComponent = (props: TeamsForOrganizationComponentProps) => (
      <ApolloReactComponents.Query<TeamsForOrganizationQuery, TeamsForOrganizationQueryVariables> query={TeamsForOrganizationDocument} {...props} />
    );
    

/**
 * __useTeamsForOrganizationQuery__
 *
 * To run a query within a React component, call `useTeamsForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsForOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamsForOrganizationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamsForOrganizationQuery, TeamsForOrganizationQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamsForOrganizationQuery, TeamsForOrganizationQueryVariables>(TeamsForOrganizationDocument, baseOptions);
      }
export function useTeamsForOrganizationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamsForOrganizationQuery, TeamsForOrganizationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamsForOrganizationQuery, TeamsForOrganizationQueryVariables>(TeamsForOrganizationDocument, baseOptions);
        }
export type TeamsForOrganizationQueryHookResult = ReturnType<typeof useTeamsForOrganizationQuery>;
export type TeamsForOrganizationLazyQueryHookResult = ReturnType<typeof useTeamsForOrganizationLazyQuery>;
export type TeamsForOrganizationQueryResult = ApolloReactCommon.QueryResult<TeamsForOrganizationQuery, TeamsForOrganizationQueryVariables>;
export const CurrentTeamDocument = gql`
    query currentTeam {
  currentOrganization {
    id
    settings {
      requireTeamBeforeSelectingProtocol
    }
  }
  session {
    ...CurrentTeam
  }
}
    ${CurrentTeamFragmentDoc}`;
export type CurrentTeamComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CurrentTeamQuery, CurrentTeamQueryVariables>, 'query'>;

    export const CurrentTeamComponent = (props: CurrentTeamComponentProps) => (
      <ApolloReactComponents.Query<CurrentTeamQuery, CurrentTeamQueryVariables> query={CurrentTeamDocument} {...props} />
    );
    

/**
 * __useCurrentTeamQuery__
 *
 * To run a query within a React component, call `useCurrentTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentTeamQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentTeamQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentTeamQuery, CurrentTeamQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentTeamQuery, CurrentTeamQueryVariables>(CurrentTeamDocument, baseOptions);
      }
export function useCurrentTeamLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentTeamQuery, CurrentTeamQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentTeamQuery, CurrentTeamQueryVariables>(CurrentTeamDocument, baseOptions);
        }
export type CurrentTeamQueryHookResult = ReturnType<typeof useCurrentTeamQuery>;
export type CurrentTeamLazyQueryHookResult = ReturnType<typeof useCurrentTeamLazyQuery>;
export type CurrentTeamQueryResult = ApolloReactCommon.QueryResult<CurrentTeamQuery, CurrentTeamQueryVariables>;
export const SwitchTeamDocument = gql`
    mutation switchTeam($input: ProfessionalSetTeamInput!) {
  professionalSetTeam(input: $input) {
    result {
      session {
        ...CurrentTeam
      }
    }
  }
}
    ${CurrentTeamFragmentDoc}`;
export type SwitchTeamMutationFn = ApolloReactCommon.MutationFunction<SwitchTeamMutation, SwitchTeamMutationVariables>;
export type SwitchTeamComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SwitchTeamMutation, SwitchTeamMutationVariables>, 'mutation'>;

    export const SwitchTeamComponent = (props: SwitchTeamComponentProps) => (
      <ApolloReactComponents.Mutation<SwitchTeamMutation, SwitchTeamMutationVariables> mutation={SwitchTeamDocument} {...props} />
    );
    

/**
 * __useSwitchTeamMutation__
 *
 * To run a mutation, you first call `useSwitchTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchTeamMutation, { data, loading, error }] = useSwitchTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSwitchTeamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SwitchTeamMutation, SwitchTeamMutationVariables>) {
        return ApolloReactHooks.useMutation<SwitchTeamMutation, SwitchTeamMutationVariables>(SwitchTeamDocument, baseOptions);
      }
export type SwitchTeamMutationHookResult = ReturnType<typeof useSwitchTeamMutation>;
export type SwitchTeamMutationResult = ApolloReactCommon.MutationResult<SwitchTeamMutation>;
export type SwitchTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<SwitchTeamMutation, SwitchTeamMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  session {
    id
    loggedIn
    type
    user {
      ... on Professional {
        ...CurrentProfessionalFields
      }
    }
  }
}
    ${CurrentProfessionalFieldsFragmentDoc}`;
export type CurrentUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CurrentUserQuery, CurrentUserQueryVariables>, 'query'>;

    export const CurrentUserComponent = (props: CurrentUserComponentProps) => (
      <ApolloReactComponents.Query<CurrentUserQuery, CurrentUserQueryVariables> query={CurrentUserDocument} {...props} />
    );
    

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = ApolloReactCommon.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;