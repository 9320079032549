import * as React from "react";
import { arrayToSentence } from "logic/array";

interface FormControlGroupProps {
  label: string;
}

export const FormControlGroup: React.FunctionComponent<FormControlGroupProps> = (props) => {
  return <div className="control-group">
    <label className="control-label">{props.label}</label>
    <div className="controls">{props.children}</div>
  </div>;
}

export interface DurationFieldProps {
  id: string;
  onChange: (number: number) => void;
  value: number;
}

function humanize(str: number | string) {
  let value: number;

  if (typeof str === "number") { value = str; } else { value = parseInt(str) }
  const days = Math.floor(value / (60 * 60 * 24))
  value = value % (60 * 60 * 24)
  const hours = Math.floor(value / (60 * 60))
  value = value % (60 * 60)
  const minutes = Math.floor(value / 60)
  value = value % 60
  const seconds = value

  const segments: string[] = [];

  if (days > 0) { segments.push(I18n.pluralize(days, "datetime.distance_in_words.x_days")) }
  if (hours > 0) { segments.push(I18n.pluralize(hours, "datetime.distance_in_words.x_hours")) }
  if (minutes > 0) { segments.push(I18n.pluralize(minutes, "datetime.distance_in_words.x_minutes")) }
  if (seconds > 0 || segments.length == 0) { segments.push(I18n.pluralize(seconds, "datetime.distance_in_words.x_seconds")) }

  return arrayToSentence(segments);
}

export const SecondDurationField: React.FunctionComponent<DurationFieldProps> = (props) => {
  const handleChange: React.FormEventHandler<HTMLInputElement> =
    (event) => { props.onChange(parseInt(event.currentTarget.value)) };

  return <span className="durationfield" id={props.id}>
    <input type="number" value={props.value} onChange={handleChange} onBlur={handleChange} />
    <span className="durationfield-humanized">
      &nbsp;({humanize(props.value)})
    </span>
  </span>

};

export const DayDurationField: React.FunctionComponent<DurationFieldProps> = (props) => {
  const handleChange: React.FormEventHandler<HTMLInputElement> =
    (event) => { props.onChange(parseInt(event.currentTarget.value)) };

  return <span className="durationfield" id={props.id}>
    <input type="number" value={props.value} onChange={handleChange} onBlur={handleChange} />
    <span className="durationfield-humanized">
      &nbsp;({I18n.pluralize(props.value, "datetime.distance_in_words.x_days")})
    </span>
  </span>

};
