import { Duration, format, isSameDay, subDays } from "date-fns";
import { Timespan, TimespanUnitEnum } from "grapqhl";
// We need wrapper here around any format method, datefns has no convention for global locale.
// So we use window.dateFnsLocale
import { enUS, nl } from 'date-fns/locale';
const locales = { en: enUS, nl }

export type Timestamp = string | Date;

export const isMidnight = (timestamp: Timestamp) => {
  if (timestamp instanceof Date) {
    return timestamp.getHours() === 0 && timestamp.getMinutes() === 0 && timestamp.getSeconds() === 0;
  } else {
    return /00:00:00/.test(timestamp);
  }
};

export function hasTime(date: Date) {
  const total = date.getHours() + date.getMinutes() + date.getSeconds();
  return total !== 0;
}

export function ensureDate(timestamp: Timestamp | null): Date | null {
  if (timestamp instanceof Date) return timestamp;
  if (!timestamp) return null;
  return new Date(timestamp)
}

export function timespanToDuration(timespan: Timespan): Duration {
  switch (timespan.unit) {
    case TimespanUnitEnum.Months:
      return { months: timespan.value };
    case TimespanUnitEnum.Weeks:
      return { weeks: timespan.value };
    case TimespanUnitEnum.Days:
      return { days: timespan.value };
    case TimespanUnitEnum.Hours:
      return { hours: timespan.value };
    default:
      return {};
  }
}

export function todayOrYesterday(time: Date) {
  const now = new Date();
  if (isSameDay(now, time))
    return I18n.t('date.today')
  else if (isSameDay(subDays(now, 1), time))
    return I18n.t('date.yesterday')
  else
    return '' // everything beside today and yesterday should be presented as a specific date
}

// The function `dateFormat` is a proxy to `format` from date-fns, but translated
// to the current locale. This is useful when using date formats that differ per
// locale, e.g. the name of the month, the name of the weekday, etc.
export const localizedFormat = (date: Date, formatStr: string): string => {
  const locale = locales[window.dateFnsLocale];
  return format(date, formatStr, { locale: locale });
};
