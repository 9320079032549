import React from "react";
import { getValue } from "logic/ResponseWithCache";
import { ResultResponseWithCache, SingleResponse } from "../../common/Graphs";
import { LABEL_FIELDS } from "../../common/Constants";
import { localizedFormat } from "../../../../logic/date";
import { findDesign } from "../../common/Results";
import { BlockProps } from "./ResultsSummary";
import { Design } from "../../common/Schema";
import { maxBy } from "lodash";

// Renders a block like:
//      28 april
//   had je de beste
// stemming (opgewekt)
const BestMoodBlock: React.FunctionComponent<BlockProps> = ({ responsesWithCache }) => {
  if (responsesWithCache.length == 0) return null;
  const design = findDesign(responsesWithCache[0].questionnaire);
  if (!design) return null;

  const maxResponse = findBestMoodResponse(design, responsesWithCache);
  if (!maxResponse || !maxResponse.response.openFrom) return null;

  return (
    <div className="petra-well">
      <h2>{formatDate(maxResponse.response.openFrom)}</h2>
      <p>had je de beste stemming (opgewekt)</p>
    </div>
  );
};

const findBestMoodResponse = (
  design: Design,
  responsesWithCache: ResultResponseWithCache[]
): ResultResponseWithCache | undefined => {
  const labelFields = LABEL_FIELDS[design];
  if (!labelFields.opgewekt?.length) return undefined;

  return maxBy(responsesWithCache, (rc) => parseFloat(getValue(rc, labelFields.opgewekt) || "-1"));
};

const formatDate = (date: Date): string => {
  return localizedFormat(new Date(date), "d LLLL");
};

export default BestMoodBlock;
