/* eslint-disable @typescript-eslint/camelcase */
import { BaseChart, BaseSettings } from "./BaseChart";
import { tooltipFormatter } from "./OQ45Tooltip";

export interface ColumnChartSettings extends BaseSettings {
  y_axis_label: any;
  y_max: any;
  categories: any;
  series: any[];
  clinically_relevant_change: any[];
}

export class ColumnChart extends BaseChart<ColumnChartSettings> {
  chart() {
    return {
      type: "bar",
      defaultSeriesType: "bar",
      animation: false,
    };
  }

  series(): Highcharts.SeriesBarOptions[] {
    return this.settings.series.map((series, index) => {
      return {
        type: "bar",
        name: series.name,
        index: index,
        data: series.data,
        clinically_relevant_change: this.settings.clinically_relevant_change[index],
      };
    });
  }

  tooltip() {
    if (this.settings.series.length < 2) {
      return {};
    }

    return tooltipFormatter;
  }

  xAxis() {
    return Object.assign(super.xAxis(), {
      categories: this.settings.categories,
    });
  }

  yAxis() {
    return Object.assign(super.yAxis(), {
      title: {
        text: this.settings.y_axis_label,
      },
      max: this.settings.y_max,
    });
  }
}
