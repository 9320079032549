import React from "react";
import { BeepLimit, TimeOffset } from "../../common/Schema";
import { durationInPercent, formatTime } from "../../common/Time";
import { DESIGN_DESCRIPTION, FIXED, ONE_TIME_PER_DAY, preferredDesigns, SEMI_RANDOM } from "../../common/Constants";
import { MeasurementSchedule } from "../../common/MeasurementSchedule";
import { Key } from "../../common/Selections";
import classNames from "classnames";

interface Props {
  schedule: MeasurementSchedule;
  subGoals: Selections<Key>;
  beepLimits: BeepLimit;
}

// Render a preview of the diary study timeline and show which design has been selected.
// This component is included on the settings page (the `SelectSettings` component).
// The shown timeline shows the start and end time with a green dot, and the blocks with
// an orange line (for the semi-random design) or an orange dot (for the fixed design).
// If an end time is after midnight, the continuation of the line is shown at the start
// of the line (essentially, the timeline is split at the end).
// The text also highlights one example line or dot during when a measurement will be
// taken, and mentions the start and end time of this specific block. For this example,
// we use the second block in the timeline (modulo the number of blocks on the timeline).
const SchedulerPreview: React.FunctionComponent<Props> = (props) => {
  if (typeof props.schedule.endTime === "undefined") return <></>;

  // Since we now know that we have defined `props.schedule.endTime`, force the
  // type to Number (= TimeOffset) instead of Number | undefined.
  const scheduleEndTime: TimeOffset = props.schedule.endTime;

  // Draw a point or line segment on a timeline.
  const drawBlock = (blockStart, blockWidth, beep, colorOddIndices = false) => {
    // Give odd segments a different color so they stand out. Only do so for semi-random
    // design, because the fixed design uses dots instead of bars, and so they are already
    // separated enough, and the different colored dots just add confusion there.
    const klassNames = classNames("point", { "odd-index": colorOddIndices && beep % 2 });
    // If our block happens to cross the 100% line, draw two segments that together
    // have the same width. We do this by drawing the first segment to 100%, and then
    // whatever width is left is used to draw a second segment, starting from 0%.
    // We only need to do this once. For future beeps, note that the
    // `durationInPercent` function takes the modulo (24 hours) of the given time offset.
    if (parseFloat(blockStart) + parseFloat(blockWidth) >= 100.0) {
      return (
        <React.Fragment key={`${beep}-double`}>
          <div className={klassNames} style={{ marginLeft: blockStart, width: `${100.0 - parseFloat(blockStart)}%` }} />
          <div
            className={klassNames}
            style={{ marginLeft: "0%", width: `${parseFloat(blockWidth) - (100.0 - parseFloat(blockStart))}%` }}
          />
        </React.Fragment>
      );
    }
    // If the block does not cross the 100% line, simply draw a single line segment with the specified offset and width.
    return <div className={klassNames} key={beep} style={{ marginLeft: blockStart, width: blockWidth }} />;
  };

  // Render the timeline for a fixed or one time per day design.
  const renderFixedBeeps = () => {
    // Initialize beepArray as an array [0, 1, 2, ..., (measurementsPerDay - 1)].
    const beepArray = Array.from(Array(props.schedule.measurementsPerDay).keys());
    const blockLength = props.beepLimits.singleBlockDuration;
    return beepArray.map((beep) => {
      // We draw fixed beeps as dots with 1% width, centered on the measurement time.
      // The centering is achieved by moving the dot 0.5% to the left (the third argument
      // to `durationInPercent`).
      const blockStart = durationInPercent(props.schedule.startTime + blockLength * beep, 0.5);
      const blockWidth = "1%";
      // Draw the dot
      return drawBlock(blockStart, blockWidth, beep);
    });
  };

  // Render the preview for the fixed design. This includes the timeline as well as the line of text
  // that shows the daily start and end times as well as the times of an example measurement.
  const fixedPreview = () => {
    // For the example measurement of which we show the time, use the second measurement (modulo the
    // number of measurements per day).
    const startTime = formatTime(
      props.schedule.startTime + props.beepLimits.singleBlockDuration * (1 % props.schedule.measurementsPerDay)
    );
    let message =
      `De ${props.schedule.measurementsPerDay} metingen worden gedaan ${
        props.schedule.design === ONE_TIME_PER_DAY ? "op" : "tussen"
      } ${formatTime(props.schedule.startTime)} ` +
      `en ${formatTime(scheduleEndTime)}. Dat betekent dat elke bruine stip hieronder staat voor ` +
      `1 dagboekmeting. Bijvoorbeeld: om ${startTime} wordt een sms verstuurd.`;
    // Handle the case where we have a single measurement per day separately, because then the
    // "for example" text no longer applies (because there are no multiple measurements, just one).
    if (props.schedule.measurementsPerDay === 1) {
      message =
        `De meting is om ${formatTime(props.schedule.startTime)}. ` +
        "De bruine stip hieronder staat voor de dagboekmeting. " +
        `Namelijk: om ${startTime} wordt een sms verstuurd.`;
    }
    // The start and end points have a defined width of 2%, specified in petra.sass. They are shifted
    // 1% to the left so that their center represents the actual start and end times.
    // Note that we first draw the start and end points, and then we overlay that with the beeps.
    // This is because the beeps are smaller in width, and the first beep would otherwise not
    // be visible as it is being overlapped by the start point always.
    // (This is in contrast to the start and end point for the semi-random design, see below.)
    return (
      <>
        <div className="petra-hint">{message}</div>
        <div className="dagschema leeg">
          <div className="dagschema-wrapper">
            {props.schedule.measurementsPerDay !== 1 && (
              <>
                <div className="start point" style={{ marginLeft: durationInPercent(props.schedule.startTime, 1) }} />
                <div className="end point" style={{ marginLeft: durationInPercent(scheduleEndTime, 1) }} />
              </>
            )}
            {renderFixedBeeps()}
          </div>
        </div>
      </>
    );
  };

  // Render the timeline for a semi-random design.
  const renderRandomBeeps = () => {
    // Initialize beepArray as an array [0, 1, 2, ..., (measurementsPerDay - 1)].
    const beepArray = Array.from(Array(props.schedule.measurementsPerDay).keys());
    // The variable `blockLength` is defined as the length of a block. It doesn't need to take the
    // min time between beeps into account.
    const blockLength = props.beepLimits.singleBlockDuration;
    return beepArray.map((beep) => {
      // For the semi-random design, we draw line segments rather than points, and so rather than the
      // center of the segment, the start and end are most important. Here, we don't shift the
      // start or end of a line segment, so that they correspond exactly to the times defined by
      // the measurement schedule (in contrast to the drawing method for the fixed design, see above).
      const blockStart = durationInPercent(props.schedule.startTime + blockLength * beep);
      // While `blockLength` is used to determine the offset where a block should start,
      // `blockWidth` is the actual width of the block as the orange line segment should be drawn,
      // i.e., without the trailing space between blocks.
      const blockWidth = durationInPercent(props.beepLimits.singleBlockDuration);
      // Draw the block
      return drawBlock(blockStart, blockWidth, beep, true);
    });
  };

  // Render the preview for the semi-random design. This includes the timeline as well as the line of text
  // that shows the daily start and end times as well as the times of an example measurement.
  const randomPreview = () => {
    // The `blockLength` used for off-setting the blocks does not have to take time between beeps into account.
    const blockLength = props.beepLimits.singleBlockDuration;
    // For the example measurement of which we show the time, use the second measurement (modulo the
    // number of measurements per day).
    const startTime = props.schedule.startTime + blockLength * (1 % props.schedule.measurementsPerDay);
    const endTime = startTime + props.beepLimits.singleBlockDuration;
    const message =
      `De ${props.schedule.measurementsPerDay} metingen worden gedaan tussen ${formatTime(props.schedule.startTime)} ` +
      `en ${formatTime(scheduleEndTime)}. Dat betekent dat elke bruine balk hieronder staat voor ` +
      `1 dagboekmeting. Bijvoorbeeld: tussen ${formatTime(startTime)} en ${formatTime(
        endTime
      )} wordt 1 keer een sms verstuurd.`;
    // For the semi-random diary study design, we first plot the blocks and then overlay the start and end points
    // on top of them. This is so that they both remain visible,  because with our limits on measurements per day,
    // the start and end blocks of 2% width will always be much smaller than the line segments of a design of
    // 5, 7, or even 10 measurements per day.
    return (
      <>
        <div className="petra-hint">{message}</div>
        <div className="dagschema leeg">
          <div className="dagschema-wrapper">
            {renderRandomBeeps()}
            <div className="start point" style={{ marginLeft: durationInPercent(props.schedule.startTime, 1) }} />
            <div className="end point" style={{ marginLeft: durationInPercent(scheduleEndTime, 1) }} />
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="petra-field-with-example">
      <div className="petra-field">
        <h3>Gekozen schema voor dagboek</h3>
        <div className="disabled">
          <input type="radio" name="dagschema-radio" checked readOnly />
          <label className="radio">
            {DESIGN_DESCRIPTION[props.schedule.design]}{" "}
            {preferredDesigns(props.subGoals).includes(props.schedule.design) && (
              <label className="preferred">voorkeur</label>
            )}
          </label>
        </div>
        <p>
          <em>Op basis van de aantallen en tijden hierboven zie je hier hoe de dag er uit gaat zien.</em>
        </p>
      </div>
      <div className="petra-example">
        <p>Dat leidt tot het volgende dagschema</p>
        {[FIXED, ONE_TIME_PER_DAY].includes(props.schedule.design) && fixedPreview()}
        {props.schedule.design === SEMI_RANDOM && randomPreview()}
      </div>
    </div>
  );
};

export default SchedulerPreview;
