import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  InitialTableState,
  Table,
  useReactTable,
} from "@tanstack/react-table";
import { withRailsMountable } from "logic/rails_component";
import React from "react";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  initialState?: InitialTableState;
  columnVisibilityControls?: (table: Table<TData>) => React.ReactNode;
}

type ColumnMeta = undefined | null | { sticky?: boolean; enableGlobalFilter?: boolean };

export function DataTable<TData, TValue>(props: DataTableProps<TData, TValue>) {
  const { columns, data } = props;
  const table = useReactTable({
    data,
    columns,
    initialState: props.initialState,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getColumnCanGlobalFilter: (column) =>
      (column.getIsVisible() && (column.columnDef.meta as ColumnMeta)?.enableGlobalFilter) ?? false,
  });
  const globalFilterEnabled = !!table
    .getAllFlatColumns()
    .find((col) => (col.columnDef.meta as ColumnMeta)?.enableGlobalFilter);

  return (
    <div>
      <div className="flex">
        {globalFilterEnabled && (
          <div className="flex-1">
            <input
              type="search"
              placeholder="Filter"
              value={table.getState().globalFilter ?? ""}
              onChange={(e) => table.setGlobalFilter(e.target.value)}
            />
          </div>
        )}
        {props.columnVisibilityControls && props.columnVisibilityControls(table)}
      </div>

      <div className="overflow-x-scroll overscroll-x-none relative">
        <table className="max-w-none divide-y divide-gray-300 text-left" style={{ width: table.getCenterTotalSize() }}>
          <thead className="dividy-y divide-gray-300">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    style={{ width: header.getSize() }}
                    className={`group relative ${
                      (header.column.columnDef.meta as ColumnMeta)?.sticky
                        ? "sticky z-10 first:left-0 last:right-0 bg-gray-200 bg-opacity-90"
                        : ""
                    }`}
                    colSpan={header.colSpan}
                  >
                    <div onClick={header.column.getToggleSortingHandler()} className="px-2">
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}

                      {{
                        asc: " 🔼",
                        desc: " 🔽",
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>

                    <div
                      {...{
                        onMouseDown: header.getResizeHandler(),
                        onTouchStart: header.getResizeHandler(),
                        className: `absolute right-0 top-0 h-full w-[5px] cursor-col-resize select-none touch-none ${
                          header.column.getIsResizing() ? "bg-blue-500" : ""
                        } group-hover:bg-blue-500`,
                      }}
                    />
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="divide-y divide-gray-200">
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="group hover:bg-gray-100">
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={`px-2 ${
                      (cell.column.columnDef.meta as ColumnMeta)?.sticky
                        ? "sticky z-10 first:left-0 last:right-0 bg-gray-100 group-hover:bg-gray-200"
                        : ""
                    }`}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </div>
  );
}

export default withRailsMountable(DataTable);
