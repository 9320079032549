import classNames from "classnames";
import { Badge } from "components/ui/Badge";
import { isFuture } from "date-fns";
import { compact } from "lodash";
import { hasTime } from "logic/date";
import moment from "moment";
import React from "react";

interface Answer {
  id: number;
  isDuplicate: boolean;
  get: (key: string) => any;
  isCompletable: () => boolean;
  expired: () => boolean;
  fillOutTimeWindow: () => number;
  openFrom: () => Date;
  openTill: () => Date;
}

interface Props {
  model: Answer;
  onRemovePendingAnswer: any;
}

function expiredMessage(model: Answer) {
  if (model.expired()) {
    return I18n.t("epd_area.pending_answers.expired_message", {
      time_window: moment()
        .subtract(model.fillOutTimeWindow(), "seconds")
        .fromNow(true),
    });
  }
}

function timeWindowMessage(model: Answer): string | undefined {
  const format = (datetime: Date) => {
    if (datetime && hasTime(datetime)) {
      return I18n.l('time.formats.short_with_year', datetime);
    } else {
      return I18n.l('time.formats.date', datetime);
    }
  }

  const openFrom = model.openFrom();
  const openTill = model.openTill();

  if (openFrom && isFuture(openFrom)&& openTill) {
    return I18n.t('epd_area.pending_answers.open_from_till', { open_from: format(openFrom), open_till: format(openTill) });
  } else if (openFrom && isFuture(openFrom)) {
    return I18n.t('epd_area.pending_answers.open_from', {open_from: format(openFrom)});
  } else if (openTill && isFuture(openTill)) {
    return I18n.t('epd_area.pending_answers.open_till', {open_till: format(openTill)});
  }
}

function duplicateAnswerMessage(model: Answer) {
  if (model.isDuplicate) {
    return I18n.t("epd_area.pending_answers.duplicate_answer");
  }
}

export const AnswerBadge: React.FunctionComponent<Props> = ({ model, onRemovePendingAnswer }) => {
  const className = classNames("pending-answer", `answer-${model.id}`, {
    "pending-answer-important": model.expired(),
    "pending-answer-info": !model.expired() && model.isCompletable() && !model.isDuplicate,
    "pending-answer-future": !(model.expired() || (model.isCompletable() && !model.isDuplicate)),
  });

  const removePendingAnswer = () => onRemovePendingAnswer(model);
  const titles = [expiredMessage(model), timeWindowMessage(model), duplicateAnswerMessage(model)];
  const title = compact(titles).join(' ');

  return (
    <Badge className={className} title={title} onRemove={removePendingAnswer}>
      { model.get("questionnaire_name") }
    </Badge>
  );
};
